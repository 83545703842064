import {Component, Inject, OnInit, Optional} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
	selector: 'app-iframe',
	templateUrl: './iframe.component.html',
	styleUrls: ['./iframe.component.css'],
})
export class IFrameComponent implements OnInit {
	inputRoute: string = '';
	iframeUrl: SafeResourceUrl = '';

	constructor(
		public router: Router,
		public route: ActivatedRoute,
		public dialogRef: MatDialogRef<IFrameComponent>,
		@Optional() @Inject(MAT_DIALOG_DATA) public modalData: any,
		public sanitizer: DomSanitizer,
	) {
		this.inputRoute = this.modalData.route;
		this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.modalData.route);
	}

	ngOnInit() {
		window.addEventListener('message', (event) => {
			if (event?.data) {
				this.dialogRef.close(event.data);
			}
		});
	}
}
