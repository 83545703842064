import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {ArtifactInfo} from '../../data-models/core-model.model';
import {InstanceManagerResult} from './instance-model';

@Injectable({
	providedIn: 'root',
})
export class InstanceService {
	defaultHttpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		}),
	};

	constructor(private http: HttpClient) {}

	getInstanceList(all = false): Observable<ArtifactInfo[]> {
		if (all) {
			return this.http.get<ArtifactInfo[]>(`${environment.apiHost + environment.apiUrl}/instance/allartifacts`).pipe(
				map((instances: any[]) => {
					return instances.map((instance) => new ArtifactInfo(instance)).sort(ArtifactInfo.sort);
				}),
			);
		} else {
			return this.http.get<ArtifactInfo[]>(`${environment.apiHost + environment.apiUrl}/instance/artifacts`).pipe(
				map((instances: any[]) => {
					return instances.map((instance) => new ArtifactInfo(instance)).sort(ArtifactInfo.sort);
				}),
			);
		}
	}

	getInstanceById(id: any): Observable<InstanceManagerResult> {
		return this.http.get<any>(`${environment.apiHost + environment.apiUrl}/instance/${id}`).pipe(map((res) => new InstanceManagerResult(res)));
	}

	addInstance(instanceReq: any): Observable<InstanceManagerResult> {
		return this.http.post(`${environment.apiHost + environment.apiUrl}/instance`, instanceReq).pipe(map((res) => new InstanceManagerResult(res)));
	}

	releaseInstance(id, version) {
		return this.http.post(`${environment.apiHost + environment.apiUrl}/instance/${id}:${version}/release`, null, this.defaultHttpOptions);
	}

	updateInstance(instanceReq: any): Observable<InstanceManagerResult> {
		return this.http.put(`${environment.apiHost + environment.apiUrl}/instance`, instanceReq).pipe(map((res) => new InstanceManagerResult(res)));
	}

	deleteInstance(id: string) {
		return this.http.delete(`${environment.apiHost + environment.apiUrl}/instance/${id}`);
	}

	importInstance(obj) {
		if (obj.info && obj.info.identifier && obj.info.identifier.version) {
			obj.info.version = obj.info.identifier.version;
			delete obj.info['identifier'];
		}

		return this.addInstance(obj);
	}
}
