import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {MessageResponseService} from './message-response.service';
import {GlobalService} from './global.service';
import {Button} from '../shared/generic-dialog/generic-dialog.component';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
	constructor(
		private msgSrv: MessageResponseService,
		public router: Router,
		public globalSrv: GlobalService,
		public translate: TranslateService,
	) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		request = request.clone({withCredentials: true});

		if (!(request.body instanceof FormData)) {
			if (!request.headers.has('Content-Type') && !request.headers.has('size')) {
				request = request.clone({headers: request.headers.set('Content-Type', 'application/json')});
				request = request.clone({headers: request.headers.set('Accept', 'application/json')});
			}
		}

		return next.handle(request).pipe(
			catchError((err) => {
				if (!request.headers.get('skipInterceptor')) {
					this.msgSrv.onLoading.next({command: 'stop'});
				}

				if (this.globalSrv.debugMode) {
					console.log('ERROR', err);
				}

				switch (err.status) {
					case 200:
						console.log(err);
						break;

					case 400:
						if (err.url.indexOf('instance') > 0) {
							let message = '';
							if (err.error instanceof Array) {
								err.error.forEach((e) => {
									Object.keys(e).forEach((key) => {
										message = message + 'In ' + key + ' configuration: \n';
										e[key].forEach((p) => {
											if (p['propertyPath'] && p['failedConstraints']) {
												message = message + '  ' + p.propertyPath + ' ';
												p.failedConstraints.forEach((f) => {
													message = message + f.key;
												});
												message = message + '\n';
											}
										});
									});
								});
								this.msgSrv.showError(message);
							} else {
								this.msgSrv.showError(this.translate.instant('i18n.errorMsg.defaultError'));
							}
						} else {
							if (err.error) {
								this.msgSrv.showError(err.error);
							} else {
								this.msgSrv.showError(this.translate.instant('i18n.errorMsg.defaultError'));
							}
						}
						break;
					case 401:
						if (request.headers.get('skipInterceptor')) {
							return next.handle(request);
						} else {
							this.msgSrv.loginPopup();
						}
						break;

					case 403:
						this.router.navigate(['home']);
						break;

					case 404:
						break;

					case 422:
						// ignore and handle inside component // mapping rule compilation
						return throwError(err.error);

					case 405:
					case 409:
					case 412:
					case 500:
						if (request.headers.get('skipInterceptor')) {
							return next.handle(request);
						} else {
							if (typeof err.error === 'string') {
								this.msgSrv.showError(err.error);
							} else if (err.error?.usedBy) {
								let message = 'Artifact used by: \n';
								err.error.usedBy.forEach((u) => {
									message = message + u.artifactType + ': ' + u.externalId + '\n';
								});
								this.msgSrv.showError(message);
							} else {
								if (err.error) {
									err.error.failure ? this.msgSrv.showError(this.translate.instant(err.error.failure)) : this.msgSrv.showError(this.translate.instant('i18n.errorMsg.defaultError'));
								} else {
									return next.handle(request);
								}
							}
						}
						break;

					default:
						if (request.headers.get('skipInterceptor')) {
							return next.handle(request);
						} else {
							this.msgSrv.showError(this.translate.instant('i18n.errorMsg.defaultError'));
						}
						break;
				}

				const error = typeof err === 'object' && Object.keys(err).length ? err.error || err.error?.message || err.statusText : err;
				return throwError(error);
			}),
		);
	}
}
