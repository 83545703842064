import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {CredentialManagerResult} from './credential-management-model';

@Injectable()
export class CredentialManagementService {
	constructor(private http: HttpClient) {}

	credentialManagementTypes = [
		{id: 'password', label: 'Password'},
		{id: 'token', label: 'Token'},
		{id: 'usernameandpassword', label: 'Username and password'},
	];

	public getCredentials(type?: string): Observable<CredentialManagerResult[]> {
		//TODO = implement filtering so we can get only password or usernameAndPassword credential types for credential selector dropdowns

		return this.http.get<CredentialManagerResult[]>(`${environment.apiHost + environment.apiUrl}/credentials`, {withCredentials: true});
	}

	public addCredential(credential: CredentialManagerResult) {
		return this.http.post(`${environment.apiHost + environment.apiUrl}/credentials`, credential.serialize(), {withCredentials: true});
	}

	public getCredentialById(id) {
		return this.http.get(`${environment.apiHost + environment.apiUrl}/credentials/${id}`, {withCredentials: true});
	}

	public getCredentialsInfo(): Observable<CredentialManagerResult[]> {
		return this.http.get<CredentialManagerResult[]>(`${environment.apiHost + environment.apiUrl}/credentials/infos`, {withCredentials: true});
	}

	public updateCredential(credential) {
		return this.http.put(`${environment.apiHost + environment.apiUrl}/credentials/${credential.id}`, credential.serialize(), {withCredentials: true});
	}

	public deleteCredential(id: string) {
		return this.http.delete<CredentialManagerResult>(`${environment.apiHost + environment.apiUrl}/credentials/${id}`, {withCredentials: true});
	}
}
