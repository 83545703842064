import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {I40FlatMember} from '../../../types';
import {JSONSchemaProperty, SchemaLayer} from '../../../../../data-models/json-schema.model';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {I40TreeChannelService} from '../../i40-tree-channel.service';
import {AuthService} from '../../../../../core-services/auth.service';
import {GlobalService} from '../../../../../core-services/global.service';

@Component({
	selector: 'app-array',
	templateUrl: './array.component.html',
	styleUrls: ['./array.component.css'],
})
export class ArrayComponent implements OnInit, OnChanges {
	@Input() form: UntypedFormArray;
	@Input() selectedObject: I40FlatMember;
	@Input() layer: string;
	@Input() property: JSONSchemaProperty;
	@Input() items?: JSONSchemaProperty;

	@Input() savedValue: any;
	@Input() emptyFormValue: any;

	@Output() visible: EventEmitter<boolean> = new EventEmitter<boolean>();

	checked = false;

	constructor(
		public treeSrv: I40TreeChannelService,
		public authSrv: AuthService,
		public globalSrv: GlobalService,
	) {}

	ngOnInit(): void {}

	ngOnChanges() {
		this.initForm();
	}

	returnZero() {
		return 0;
	}

	initForm() {
		this.checked = this.form.controls.length > 0;
	}

	onToggleArray(event: MatCheckboxChange) {
		if (event.checked) {
			this.addElement();
			this.checked = true;
		} else {
			this.form.clear();
			this.checked = false;
		}
	}

	addElement() {
		switch (this.items.type) {
			case 'object':
				const fg = new UntypedFormGroup({});
				SchemaLayer.generateForm(this.items.properties, {}, fg, []);
				this.form.push(fg);
				break;
			case 'string':
				const fc = new UntypedFormControl(this.items.default ?? '');
				this.form.push(fc);
				break;
		}
	}

	removeElement(i) {
		this.form.removeAt(i);
	}

	subgroupVisible(visible: boolean) {
		this.visible.emit(visible);
	}

	getFormControl(index): UntypedFormControl {
		return this.form.at(index) as UntypedFormControl;
	}

	getFormGroup(abstractFormControl): UntypedFormGroup {
		return abstractFormControl as UntypedFormGroup;
	}
}
