import {ChangeDetectorRef, Component, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {I40TreeMemberEditorComponent} from '../i40-tree-member-editor-wrapper';
import {I40TreeCrudService} from '../../i40-tree-crud.service';
import {AuthService} from '../../../../../core-services/auth.service';
import {ValidatorService} from '../../../../../core-services/validator.service';
import {MessageResponseService} from '../../../../../core-services/message-response.service';
import {TranslateService} from '@ngx-translate/core';
import {Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {GlobalService} from '../../../../../core-services/global.service';

@Component({
	selector: 'app-i40-tree-command-reply-editor',
	templateUrl: './i40-tree-command-reply-editor.component.html',
	styleUrls: ['./i40-tree-command-reply-editor.component.css', '../i40-tree-member-editor-wrapper.scss'],
})
export class I40TreeCommandReplyEditorComponent extends I40TreeMemberEditorComponent implements OnInit, OnChanges, OnDestroy {
	constructor(
		tsrv: I40TreeCrudService,
		authSrv: AuthService,
		validatorSrv: ValidatorService,
		msgSrv: MessageResponseService,
		translate: TranslateService,
		router: Router,
		globalSrv: GlobalService,
		cdRef: ChangeDetectorRef,
	) {
		super(tsrv, authSrv, validatorSrv, msgSrv, translate, router, globalSrv, cdRef);
	}

	getAllowedMemberTypes() {
		const allowedMemberTypes = [];

		this.tsrv.modelMemberTypes.forEach((m, k) => {
			if (m.type !== 'Command') {
				allowedMemberTypes.push(k);
			}
		});

		this.allowedMemberTypes = allowedMemberTypes.sort();
	}

	addValidation() {
		this.form.get('id').setValidators([Validators.required, this.validatorSrv.validateMemberId(), this.validatorSrv.validateUniqueSiblings(this.selectedObject)]);
		this.form
			.get('typeName')
			.setValidators([
				Validators.required,
				this.validatorSrv.validateMemberType(this.selectedObject),
				this.validatorSrv.validateWhiteSpace(),
				this.validatorSrv.invalidMemberTypes(this.tsrv.simpleMemberTypes),
			]);
	}
}
