<as-split [direction]="direction" class="noscroll" fxLayout.xs="column" class="main-ui">
	<as-split-area id="leftModelTreeView_splitArea" [size]="40" class="noscroll">
		<mat-card appearance="outlined" fxFlex style="padding-top: 0">
			<div *ngIf="loaded" style="height: 100%; width: 100%; overflow: hidden">
				<div class="tree-header" [ngClass]="{'search-visible': searchVisible}">
					<mat-form-field *ngIf="searchVisible" class="search-wrapper">
						<input matInput type="text" placeholder="{{ 'i18n.searchModel' | translate }}" [(ngModel)]="searchString" (ngModelChange)="onSearch()" #searchField="matInput" />
						<button mat-button *ngIf="searchString" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
							<mat-icon>close</mat-icon>
						</button>
					</mat-form-field>
					<button mat-icon-button matTooltip="{{ 'i18n.searchModel' | translate }}" (click)="toggleFilter()"><mat-icon>search</mat-icon></button>
					<button mat-icon-button matTooltip="{{ 'i18n.toggle' | translate }}" (click)="tsrv.toggleCollapseAll()"><mat-icon>unfold_more</mat-icon></button>
				</div>
				<div [ngStyle]="{'padding-top': searchVisible ? '50px' : '15px'}" style="height: 100%; transition: padding-top ease 0.3s">
					<cdk-virtual-scroll-viewport
						itemSize="24"
						[ngStyle]="{height: searchVisible ? 'calc(100% - 50px)' : 'calc(100% - 15px)'}"
						class="tree-wrapper"
						cdkDropList
						[cdkDropListDisabled]="!enableDrop || searchString.length > 0"
						(cdkDropListDropped)="onNodeDrop($event)"
					>
						<i40-tree-member
							*cdkVirtualFor="let member of tsrv.visibleFlatMembers; trackBy: getIndex"
							[member]="member"
							(nodeClick)="nodeClick($event, member)"
							[ngClass]="{selected: selectedObject ? member._uniqueIndex === selectedObject._uniqueIndex : false}"
							cdkDrag
							[cdkDragData]="member"
							[cdkDragDisabled]="!enableDrag || !globalSrv.canEdit(tsrv.inputModel)"
							(contextmenu)="openMenu($event, member)"
							[attr.data-path]="member._path.join('/')"
							[attr.data-fullPath]="member._fullPath.join('/')"
							[attr.data-memberPath]="member._memberPath.join('/')"
						>
						</i40-tree-member>
					</cdk-virtual-scroll-viewport>
				</div>
			</div>
		</mat-card>
	</as-split-area>
	<as-split-area id="rightModelEdit_splitArea" [size]="60" style="overflow: hidden">
		<mat-card appearance="outlined" fxFlex>
			<i40-tree-member-editor-wrapper #treeEditorWrapper *ngIf="loaded" [selectedObject]="selectedObject"></i40-tree-member-editor-wrapper>
		</mat-card>
	</as-split-area>
</as-split>

<div style="visibility: hidden; position: fixed" [style.left]="memberMenuPosition.x" [style.top]="memberMenuPosition.y" [matMenuTriggerFor]="memberMenu"></div>

<mat-menu #memberMenu="matMenu">
	<ng-template matMenuContent let-member="member" let-data="data">
		<section class="context-menu">
			<div class="header-item"><span class="{{ member.definitionType.toLowerCase() }}-icon" style="margin-right: 10px"></span>{{ member.id }}</div>
			<hr />
			<div
				id="add_node_button"
				mat-menu-item
				class="menu-item"
				*ngIf="member._memberType.category !== 'simple' && !['Command'].includes(member.definitionType)"
				[matMenuTriggerFor]="memberTypesMenu"
				[matMenuTriggerData]="data"
			>
				<mat-icon>add</mat-icon>Add Node
			</div>
			<div
				id="copy_button"
				mat-menu-item
				class="menu-item"
				*ngIf="!['CommandParameters', 'CommandReply'].includes(member.definitionType)"
				(click)="menuCopyNode(member)"
				(keydown.enter)="menuCopyNode(member)"
			>
				<mat-icon>content_copy</mat-icon>Copy
			</div>
			<div
				id="cut_button"
				mat-menu-item
				class="menu-item"
				*ngIf="!['CommandParameters', 'CommandReply', 'Model'].includes(member.definitionType)"
				(click)="menuCutNode(member)"
				(keydown.enter)="menuCutNode(member)"
			>
				<mat-icon>content_cut</mat-icon>Cut
			</div>
			<div
				id="paste_button"
				mat-menu-item
				class="menu-item"
				*ngIf="clipboardObject && member._memberType.category !== 'simple' && !['Command', 'Array'].includes(member.definitionType)"
				(click)="menuPasteNode(member)"
				(keydown.enter)="menuPasteNode(member)"
			>
				<mat-icon>content_paste</mat-icon>Paste
			</div>
			<div
				id="remove_button"
				mat-menu-item
				class="menu-item"
				*ngIf="!['CommandParameters', 'CommandReply', 'Model'].includes(member.definitionType)"
				(click)="menuDeteleNode(member)"
				(keydown.enter)="menuDeteleNode(member)"
			>
				<mat-icon>delete</mat-icon>Remove
			</div>
		</section>
	</ng-template>
</mat-menu>

<mat-menu #memberTypesMenu="matMenu">
	<ng-template matMenuContent let-member="member">
		<section class="context-menu">
			<div
				mat-menu-item
				class="menu-item"
				id="{{ type.toLowerCase() }}_menu_item"
				*ngFor="let type of getAvailableMemberTypes(member)"
				(click)="menuAddNode(member, type)"
				(keydown.enter)="menuAddNode(member, type)"
			>
				<span class="{{ type.toLowerCase() }}-icon"></span>
				&nbsp;&nbsp;{{ type }}
			</div>
		</section>
	</ng-template>
</mat-menu>
