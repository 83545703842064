import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ArtifactInfo} from 'src/app/data-models/core-model.model';
import {environment} from '../../../environments/environment';
import {ModelService} from '../information-models/model.service';
import {ChannelInfo, ChannelManagerResult} from './channel.model';

@Injectable()
export class ChannelService {
	defaultHttpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		}),
	};

	constructor(
		private http: HttpClient,
		private modelService: ModelService,
	) {}

	getChannelInfos(all = false): Observable<ChannelInfo[]> {
		if (all) {
			return this.http.get<ChannelInfo[]>(`${environment.apiHost + environment.apiUrl}/channel/allinfos`).pipe(map((result) => result.map((c) => new ChannelInfo(c)).sort(ArtifactInfo.sort)));
		} else {
			return this.http.get<ChannelInfo[]>(`${environment.apiHost + environment.apiUrl}/channel/info`).pipe(map((result) => result.map((c) => new ChannelInfo(c)).sort(ArtifactInfo.sort)));
		}
	}

	getChannel(id: string): Observable<ChannelManagerResult> {
		return this.http.get<any>(`${environment.apiHost + environment.apiUrl}/channel/${id}`).pipe(
			map((channel) => {
				return new ChannelManagerResult(channel);
			}),
		);
	}

	getDefaultConfiguration(type: string) {
		return this.http.get(`${environment.apiHost + environment.apiUrl}/channel/type/${type}/configuration/default`);
	}

	getConfigurationSchema(type) {
		return this.http.get<any>(`${environment.apiHost + environment.apiUrl}/channel/type/${type}/configuration/schema`);
	}

	releaseChannel(id, version) {
		return this.http.post(`${environment.apiHost + environment.apiUrl}/channel/${id}:${version}/release`, null, this.defaultHttpOptions);
	}

	addChannel(channel: ChannelManagerResult) {
		// const req = channel.serialize();

		return this.http.post(`${environment.apiHost + environment.apiUrl}/channel`, channel).pipe(
			map((channel) => {
				return new ChannelManagerResult(channel);
			}),
		);
	}

	updateChannel(channel: any) {
		return this.http.put(`${environment.apiHost + environment.apiUrl}/channel`, channel).pipe(
			map((channel) => {
				return new ChannelManagerResult(channel);
			}),
		);
	}

	deleteChannel(id) {
		return this.http.delete(`${environment.apiHost + environment.apiUrl}/channel/${id}`);
	}

	importChannel(obj) {
		if (obj.info && obj.info.identifier && obj.info.identifier.version) {
			obj.info.version = obj.info.identifier.version;
			delete obj.info['identifier'];
		}
		return this.addChannel(obj);
	}
}
