<div fxLayout="column" fxFill style="flex: 1 0 auto">
	<div fxFlex="0 0 auto">
		<mat-toolbar fxLayout="row" class="small_toolbar">
			<mat-icon>{{ mat_icon_type }}</mat-icon>
			<span class="push-left-sm" fxFlex>
				<span class="md-title">{{ mat_title | translate }}</span>
			</span>
			<button
				id="save_channel_button"
				color="accent"
				mat-icon-button
				matTooltip="{{ 'i18n.save' | translate }}"
				[disabled]="!(pluginForm_update.valid && pluginForm_update.dirty)"
				*ngIf="globalSrv.canEdit()"
				(click)="run()"
			>
				<mat-icon>play_arrow</mat-icon>
			</button>
			<button id="cancel_button" color="warn" mat-icon-button matTooltip="{{ 'i18n.close' | translate }}" (click)="close()">
				<mat-icon>cancel</mat-icon>
			</button>
		</mat-toolbar>
		<mat-divider></mat-divider>
	</div>

	<div fxLayout="column" style="overflow: auto; flex: 1 1 100%">
		<div fxLayout="row" class="main-ui" style="flex: 1 0 auto; overflow: hidden">
			<mat-card appearance="outlined" fxFlex="100" fxLayout="column" class="form-card">
				<b>Selected Model:</b>
				<div>{{ plugin_update.getExternalId() }}</div>

				<mat-toolbar class="smaller_toolbar" fxLayout="row" style="padding-left: 0 !important; padding-right: 0 !important; flex: 0 0 auto">
					<span class="push-left-sm" fxFlex
						><span mat-subheader class="typography" style="padding-left: 6px">{{ 'i18n.items' | translate }}</span></span
					>
					<button id="update_add_item_button" *ngIf="globalSrv.canEdit()" mat-icon-button color="accent" (click)="addItem()" matTooltip="{{ 'i18n.addItem' | translate }}">
						<mat-icon>add</mat-icon>
					</button>
				</mat-toolbar>

				<div *ngFor="let item of plugin_update.items; let i = index" style="display: flex; flex-direction: column; margin-top: 15px; margin-bottom: 15px">
					<mat-form-field>
						<mat-select id="update_item_type_select" [formControl]="getFormControl(plugin_update.items[i].getFormControl('definitionType'))" placeholder="{{ 'i18n.type' | translate }}" required>
							<mat-option *ngFor="let type of definitionTypes" [value]="type">{{ type }}</mat-option>
						</mat-select>
					</mat-form-field>

					<mat-form-field fxFlex="">
						<input id="update_item_name_input" matInput placeholder="{{ 'i18n.name' | translate }}" [formControl]="getFormControl(plugin_update.items[i].getFormControl('definitionName'))" required />
					</mat-form-field>

					<span>JSON Input:</span>
					<ngx-codemirror
						id="update_jsonInputEditor_editor"
						[formControl]="getFormControl(plugin_update.items[i].getFormControl('inputJson'))"
						[options]="{
							lineNumbers: true,
							matchBrackets: true,
							autoCloseBrackets: true,
							styleActiveLine: true,
							mode: 'application/json',
							theme: themeSrv.selectedTheme === 'light-theme' ? 'eclipse' : 'monokai'
						}"
						style="height: 300px; overflow: auto"
					>
					</ngx-codemirror>

					<ng-container *ngIf="item.definitionType === 'Command'">
						<span>Reply JSON Input:</span>
						<ngx-codemirror
							id="update_replyJsonInputEditor_editor"
							[formControl]="getFormControl(plugin_update.items[i].getFormControl('replyInputJson'))"
							[options]="{
								lineNumbers: true,
								matchBrackets: true,
								autoCloseBrackets: true,
								styleActiveLine: true,
								mode: 'application/json',
								theme: themeSrv.selectedTheme === 'light-theme' ? 'eclipse' : 'monokai'
							}"
							style="height: 300px; overflow: auto"
						>
						</ngx-codemirror>
					</ng-container>
					<button mat-raised-button [color]="'primary'" (click)="removeItem(i)" style="margin-top: 4px">{{ 'i18n.delete' | translate }}</button>
				</div>

				<mat-expansion-panel expanded="false" style="margin-bottom: 8px" *ngIf="globalSrv.debugMode">
					<mat-expansion-panel-header>DEBUG UPDATE FORM</mat-expansion-panel-header>
					<b>Dirty:</b> {{ pluginForm_update.dirty ? 'TRUE' : 'FALSE' }} / <b>Valid:</b> {{ pluginForm_update.valid ? 'TRUE' : 'FALSE' }}
					<pre>{{ globalSrv.circularJSONParse(pluginForm_update.value) | json }}</pre>
				</mat-expansion-panel>
			</mat-card>
		</div>
	</div>
</div>
