import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {map, Observable} from 'rxjs';
import {EnvironmentVariableDTO} from './environment-variables-model';

@Injectable()
export class EnvironmentVariablesService {
	path: string = 'env_variables';

	constructor(private http: HttpClient) {}

	public add(ev: EnvironmentVariableDTO) {
		return this.http.post(`${environment.apiHost + environment.apiUrl}/${this.path}`, ev.serialize(), {
			withCredentials: true,
			headers: new HttpHeaders({
				skipInterceptor: 'true',
			}),
		});
	}

	public getById(id) {
		return this.http.get(`${environment.apiHost + environment.apiUrl}/${this.path}/${id}`, {
			withCredentials: true,
			headers: new HttpHeaders({
				skipInterceptor: 'true',
			}),
		});
		//.pipe(filter((data:EnvironmentVariableDTO) => data.id == id),toArray())
		//.pipe(map((data:EnvironmentVariableDTO[]) => data.filter(ev => ev.id == id)));
	}

	public getInfo(): Observable<EnvironmentVariableDTO[]> {
		return this.http.get<EnvironmentVariableDTO[]>(`${environment.apiHost + environment.apiUrl}/${this.path}`, {withCredentials: true});
	}

	public update(ev) {
		return this.http.put(`${environment.apiHost + environment.apiUrl}/${this.path}`, ev.serialize(), {withCredentials: true}).pipe(map((r) => {}));
	}

	public delete(id: string) {
		return this.http.delete<EnvironmentVariableDTO>(`${environment.apiHost + environment.apiUrl}/${this.path}/${id}`, {withCredentials: true});
	}
}
