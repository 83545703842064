<div fxFlex="1 0 auto" fxLayout="column">
	<div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="flex-start center">
		<div fxFlex="30" fxLayoutAlign="start center" style="padding-top: 10px !important">
			<h4>{{ modelChannel.modelInfo.name }}</h4>
		</div>
		<div fxFlex>
			<mat-form-field fxFlex="1 0 auto" style="width: 100%">
				<mat-select
					id="channel_select"
					[formControl]="
						modelChannel.channel.info._formGroup.toFormControl(
							[this.validatorSrv.validateArtifactInfo()],
							!this.globalSrv.canEdit(this.dataSrv.deviceTypeResult) || !this.dataSrv.editable || this.loadingChannel
						)
					"
					required
					[compareWith]="compareChannels"
					[placeholder]="!modelChannel.channel.info.identifier.id ? 'Channel' : ''"
					class="channel_select"
					(selectionChange)="channelSelectionChange($event)"
				>
					<mat-option *ngFor="let channel of channelInfoList" [value]="channel" class="channel_option">
						{{ channel.getExternalId() }}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<button
			id="config_button"
			mat-icon-button
			color="accent"
			(click)="showConfigurationClick()"
			matTooltip="{{ 'i18n.settings' | translate }}"
			class="settings_button"
			[disabled]="loadingChannel || !modelChannel.channel.info.identifier.id"
		>
			<mat-icon>{{ globalSrv.canEdit(dataSrv.instance ?? dataSrv.deviceTypeResult) ? 'settings' : 'visibility' }}</mat-icon>
		</button>
	</div>
</div>
