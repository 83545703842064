<form fxFlex fxLayout="column" [formGroup]="form" (ngSubmit)="onLogin()" class="mat-primary-bg login-popup-form">
	<mat-card-content fxFlex fxLayout="column">
		<div fxFlex fxLayoutAlign="center center" fxLayout="column">
			<img src="../assets/amorph-pro-{{ theme.selectedTheme === 'light-theme' ? 'light' : 'dark' }}.png" width="40%" />
			<span fxLayout="row" style="font-size: 1.9em; margin-bottom: 1.2%; font-weight: 100" fxLayoutAlign="center center">SMART<span style="font-weight: bold">UNIFIER</span></span>
		</div>

		<div fxFlex fxLayoutAlign="center center" fxLayout="column" style="text-align: center; font-size: 1.2em; font-weight: 300; color: var(--text-color-primary)">
			Your current login session has expired. Please re-enter your login credentials and continue where you left off
		</div>

		<mat-form-field class="example-full-width" fxFlex>
			<input id="user_id_input" matInput placeholder="{{ 'i18n.userId' | translate }}" formControlName="userId" autofocus />
		</mat-form-field>

		<mat-form-field class="example-full-width" fxFlex>
			<input id="password_input" type="password" matInput placeholder="{{ 'i18n.password' | translate }}" formControlName="password" />
		</mat-form-field>
	</mat-card-content>

	<mat-card-actions fxFlex="20" fxLayout="row" fxLayoutAlign="center center" style="background-color: transparent">
		<button id="login_button" mat-button color="accent" style="width: 100%">{{ 'i18n.login' | translate }}</button>
	</mat-card-actions>
</form>
