<div fxLayout="column" fxFill>
	<div fxFlex="0 0 auto">
		<mat-toolbar fxLayout="row" class="small_toolbar">
			<mat-icon>settings_input_component</mat-icon>
			<span class="push-left-sm" fxFlex
				><span id="page-title" class="md-title"
					><b>Channel Configuration: {{ channelManagerResult.info.getExternalId() ? channelManagerResult.info.getExternalId() : ('i18n.addChannelConfiguration' | translate) }}</b></span
				></span
			>
			<button id="help-button" mat-icon-button matTooltip="Help" color="accent" (click)="openChannelHelp()">
				<mat-icon>help_outline</mat-icon>
			</button>
			<button *ngIf="globalSrv.canEdit(tsrv.rootParent)" mat-icon-button id="editor_button" (click)="toggleEditor()">
				<span class="mat-typography" fxLayoutAlign="center center" style="font-weight: bold" matTooltip="{{ 'i18n.editCode' | translate }}"><mat-icon>code</mat-icon></span>
			</button>
			<button *ngIf="!showJsonEditor && globalSrv.canEdit(tsrv.rootParent)" id="apply_button" class="btnApply" mat-icon-button (click)="apply()" [disabled]="formDisabled()" color="accent">
				<mat-icon matTooltip="{{ 'i18n.apply' | translate }}">done</mat-icon>
			</button>
			<button id="close_config_button" color="accent" matTooltip="{{ 'i18n.close' | translate }}" mat-icon-button (click)="close()">
				<mat-icon>close</mat-icon>
			</button>
		</mat-toolbar>
		<mat-divider></mat-divider>
	</div>

	<div class="layout" style="display: flex; flex-direction: column; flex: 1 1 auto; width: calc(100% - 20px); height: calc(100% - 85px)">
		<as-split [direction]="direction" fxLayout.xs="column" class="main-ui noscroll">
			<as-split-area id="leftModelTreeView_splitArea" [size]="40" class="noscroll">
				<mat-card appearance="outlined" fxFlex style="padding-top: 0">
					<div *ngIf="loaded" style="height: 100%; width: 100%; overflow: hidden">
						<div class="tree-header" [ngClass]="{'search-visible': searchVisible}">
							<mat-form-field floatLabel="auto" *ngIf="searchVisible" class="search-wrapper">
								<input matInput type="text" placeholder="{{ 'i18n.searchModel' | translate }}" [(ngModel)]="searchString" (ngModelChange)="onSearch()" #searchField="matInput" />
								<button mat-button *ngIf="searchString" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
									<mat-icon>close</mat-icon>
								</button>
							</mat-form-field>
							<button mat-icon-button matTooltip="{{ 'i18n.searchModel' | translate }}" (click)="toggleFilter()">
								<mat-icon>search</mat-icon>
							</button>
							<button mat-icon-button matTooltip="{{ 'i18n.toggle' | translate }}" (click)="tsrv.toggleCollapseAll()">
								<mat-icon>unfold_more</mat-icon>
							</button>
						</div>
						<div [ngStyle]="{'padding-top': searchVisible ? '50px' : '15px'}" style="height: 100%; transition: padding-top ease 0.3s">
							<cdk-virtual-scroll-viewport itemSize="24" [ngStyle]="{height: searchVisible ? 'calc(100% - 50px)' : 'calc(100% - 15px)'}" class="tree-wrapper">
								<i40-tree-member
									*cdkVirtualFor="let member of tsrv.visibleFlatMembers; trackBy: getIndex"
									[member]="member"
									(nodeClick)="nodeClick($event, member)"
									[ngClass]="{selected: selectedObject ? member._uniqueIndex === selectedObject._uniqueIndex : false}"
									[attr.data-path]="member._path.join('/')"
									[attr.data-fullPath]="member._fullPath.join('/')"
									[attr.data-memberPath]="member._memberPath.join('/')"
								>
								</i40-tree-member>
							</cdk-virtual-scroll-viewport>
						</div>
					</div>
				</mat-card>
			</as-split-area>

			<as-split-area id="rightModelEdit_splitArea" [size]="60">
				<div fxFlex *ngIf="loaded && !showJsonEditor && !loadingLayers" style="overflow: auto" class="channel-editor-layer-wrapper">
					<div fxLayout="row">
						<form fxFlex fxLayout="column">
							<div style="display: none">{{ selectedObject.definitionType }}: {{ selectedObject._fullPath.join('/') }}</div>

							<ng-container *ngFor="let layer of layerArray; let i = index">
								<app-layer [selectedObject]="selectedObject" [layer]="layer"></app-layer>

								<mat-expansion-panel expanded="false" style="margin-bottom: 8px" *ngIf="globalSrv.debugMode">
									<mat-expansion-panel-header>DEBUG FORM ({{ layer.label }})</mat-expansion-panel-header>
									<b>Dirty:</b> {{ layer.form.dirty ? 'TRUE' : 'FALSE' }} / <b>Valid:</b> {{ layer.form.valid ? 'TRUE' : 'FALSE' }}
									<pre>{{ layer.form.value | json }}</pre>
								</mat-expansion-panel>
							</ng-container>
						</form>
					</div>
				</div>

				<div style="height: 100%; flex-direction: column; flex: 1" [ngStyle]="{display: !showJsonEditor ? 'none' : 'flex'}">
					<span style="font-size: 0.9em"
						><b>Note:</b> In order to SAVE/APPLY your changes inside the editor you need to revert to the normal view to make sure they are compatible with the channel data structure</span
					>
					<ng-container *ngFor="let e of editorCodeInput; let i = index">
						<h2 style="margin-bottom: 8px">{{ layerLabels[channelType.layers[i]] }}</h2>
						<ngx-codemirror
							id="aceEditor"
							[(ngModel)]="editorCodeOutput[i]"
							[options]="{
								lineNumbers: true,
								matchBrackets: true,
								autoCloseBrackets: true,
								styleActiveLine: true,
								refresh: true,
								mode: 'application/x-json',
								theme: themeSrv.selectedTheme === 'light-theme' ? 'eclipse' : 'monokai'
							}"
							style="min-height: 200px; height: 100%; margin-bottom: 15px"
						>
						</ngx-codemirror>
					</ng-container>
				</div>
			</as-split-area>
		</as-split>
	</div>
</div>
