<ng-container *ngIf="loaded">
	<form [formGroup]="form" class="form" novalidate fxLayout="column" style="padding: 20px" (ngSubmit)="save()">
		<mat-form-field subscriptSizing="dynamic" fxFlex="">
			<mat-label>{{ 'i18n.id' | translate }}</mat-label>
			<input id="id_input" formControlName="id" matInput (keydown)="onKeydown($event)" #selectOnInit required />
			<mat-error *ngIf="form.get('id').errors">{{ form.get('id').errors.message }}</mat-error>
		</mat-form-field>

		<mat-form-field subscriptSizing="dynamic" fxFlex="">
			<mat-label>{{ 'i18n.description' | translate }}</mat-label>
			<input id="description_input" matInput formControlName="description" />
		</mat-form-field>

		<mat-form-field subscriptSizing="dynamic" fxFlex="">
			<mat-label>{{ 'i18n.memberType' | translate }}</mat-label>
			<input id="member_type_input" formControlName="typeName" matInput aria-label="memberType" [matAutocomplete]="auto" [(ngModel)]="selectedMemberType" required />

			<mat-error *ngIf="form.get('typeName').errors">{{ form.get('typeName').errors.message }}</mat-error>

			<button id="reset_member_type_button" *ngIf="selectedMemberType && globalSrv.canEdit(selectedObject)" matSuffix mat-icon-button aria-label="Clear" (click)="resetMemberType()">
				<mat-icon>close</mat-icon>
			</button>

			<mat-autocomplete #auto="matAutocomplete" (optionSelected)="onChangeType($event)">
				<mat-option *ngFor="let type of allowedMemberTypes$ | async" [value]="type">
					<span>{{ type }}</span>
				</mat-option>
			</mat-autocomplete>
		</mat-form-field>
	</form>
</ng-container>
