<block-ui>
	<div *ngIf="messages.length !== 0">
		<progress-log #progressLog [messages]="messages"></progress-log>
	</div>
	<div fxLayout="column" fxFill>
		<div fxFlex="0 0 auto">
			<mat-toolbar fxLayout="row" fxLayoutAlign="center center" class="mat-primary main-toolbar" fxFlex>
				<button mat-icon-button (click)="sidenav.toggle(); onSidebarCloseToggle()" class="menuButton" id="menu_button">
					<mat-icon>menu</mat-icon>
				</button>
				<span [routerLink]="['/']" fxLayout="row" fxLayoutAlign="start center" (click)="onLogoClick($event)" style="outline: none; cursor: pointer !important">
					<img src="../assets/amorph-pro-dark.png" class="cursor-pointer" />
				</span>
				<div fxFlex="100"></div>
				<div *ngIf="globalSrv.debugMode" style="opacity: 0.5">[DEBUG]&nbsp;&nbsp;</div>
				<div fxLayout="row" class="logo">
					<span style="font-weight: 100">{{ 'i18n.smart' | translate }}</span>
					<span style="font-weight: bold">{{ 'i18n.unifier' | translate }}</span>
				</div>
				<span class="spacer"></span>
				<button id="notifications_button" mat-icon-button (click)="notificationsMenuTrigger.openMenu()">
					<mat-icon
						#notificationsMenuTrigger="matMenuTrigger"
						[matMenuTriggerFor]="notificationsMenu"
						[matBadge]="non_read_notifications.length ? non_read_notifications.length : null"
						matBadgeColor="accent"
						>notifications</mat-icon
					>
				</button>
				<button id="account_button" mat-icon-button (click)="userMenuTrigger.openMenu()">
					<mat-icon #userMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu">account_circle</mat-icon>
				</button>
				<a id="help_button" mat-icon-button matTooltip="Help" href="{{ helpLink() }}" target="_blank" style="display: flex; flex-direction: column; align-items: center; justify-content: center">
					<mat-icon>help_outline</mat-icon>
				</a>
			</mat-toolbar>
		</div>

		<div fxFlex="1 1 auto" fxLayout="column">
			<mat-sidenav-container fxFlex autosize [hasBackdrop]="false" class="main-side-menu">
				<mat-sidenav #sidenav mode="side" id="drawer" [opened]="!isSidebarClosed">
					<mat-nav-list style="width: max-content">
						<ng-container *ngFor="let item of menuItems; let i = index">
							<ng-container *ngIf="item.data.display">
								<div [ngSwitch]="item.path == 'SECTION'">
									<div *ngIf="item.data.menuitemid == 'smartunifierconfiguration'" class="custom-mat-nav-header">
										<ng-container *ngIf="!isSidebarCollapsed">
											<span mat-subheader *ngSwitchCase="true" mat-list-item class="header-content"
												><span [style.font-weight]="100">SMART</span> <span [style.font-weight]="400">UNIFIER</span> {{ item.data.title | translate }}</span
											>
										</ng-container>

										<button mat-icon-button *ngIf="i == 0" (click)="onSidebarCollapseToggle()" class="sidebar-toggle">
											<mat-icon *ngIf="!isSidebarCollapsed">keyboard_arrow_left</mat-icon>
											<mat-icon *ngIf="isSidebarCollapsed">keyboard_arrow_right</mat-icon>
										</button>
									</div>

									<mat-list-item
										id="{{ item.data.menuitemid }}_menuitem"
										class="menu_item custom-menu-item"
										*ngSwitchCase="false"
										(click)="onSelectItem()"
										[activated]="item.isActive"
										matTooltip="{{ item.data.title | translate }}"
										[matTooltipPosition]="'right'"
										[matTooltipDisabled]="!isSidebarCollapsed"
										[routerLink]="[item.path]"
										[ngClass]="{selected: router.url.includes(item.path)}"
									>
										<div class="mat-icon-text-wrapper">
											<mat-icon class="custom-mat-icon" *ngIf="!item.data.svgIcon">{{ item.data.icon }}</mat-icon>
											<mat-icon class="custom-mat-icon" *ngIf="item.data.svgIcon" [svgIcon]="item.data.icon"></mat-icon>

											<ng-container *ngIf="!isSidebarCollapsed">
												<span class="custom-mat-nav-text">
													{{ item.data.title | translate }}
												</span>
											</ng-container>
										</div>
									</mat-list-item>
								</div>
							</ng-container>
						</ng-container>
					</mat-nav-list>
				</mat-sidenav>
				<mat-sidenav-content>
					<div class="page-content">
						<as-split direction="vertical" [useTransition]="true">
							<as-split-area [size]="globalSrv.splitTop">
								<router-outlet></router-outlet>
							</as-split-area>
							<as-split-area [size]="globalSrv.splitBottom.size" [visible]="globalSrv.splitBottom.visible">
								<!-- <nav mat-tab-nav-bar [tabPanel]="tabPanel">
									<a mat-tab-link>Validation</a> -->
								<compile-artifact-results></compile-artifact-results>

								<!-- <a mat-tab-link>Content 2</a>
									<a mat-tab-link>Content 3</a>
									<mat-icon>delete</mat-icon>	
								</nav>
								<mat-tab-nav-panel #tabPanel></mat-tab-nav-panel> -->
							</as-split-area>
						</as-split>
					</div>
				</mat-sidenav-content>
			</mat-sidenav-container>
		</div>

		<div *ngIf="authSrv.licenseWarning" class="license-warning-wrapper license-warning-{{ authSrv.license.licenseActivationData.licenseType | lowercase }}">
			<ng-container *ngIf="authSrv.licenseWarning && !authSrv.expirationWarning && !authSrv.nolicense"> USING A {{ authSrv.license.licenseActivationData.licenseType }} LICENSE </ng-container>
			<ng-container *ngIf="authSrv.expirationWarning && !authSrv.nolicense"> LICENSE WILL EXPIRE IN {{ authSrv.expirationCounter }} DAYS </ng-container>
			<ng-container *ngIf="authSrv.nolicense"> YOU DO NOT HAVE AN ACTIVE LICENSE </ng-container>
		</div>

		<mat-menu #menu="matMenu" style="min-width: 10%" class="userMenu" id="user_menu_wrapper">
			<mat-card appearance="outlined" (click)="$event.stopPropagation()">
				<mat-card-content fxLayout="column" fxFlex>
					<div fxFlex="1 1 auto" fxLayoutAlign="center center">
						<img src="../assets/profile-picture.png" style="width: 200px; height: auto" />
					</div>
					<div fxFlex fxLayoutAlign="center center">
						Hi,&nbsp;<span>{{ authSrv.userData.firstName }}</span
						>&nbsp;<span>{{ authSrv.userData.lastName }}</span
						>!
					</div>

					<div style="margin-top: 15px">
						<div fxLayout="column" *ngFor="let item of userMenuItems">
							<!-- {{item.title}}:"'display': {{item.display ? 'block' : 'none'}}" -->
							<button
								style="display: flex; flex-direction: row"
								[ngStyle]="{display: item.display ? 'flex' : 'none'}"
								*ngIf="item.children"
								mat-menu-item
								id="{{ item.id }}"
								[matMenuTriggerFor]="submenu"
								(click)="execute(item, $event)"
							>
								<mat-icon *ngIf="!item.svgIcon">{{ item.icon }}</mat-icon>
								<mat-icon *ngIf="item.svgIcon" [svgIcon]="item.icon"></mat-icon>
								<span class="mat-typography">{{ item.title | translate }}</span>
							</button>

							<button style="display: flex; flex-direction: row" *ngIf="!item.children" mat-menu-item id="{{ item.id }}" [ngStyle]="{display: item.display ? 'flex' : 'none'}" (click)="execute(item)">
								<mat-icon *ngIf="!item.svgIcon">{{ item.icon }}</mat-icon>
								<mat-icon *ngIf="item.svgIcon" [svgIcon]="item.icon"></mat-icon>
								<span class="mat-typography">{{ item.title | translate }}</span>
							</button>

							<mat-divider></mat-divider>

							<mat-menu #submenu="matMenu" class="user_submenu">
								<div fxLayout="column" *ngFor="let subitem of userSubmenus[item.id]">
									<button fxLayout="row" mat-menu-item id="{{ subitem.id }}" [ngStyle]="{display: subitem.display ? 'block' : 'none'}" (click)="execute(subitem)" *ngIf="subitem.display">
										<mat-icon *ngIf="!subitem.svgIcon">{{ subitem.icon }}</mat-icon>
										<mat-icon *ngIf="subitem.svgIcon" [svgIcon]="subitem.icon"></mat-icon>
										<span class="mat-typography">{{ subitem.title | translate }}</span>
									</button>
									<mat-divider></mat-divider>
								</div>
							</mat-menu>
						</div>
					</div>
				</mat-card-content>
			</mat-card>
		</mat-menu>

		<mat-menu #notificationsMenu="matMenu" style="min-width: 10%" class="notificationsMenu" id="notifications_menu_wrapper">
			<mat-card appearance="outlined" (click)="$event.stopPropagation()">
				<mat-card-content fxLayout="column" fxFlex style="width: 100%">
					<button mat-menu-item (click)="gotoNotification(null)">
						<mat-icon color="warn">list</mat-icon>
						View All Notifications
					</button>
					<ng-container *ngFor="let notification of non_read_notifications | limit: 10">
						<ng-container [ngSwitch]="notification.eventType">
							<button *ngSwitchCase="'InstanceError'" mat-menu-item (click)="gotoNotification(notification)" style="display: flex; flex-direction: row; align-items: center">
								<mat-icon color="warn">error</mat-icon>
								<div style="overflow: hidden">
									<div class="mat-typography">Instance Error: {{ notification.instanceExternalId.getName() }}</div>
									<div style="overflow: hidden; text-overflow: ellipsis">
										<div style="white-space: nowrap; font-size: 0.8em; opacity: 0.6; text-overflow: ellipsis; overflow: hidden">{{ notification.message }}</div>
									</div>
									<div style="font-size: 0.8em">{{ notification.eventTime.fromNow() }}</div>
								</div>
								<span style="flex: 1 1 auto"></span>
								<button (click)="dismissNotification($event, notification)" mat-icon-button color="accent" class="small_text_button"><mat-icon>mark_email_unread</mat-icon></button>
							</button>
						</ng-container>
					</ng-container>
					<button *ngIf="non_read_notifications.length > 10" mat-menu-item (click)="gotoNotification(null)" style="display: flex; flex-direction: row; align-items: center">
						<div>&nbsp;...&nbsp;&nbsp;</div>
						<div style="overflow: hidden; flex: 1 0 auto">
							<div class="mat-typography">{{ non_read_notifications.length - 10 }} more</div>
						</div>
					</button>
				</mat-card-content>
			</mat-card>
		</mat-menu>
	</div>
</block-ui>
