import {ChangeDetectorRef, Component, ElementRef, NgZone, OnInit, ViewChild} from '@angular/core';
import {ArtifactInfo, Identifier} from '../../data-models/core-model.model';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort, MatSortable} from '@angular/material/sort';
import {DeploymentService} from '../deployment/deployment.service';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {MessageResponseService} from '../../core-services/message-response.service';
import {AuthService} from '../../core-services/auth.service';
import {TranslateService} from '@ngx-translate/core';
import {InstanceService} from '../instance/instance.service';
import {Button, DialogData, GenericDialogComponent} from '../../shared/generic-dialog/generic-dialog.component';

@Component({
	selector: 'app-dashboard',
	templateUrl: './home-dashboard.component.html',
	styleUrls: ['./home-dashboard.component.scss'],
})
export class HomeDashboardComponent implements OnInit {
	columns = ['deploymentStatus', 'info', 'location', 'deployType', 'usage', 'times', 'actions'];

	instanceData: ArtifactInfo[] = [];
	deploymentData: any[] = [];

	originalRows: any[] = [];
	rows: any[] = [];

	data: MatTableDataSource<any> = new MatTableDataSource([]);

	//Init states for buttons
	running: boolean;
	stopped: boolean;

	@ViewChild(MatSort, {static: true}) sort: MatSort;
	@ViewChild('searchInput', {static: false}) searchInput: ElementRef;

	constructor(
		private deploymentService: DeploymentService,
		public dialog: MatDialog,
		private _zone: NgZone,
		public router: Router,
		private route: ActivatedRoute,
		private msgSrv: MessageResponseService,
		public authSrv: AuthService,
		private changeDetectorRefs: ChangeDetectorRef,
		public translate: TranslateService,
		private instanceService: InstanceService,
	) {}

	ngOnInit() {
		this.reloadData();
	}

	generateRows(instanceData: ArtifactInfo[], deploymentData: any[]) {
		const rows = [];
		deploymentData.forEach((deployment) => {
			if (deployment.info) {
				let instanceId = new Identifier(deployment.info.instance).getId();
				let instanceExternalIdentifier = this.instanceData.find((x) => x.identifier.id == deployment.info.instance.id);

				let deploymentSettingElement = {
					instanceId: instanceId,
					info: {
						id: instanceId,
						group: instanceExternalIdentifier.externalIdentifier.group,
						name: instanceExternalIdentifier.externalIdentifier.name,
						version: instanceExternalIdentifier.identifier.version,
					},
					location: {
						location: 'n/a',
						area: 'n/a',
					},
					usage: {
						cpu: '50%',
						memory: '80%',
					},
					deployType: deployment.info.deploymentType,
					uuid: deployment.info.locationUuid,
					instanceIdentifier: deployment.info.instance,
					deploymentStatus: deployment.state,

					icon: this.getInstanceIcon(deployment.state),

					times: {
						lastStart: 'n/a',
						lastDeployed: 'n/a',
					},
				};
				rows.push(deploymentSettingElement);
			}
		});
		return rows;
	}

	reloadData() {
		this.msgSrv.onLoading.next({command: 'start'});

		this.instanceService.getInstanceList().subscribe((instanceData) => {
			this.deploymentService.getInstanceDeploymentInfo().subscribe((deploymentData) => {
				this.instanceData = instanceData;
				this.deploymentData = deploymentData;

				this.updateData(instanceData, deploymentData);

				this.msgSrv.onLoading.next({command: 'stop'});
			});
		});
	}

	updateData(instanceData: ArtifactInfo[], deploymentData: any[]) {
		this.originalRows = this.generateRows(instanceData, deploymentData);

		this.originalRows.sort((a, b) => {
			const grpA = a.info.group.toLowerCase();
			const grpB = b.info.group.toLowerCase();
			if (grpA < grpB) return -1;
			if (grpA > grpB) return 1;
			return 0;
		});

		this.updateRows(this.originalRows);

		this.sort.sort({id: 'info', start: 'asc'} as MatSortable);
	}

	updateRows(rows: any[]) {
		this.rows = rows;
		this.data.data = this.rows;
		this.data._updateChangeSubscription();
	}

	//Check if instance is already started
	getInstanceIcon(currentState) {
		switch (currentState.toLowerCase()) {
			case 'started':
				return 'play_arrow';
				break;
			case 'stopped':
				return 'stop';
				break;
			case 'error':
				return 'error';
				break;
			case 'notdeployed':
				return 'disabled_by_default';
				break;
		}
	}

	showDashboard(row) {
		let id = row.instanceId;

		if (row.deploymentStatus === 'Unknown' || row.deploymentStatus === 'NotDeployed') {
			this.msgSrv.customDialogMessage(
				this.translate.instant('i18n.instanceIsNotYetDeployed'),
				this.translate.instant('i18n.instanceIsNotYetDeployedText', {value: row.name}),
				[new Button(this.translate.instant('i18n.ok'), 'ok', 'flat', 'accent', 'center')],
				{
					ok: (dialogRef, resolve) => {
						dialogRef.close();
						resolve(false);
					},
				},
			);
		} else {
			this.router.navigate([`/status/${id}/dashboard`]);
		}
	}
}
