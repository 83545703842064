import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from '../../core-services/auth.service';
import {MessageResponseService} from '../../core-services/message-response.service';
import {GlobalService} from '../../core-services/global.service';
import {GenericListService} from '../../core-services/generic-list.service';

@Component({
	selector: 'generic-list-toolbar',
	templateUrl: './generic-list-toolbar.component.html',
	styleUrls: ['./generic-list-toolbar.component.css'],
})
export class GenericListToolbarComponent implements OnInit {
	@Input() showImport: boolean = true;
	@Input() showAdd: boolean = true;
	@Input() showRefresh: boolean = true;

	@ViewChild('searchInput', {static: false}) searchInput: ElementRef;
	@ViewChild('fileUpload', {static: false}) fileUpload: ElementRef;

	constructor(
		public translate: TranslateService,
		public authSrv: AuthService,
		public msgSrv: MessageResponseService,
		public listSrv: GenericListService,
		public globalSrv: GlobalService,
	) {}

	ngOnInit(): void {}

	showSearch() {
		this.listSrv.searchVisible = true;
		setTimeout(() => {
			this.searchInput.nativeElement.focus();
		});
	}

	import() {
		this.listSrv.import(this.fileUpload);
	}
}
