import {Injectable} from '@angular/core';
import {DeviceTypeManagerResult} from './device-type.model';
import {MappingInfo} from '../mapping/mapping-model';
import {ChannelInfo, ChannelManagerResult} from '../channel/channel.model';
import {InstanceManagerResult} from '../instance/instance-model';

@Injectable({
	providedIn: 'root',
})
export class DeviceTypeDataService {
	deviceTypeResult: DeviceTypeManagerResult;

	mappings: MappingInfo[] = [];
	channels: ChannelInfo[] = [];

	channelsMap: Map<string, ChannelManagerResult> = new Map();

	editable = true;
	showChannelConfigurationEditor = false;

	selectedChannelId: string = null;
	selectedChannel: ChannelManagerResult = null;

	instance: InstanceManagerResult = null;

	constructor() {}
}
