<mat-expansion-panel
	id="{{ titleToId('expansion_panel') }}"
	hideToggle
	class="schema-group-panel"
	style="margin-bottom: 8px; padding: 4px"
	fxLayout="column"
	[ngClass]="{nonexpandable: !collapsible && !showContainer, hidden_form_element: !parsed}"
	[(expanded)]="expanded"
	[disabled]="!globalSrv.canEdit(treeSrv.rootParent)"
	(expandedChange)="onExpantionToggle($event)"
>
	<mat-expansion-panel-header *ngIf="collapsible">
		<mat-checkbox [checked]="expanded" disabled="true" class="{{ titleToId('checkbox') }}"></mat-checkbox>&nbsp;&nbsp;
		<span class="group-title" *ngIf="title.length" id="{{ titleToId('group_title') }}" fxFlex>{{ title }}</span>
	</mat-expansion-panel-header>

	<h3 *ngIf="!collapsible && title.length">
		<span class="group-title" id="{{ titleToId('group_title') }}" fxFlex>{{ title }}</span>
	</h3>

	<ng-container *ngIf="expanded && form">
		<div *ngFor="let property of properties" fxFlex>
			<ng-container [ngSwitch]="property.type">
				<app-input
					*ngSwitchCase="'string'"
					[ngClass]="{hidden_form_element: !visibilityMap[property.key]}"
					[selectedObject]="selectedObject"
					[control]="getFormControl(property.key)"
					[property]="property"
				></app-input>
				<app-input
					*ngSwitchCase="'number'"
					[ngClass]="{hidden_form_element: !visibilityMap[property.key]}"
					[selectedObject]="selectedObject"
					[control]="getFormControl(property.key)"
					[property]="property"
				></app-input>
				<app-input
					*ngSwitchCase="'date'"
					[ngClass]="{hidden_form_element: !visibilityMap[property.key]}"
					[selectedObject]="selectedObject"
					[control]="getFormControl(property.key)"
					[property]="property"
				></app-input>
				<app-input
					*ngSwitchCase="'integer'"
					[ngClass]="{hidden_form_element: !visibilityMap[property.key]}"
					[selectedObject]="selectedObject"
					[control]="getFormControl(property.key)"
					[property]="property"
				></app-input>
				<app-select
					*ngSwitchCase="'enum'"
					[ngClass]="{hidden_form_element: !visibilityMap[property.key]}"
					[selectedObject]="selectedObject"
					[control]="getFormControl(property.key)"
					[property]="property"
				></app-select>
				<app-checkbox
					*ngSwitchCase="'boolean'"
					[ngClass]="{hidden_form_element: !visibilityMap[property.key]}"
					[selectedObject]="selectedObject"
					[control]="getFormControl(property.key)"
					[property]="property"
				></app-checkbox>

				<app-oneof
					*ngSwitchCase="'oneOf'"
					[ngClass]="{hidden_form_element: !visibilityMap[property.key]}"
					[selectedObject]="selectedObject"
					[form]="getFormGroup(property.key)"
					[property]="property"
				></app-oneof>
				<app-anyof
					*ngSwitchCase="'anyOf'"
					[ngClass]="{hidden_form_element: !visibilityMap[property.key]}"
					[selectedObject]="selectedObject"
					[form]="getFormGroup(property.key)"
					[property]="property"
				></app-anyof>

				<app-credentials-selector
					*ngSwitchCase="'credentials-selector'"
					[ngClass]="{hidden_form_element: !visibilityMap[property.key]}"
					[selectedObject]="selectedObject"
					[control]="getFormControl(property.key)"
					[property]="property"
				></app-credentials-selector>

				<app-model-path-array
					*ngSwitchCase="'model_path_array'"
					[ngClass]="{hidden_form_element: !visibilityMap[property.key]}"
					[selectedObject]="selectedObject"
					[form]="getFormArray(property.key)"
					[property]="property"
					[items]="property.items"
					[savedValue]="savedValue ? savedValue[property.key] : null"
					[emptyFormValue]="emptyFormValue ? emptyFormValue[property.key] : null"
					(visible)="subgroupVisible($event, property)"
				>
				</app-model-path-array>

				<app-array
					*ngSwitchCase="'array'"
					[ngClass]="{hidden_form_element: !visibilityMap[property.key]}"
					[selectedObject]="selectedObject"
					[layer]="layer"
					[form]="getFormArray(property.key)"
					[property]="property"
					[items]="property.items"
					[savedValue]="savedValue ? savedValue[property.key] : null"
					[emptyFormValue]="emptyFormValue ? emptyFormValue[property.key] : null"
				></app-array>

				<app-model-path *ngSwitchCase="'model_path'" [selectedObject]="selectedObject" [form]="getFormGroup(property.key)" [property]="property" style="display: none"></app-model-path>

				<app-group
					*ngSwitchCase="'object'"
					[title]="property.description"
					[ngClass]="{hidden_form_element: !visibilityMap[property.key]}"
					[selectedObject]="selectedObject"
					[layer]="layer"
					[form]="getFormGroup(property.key)"
					[properties]="property.properties"
					[requiredChildren]="property.required"
					[isRequired]="requiredChildren.includes(property.refId)"
					[savedValue]="savedValue ? savedValue[property.key] : null"
					[emptyFormValue]="emptyFormValue ? emptyFormValue[property.key] : null"
					[expanded]="property.expanded"
					[collapsible]="!requiredChildren.includes(property.key)"
					(visible)="subgroupVisible($event, property)"
					(onExpandToggle)="toggleGroup($event, property)"
				></app-group>
			</ng-container>
		</div>
	</ng-container>
</mat-expansion-panel>
