<div class="progress" fxFlex fxLayout="column" *ngIf="!canClose" style="overflow: hidden">
	<div fxFlex="1" *ngIf="!finished">
		<mat-progress-bar class="progress-bar" matSuffix mode="indeterminate" diameter="16" style=""></mat-progress-bar>
	</div>
	<div fxFlex style="overflow-y: auto; white-space: pre-wrap" #content>
		<div *ngFor="let part of messages">
			<span class="{{ part.level.toLowerCase() }}">{{ part.level }}</span
			>: <span [ngClass]="part.level.toLowerCase() === 'error' ? 'error' : ''">{{ part.message }}</span>
		</div>
	</div>
	<div fxFlex="5" style="display: flex; flex-direction: row; align-items: center; justify-content: center" *ngIf="finished">
		<button mat-button id="copy_button" color="accent" style="transform-style: preserve-3d" (click)="copy()">
			<span style="display: flex; flex-direction: row; align-items: center"><mat-icon>content_copy</mat-icon>&nbsp;{{ 'i18n.matTreeCopy' | translate }}</span>
		</button>
		<span style="flex: 1 0 auto"></span>
		<button mat-button id="ok_button" color="accent" style="transform-style: preserve-3d" (click)="close()">
			<span style="display: flex; flex-direction: row; align-items: center"><mat-icon>close</mat-icon>&nbsp;{{ 'i18n.close' | translate }}</span>
		</button>
	</div>
</div>
