import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {ArtifactInfo} from '../../data-models/core-model.model';
import {ModelManagerResult} from '../../shared/i40-tree/types';

@Injectable()
export class ModelService {
	defaultHttpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		}),
	};

	constructor(private http: HttpClient) {}

	getModelsInfo(all = false): Observable<ArtifactInfo[]> {
		if (all) {
			return this.http.get<ArtifactInfo[]>(`${environment.apiHost + environment.apiUrl}/model/allartifacts`).pipe(
				map((result) => {
					return result.map((r) => new ArtifactInfo(r)).sort(ArtifactInfo.sort);
				}),
			);
		} else {
			return this.http.get<ArtifactInfo[]>(`${environment.apiHost + environment.apiUrl}/model`).pipe(
				map((result) => {
					return result.map((r) => new ArtifactInfo(r)).sort(ArtifactInfo.sort);
				}),
			);
		}
	}

	getModelData(modelId: string): Observable<ModelManagerResult> {
		return this.http.get(`${environment.apiHost + environment.apiUrl}/model/${modelId}`).pipe(
			map((result: any) => {
				return new ModelManagerResult(result);
			}),
		);
	}

	deleteModel(modelId: string) {
		return this.http.delete(`${environment.apiHost + environment.apiUrl}/model/${modelId}`);
	}

	releaseModel(id, version) {
		return this.http.post(`${environment.apiHost + environment.apiUrl}/model/${id}:${version}/release`, null, this.defaultHttpOptions);
	}

	saveModel(data) {
		return this.http.post(`${environment.apiHost + environment.apiUrl}/model`, data, this.defaultHttpOptions);
	}

	updateModel(data) {
		return this.http.put(`${environment.apiHost + environment.apiUrl}/model`, data, this.defaultHttpOptions);
	}

	importModel(obj) {
		if (obj.info && obj.info.identifier && obj.info.identifier.version) {
			obj.info.version = obj.info.identifier.version;
			delete obj.info['identifier'];
		}
		return this.saveModel(obj);
	}
}
