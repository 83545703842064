import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../core-services/auth.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MessageResponseService} from '../../core-services/message-response.service';
import {TranslateService} from '@ngx-translate/core';
import {ThemeService} from '../../core-services/theme.service';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
	selector: 'app-login-popup',
	templateUrl: './login-popup.component.html',
	styleUrls: ['./login-popup.component.css'],
})
export class LoginPopupComponent implements OnInit {
	form: UntypedFormGroup;

	constructor(
		public dialogRef: MatDialogRef<LoginPopupComponent>,
		private fb: UntypedFormBuilder,
		private authSrv: AuthService,
		private snackBar: MatSnackBar,
		private msgSrv: MessageResponseService,
		public translate: TranslateService,
		public theme: ThemeService,
	) {
		this.form = new UntypedFormGroup({
			userId: this.fb.control('', [Validators.required]),
			password: this.fb.control('', [Validators.required]),
		});
	}

	onLogin() {
		let user = {identifier: null, password: null};

		user.identifier = this.form.value.userId;
		user.password = this.form.value.password;

		this.authSrv.login(user).subscribe(
			(result: any) => {
				if (this.snackBar) {
					this.snackBar.dismiss();
				}

				localStorage.setItem('activeDirectory', result.loginInfo.providerID === 'activedirectory' ? 'true' : 'false');

				this.dialogRef.close();
			},
			(error) => {
				if (error.status == 0) {
					this.msgSrv.showError(this.translate.instant('i18n.errorMsg.serverConnect'));
				}

				this.msgSrv.showError(this.translate.instant('i18n.errorMsg.invalidCredentials'));
			},
		);
	}

	ngOnInit() {}
}
