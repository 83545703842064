import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {UntypedFormArray, UntypedFormGroup} from '@angular/forms';
import {I40FlatMember} from '../../../types';
import {JSONSchemaProperty, SchemaLayer} from '../../../../../data-models/json-schema.model';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {I40TreeChannelService} from '../../i40-tree-channel.service';
import {AuthService} from '../../../../../core-services/auth.service';
import {GlobalService} from '../../../../../core-services/global.service';
import * as _ from 'lodash';

@Component({
	selector: 'app-model-path-array',
	templateUrl: './model-path-array.component.html',
	styleUrls: ['./model-path-array.component.css'],
})
export class ModelPathArrayComponent implements OnInit, OnChanges {
	@Input() form: UntypedFormArray;
	@Input() selectedObject: I40FlatMember;
	@Input() property: JSONSchemaProperty;
	@Input() items?: JSONSchemaProperty;

	@Input() savedValue: any;
	@Input() emptyFormValue: any;

	@Output() visible: EventEmitter<boolean> = new EventEmitter<boolean>();

	selectedObjectPath: string;
	index = 0;
	checked = false;
	noVisibleChildren = false;

	constructor(
		public treeSrv: I40TreeChannelService,
		public authSrv: AuthService,
		public globalSrv: GlobalService,
	) {}

	ngOnInit(): void {}

	ngOnChanges() {
		this.initForm();
	}

	returnZero() {
		return 0;
	}

	initForm() {
		this.index = 0;
		this.checked = false;

		this.selectedObjectPath = '/' + this.selectedObject._fullPath.join('/');

		// first check if it's and existing array entry
		if (this.form.controls.length > 0) {
			this.form.value.forEach((c, i) => {
				const selectedObjectPathSegments = this.selectedObjectPath.split('/').length;
				const modelPathSegments = c.modelPath?.path.split('/').length ?? 0;
				if (
					(selectedObjectPathSegments > modelPathSegments && this.selectedObjectPath.indexOf(c.modelPath?.path) === 0) ||
					(selectedObjectPathSegments === modelPathSegments && this.selectedObjectPath === c.modelPath?.path)
				) {
					this.checked = true;
					this.index = i;
				}
			});
		}
	}

	onToggleArray(event: MatCheckboxChange) {
		if (event.checked) {
			const fg = new UntypedFormGroup({});
			SchemaLayer.generateForm(this.items.properties, {}, fg, []);
			this.form.push(fg);
			this.index = this.form.controls.length - 1;
			this.emptyFormValue[this.index] = _.cloneDeep(fg.value);
			this.checked = true;
			fg.markAsDirty();
		} else {
			this.form.removeAt(this.index);
			this.checked = false;
		}

		this.form.markAsDirty();
	}

	subgroupVisible(visible: boolean) {
		this.visible.emit(visible);
		this.noVisibleChildren = !visible;
	}

	getFormGroup(index): UntypedFormGroup {
		return this.form.at(index) as UntypedFormGroup;
	}
}
