import {Component, Input, OnInit} from '@angular/core';
import {GlobalService} from '../../../core-services/global.service';
import {PluginsService} from '../plugins.service';
import {PluginManagerListItem} from '../plugins.model';
import * as _ from 'lodash';

@Component({
	selector: 'plugin-toolbar-menu',
	templateUrl: './plugin-toolbar-menu.component.html',
	styleUrls: ['./plugin-toolbar-menu.component.scss'],
})
export class PluginToolbarMenuComponent implements OnInit {
	@Input() location: string;
	@Input() data: any;

	menus: any[] = [];
	activePlugins: PluginManagerListItem[] = [];

	constructor(
		public globalSrv: GlobalService,
		public pluginSrv: PluginsService,
	) {}

	ngOnInit(): void {
		this.pluginSrv.plugins.subscribe({
			next: (plugins) => {
				this.activePlugins = this.parseLocations(plugins).filter((p) => p); // TODO add license logic inside filter
			},
		});
	}

	parseLocations(plugins: PluginManagerListItem[]): PluginManagerListItem[] {
		let activePlugins = [];

		plugins.forEach((p) => {
			if (p.locations.length) {
				p.locations.forEach((l) => {
					if (l.location === this.location) {
						const pluginInstance = _.cloneDeep(p);
						pluginInstance.currentAction = l.action;
						if (this.data) {
							pluginInstance.locationData = this.data;
						}
						activePlugins.push(pluginInstance);
					}
				});
			}
		});

		return activePlugins;
	}
}
