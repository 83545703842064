<div style="display: flex; flex-direction: column">
	<mat-form-field subscriptSizing="dynamic" fxFlex [class.mat-form-field-invalid]="typeHasError">
		<mat-label>{{ property.description }}</mat-label>
		<mat-select
			id="{{ treeSrv.getId(property, 'select') }}"
			[placeholder]="property.description"
			[(ngModel)]="selectedElement"
			(ngModelChange)="selectionChanged($event)"
			[required]="property.isRequired"
			[disabled]="!globalSrv.canEdit(treeSrv.rootParent)"
		>
			<mat-option value="" *ngIf="!property.isRequired">{{ 'i18n.none' | translate }}</mat-option>
			<mat-option *ngFor="let item of property.anyOf" [value]="item">
				<ng-container *ngIf="item.description">{{ item.description }}</ng-container>
				<ng-container *ngIf="!item.description">{{ item.key }}</ng-container>
			</mat-option>
		</mat-select>
	</mat-form-field>

	<app-group
		fxFlex
		*ngIf="selectedElement && selectedElement['properties'] && selectedElement['properties'].length"
		[title]="selectedElement.description || selectedElement.refId?.split('.').pop()"
		[selectedObject]="selectedObject"
		[properties]="selectedElement['properties']"
		[form]="form"
		[expanded]="true"
		[collapsible]="false"
		[showContainer]="true"
	></app-group>
</div>
