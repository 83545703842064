import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {merge, Observable, Subject} from 'rxjs';
import {environment} from '../../environments/environment';
import {
	ArtifactValidatedEvent,
	ChannelStateChangeNotification,
	DeploymentEndpointStatusNotification,
	DeploymentErrorNotification,
	i40Notification,
	InstanceDeploymentStatusNotification,
	InstanceErrorNotification,
	InstanceStateChangeNotification,
	NotificationActionEvent,
	VmStatusNotification,
} from '../apps/notifications/notifications.model';
import {filter, map} from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class NotificationsService {
	private eventStream: EventSource;
	private eventSubjects: {
		[eventType: string]: Subject<any>;
	} = {};

	refreshRequired: Subject<string> = new Subject<string>();

	constructor(public http: HttpClient) {
		this.initEventStream();
	}

	private initEventStream() {
		const url = `${environment.apiHost + environment.apiUrl}/notifications/stream`;
		this.eventStream = new EventSource(url, {withCredentials: true});

		this.eventStream.onmessage = (event) => {
			if (typeof event === 'object' && event.data !== 'ping') {
				//console.log(`Received event`, event)
				const messages = event.data.split('\n').filter((msg) => msg.trim());
				messages.forEach((msg) => {
					if (msg != 'ping') {
						try {
							const data = JSON.parse(msg);
							this.dispatchToSubjects(data);
						} catch (e) {
							console.error('Error parsing JSON:', e);
							console.log('Raw message:', event.data);
						}
					}
				});
			}
		};

		this.eventStream.onerror = (err) => {
			console.error('Stream Error', err);
		};
	}

	private dispatchToSubjects(notification: any) {
		const eventType = notification.eventType;
		let subject = this.eventSubjects[eventType];
		if (!subject) {
			subject = new Subject<any>();
			this.eventSubjects[eventType] = subject;
		}

		const eventInstance = this.notifConstruct(notification);
		subject.next(eventInstance);
	}

	listenToEvent<T>(eventType: string): Observable<T> {
		if (!this.eventSubjects[eventType]) {
			this.eventSubjects[eventType] = new Subject<T>();
		}
		return this.eventSubjects[eventType].asObservable();
	}

	notifConstruct(notification: any) {
		switch (notification.eventType) {
			case 'VmStatus':
				return new VmStatusNotification(notification);
			case 'ChannelStateChange':
				return new ChannelStateChangeNotification(notification);
			case 'InstanceError':
				return new InstanceErrorNotification(notification);
			case 'DeploymentError':
				return new DeploymentErrorNotification(notification);
			case 'InstanceDeploymentStatus':
				return new InstanceDeploymentStatusNotification(notification);
			case 'InstanceStateChange':
				return new InstanceStateChangeNotification(notification);
			case 'EndpointClientStatus':
				return new DeploymentEndpointStatusNotification(notification);
			case 'NotificationAction':
				return new NotificationActionEvent(notification);
			case 'ArtifactValidated':
				return new ArtifactValidatedEvent(notification);
			default:
				return new i40Notification(notification);
		}
	}

	getInstanceEvents(instanceId: string, eventTypes: string[] = ['ChannelStateChange', 'VmStatus', 'InstanceDeploymentStatus', 'InstanceStateChange', 'ArtifactCompiled']): Observable<any> {
		const eventStreams = eventTypes.map((eventType) => this.listenToEvent<any>(eventType));
		const mergedStream = merge(...eventStreams);
		return mergedStream.pipe(
			filter((event) => {
				return event.instanceId.getId() === instanceId;
			}),
		);
	}

	getEndpointStatusEvents(): Observable<any> {
		return this.listenToEvent<any>('EndpointClientStatus');
	}

	getGlobalEvents(event_types: string[] = ['InstanceError', 'DeploymentError']): Observable<any> {
		const eventObservables = event_types.map((eventType) => this.listenToEvent<any>(eventType));
		return merge(...eventObservables);
	}

	getInstanceDeploymentStatusEvents(): Observable<any> {
		return this.listenToEvent<any>('InstanceDeploymentStatus').pipe(
			map((eventData) => {
				return this.notifConstruct(eventData);
			}),
		);
	}

	getInstanceInternalStatusEvents(): Observable<any> {
		return this.listenToEvent<any>('InstanceStateChange').pipe(
			map((eventData) => {
				return this.notifConstruct(eventData);
			}),
		);
	}

	getHistoryEvents(event_types = ['InstanceError', 'DeploymentError'], dismissed?: boolean) {
		let params = [];

		if (event_types.length) {
			event_types.forEach((e) => {
				params.push(`eventTypes=${e}`);
			});
		}

		if (dismissed !== undefined) {
			params.push(`dismissed=${dismissed}`);
		}

		return this.http.get(`${environment.apiHost + environment.apiUrl}/notifications?${params.join('&')}`, {withCredentials: true}).pipe(
			map((notifications: any[]) => {
				return notifications.map((n) => this.notifConstruct(n));
			}),
		);
	}

	dismissNotification(ids: number[]) {
		return this.http.put(`${environment.apiHost + environment.apiUrl}/notifications/dismiss`, ids);
	}

	deleteNotification(ids: string[]) {
		return this.http.delete(`${environment.apiHost + environment.apiUrl}/notifications`, {withCredentials: true, body: ids});
	}

	getNotificationActionEvents(): Observable<any> {
		return this.listenToEvent<any>('NotificationAction').pipe(
			map((eventData) => {
				if (Array.isArray(eventData)) {
					return eventData.map((e) => new NotificationActionEvent(e));
				} else {
					return [new NotificationActionEvent(eventData)];
				}
			}),
		);
	}

	getArtifactValidationEvents(): Observable<any[]> {
		return this.listenToEvent<any[]>('ArtifactValidated').pipe(
			map((result) => {
				if (Array.isArray(result)) {
					return result.map((e) => new ArtifactValidatedEvent(e));
				} else {
					return [new ArtifactValidatedEvent(result)];
				}
			}),
		);
	}
}
