<div fxFlex fxLayout="row" class="mat-typography">
	<div id="snackbar-text" fxFlex [ngClass]="data.type" class="mat-typography" fxLayoutAlign="start center" style="white-space: pre-wrap !important; text-align: left !important">
		{{ data.text }}
	</div>
	<div *ngIf="data.type == 'error'" fxFlex="20" fxLayoutAlign="end center">
		<button class="mat-typography" mat-button color="warn" (click)="close()">OK</button>
	</div>
	<div *ngIf="data.type !== 'error'" fxFlex="20" fxLayoutAlign="end center">
		<button class="mat-typography" mat-button (click)="close()">OK [{{ countDown(5) }}]</button>
	</div>
</div>
