import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {I40FlatMember} from '../../../types';
import {JSONSchemaProperty} from '../../../../../data-models/json-schema.model';
import {I40TreeChannelService} from '../../i40-tree-channel.service';
import {CredentialManagerResult} from '../../../../../apps/credential-management/credential-management-model';
import {CredentialManagementService} from '../../../../../apps/credential-management/credential-management.service';
import {map} from 'rxjs/operators';
import {MessageResponseService} from '../../../../../core-services/message-response.service';
import {AuthService} from '../../../../../core-services/auth.service';
import {GlobalService} from '../../../../../core-services/global.service';

@Component({
	selector: 'app-credentials-selector',
	templateUrl: './credentials-selector.component.html',
	styleUrls: ['./credentials-selector.component.css'],
})
export class CredentialsSelectorComponent implements OnInit {
	@Input() control: UntypedFormControl;
	@Input() selectedObject: I40FlatMember;
	@Input() property: JSONSchemaProperty;

	loading = true;
	credentials: CredentialManagerResult[] = [];
	type: string;

	constructor(
		public treeSrv: I40TreeChannelService,
		public credSrv: CredentialManagementService,
		public msgSrv: MessageResponseService,
		public authSrv: AuthService,
		public globalSrv: GlobalService,
	) {}

	ngOnInit(): void {
		this.loading = true;

		this.loadCredentials().subscribe((creds) => {
			this.loading = false;
		});

		switch (this.property.parent.key) {
			case 'PasswordConfigurationReference':
				this.type = 'password';
				break;
			case 'UserNameAndPasswordConfigurationReference':
				this.type = 'usernameandpassword';
				break;
			case 'TokenConfigurationReference':
				this.type = 'token';
				break;
		}
	}

	addCredentials() {
		this.msgSrv.addElementPopup('/modal/credential-management/add/' + this.type).subscribe((res) => {
			switch (res.action) {
				case 'close':
					// got nothing
					break;
				case 'close_reload':
					this.loading = true;
					this.loadCredentials().subscribe((creds) => {
						if (res.entry) {
							this.control.setValue(res.entry.id);
						}
						this.loading = false;
					});
					break;
			}
		});
	}

	loadCredentials() {
		return this.credSrv.getCredentialsInfo().pipe(
			map((res) => {
				this.credentials = res.map((r) => new CredentialManagerResult(r));
			}),
		);
	}
}
