import {ArtifactInfo, ExternalIdentifier, Identifier, Root, ValidationStatus} from '../../data-models/core-model.model';

//Model for table overview
//Model of setting with all infos
export class InstanceDeploymentSettings extends Root {
	_className = 'InstanceDeploymentSettings';

	public info: InstanceDeploymentSettingsInfo = new InstanceDeploymentSettingsInfo({}, this);
	public data: InstanceDeploymentSettingsData = new InstanceDeploymentSettingsData({}, this);

	constructor(input?: {info?: any; data?: any}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}
}

export class InstanceJmxProperties extends Root {
	_className = 'InstanceJmxProperties';

	jmxHostName: string = '';
	jmxAuthenticate: boolean = false;
	jmxPort: number = null;
	authenticate: boolean = false;
	localOnly: boolean = false;
	useSSL: boolean = false;

	constructor(input: {jmxHostName?: string; jmxAuthenticate?: boolean; jmxPort?: number; authenticate?: boolean; localOnly?: boolean; useSSL?: boolean}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}

	serialize() {
		const output: any = super.serialize();
		let n = Object.keys(output).length;
		Object.keys(output).forEach((k) => {
			if (output[k] === null) {
				delete output[k];
			}
		});
		if (Object.keys(output).length < n) {
			return null;
		}
		return output;
	}

	getDefaultValues() {
		return {
			jmxHostName: 'localhost',
			localOnly: true,
		};
	}
}

export class InstanceVmArgs extends Root {
	_className = 'InstanceVmArgs';

	Xms: string = '';
	Xmx: string = '';
	HeapDumpOnOutOfMemoryError: boolean = false;

	constructor(input: {Xms?: string; Xmx?: string; jmxPort?: number; HeapDumpOnOutOfMemoryError?: boolean}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}

	getDefaultValues() {
		return {
			Xms: '32m',
			Xmx: '256m',
			HeapDumpOnOutOfMemoryError: true,
		};
	}
}

//Deployment infos
export class InstanceDeploymentSettingsInfo extends Root {
	_className = 'InstanceDeploymentSettingsInfo';

	public instance: Identifier = new Identifier({}, this);
	public deploymentType: string = '';
	public loggingSettingsUuid: string = '';
	public locationUuid: string = '';
	public protectDeployment: boolean = false;
	public receiveNotifications: boolean = false;

	constructor(
		input?: {instance?: any; deploymentType?: string; loggingSettingsUuid?: string; locationUuid?: string; protectDeployment?: boolean; receiveNotifications?: boolean},
		parent?: Root,
		inherited: boolean = false,
	) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}
}

//Deployment Data - Docker or Local
export class InstanceDeploymentSettingsData extends Root {
	_className = 'InstanceDeploymentSettingsData';

	public settings: any = {};

	constructor(input?: {settings?: []}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}

	setSettings(settings, parent) {
		if (settings) {
			const classNameStr = Object.keys(settings)[0];
			const className = deploymentClasses[classNameStr];

			if (className) {
				const wrapperClassStr = classNameStr + 'Wrapper';
				const wrapperClass = deploymentClasses[wrapperClassStr];

				if (wrapperClass) {
					this.settings = new wrapperClass(settings, parent);
				} else {
					this.settings = {};
					this.settings[classNameStr] = new className(settings[classNameStr], parent);
				}
			}
		}
	}
}

//Local Deployment Settings
export class InstanceLocalDeploymentSettings extends Root {
	_className = 'InstanceLocalDeploymentSettings';

	public location: string = '';
	public jmxProperties: InstanceJmxProperties = new InstanceJmxProperties({}, this);
	public vmArgs: InstanceVmArgs = new InstanceVmArgs({}, this);
	public additionalArgs: string[] = [];
	public encryptConfiguration: boolean = false;
	public encryptionKeyLocation: string = '';

	constructor(
		input?: {location?: string; jmxProperties?: InstanceJmxProperties; vmArgs?: InstanceVmArgs; additionalArgs?: string[]; encryptConfiguration?: boolean; encryptionKeyLocation?: string},
		parent?: Root,
		inherited: boolean = false,
	) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}

	additionalArgsToFormControl() {
		return this.rootArrayToFormControl(this.additionalArgs);
	}
}

export class InstanceLocalDeploymentSettingsWrapper extends Root {
	_className = 'InstanceLocalDeploymentSettingsWrapper';

	_type: string = 'InstanceLocalDeploymentSettings';

	InstanceLocalDeploymentSettings: InstanceLocalDeploymentSettings = new InstanceLocalDeploymentSettings({}, this);

	constructor(input?: {InstanceLocalDeploymentSettings?: any}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}

	clear() {
		this[this._type] = new InstanceLocalDeploymentSettings({}, this);
	}

	serialize() {
		const output: any = super.serialize();
		delete output._type;
		return output;
	}
}

//SSH Deployment Settings
export class InstanceSshDeploymentSettings extends Root {
	_className = 'InstanceSshDeploymentSettings';

	public location: string = '';
	public jmxProperties: InstanceJmxProperties = new InstanceJmxProperties({}, this);
	public vmArgs: InstanceVmArgs = new InstanceVmArgs({}, this);
	public additionalArgs: string[] = [];
	public encryptConfiguration: boolean = false;
	public encryptionKeyLocation: string = '';

	constructor(
		input?: {location?: string; jmxProperties?: InstanceJmxProperties; vmArgs?: InstanceVmArgs; additionalArgs?: string[]; encryptConfiguration?: boolean; encryptionKeyLocation?: string},
		parent?: Root,
		inherited: boolean = false,
	) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}

	additionalArgsToFormControl() {
		return this.rootArrayToFormControl(this.additionalArgs);
	}
}

export class InstanceSshDeploymentSettingsWrapper extends Root {
	_className = 'InstanceSshDeploymentSettingsWrapper';

	_type: string = 'InstanceSshDeploymentSettings';

	InstanceSshDeploymentSettings: InstanceSshDeploymentSettings = new InstanceSshDeploymentSettings({}, this);

	constructor(input?: {InstanceSshDeploymentSettings?: any}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}

	clear() {
		this[this._type] = new InstanceSshDeploymentSettings({}, this);
	}

	serialize() {
		const output: any = super.serialize();
		delete output._type;
		return output;
	}
}

export class DockerVolume extends Root {
	_className = 'DockerVolume';

	public local: string = '';
	public container: string = '';

	constructor(input?: {local?: string; container?: string}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}
}

//Docker Deployment Settings
export class InstanceDockerDeploymentSettings extends Root {
	_className = 'InstanceDockerDeploymentSettings';

	public containerName: string = '';
	public ports: number[] = [];
	public image: string = '';
	public volumes: DockerVolume[] = [];
	public jmxProperties: InstanceJmxProperties = new InstanceJmxProperties({}, this);
	public vmArgs: InstanceVmArgs = new InstanceVmArgs({}, this);
	public additionalArgs: string[] = [];
	public encryptConfiguration: boolean = false;
	public encryptionKeyLocation: string = '';

	constructor(
		input?: {
			containerName?: string;
			ports?: number[];
			image?: string;
			volumes?: any[];
			jmxProperties?: InstanceJmxProperties;
			vmArgs?: InstanceVmArgs;
			encryptConfiguration?: boolean;
			encryptionKeyLocation?: string;
		},
		parent?: Root,
		inherited: boolean = false,
	) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}

	volumesToFormControl() {
		return this.rootArrayToFormControl(this.volumes);
	}

	additionalArgsToFormControl() {
		return this.rootArrayToFormControl(this.additionalArgs);
	}

	setVolumes(volumes) {
		if (volumes) {
			if (volumes instanceof Array) {
				this.volumes = volumes.map((v: any) => {
					return new DockerVolume(v);
				});
			} else {
				let arr = [];
				Object.keys(volumes).forEach((k) => {
					arr.push([k, volumes[k]]);
				});
				this.volumes = arr.map((v) => {
					return new DockerVolume({local: v[1], container: v[0]});
				});
			}
		}
	}

	serializeVolumes() {
		const arr = {};
		this.volumes.forEach((volume) => {
			arr[volume.container] = volume.local;
		});
		return arr;
	}
}

export class InstanceDockerDeploymentSettingsWrapper extends Root {
	_className = 'InstanceDockerDeploymentSettingsWrapper';

	_type: string = 'InstanceDockerDeploymentSettings';

	InstanceDockerDeploymentSettings: InstanceDockerDeploymentSettings = new InstanceDockerDeploymentSettings({}, this);

	constructor(input?: {InstanceDockerDeploymentSettings?: any}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}

	clear() {
		this[this._type] = new InstanceDockerDeploymentSettings({}, this);
	}

	serialize() {
		const output: any = super.serialize();
		delete output._type;
		return output;
	}
}

// AWS Deployment Settings
export class InstanceAwsDeploymentSettings extends Root {
	_className = 'InstanceAwsDeploymentSettings';

	vpcId: string = '';
	s3BucketName: string = '';
	codeBuildRole: string = '';
	taskExecutionRole: String = '';
	ecrRepositoryUri: string = '';
	ecrRepositoryName: string = '';
	clusterName: string = '';
	securityGroup: string = '';
	subnetName: string = '';
	taskMemory: string = '';
	taskCPU: string = '';
	ports: number[] = [];
	jmxProperties: InstanceJmxProperties = new InstanceJmxProperties({}, this);
	public vmArgs: InstanceVmArgs = new InstanceVmArgs({}, this);
	public additionalArgs: string[] = [];
	public encryptConfiguration: boolean = false;
	public encryptionKeyLocation: string = '';

	constructor(
		input?: {
			vpcId?: string;
			s3BucketName?: string;
			codeBuildRole?: string;
			taskExecutionRole?: string;
			ecrRepositoryUri?: string;
			ecrRepositoryName?: string;
			clusterName?: string;
			securityGroup?: string;
			subnetName?: string;
			taskMemory?: string;
			taskCPU?: string;
			ports?: number[];
			vmArgs?: InstanceVmArgs;
			additionalArgs?: string[];
			encryptConfiguration?: boolean;
			encryptionKeyLocation?: string;
		},
		parent?: Root,
		inherited: boolean = false,
	) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}

	additionalArgsToFormControl() {
		return this.rootArrayToFormControl(this.additionalArgs);
	}
}

export class InstanceAwsDeploymentSettingsWrapper extends Root {
	_className = 'InstanceAwsDeploymentSettingsWrapper';

	_type: string = 'InstanceAwsDeploymentSettings';

	InstanceAwsDeploymentSettings: InstanceAwsDeploymentSettings = new InstanceAwsDeploymentSettings({}, this);

	constructor(input?: {InstanceAwsDeploymentSettings?: any}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}

	clear() {
		this[this._type] = new InstanceAwsDeploymentSettings({}, this);
	}

	serialize() {
		const output: any = super.serialize();
		delete output._type;
		return output;
	}
}

// Agent Deployment Settings
export class InstanceAgentDeploymentSettings extends Root {
	_className = 'InstanceAgentDeploymentSettings';

	public location: string = '';
	public jmxProperties: InstanceJmxProperties = new InstanceJmxProperties({}, this);
	public vmArgs: InstanceVmArgs = new InstanceVmArgs({}, this);
	public additionalArgs: string[] = [];
	public encryptConfiguration: boolean = false;
	public encryptionKeyLocation: string = '';

	constructor(
		input?: {location?: string; jmxProperties?: InstanceJmxProperties; vmArgs?: InstanceVmArgs; additionalArgs?: string[]; encryptConfiguration?: boolean; encryptionKeyLocation?: string},
		parent?: Root,
		inherited: boolean = false,
	) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}

	additionalArgsToFormControl() {
		return this.rootArrayToFormControl(this.additionalArgs);
	}
}

export class InstanceAgentDeploymentSettingsWrapper extends Root {
	_className = 'InstanceAgentDeploymentSettingsWrapper';

	_type: string = 'InstanceAgentDeploymentSettings';

	InstanceAgentDeploymentSettings: InstanceAgentDeploymentSettings = new InstanceAgentDeploymentSettings({}, this);

	constructor(input?: {InstanceAwsDeploymentSettings?: any}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}

	clear() {
		this[this._type] = new InstanceAgentDeploymentSettings({}, this);
	}

	serialize() {
		const output: any = super.serialize();
		delete output._type;
		return output;
	}
}

//Instance runtime status
export class InstanceRuntimeStatus extends Root {
	_className = 'InstanceRuntimeStatus';

	public state: string = '';
	public instanceInformation: InstanceInformation = new InstanceInformation({}, this);

	constructor(input?: {state?: string; instanceInformation?: any}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}
}

//Summarized informations about the actual deployed instance
export class InstanceInformation extends Root {
	_className = 'InstanceInformation';

	public info: InstanceInformationInfo = new InstanceInformationInfo({}, this);
	public data: InstanceInformationData = new InstanceInformationData({}, this);

	constructor(input?: {info?: any; data?: any}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}
}

export class InstanceInformationInfo extends Root {
	_className = 'InstanceInformationInfo';

	public identifier: Identifier = new Identifier({}, this);
	public externalIdentifer: ExternalIdentifier = new ExternalIdentifier({}, this);
	public locationUuid: string = '';

	constructor(input?: {identifier?: Identifier; externalIdentifer?: ExternalIdentifier; locationUuid?: string}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}
}

export class InstanceInformationData extends Root {
	_className = 'InstanceInformationData';

	public communicationLogFile: string = '';
	public statusLogFile: string = '';
	public vmLogFile: string = '';
	public channels: ChannelInformation = new ChannelInformation({}, this);
	public mappings: ArtifactInfo = new ArtifactInfo({}, this);

	constructor(input?: {communicationLogFile?: string; statusLogFile?: string; vmLogFile?: string; channels?: any; mappings?: any}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}
}

export class ChannelInformation extends Root {
	_className = 'ChannelInformation';

	public identifer: Identifier = new Identifier({}, this);
	public model: Identifier = new Identifier({}, this);
	public description: string = '';
	public implementationType: string = '';

	constructor(input?: {identifer?: any; model?: any; description?: string; implementationType?: string}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}
}

export class BaseImage extends Root {
	_className = 'BaseImage';

	info: BaseImageInfo = new BaseImageInfo();
	data: BaseImageData = new BaseImageData();

	constructor(input?: {info?: BaseImageInfo; data?: BaseImageData}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}
}

export class BaseImageInfo extends Root {
	_className = 'BaseImageInfo';

	identifier: Identifier = new Identifier();
	externalIdentifier: ExternalIdentifier = new ExternalIdentifier();

	constructor(input?: {identifier?: string; externalIdentifier?: ExternalIdentifier}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}
}

export class BaseImageData extends Root {
	_className = 'BaseImageData';

	additionalArgs: string[] = [];
	tag: string = '';
	parentTag: string = '';

	constructor(input?: {additionalArgs: string[]; tag: string; parentTag: string}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}
}

export class ModuleDescriptor extends Root {
	_className = 'ModuleDescriptor';

	moduleVersionIdentifier: ModuleVersionIdentifier = new ModuleVersionIdentifier();
	description: string = '';

	constructor(input?: {moduleVersionIdentifier?: ModuleVersionIdentifier; description?: string}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}
}

export class ModuleVersionIdentifier extends Root {
	_className = 'ModuleVersionIdentifier';

	group: string = '';
	name: string = '';
	version: string = '';

	constructor(input?: {group?: string; name?: string; version?: string}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}
}

export enum InstanceState {
	Deploying = 'Deploying',
	Starting = 'Starting',
	Started = 'Started',
	Connected = 'Connected',
	Disconnected = 'Disconnected',
	Stopping = 'Stopping',
	Stopped = 'Stopped',
	Error = 'Error',
	Unknown = 'Unknown',
	Undeploying = 'Undeploying',
	NotDeployed = 'NotDeployed',
}

export class InstanceDeploymentInfo extends Root {
	_className = 'InstanceDeploymentInfo';

	info: InstanceDeploymentSettingsInfo = new InstanceDeploymentSettingsInfo({}, this);
	instanceInternalState: string = 'Unknown';
	state: InstanceState = InstanceState.Unknown;
	error?: String;

	constructor(input?: {info?: InstanceDeploymentInfo; instanceInternalState?: string; state?: InstanceState; error?: String}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
		this.instanceInternalState = this.instanceInternalState.replace('Instance', '').replace('State', '');
	}
}

export class InstanceDeploymentInfoView extends Root {
	_className = 'InstanceDeploymentInfoView';

	info: InstanceDeploymentInfo = new InstanceDeploymentInfo({}, this);
	status: ValidationStatus = new ValidationStatus({}, this);

	constructor(input?: {info?: InstanceDeploymentInfo; status?: ValidationStatus}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}
}

export const deploymentClasses = {
	InstanceDeploymentSettings,
	InstanceDeploymentSettingsInfo,
	InstanceDeploymentSettingsData,
	InstanceLocalDeploymentSettings,
	InstanceLocalDeploymentSettingsWrapper,
	InstanceSshDeploymentSettings,
	InstanceSshDeploymentSettingsWrapper,
	InstanceDockerDeploymentSettings,
	InstanceDockerDeploymentSettingsWrapper,
	InstanceAwsDeploymentSettings,
	InstanceAwsDeploymentSettingsWrapper,
	InstanceAgentDeploymentSettings,
	InstanceAgentDeploymentSettingsWrapper,
	InstanceRuntimeStatus,
	InstanceInformation,
	InstanceInformationInfo,
	InstanceInformationData,
	ChannelInformation,
	InstanceJmxProperties,
	InstanceVmArgs,
	DockerVolume,
};
