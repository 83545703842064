import {Component, OnInit} from '@angular/core';
import {MessageResponseService} from '../../core-services/message-response.service';
import {AuthService} from '../../core-services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../../core-services/api.service';
import {TranslateService} from '@ngx-translate/core';
import {ThemeService} from '../../core-services/theme.service';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
	selector: 'app-wrapper-modal',
	templateUrl: './wrapper-modal.component.html',
	styleUrls: ['./wrapper-modal.component.css'],
})
export class WrapperModalComponent implements OnInit {
	constructor(
		public authSrv: AuthService,
		public router: Router,
		public api: ApiService,
		public route: ActivatedRoute,
		public translate: TranslateService,
		public themeService: ThemeService,
		public msgSrv: MessageResponseService,
		public iconRegistry: MatIconRegistry,
		public sanitizer: DomSanitizer,
	) {
		// custom icons
		iconRegistry.addSvgIcon('ico-manage-accounts', this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Manage-Accounts.svg'));
		iconRegistry.addSvgIcon('ico-deployment', this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Deployment.svg'));
		iconRegistry.addSvgIcon('ico-deploy', this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Deploy.svg'));
		iconRegistry.addSvgIcon('ico-undeploy', this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Un-Deploy.svg'));
		iconRegistry.addSvgIcon('ico-device-types', this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Device-Types.svg'));
		iconRegistry.addSvgIcon('ico-export', this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Export.svg'));
		iconRegistry.addSvgIcon('ico-import', this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Import.svg'));
		iconRegistry.addSvgIcon('ico-information-models', this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Information-Model.svg'));
		iconRegistry.addSvgIcon('ico-re-index', this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Re-Index-Repository.svg'));
	}

	ngOnInit(): void {
		this.msgSrv.weAreInAModal = true;
	}
}
