<mat-form-field fxFlex>
	<mat-select id="credentials_selector_select" [formControl]="control" placeholder="Credentials Manager Selector" [disabled]="loading || !globalSrv.canEdit(treeSrv.rootParent)">
		<mat-option (click)="addCredentials()">+ Add credentials</mat-option>
		<mat-divider></mat-divider>
		<mat-option *ngFor="let item of credentials" [value]="item.id">
			{{ item.name }}
		</mat-option>
	</mat-select>
	<mat-error *ngIf="control.errors?.message && control.value.length > 0">{{ control.errors.message }}</mat-error>
</mat-form-field>
