import * as moment from 'moment';
import {ArtifactInfo, ExternalIdentifier, Identifier} from '../../data-models/core-model.model';

export type NotificationEventType =
	| 'VmStatus'
	| 'ChannelStateChange'
	| 'InstanceStateChange'
	| 'InstanceError'
	| 'DeploymentError'
	| 'InstanceDeploymentStatus'
	| 'EndpointClientStatus'
	| 'EndpointClientStatus';
export type DeploymentEndpointNotificationState = 'Starting' | 'Running' | 'Stopping' | 'Stopped' | 'Failure';

export class i40Notification {
	id: number;
	eventType: NotificationEventType;
	eventTime: moment.Moment; // "2021-10-26T15:34:20.748408+03:00[Europe/Bucharest]"
	read: boolean = false;
	dismissedBy: any = null;

	constructor(notification: {id: number; eventType: NotificationEventType; eventTime: string; dismissedBy: boolean}) {
		this.id = notification.id;
		this.eventType = notification.eventType;
		this.eventTime = notification.eventTime ? moment(String(notification.eventTime).split('[')[0]) : moment();
		this.dismissedBy = notification.dismissedBy ?? false;
		if (notification.dismissedBy) {
			this.read = true;
		}
	}
}

export class VmStatusNotification extends i40Notification {
	metrics: any;
	instanceId: Identifier;

	constructor(notification: {id: number; eventType: NotificationEventType; eventTime: string; dismissedBy: boolean; metrics: any; instanceId: any}) {
		super(notification);
		this.metrics = notification.metrics;
		this.instanceId = new Identifier(notification.instanceId);
	}
}

export class InstanceDeploymentStatusNotification extends i40Notification {
	state: string;
	instanceId: Identifier;

	constructor(notification: {id: number; eventType: NotificationEventType; eventTime: string; dismissedBy: boolean; state: string; instanceId: any}) {
		super(notification);
		this.state = notification.state;
		this.instanceId = new Identifier(notification.instanceId);
	}
}

export class InstanceStateChangeNotification extends i40Notification {
	toState: string;
	fromState: string;
	instanceId: Identifier;

	constructor(notification: {id: number; eventType: NotificationEventType; eventTime: string; dismissedBy: boolean; toState: string; fromState: string; instanceId: any}) {
		super(notification);
		this.toState = notification.toState.replace('Instance', '').replace('State', '');
		this.fromState = notification.fromState.replace('Instance', '').replace('State', '');
		this.instanceId = new Identifier(notification.instanceId);
	}
}

export class DeploymentEndpointStatusNotification extends i40Notification {
	endpointUUID: string = '';
	state: DeploymentEndpointNotificationState = 'Stopped';
	message: string = '';

	constructor(notification: {
		id: number;
		eventType: NotificationEventType;
		eventTime: string;
		dismissedBy: boolean;
		state: DeploymentEndpointNotificationState | 'Failure';
		message: string;
		endpointUUID: string;
	}) {
		super(notification);

		this.state = notification.state;
		this.endpointUUID = notification.endpointUUID;
		this.message = notification.message;
	}
}

export class ChannelStateChangeNotification extends i40Notification {
	states: any[];
	instanceId: Identifier;

	constructor(notification: {id: number; eventType: NotificationEventType; eventTime: string; dismissedBy: boolean; states: any[]; instanceId: any}) {
		super(notification);
		this.states = notification.states;
		this.instanceId = new Identifier(notification.instanceId);
	}
}

export class InstanceErrorNotification extends i40Notification {
	message: string;
	instanceExternalId: ExternalIdentifier;
	instanceId: Identifier;

	constructor(notification: {id: number; eventType: NotificationEventType; eventTime: string; dismissedBy: boolean; message: string; instanceExternalId: any; instanceId: any}) {
		super(notification);

		this.message = notification.message;
		this.instanceExternalId = new ExternalIdentifier(notification.instanceExternalId);
		this.instanceId = new Identifier(notification.instanceId);
	}
}

export class DeploymentErrorNotification extends i40Notification {
	message: string;
	instanceExternalId: ExternalIdentifier;
	instanceId: Identifier;

	constructor(notification: {id: number; eventType: NotificationEventType; eventTime: string; dismissedBy: boolean; message: string; instanceExternalId: any; instanceId: any}) {
		super(notification);

		this.message = notification.message;
		this.instanceExternalId = new ExternalIdentifier(notification.instanceExternalId);
		this.instanceId = new Identifier(notification.instanceId);
	}
}

export class NotificationActionEvent {
	notificationId: number[];
	action: string;

	constructor(notification: {eventType: 'NotificationAction'; eventTime: string; notificationId: number[]; action: 'dismiss' | 'delete'}) {
		this.notificationId = notification.notificationId;
		this.action = notification.action;
	}
}

export class ArtifactValidationResult {
	info: ArtifactInfo;
	messages: ValidationMessage[];
	timestamp: number;
	valid: boolean;
	formatedTime: string;

	constructor(o: {info: any; messages: any[]; timestamp: any; valid: boolean}) {
		this.info = new ArtifactInfo(o.info);
		if (o.messages) {
			this.messages = o.messages.map((m) => new ValidationMessage(m));
		}
		this.timestamp = o.timestamp;
		this.formatedTime = this.toFormatedTime(this.timestamp);
		this.valid = o.valid;
	}

	toFormatedTime(timestamp: number): string {
		var date = new Date(timestamp * 1000);
		var hours = date.getHours();
		var minutes = '0' + date.getMinutes();
		var seconds = '0' + date.getSeconds();
		var formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
		return formattedTime;
	}
}

export class ValidationMessage {
	file: string;
	line: number;
	message: string;
	level: string = 'error';

	constructor(o: {file: string; line: number; message: string; level: string}) {
		this.file = o.file;
		this.line = o.line;
		this.message = o.message;
		this.level = o.level;
	}
}

export class ArtifactValidatedEvent {
	eventType: string;
	eventTime: string;
	status: ArtifactValidationResult;

	constructor(notification: {eventType: string; eventTime: string; status: any}) {
		this.eventType = notification.eventType;
		this.eventTime = notification.eventTime;
		this.status = new ArtifactValidationResult(notification.status);
		if (this.status.valid == undefined) {
			if (notification.status && notification.status._type && notification.status._type == 'com.amorphsys.smartunifier.manager.validation.ArtifactValid') {
				this.status.valid = true;
			} else {
				this.status.valid = false;
			}
		}
	}
}
