import {AfterViewInit, Component, Input, OnChanges, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {I40FlatMember} from '../../../types';
import {JSONSchemaProperty, SchemaLayer} from '../../../../../data-models/json-schema.model';
import {I40TreeChannelService} from '../../i40-tree-channel.service';
import {AuthService} from '../../../../../core-services/auth.service';
import {GlobalService} from '../../../../../core-services/global.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
	selector: 'app-anyof',
	templateUrl: './anyof.component.html',
	styleUrls: ['./anyof.component.css'],
})
export class AnyofComponent implements OnInit, OnChanges {
	@Input() form: UntypedFormGroup;
	@Input() selectedObject: I40FlatMember;
	@Input() property: JSONSchemaProperty;

	selectedElement: any = '';
	typeHasError: boolean = true;

	constructor(
		public treeSrv: I40TreeChannelService,
		public authSrv: AuthService,
		public globalSrv: GlobalService,
		private translate: TranslateService,
	) {}

	ngOnInit(): void {
		/*
		this.property.anyOf.forEach(p => {
			if(p.description) {
				p.description = this.translate.instant(p.description)
			} 
		})
		*/
		this.globalSrv.sortSelect(this.property.anyOf, 'description', 'key');
	}

	ngOnChanges() {
		if (!this.property.isRequired) {
			this.typeHasError = false;
			if (Object.keys(this.form.value).length) {
				this.form.enable();
			} else {
				this.form.disable();
			}
		} else {
			this.form.enable();
		}

		if (this.form.value['type']) {
			this.property.anyOf.forEach((p: JSONSchemaProperty) => {
				if (p.key === this.form.value['type']) {
					this.selectedElement = p;
					this.typeHasError = false;
				}
			});
		}

		if (this.typeHasError) {
			this.form.setErrors({empty: true});
		}

		if (!this.globalSrv.canEdit(this.treeSrv.rootParent)) {
			this.form.disable();
		}
	}

	selectionChanged(e: JSONSchemaProperty) {
		if (e) {
			if (e?.key) {
				this.form.enable();
				Object.entries(this.form.controls).forEach(([c, i]) => {
					this.form.removeControl(c);
				});
				SchemaLayer.generateForm(e.properties, e.default ?? {}, this.form, []);
				this.form.addControl('type', new UntypedFormControl(e.key));
				this.typeHasError = false;
				this.form.markAsDirty();
			}
		} else {
			if (!this.property.isRequired) {
				Object.entries(this.form.controls).forEach(([c, i]) => {
					this.form.removeControl(c);
				});
				this.typeHasError = false;
				this.form.parent.markAsDirty();
				this.form.disable();
			}
		}

		if (this.typeHasError) {
			this.form.setErrors({empty: true});
		}
	}
}
