import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {ChannelTypeInfo, ChannelTypeManagerResult, ImplementationType} from './channel-types-model';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable()
export class ChannelTypesService {
	constructor(private http: HttpClient) {}

	// list
	getChannelTypeInfos() {
		return this.http.get<ChannelTypeInfo[]>(`${environment.apiHost + environment.apiUrl}/channel-types`).pipe(
			map((res) => {
				return res.map((cht) => new ChannelTypeInfo(cht));
			}),
		);
	}

	// add
	addChannelType(ct: ChannelTypeManagerResult) {
		return this.http.post(`${environment.apiHost + environment.apiUrl}/channel-types`, ct.serialize()).pipe(
			map((result: ChannelTypeManagerResult) => {
				return new ChannelTypeManagerResult(result);
			}),
		);
	}

	// update
	updateChannelType(ct: ChannelTypeManagerResult) {
		return this.http.put(`${environment.apiHost + environment.apiUrl}/channel-types`, ct.serialize()).pipe(
			map((result: ChannelTypeManagerResult) => {
				return new ChannelTypeManagerResult(result);
			}),
		);
	}

	// delete
	deleteChannelTypeById(id: string) {
		return this.http.delete<ChannelTypeInfo>(`${environment.apiHost + environment.apiUrl}/channel-types/${id}`);
	}

	// get one
	getChannelTypeById(id: string) {
		return this.http.get<ChannelTypeManagerResult>(`${environment.apiHost + environment.apiUrl}/channel-types/${id}`).pipe(
			map((res) => {
				return new ChannelTypeManagerResult(res);
			}),
		);
	}

	// implementations
	getImplementationTypes(): Observable<ImplementationType[]> {
		return this.http.get<ImplementationType[]>(`${environment.apiHost + environment.apiUrl}/channel/implementations`);
	}

	// layers
	getImplementationLayers(): Observable<ImplementationType[]> {
		return this.http.get<ImplementationType[]>(`${environment.apiHost + environment.apiUrl}/channel/layers`);
	}

	getLayersDetails(layers: string[]): Observable<any[]> {
		return this.http.post<any[]>(`${environment.apiHost + environment.apiUrl}/channel/layers-details`, layers);
	}
}
