import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({
	name: 'logparser',
})
export class LogparserPipe implements PipeTransform {
	constructor() {}

	transform(value: any): any {
		let replacedValue = value;

		// date
		const re_date = new RegExp(/\d{4}\-\d{2}\-\d{2}\s\d{2}\:\d{2}\:\d{2}\,\d+/gim);
		const match_date = replacedValue.match(re_date);

		if (match_date) {
			replacedValue = replacedValue.replace(re_date, "<span class='date_block'>" + match_date[0] + '</span>');
		}

		// []
		const re_block = new RegExp(/\[(INFO|DEBUG|WARN|ERROR)?\]/gim);
		const match_block = replacedValue.match(re_block);

		let extraClass = ' ';

		if (match_block) {
			if (match_block[0] === '[WARN ]') {
				extraClass += 'warn';
			}
			if (match_block[0] === '[ERROR]') {
				extraClass += 'error';
			}
			replacedValue = replacedValue.replace(re_block, "<span class='bracket_block" + extraClass + "'>" + match_block[0] + '</span>');
		}

		return replacedValue;
	}
}
