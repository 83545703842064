import {Component, Input, OnInit} from '@angular/core';
import {AuthService} from '../../../core-services/auth.service';
import {ChannelInfo, ChannelManagerResult} from '../../channel/channel.model';
import {DeviceTypeChannel} from '../device-type.model';
import {DeviceTypeDataService} from '../device-type-data.service';
import {ChannelService} from '../../channel/channel.service';
import {ValidatorService} from '../../../core-services/validator.service';
import {GlobalService} from '../../../core-services/global.service';
import * as _ from 'lodash';

@Component({
	selector: 'app-device-type-mapping-channel',
	templateUrl: './device-type-mapping-channel.component.html',
	styleUrls: ['./device-type-mapping-channel.component.scss'],
})
export class DeviceTypeMappingChannelComponent implements OnInit {
	@Input() channelIndex: number;
	@Input() mappingIndex: number;
	@Input() channelInfoList: ChannelInfo[];

	modelChannel: DeviceTypeChannel;
	loadingChannel = false;

	constructor(
		public authSrv: AuthService,
		public dataSrv: DeviceTypeDataService,
		public channelService: ChannelService,
		public validatorSrv: ValidatorService,
		public globalSrv: GlobalService,
	) {}

	ngOnInit() {
		this.modelChannel = this.dataSrv.deviceTypeResult.mappings[this.mappingIndex].channels[this.channelIndex];
	}

	compareChannels(c1: any, c2: any) {
		const channel2 = new ChannelInfo(c2);
		return c1.identifier.id === channel2.identifier.id && c1.identifier.version === channel2.identifier.version;
	}

	showConfigurationClick() {
		this.dataSrv.selectedChannelId = this.modelChannel.channel.info.getExternalId();
		this.dataSrv.selectedChannel = this.dataSrv.channelsMap.get(this.dataSrv.selectedChannelId);

		this.dataSrv.showChannelConfigurationEditor = true;
	}

	channelSelectionChange(event) {
		const channelInfo: ChannelInfo = new ChannelInfo(event.value);

		const channelInfoId: string = channelInfo.getExternalId();
		const found: ChannelManagerResult = this.dataSrv.channelsMap.get(channelInfoId);

		this.loadingChannel = true;

		if (!found) {
			this.channelService.getChannel(channelInfo.identifier.getId()).subscribe((chanel) => {
				const channelClone = _.cloneDeep(chanel);
				this.dataSrv.channelsMap.set(channelInfoId, channelClone);

				this.modelChannel.channel.set(chanel);
				this.modelChannel.channel._formGroup.markAsDirty();
				this.loadingChannel = false;
			});
		} else {
			this.modelChannel.channel._formGroup.markAsDirty();
			this.loadingChannel = false;
		}
	}
}
