<div class="group-filter-wrapper" [ngClass]="{collapsed: !listSrv.groupFilterVisible}" style="height: 100%; overflow: auto; border-right: 1px solid rgba(255, 255, 255, 0.12)">
	<div style="padding: 16px; display: flex; align-items: center" [ngStyle]="{'flex-direction': listSrv.groupFilterVisible ? 'row' : 'column'}">
		<span *ngIf="listSrv.groupFilterVisible">Group Filter</span>
		<span *ngIf="listSrv.groupFilterVisible" fxFlex></span>
		<button mat-icon-button class="small_icon" (click)="listSrv.toggleGroupFilters()">
			<mat-icon>{{ listSrv.groupFilterVisible ? 'chevron_left' : 'chevron_right' }}</mat-icon>
		</button>
		<span *ngIf="!listSrv.groupFilterVisible" style="transform: rotate(-90deg); white-space: nowrap; margin-top: 52px">GROUP FILTER</span>
	</div>
	<mat-tree [dataSource]="listSrv.groupFilterData" [treeControl]="listSrv.groupFilterControl" class="group-filter-tree" *ngIf="listSrv.groupFilterVisible">
		<!-- This is the tree node template for leaf nodes -->
		<mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
			<li class="mat-tree-node" [ngClass]="{selected: listSrv.selectedGroupFilter !== null && node.path === listSrv.selectedGroupFilter.path}">
				<div class="mat-tree-node">
					<!-- use a disabled button to provide padding for tree leaf -->
					<button mat-icon-button disabled class="small_icon"></button>
					<span class="clickable-element tree-group-filter-node" (click)="listSrv.filterByGroup(node)">{{ node.name }}</span>
				</div>
			</li>
		</mat-tree-node>
		<!-- This is the tree node template for expandable nodes -->
		<mat-nested-tree-node *matTreeNodeDef="let node; when: listSrv.groupFilterTreeHasChild">
			<li>
				<div class="mat-tree-node" [ngClass]="{selected: listSrv.selectedGroupFilter !== null && node.path === listSrv.selectedGroupFilter.path}">
					<button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name" class="small_icon">
						<mat-icon class="mat-icon-rtl-mirror">
							{{ listSrv.groupFilterControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
						</mat-icon>
					</button>
					<span class="clickable-element tree-group-filter-node" (click)="listSrv.filterByGroup(node)">{{ node.name }}</span>
				</div>
				<ul [class.group-filter-tree-invisible]="!listSrv.groupFilterControl.isExpanded(node)">
					<ng-container matTreeNodeOutlet></ng-container>
				</ul>
			</li>
		</mat-nested-tree-node>
	</mat-tree>
</div>
