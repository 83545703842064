import {ArtifactInfo, Identifier, ModelInfo, Root} from '../../data-models/core-model.model';
import {ChannelManagerResult} from '../channel/channel.model';
import {MappingInfo} from '../mapping/mapping-model';

export class DeviceTypeManagerResult extends Root {
	_className = 'DeviceTypeManagerResult';

	public info: ArtifactInfo = new ArtifactInfo({}, this);
	public mappings: DeviceTypeMapping[] = [];

	constructor(input?: {info?: any; mappings?: any[]}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}

	artifactIdentifier(): Identifier {
		return this.info.identifier;
	}

	setMappings(mappings, parent) {
		if (mappings) {
			this.mappings = this.toInstanceArray(DeviceTypeMapping, mappings, parent);
		}
	}

	serializeMappings() {
		return this.toSimpleArray(this.mappings);
	}

	mappingsToFormControl() {
		return this.rootArrayToFormControl(this.mappings);
	}

	serialize() {
		const output: any = super.serialize();

		output.version = output.info.identifier.version;

		return output;
	}
}

export class DeviceTypeMapping extends Root {
	_className = 'DeviceTypeMapping';

	public mappingInfo: MappingInfo = new MappingInfo({}, this);
	public channels: DeviceTypeChannel[] = [];

	constructor(input?: {mappingInfo?: any; channels?: any[]}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}

	setChannels(channelList, parent) {
		if (channelList) {
			this.channels = this.toInstanceArray(DeviceTypeChannel, channelList, parent);
		}
	}

	serializeChannels() {
		return this.toSimpleArray(this.channels);
	}

	channelsToFormControl() {
		return this.rootArrayToFormControl(this.channels);
	}
}

export class DeviceTypeChannel extends Root {
	_className = 'DeviceTypeChannel';

	public modelInfo: ModelInfo = new ModelInfo({}, this);
	public channel: ChannelManagerResult = new ChannelManagerResult({}, this);

	constructor(input?: {modelInfo?: any; channelInfo?: any; channelTypeConfigurations?: any}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}
}

export const deviceClasses = {DeviceTypeManagerResult, DeviceTypeMapping, DeviceTypeChannel};
