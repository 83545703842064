<div fxFlex fxLayout="column">
	<div fxFlex class="wrapper">
		<span>{{ 'i18n.arguments' | translate }}</span>
	</div>
	<mat-expansion-panel hideToggle class="nonexpandable" style="margin-bottom: 8px; padding: 4px" fxLayout="column" [expanded]="true">
		<div fxFlex fxLayout="column" fxLayoutGap="4px">
			<div fxFlex class="wrapper">
				<mat-checkbox #jmx (change)="onCheckJxm($event)" [disabled]="!globalSrv.canEdit() || isDisabled" class="jmx_properties_checkbox">{{ 'i18n.jmxProperties' | translate }}</mat-checkbox>
			</div>
			<div fxFlex *ngIf="jmx.checked && form.get('jmxProperties')">
				<div fxFlex fxLayout="column" fxLayoutGap="4px" style="padding: 10px">
					<mat-form-field subscriptSizing="dynamic" fxFlex>
						<mat-label>{{ 'i18n.jmxHostName' | translate }}</mat-label>
						<input matInput type="text" [formControl]="dataModel.jmxProperties.getFormControl('jmxHostName')" required />
					</mat-form-field>
					<mat-form-field subscriptSizing="dynamic" fxFlex>
						<mat-label>{{ 'i18n.jmxPort' | translate }}</mat-label>
						<input matInput type="number" [formControl]="dataModel.jmxProperties.getFormControl('jmxPort')" required />
						<mat-error *ngIf="form.get('jmxProperties').get('jmxPort').errors">{{ form.get('jmxProperties').get('jmxPort').errors.message }}</mat-error>
					</mat-form-field>
					<mat-checkbox [formControl]="dataModel.jmxProperties.getFormControl('jmxAuthenticate')" class="jmx_auth_checkbox">{{ 'i18n.jmxAuthenticate' | translate }}</mat-checkbox>
					<mat-checkbox [formControl]="dataModel.jmxProperties.getFormControl('authenticate')" class="auth_checkbox">{{ 'i18n.authenticate' | translate }}</mat-checkbox>
					<mat-checkbox [formControl]="dataModel.jmxProperties.getFormControl('localOnly')" class="local_only_checkbox">{{ 'i18n.localOnly' | translate }}</mat-checkbox>
					<mat-checkbox [formControl]="dataModel.jmxProperties.getFormControl('useSSL')" class="use_ssl_checkbox">{{ 'i18n.useSSL' | translate }}</mat-checkbox>
				</div>
			</div>

			<div fxFlex class="wrapper">
				<div>{{ 'i18n.defaultArguments' | translate }}</div>
			</div>
			<div fxFlex *ngIf="form.get('vmArgs')">
				<div fxFlex fxLayout="column" fxLayoutGap="4px" style="padding: 10px">
					<mat-form-field subscriptSizing="dynamic" fxFlex>
						<mat-label>{{ 'i18n.xms' | translate }}</mat-label>
						<input id="xms_input" matInput [readonly]="isDisabled" type="text" [formControl]="dataModel.vmArgs.getFormControl('Xms')" required />
					</mat-form-field>
					<mat-form-field subscriptSizing="dynamic" fxFlex>
						<mat-label>{{ 'i18n.xmx' | translate }}</mat-label>
						<input id="xmx_input" matInput [readonly]="isDisabled" type="text" [formControl]="dataModel.vmArgs.getFormControl('Xmx')" required />
					</mat-form-field>
					<mat-checkbox [disabled]="isDisabled" [formControl]="dataModel.vmArgs.getFormControl('HeapDumpOnOutOfMemoryError')" class="heap_dump_checkbox">{{
						'i18n.heapDumpOnOutOfMemoryError' | translate
					}}</mat-checkbox>
				</div>
			</div>
			<div fxFlex class="wrapper" fxLayoutAlign="center center">
				<div fxFlex>{{ 'i18n.Additional' | translate }}</div>
				<button
					[disabled]="isDisabled"
					fxFlex="2"
					style="height: 24px"
					mat-icon-button
					color="accent"
					*ngIf="globalSrv.canEdit()"
					(click)="add(form.get('additionalArgs'))"
					matTooltip="{{ 'i18n.addArg' | translate }}"
				>
					<mat-icon>add</mat-icon>
				</button>
			</div>
			<div fxFlex fxLayout="column">
				<div *ngFor="let arg of getFormArray(form.get('additionalArgs'))?.controls; let i = index" fxFlex fxLayout="column" fxLayoutGap="4px" style="padding: 10px">
					<div fxFlex fxLayout="row" style="padding-left: 5px">
						<mat-form-field subscriptSizing="dynamic" fxFlex>
							<mat-label>{{ 'i18n.argument' | translate }}</mat-label>
							<input #name matInput id="arg_input" [formControl]="dataModel.getFormArrayItemFormControl('additionalArgs', i)" />
						</mat-form-field>
						<div fxFlex="5" fxLayoutAlign="center center">
							<button id="delete_arg_button" mat-icon-button color="accent" (click)="delete(form.get('additionalArgs'), i)" matTooltip="{{ 'i18n.deleteArg' | translate }}">
								<mat-icon>delete_outline</mat-icon>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</mat-expansion-panel>
</div>
