import {ChangeDetectorRef, Component, Inject, OnInit, Input} from '@angular/core';
import {ArtifactInfo, Identifier, Root} from '../../../../data-models/core-model.model';
import {OpcuaModelGeneratorItem} from '../opcua-model-generator-plugin.model';
import {FormGroup, UntypedFormControl} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../../../core-services/auth.service';
import {MessageResponseService} from '../../../../core-services/message-response.service';
import {TranslateService} from '@ngx-translate/core';
import {ValidatorService} from '../../../../core-services/validator.service';
import {GlobalService} from '../../../../core-services/global.service';
import {PluginsService} from '../../plugins.service';
import {ThemeService} from '../../../../core-services/theme.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatTabChangeEvent} from '@angular/material/tabs';

@Component({
	selector: 'opcua-model-generator-modal-add',
	templateUrl: './opcua-model-generator-modal-add.component.html',
	styleUrls: ['./opcua-model-generator-modal-add.component.scss'],
})
export class OpcuaModelGeneratorModalAddComponent implements OnInit {
	mat_icon_type: string = 'extension';
	mat_title: string = 'OpcUa Model Generator Plugin';

	selectedType: 'OpcUa' | 'XML' = 'XML';

	plugin_add: any;

	definitionTypes: string[] = ['Model', 'Event', 'Variable'];
	securityTypes: string[] = ['None', 'Basic128Rsa15', 'Basic256', 'Basic256Sha256', 'Aes128_Sha256_RsaOaep', 'Aes256_Sha256_RsaPs'];

	constructor(
		public router: Router,
		public route: ActivatedRoute,
		public authSrv: AuthService,
		public msgSrv: MessageResponseService,
		public translate: TranslateService,
		public validatorSrv: ValidatorService,
		public globalSrv: GlobalService,
		public pluginsSrv: PluginsService,
		public cdRef: ChangeDetectorRef,
		public themeSrv: ThemeService,
		public dialogRef: MatDialogRef<OpcuaModelGeneratorModalAddComponent>,
		@Inject(MAT_DIALOG_DATA) public modalData,
	) {
		this.plugin_add = {
			OpcUa: new OpcuaModelGeneratorItem({_type: 'OpcUa', item: {}}),
			XML: new OpcuaModelGeneratorItem({_type: 'XML', item: {}}),
		};

		this.router.routeReuseStrategy.shouldReuseRoute = () => false;
	}

	add(controls) {
		controls.push(new UntypedFormControl(''));
		this.cdRef.detectChanges();
	}

	delete(controls, i) {
		controls.removeAt(i);
		this.cdRef.detectChanges();
	}

	ngOnInit(): void {}

	changedTypeTab(event: MatTabChangeEvent) {
		if (event.index === 0) this.selectedType = 'XML';
		if (event.index === 1) this.selectedType = 'OpcUa';
	}

	run() {
		this.msgSrv.onLoading.next({command: 'start', message: this.translate.instant('i18n.snackbar.running')});
		this.plugin_add[this.selectedType].fromFormControls(this.plugin_add[this.selectedType]._formGroup);

		switch (this.selectedType) {
			case 'OpcUa':
				this.pluginsSrv.runPlugin('OpcUa model generator', {OpcUaToModelImportAction: this.plugin_add[this.selectedType].serialize()}).subscribe({
					next: (res) => {
						this.msgSrv.onLoading.next({command: 'stop'});
						this.msgSrv.showSuccess('Plugin run successfully');
						this.plugin_add[this.selectedType]._formGroup.markAsPristine();
						this.close();

						if (res['OpcUaToModelImportActionResult']) {
							const new_id_ver = new Identifier(res['OpcUaToModelImportActionResult'].channel.info.identifier).getId();
							this.router.navigate([`/channel-management/edit/${new_id_ver}`]);
						}
					},
					error: (err) => {
						this.msgSrv.onLoading.next({command: 'stop'});
					},
				});
				break;

			case 'XML':
				this.pluginsSrv.runPlugin('OpcUa model generator', {UANodesetXMLToModelImportAction: this.plugin_add[this.selectedType].serialize()}).subscribe({
					next: (res) => {
						this.msgSrv.onLoading.next({command: 'stop'});
						this.msgSrv.showSuccess('Plugin run successfully');
						this.plugin_add[this.selectedType]._formGroup.markAsPristine();
						this.close();

						if (res['UANodesetXMLToModelImportActionResult']) {
							const new_id_ver = new Identifier(res['UANodesetXMLToModelImportActionResult'].model.info.identifier).getId();
							this.router.navigate([`/information-models/edit/${new_id_ver}`]);
						}
					},
					error: (err) => {
						this.msgSrv.onLoading.next({command: 'stop'});
					},
				});
				break;
		}
	}

	close(): void {
		const callback = () => {
			this.plugin_add['OpcUa']._formGroup.markAsPristine();
			this.plugin_add['XML']._formGroup.markAsPristine();
			this.dialogRef.close();
		};

		if (this.plugin_add[this.selectedType]._formGroup.dirty) {
			this.msgSrv.unsavedChangesClose(callback);
		} else {
			callback();
		}
	}
}
