import {Injectable} from '@angular/core';
import {Subject, Subscription} from 'rxjs';

@Injectable()
export class CommunicationService {
	private broadcast: Subject<CommunicationPackage> = new Subject<CommunicationPackage>();

	constructor() {}

	cast(message: CommunicationMessage, to?, from?) {
		const pck: CommunicationPackage = {
			from: from ? from : 'ROOT',
			to: to ? to : 'ROOT',
			message,
		};

		this.broadcast.next(pck);
	}

	onMessage(callback: Function): Subscription {
		return this.broadcast.subscribe((pck: CommunicationPackage) => {
			callback(pck);
		});
	}

	on(what, callback: Function): Subscription {
		return this.broadcast.subscribe((pck: CommunicationPackage) => {
			if (pck.to === what) {
				callback(pck);
			}
		});
	}
}

export type CommunicationPackage = {
	from: string;
	to: string;
	message: CommunicationMessage;
};

export type CommunicationMessage = {
	action: string;
	data: any;
};
