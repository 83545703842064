import {ArtifactInfo, Identifier, Root} from '../../data-models/core-model.model';
import {DeviceTypeManagerResult, DeviceTypeMapping} from '../device-type/device-type.model';
import {ArtifactRevisionIdentifier, ImplementationComponent} from '../channel-types/channel-types-model';

export class InstanceManagerResult extends Root {
	_className = 'InstanceManagerResult';

	public info: ArtifactInfo = new ArtifactInfo({}, this);
	public description: InstanceDescription = new InstanceDescription({}, this);
	public ignoreCompileErrors: boolean = false;

	constructor(input?: {info?: any; description?: any; implementationOverride?: ImplementationOverride[]}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}

	artifactIdentifier(): Identifier {
		return this.info.identifier;
	}

	serialize() {
		const output: any = super.serialize();
		output.version = output.info.identifier.version;
		output.ignoreCompileErrors = true;

		return output;
	}
}

export class InstanceDescription extends DeviceTypeManagerResult {
	_className = 'InstanceDescription';

	public implementationOverride: ImplementationOverride = new ImplementationOverride({}, this);

	constructor(input?: {implementationOverride?: ImplementationOverride; info?: ArtifactInfo; mappings?: DeviceTypeMapping[]}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}
}

export class ImplementationOverride extends Root {
	_className = 'ImplementationOverride';

	public framework: ArtifactRevisionIdentifier = new ArtifactRevisionIdentifier('', '', '');
	public implementations: ArtifactRevisionIdentifier[] = [];

	constructor(input?: {framework?: ArtifactRevisionIdentifier; implementations?: ArtifactRevisionIdentifier[]}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}

	implementationsToFormControl() {
		return this.rootArrayToFormControl(this.implementations);
	}

	serialize() {
		const output: any = super.serialize();
		return output;
	}
}

export class InstanceImplementationDetails extends Root {
	_className = 'InstanceImplementationDetails';

	public availableFrameworks: FrameworkImplementation[] = [];
	public availableImplementations: ImplementationComponent[] = [];

	constructor(input?: {availableFrameworks?: FrameworkImplementation[]; availableImplementations: ImplementationComponent[]}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}

	serialize() {
		const output: any = super.serialize();
		return output;
	}
}

export class FrameworkImplementation extends Root {
	_className = 'FrameworkImplementation';

	public artifactId: ArtifactRevisionIdentifier = new ArtifactRevisionIdentifier('', '', '');
	public apiVersion: string = '';
	public location: string = '';

	constructor(input?: {settings?: any}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}

	serialize() {
		const output: any = super.serialize();
		return output;
	}
}
