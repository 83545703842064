<ng-container [ngSwitch]="selectedObject.definitionType">
	<app-i40-tree-model-editor *ngSwitchCase="MemberDefinitionTypes.Model" [selectedObject]="selectedObject"></app-i40-tree-model-editor>

	<app-i40-tree-variable-editor *ngSwitchCase="MemberDefinitionTypes.Variable" [selectedObject]="selectedObject"></app-i40-tree-variable-editor>
	<app-i40-tree-property-editor *ngSwitchCase="MemberDefinitionTypes.Property" [selectedObject]="selectedObject"></app-i40-tree-property-editor>
	<app-i40-tree-list-editor *ngSwitchCase="MemberDefinitionTypes.List" [selectedObject]="selectedObject"></app-i40-tree-list-editor>
	<app-i40-tree-array-editor *ngSwitchCase="MemberDefinitionTypes.Array" [selectedObject]="selectedObject"></app-i40-tree-array-editor>
	<app-i40-tree-command-editor *ngSwitchCase="MemberDefinitionTypes.Command" [selectedObject]="selectedObject"></app-i40-tree-command-editor>
	<app-i40-tree-command-reply-editor *ngSwitchCase="MemberDefinitionTypes.CommandReply" [selectedObject]="selectedObject"></app-i40-tree-command-reply-editor>
	<app-i40-tree-command-parameters-editor *ngSwitchCase="MemberDefinitionTypes.CommandParameters" [selectedObject]="selectedObject"></app-i40-tree-command-parameters-editor>
	<app-i40-tree-event-editor *ngSwitchCase="MemberDefinitionTypes.Event" [selectedObject]="selectedObject"></app-i40-tree-event-editor>
</ng-container>
