import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpBackend, HttpClient, HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {PreloadAllModules, RouterModule} from '@angular/router';
import {MissingTranslationHandler, TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {AngularSplitModule} from 'angular-split';
import {BlockUIModule} from 'ng-block-ui';
import {AppMaterialModule} from './app-material.module';
import {AppComponent} from './app.component';
import {rootRoutes} from './app.routes';
import {StartScreenComponent} from './apps/start-screen/start-screen.component';
import {AuthGuard} from './core-services/auth.guard';
import {AuthService} from './core-services/auth.service';
import {HttpConfigInterceptor} from './core-services/httpconfig.interceptor';
import {RoleGuard} from './core-services/role.guard';
import {FooterComponent} from './root/footer/footer.component';
import {WrapperComponent} from './root/wrapper.component';
import {SharedModule} from './shared/shared.module';
import {MultiTranslateHttpLoader, MyMissingTranslationHandler} from './translation-loader';
import {HomeDashboardComponent} from './apps/dashboard/home-dashboard.component';
import {CanDeactivateGuard} from './core-services/can-deactivate.guard';
import {WrapperModalComponent} from './root/wrapper-modal/wrapper-modal.component';
import {AboutUnifierComponent} from './shared/about-unifier/about-unifier.component';
import {ProgressLogComponent} from './root/progress-log/progress-log.component';
import {ArtifactListComponent} from './shared/artifact-list/artifact-list.component';
import {CompileArtifactResultsComponent} from './shared/compile-artifact-results/compile-artifact-results.component';

@NgModule({
	declarations: [
		AppComponent,
		WrapperComponent,
		HomeDashboardComponent,
		StartScreenComponent,
		FooterComponent,
		WrapperModalComponent,
		AboutUnifierComponent,
		ProgressLogComponent,
		ArtifactListComponent,
	],
	imports: [
		CommonModule,
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		AppMaterialModule,
		FormsModule,
		FlexLayoutModule,
		ReactiveFormsModule,

		TranslateModule.forRoot({
			missingTranslationHandler: {provide: MissingTranslationHandler, useClass: MyMissingTranslationHandler},
			useDefaultLang: true,
			defaultLanguage: 'en',
			loader: {
				provide: TranslateLoader,
				useClass: MultiTranslateHttpLoader,
				deps: [HttpBackend, HttpClient],
			},
			isolate: true,
		}),

		SharedModule,

		AngularSplitModule,
		BlockUIModule.forRoot(),

		RouterModule.forRoot(rootRoutes, {enableTracing: false, preloadingStrategy: PreloadAllModules, onSameUrlNavigation: 'reload'}),
	],
	providers: [
		AuthGuard,
		RoleGuard,
		AuthService,
		CanDeactivateGuard,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpConfigInterceptor,
			multi: true,
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
