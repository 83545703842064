<div fxLayout="column" [formGroup]="form">
	<mat-form-field subscriptSizing="dynamic" fxFlex="">
		<mat-label>{{ 'i18n.group' | translate }}</mat-label>
		<input id="group_input" matInput [formControl]="groupControl" #selectOnInit [matAutocomplete]="auto" required />
		<mat-autocomplete #auto="matAutocomplete">
			<mat-option *ngFor="let option of filteredGroups | async" [value]="option">
				{{ option }}
			</mat-option>
		</mat-autocomplete>
		<mat-error *ngIf="groupControl.errors?.message && groupControl.value.length > 0">
			{{ groupControl.errors.message }}
		</mat-error>
	</mat-form-field>

	<mat-form-field subscriptSizing="dynamic" fxFlex="">
		<mat-label>{{ 'i18n.name' | translate }}</mat-label>
		<input id="name_input" matInput [formControl]="nameControl" required />
		<mat-error *ngIf="nameControl.errors?.message && nameControl.value.length > 0">
			{{ nameControl.errors.message }}
		</mat-error>
	</mat-form-field>

	<mat-form-field subscriptSizing="dynamic" fxFlex="" *ngIf="descriptionControl">
		<mat-label>{{ 'i18n.description' | translate }}</mat-label>
		<input id="description_input" matInput [formControl]="descriptionControl" />
	</mat-form-field>
</div>
