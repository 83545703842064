import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';

@Injectable()
export class BackupService {
	stream: EventSource;

	constructor(private http: HttpClient) {}

	downloadRepositoryFiltered(data?): Observable<any> {
		const httpOptions = {
			headers: new HttpHeaders({
				Accept: 'application/json',
				withCredentials: 'true',
			}),
			responseType: 'blob' as 'json',
			observe: 'response' as 'body',
		};

		let sentData = data ? data.serialize() : null;

		return this.http.post(`${environment.apiHost + environment.apiUrl}/backup/repository/downloadFiltered`, sentData, httpOptions);
	}

	restoreRepository(data) {
		const httpOptions = {
			headers: new HttpHeaders({
				Accept: 'application/tar',
				withCredentials: 'true',
			}),
		};
		return this.http.post(`${environment.apiHost + environment.apiUrl}/backup/repository/restore`, data, httpOptions);
	}

	restoreChunked(data): Observable<any> {
		const httpOptions = {
			headers: new HttpHeaders({
				Accept: 'application/tar',
				withCredentials: 'true',
			}),

			observe: 'events' as const,
			reportProgress: true,
			responseType: 'text' as const,
		};

		return this.http.post(`${environment.apiHost + environment.apiUrl}/backup/repository/restoreChunked`, data, httpOptions);
	}

	getArtifactInfo() {
		return this.http.get(`${environment.apiHost + environment.apiUrl}/backup/infos`);
	}

	retrieveArtifactInfo(file) {
		const httpOptions = {
			headers: new HttpHeaders({
				Accept: 'application/x-www-form-urlencoded',
				withCredentials: 'true',
			}),
			responseType: 'json' as const,
		};
		return this.http.post(`${environment.apiHost + environment.apiUrl}/backup/repository/infos`, file, httpOptions);
	}

	advancedRestore(data) {
		const httpOptions = {
			headers: new HttpHeaders({
				Accept: 'application/tar',
				withCredentials: 'true',
			}),

			observe: 'events' as const,
			reportProgress: true,
			responseType: 'text' as const,
		};

		return this.http.post(`${environment.apiHost + environment.apiUrl}/backup/repository/advancedRestore`, data, httpOptions);
	}
}
