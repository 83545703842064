import {ArtifactInfo, i40FormGroup, Identifier, Root} from '../../data-models/core-model.model';
import {I40TreeService} from './i40-tree.service';
import {Subscription} from 'rxjs';

export enum MemberDefinitionType {
	Unknown = 'Unknown',
	Model = 'Model',
	Event = 'Event',
	Command = 'Command',
	Variable = 'Variable',
	Property = 'Property',
	Array = 'Array',
	ComplexArray = 'ComplexArray',
	List = 'List',
	ComplexList = 'ComplexList',
	CommandParameters = 'CommandParameters',
	CommandReply = 'CommandReply',
	StructuredVariable = 'StructuredVariable',
}

export enum MemberTypes {
	Byte = 'Byte',
	Short = 'Short',
	Int = 'Int',
	Long = 'Long',
	Char = 'Char',
	String = 'String',
	Double = 'Double',
	Float = 'Float',
	Boolean = 'Boolean',
	LocalDateTime = 'LocalDateTime',
	OffsetDateTime = 'OffsetDateTime',
}

export type MemberCategory = 'simple' | 'model' | 'custom';

export class ModelManagerResult extends Root {
	_className = 'ModelManagerResult';

	public info: ArtifactInfo = new ArtifactInfo({}, this);
	public members: Map<string, MemberDescription> = new Map();
	public types: Map<string, ObjectDescription> = new Map();
	public rawModelString: string = '';
	public _new: boolean = false;

	constructor(input?: {info?: any; members?: any[]; types?: any; rawModelString?: string; _new?: boolean}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}

	artifactIdentifier(): Identifier {
		return this.info.identifier;
	}

	setMembers(members, parent) {
		if (members) {
			if (!(members instanceof Map)) {
				this.members = this.mapMembers(members, parent);
			} else {
				this.members = members;
			}
		}
	}

	serializeMembers() {
		return this.mapToArray(this.members);
	}

	setTypes(types, parent) {
		if (types) {
			this.types = this.objectToMap(ObjectDescription, types, parent);
		}
	}

	serializeTypes() {
		return this.mapToObject(this.types);
	}

	mapMembers(members: any[], parent): Map<string, MemberDescription> {
		const modelReference = this;

		return members.reduce((map, member) => {
			member._memberParent = modelReference;
			map.set(member['id'], new MemberDescription(member, parent));
			return map;
		}, new Map());
	}

	serialize() {
		const output: any = super.serialize();

		output.ignoreCompileErrors = false;

		return output;
	}
}

export class MemberDescription extends Root {
	_className = 'MemberDescription';

	public id: string = '';
	public description: string = '';
	public definitionType: MemberDefinitionType = MemberDefinitionType.Variable;
	public typeName: string = '';
	public size?: number = 0;

	public _collapsed: boolean = false;
	public _memberParent: ObjectDescription = null;

	constructor(
		input?: {id?: string; description?: string; definitionType?: MemberDefinitionType; typeName?: string; _memberParent?: any; _collapsed?: boolean; size?: number},
		parent?: Root,
		inherited: boolean = false,
	) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}

	serialize() {
		const output = super.serialize();
		if (this.definitionType !== 'Array') {
			delete output['size'];
		}
		if (this.description.length == 0) {
			delete output['description'];
		}
		return output;
	}
}

export class ObjectDescription extends Root {
	_className = 'ObjectDescription';

	public id: string = '';
	public members: Map<string, MemberDescription> = new Map();
	public category: MemberCategory = 'model';
	public type: string = 'StructuredVariable';

	constructor(input?: {id?: string; members?: any[]; category?: MemberCategory; parent?: any}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}

	setMembers(members, parent) {
		if (members) {
			if (!(members instanceof Map)) {
				this.members = this.toInstanceMap(MemberDescription, members, 'id', parent) as Map<string, MemberDescription>;
			} else {
				this.members = members;
			}
		}
	}

	serializeMembers() {
		return this.mapToArray(this.members);
	}

	serialize() {
		const output: any = super.serialize();
		return output;
	}
}

export class I40FlatMember extends MemberDescription {
	_className = 'I40FlatMember';

	_hasChildren: boolean = false;
	_level: number = 0;
	_visible: boolean = true;
	_uniqueIndex: string = '';
	_path: string[] = [];
	_fullPath: string[] = [];
	_memberPath: string[] = [];
	_originalIndex: number = 0;
	_levelIndex: number = 0;
	_visibleIndex: number = 0;
	_new: boolean = false;
	_isValid = false;
	_validFormSub?: Subscription = null;
	_model: ModelManagerResult = null;
	_memberType: ObjectDescription = null;
	_extraInfo: any = null;
	_flatParentRef: I40FlatMember | null = null;
	_original: MemberDescription = null;
	_srv: I40TreeService = null;

	constructor(
		input?: {
			id: string;
			description: string;
			definitionType: MemberDefinitionType;
			typeName: string;
			_collapsed: boolean;
			size?: number;

			/* flat specific params */
			_uniqueIndex?: string;
			_hasChildren?: boolean;
			_level?: number;
			_visible?: boolean;

			_path?: string[];
			_fullPath?: string[];
			_memberPath?: string[];
			_originalIndex: number;
			_levelIndex: number;
			_visibleIndex?: number;

			_isValid?: boolean;
			_validFormSub?: Subscription;
			_model: any;
			_memberType: any;
			_extraInfo?: any;
			_memberParent: any;
			_flatParentRef?: any;
			_original: any;
			_srv: I40TreeService;
		},
		parent?: Root,
		inherited: boolean = false,
	) {
		super(input, parent, true);
		if (!inherited) {
			super.init(input);
		}
	}

	artifactIdentifier(): Identifier {
		return this._model.artifactIdentifier();
	}

	unFlattenNode(): any {
		return {
			id: this.id,
			description: this.description,
			definitionType: this.definitionType,
			typeName: this.typeName,
			size: this.size,
			_collapsed: this._collapsed,
		};
	}

	// set fields that need to be manually handled => disable auto update
	setAutoUpdatedFields(fields) {
		this._autoUpdateFields = fields.filter((f) => !['id', 'typeName'].includes(f));
	}

	toFormControls(form: i40FormGroup) {
		if (this.definitionType !== MemberDefinitionType.Model) {
			super.toFormControls(form);
		} else {
			this._model.info.toFormControls(form);
		}
	}

	populateFormControls(form) {
		if (this.definitionType !== MemberDefinitionType.Model) {
			super.populateFormControls(form);
		} else {
			this._model.info.populateFormControls(form);
		}
	}

	fromFormControls(form) {
		if (this.definitionType !== MemberDefinitionType.Model) {
			const oldID = this._original.id;

			// update flat member id
			this.id = form.get('id').value;
			this.typeName = form.get('typeName').value;

			this._original.fromFormControls(form);

			if (oldID !== this._original.id && this._original._memberParent) {
				if (this._original._memberParent.id === ModelRoot.id) {
					if (this._model.members.has(oldID)) {
						this._model.members = Root.renameMapKey(oldID, this._original.id, this._model.members);
					}
				} else {
					if (this._original._memberParent.members.has(oldID)) {
						this._original._memberParent.members = Root.renameMapKey(oldID, this._original.id, this._original._memberParent.members);
					}
				}
			}
		} else {
			// update flat member id
			this.id = form.get('externalIdentifier').get('name').value;
			this._model.info.fromFormControls(form);
		}
	}
}

export interface ContextAction {
	action: actionType;
	member: I40FlatMember;
	type?: MemberDefinitionType;
}

export const ModelRoot = {
	id: 'ModelRoot',
	category: <MemberCategory>'custom',
};

export const NewCommandMemberType = {
	id: 'Command',
	category: <MemberCategory>'custom',
	members: [
		{
			id: 'Parameters',
			definitionType: 'CommandParameters',
			typeName: '',
		},
		{
			id: 'Reply',
			definitionType: 'CommandReply',
			typeName: '',
		},
	],
	type: 'Command',
};

type actionType = 'Add' | 'Copy' | 'Cut' | 'Paste' | 'Delete' | 'MoveUp' | 'MoveDown';
