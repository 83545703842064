import {Injectable} from '@angular/core';
import {I40TreeService} from '../i40-tree.service';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog} from '@angular/material/dialog';
import {JSONSchemaProperty} from '../../../data-models/json-schema.model';
import {ChannelManagerResult} from '../../../apps/channel/channel.model';
import {Root} from '../../../data-models/core-model.model';

@Injectable({
	providedIn: 'root',
})
export class I40TreeChannelService extends I40TreeService {
	groupForms = {};
	channel: ChannelManagerResult = null;
	rootParent: Root = null;

	constructor(translateSrv: TranslateService, dialog: MatDialog) {
		super(translateSrv, dialog);
	}

	getId(property: JSONSchemaProperty, type) {
		if (property.description.length > 0) {
			return this.translateSrv.instant(property.description).replace(/[ .]/g, '_').toLowerCase() + '_' + type;
		} else {
			return property.key.replace(/[ .]/g, '_').toLowerCase() + '_' + type;
		}
	}
}
