import {Root} from '../../data-models/core-model.model';

export class CredentialManagerResult extends Root {
	_className = 'CredentialManagerResult';

	id: string = '';
	name: string = '';
	description: string = '';
	credentials: CredentialContent = new CredentialContent({}, this);

	constructor(input?: {id?: string; name?: string; description?: string; credentials?: any}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}

	serialize() {
		const output: any = super.serialize();
		return output;
	}
}

export class CredentialContent extends Root {
	_className = 'CredentialContent';

	credentialType: string = '';
	data: any = {};

	constructor(input?: {credentialType?: string; data?: any}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}

	setData(data, parent) {
		const classNameStr = this.credentialType;
		const className = credentialClasses[classNameStr];

		if (className) {
			this.data = new className(data, parent);
		}
	}
}

export class Password extends Root {
	_className = 'Password';

	passwordValue: string = '';

	constructor(input?: {passwordValue?: string}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}
}

export class Token extends Root {
	_className = 'Token';

	tokenValue: string = '';

	constructor(input?: {tokenValue?: string}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}
}

export class UserNameAndPassword extends Root {
	_className = 'UserNameAndPassword';

	userName: string = '';
	password: string = '';

	constructor(input?: {userName?: string; password?: string}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}
}

export const credentialClasses = {CredentialManagerResult, CredentialContent, Password, UserNameAndPassword, Token};
