import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'toggleFilter', pure: false})
export class ToggleFilterPipe implements PipeTransform {
	transform(items: any[], filterConfig: any): any {
		if (!items || !filterConfig.enable) {
			return items;
		} else {
			return items.filter((item) => filterConfig.comparator(item, filterConfig.match));
		}
	}
}
