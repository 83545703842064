<div fxFlex style="height: 100%">
	<div fxLayout="row" fxLayoutAlign="center center" style="height: 100%">
		<div fxFlex="30"></div>
		<div fxFlex fxLayout="column" fxLayoutAlign="center center">
			<div fxFlex="30"></div>
			<div fxFlex>
				<img src="../assets/startPage.png" class="image" />
			</div>
			<div fxFlex="30"></div>
		</div>
		<div fxFlex="30"></div>
	</div>
</div>
