import {ChangeDetectorRef, Component, Directive, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {I40FlatMember, MemberDefinitionType, MemberTypes} from '../../types';
import {I40TreeCrudService} from '../i40-tree-crud.service';
import {AuthService} from '../../../../core-services/auth.service';
import {AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {UnifierFormWrapper} from '../../../../data-models/core-model.model';
import {ValidatorService} from '../../../../core-services/validator.service';
import {Observable, Subscription} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {MessageResponseService} from '../../../../core-services/message-response.service';
import {Button} from '../../../generic-dialog/generic-dialog.component';
import {TranslateService} from '@ngx-translate/core';
import * as _ from 'lodash';
import {Router} from '@angular/router';
import {GlobalService} from '../../../../core-services/global.service';

@Component({
	selector: 'i40-tree-member-editor-wrapper',
	templateUrl: './i40-tree-member-editor-wrapper.html',
	styleUrls: ['./i40-tree-member-editor-wrapper.scss'],
})
export class I40TreeMemberEditorWrapper implements OnInit, OnChanges {
	@Input() selectedObject: I40FlatMember; // the entire model or just a model node

	MemberDefinitionTypes = MemberDefinitionType;

	constructor() {}

	ngOnInit() {}

	ngOnChanges(changes: SimpleChanges) {}
}

@Directive()
export class I40TreeMemberEditorComponent extends UnifierFormWrapper<I40FlatMember> {
	@Input() selectedObject: I40FlatMember;

	loaded = false;

	initialValues: any;

	selectedMemberType: string;
	selectedDefinitionType: string;

	allowedMemberTypes: string[];
	allowedMemberTypes$: Observable<string[]>;

	editorSubscription: Subscription;
	updateTreeSubscription: Subscription;
	cancelUpdateSubscription: Subscription;

	onChangeTimeout = null;
	selectFirstFieldTimeout = null;

	validateOnInit = false;

	@ViewChild('selectOnInit', {static: false}) selectOnInit: ElementRef;

	constructor(
		public tsrv: I40TreeCrudService,
		public authSrv: AuthService,
		public validatorSrv: ValidatorService,
		public msgSrv: MessageResponseService,
		public translate: TranslateService,
		public router: Router,
		public globalSrv: GlobalService,
		public cdRef: ChangeDetectorRef,
	) {
		super(authSrv, validatorSrv, msgSrv, translate, router, globalSrv);
	}

	ngOnInit() {
		this.editorSubscription = this.tsrv.tellEditor.subscribe((command) => {
			switch (command) {
				// check selectedObject still exists
				case 'check':
					if ((this.selectedObject._memberParent && !this.selectedObject._memberParent.members.has(this.selectedObject.id)) || !this.selectedObject) {
						this.tsrv.parentDataSrv.editorStatus = ['clean'];
						this.tsrv.closeMemberEditor.emit(true);
					}
					break;
			}
		});

		this.updateTreeSubscription = this.tsrv.updateTreeRequest.subscribe(() => {
			this.updateTree();
		});

		this.cancelUpdateSubscription = this.tsrv.cancelUpdateRequest.subscribe(() => {
			this.resetForm();
		});
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.selectedObject.currentValue !== changes.selectedObject.previousValue) {
			this.onSelectedObjectChange();
		}
	}

	onSelectedObjectChange() {
		this.loaded = false;

		if (this.onChangeTimeout) {
			clearTimeout(this.onChangeTimeout);
		}

		if (this.selectFirstFieldTimeout) {
			clearTimeout(this.selectFirstFieldTimeout);
		}

		this.onChangeTimeout = setTimeout(() => {
			if (this.editorStatusSubscription !== null) {
				this.editorStatusSubscription.unsubscribe();
			}

			this.initForm(this.selectedObject);

			this.editorStatusSubscription = this.editorStatusSubject.subscribe((status) => {
				if (_.difference(this.tsrv.parentDataSrv.editorStatus, status).length) {
					this.tsrv.parentDataSrv.editorStatus = status;
				}
			});

			this.loaded = true;

			if (this.selectFirstFieldTimeout) {
				clearTimeout(this.selectFirstFieldTimeout);
			}

			this.selectFirstFieldTimeout = setTimeout(() => {
				this.selectFirstField();
			}, 200);

			this.cdRef.detectChanges();
		});
	}

	ngOnDestroy() {
		if (this.editorSubscription !== null) {
			this.editorSubscription.unsubscribe();
		}
		if (this.updateTreeSubscription !== null) {
			this.updateTreeSubscription.unsubscribe();
		}
		if (this.cancelUpdateSubscription !== null) {
			this.cancelUpdateSubscription.unsubscribe();
		}
		if (this.editorStatusSubscription !== null) {
			this.editorStatusSubscription.unsubscribe();
		}

		if (this.onChangeTimeout) {
			clearTimeout(this.onChangeTimeout);
		}
	}

	validateForm() {
		this.form.markAllAsTouched();
		this.triggerValidityCheck(this.form);
	}

	selectFirstField() {
		if (this.selectOnInit) {
			setTimeout(() => {
				if (this.selectedObject._new) {
					this.selectOnInit.nativeElement.select();
				} else {
					// this.selectOnInit.nativeElement.focus();
				}
			});
		}
	}

	generateFields() {
		this.form = this.selectedObject._formGroup;

		if (this.selectedObject.definitionType !== MemberDefinitionType.Model) {
			this.getAllowedMemberTypes();

			this.allowedMemberTypes$ = this.form.get('typeName').valueChanges.pipe(
				startWith(''),
				map((value) => this.filterMemberTypes(value)),
			);
		}
	}

	addValidation() {
		this.form.get('id').setValidators([Validators.required, this.validatorSrv.validateMemberId(), this.validatorSrv.validateUniqueSiblings(this.selectedObject)]);
		this.form.get('typeName').setValidators([Validators.required, this.validatorSrv.validateMemberType(this.selectedObject), this.validatorSrv.validateWhiteSpace()]);
	}

	populateFields() {
		if (this.selectedObject.definitionType !== undefined) {
			this.selectedDefinitionType = this.selectedObject.definitionType;
		}

		if (this.selectedObject.typeName !== undefined) {
			this.selectedMemberType = this.selectedObject.typeName;
		}

		let isArray = false;
		if (this.selectedObject._memberPath.length > 1) {
			isArray = this.selectedObject._memberPath[this.selectedObject._memberPath.length - 2].substr(0, 5) === 'Array';
		}

		// if this is a child of an array disable form
		if (isArray) {
			this.form.disable();
			this.tsrv.parentDataSrv.editorStatus = ['clean'];
		} else {
			// validate form
			this.validateForm();

			// TODO - check in future angular versions or maybe just revisit - this should work inside "spyFormStatus" - this code should not be required - works fine everywhere except for Command
			setTimeout(() => {
				if (this.form.status === 'INVALID') {
					this.tsrv.parentDataSrv.editorStatus = ['invalid'];
				} else {
					this.tsrv.parentDataSrv.editorStatus = ['clean'];
				}
			});
		}
	}

	saveInitialValues() {
		this.initialValues = this.form.getRawValue();
	}

	getAllowedMemberTypes() {
		const allowedMemberTypes = Object.values(MemberTypes) as string[];
		const parentMemberTypes: string[] = this.selectedObject._memberPath || [];

		this.tsrv.modelMemberTypes.forEach((m, k) => {
			if (!parentMemberTypes.includes(k) && m.type !== 'Command') {
				allowedMemberTypes.push(k);
			}
		});

		this.allowedMemberTypes = allowedMemberTypes.sort();
	}

	filterMemberTypes(value: string): string[] {
		if (value) {
			const filterValue = value.toLowerCase();
			return this.allowedMemberTypes.filter((option) => option.toLowerCase().includes(filterValue));
		} else {
			return this.allowedMemberTypes;
		}
	}

	triggerValidityCheck(el: AbstractControl) {
		if (el instanceof UntypedFormControl) {
			el.updateValueAndValidity({onlySelf: true});
		} else if (el instanceof UntypedFormGroup) {
			for (let c in el.controls) {
				this.triggerValidityCheck(el.controls[c]);
			}
		} else if (el instanceof UntypedFormArray) {
			for (let ac of el.controls) {
				this.triggerValidityCheck(ac);
			}
		}
	}

	valuesHaveChanged(): boolean {
		const currentValues = this.form.getRawValue();
		return JSON.stringify(currentValues) !== JSON.stringify(this.initialValues);
	}

	resetMemberType() {
		this.selectedMemberType = '';
	}

	resetForm() {
		if (this.selectedObject.definitionType !== undefined) {
			this.selectedDefinitionType = this.selectedObject.definitionType;
		}

		if (this.selectedObject.typeName !== undefined) {
			this.selectedMemberType = this.selectedObject.typeName;
		}

		this.form.patchValue(this.initialValues);
	}

	updateTree() {
		const oldType = this.selectedObject.typeName;
		const newType = this.selectedMemberType;

		const actualUpdate = () => {
			// set new values for flat member as well as original member
			this.selectedObject.setFormValues();

			if (this.selectedObject.definitionType !== MemberDefinitionType.Model) {
				if (!this.tsrv.allMemberTypes.has(newType)) {
					this.tsrv.addNewModelMemberType(newType);
				}
			}

			this.selectedObject._path = this.tsrv.buildPath(this.selectedObject).reverse();
			this.selectedObject._uniqueIndex = this.tsrv.generateId(this.selectedObject);

			// presume valid until refresh when it mark invalid nodes
			this.tsrv.isValid = true;

			// refresh model to display update
			this.tsrv.refreshModel();

			this.selectedObject = this.tsrv.getNodeByPath(this.selectedObject._path.join('/'));

			this.onSelectedObjectChange();

			// mark model as dirty
			this.tsrv.isDirty = true;

			this.tsrv.parentDataSrv.editorStatus = ['clean'];
		};

		const actualRename = () => {
			// set new values for flat member as well as original member
			this.selectedObject.setFormValues();

			this.tsrv.modelMemberTypes = this.tsrv.renameMemberType(this.tsrv.modelMemberTypes, oldType, newType);
			this.tsrv.allMemberTypes = this.tsrv.getAllMemberTypes();

			this.tsrv.inputModel.members.forEach((member) => {
				if (member.typeName === oldType) {
					member.typeName = newType;
				}
			});

			this.tsrv.inputModel.types = this.tsrv.modelMemberTypes;

			this.selectedObject._path = this.tsrv.buildPath(this.selectedObject).reverse();
			this.selectedObject._uniqueIndex = this.tsrv.generateId(this.selectedObject);

			// presume valid until refresh when it mark invalid nodes
			this.tsrv.isValid = true;

			// refresh model to display update
			this.tsrv.refreshModel();

			this.selectedObject = this.tsrv.getNodeByPath(this.selectedObject._path.join('/'));

			this.onSelectedObjectChange();

			// mark model as dirty
			this.tsrv.isDirty = true;

			this.tsrv.parentDataSrv.editorStatus = ['clean'];
		};

		const actualCancel = () => {
			this.selectedMemberType = oldType;
		};

		// SHOW WARNING IF CUSTOM MEMBER TYPE CHANGES
		if (
			this.selectedObject._memberType !== null &&
			this.selectedObject.definitionType !== MemberDefinitionType.Model &&
			!this.selectedObject._new &&
			this.selectedObject._memberType.category !== 'simple' &&
			newType !== oldType
		) {
			this.msgSrv.customDialogMessage(
				this.translate.instant('i18n.memberType'),
				this.translate.instant('i18n.memberTypeUpdate'),
				[
					new Button(this.translate.instant('i18n.change'), 'change', 'flat', 'accent', 'start'),
					new Button(this.translate.instant('i18n.rename'), 'rename', 'flat', 'accent', 'start'),
					new Button(this.translate.instant('i18n.cancel'), 'cancel', 'flat', 'accent', 'end'),
				],
				{
					change: (diagRef, resolve) => {
						diagRef.close();
						actualUpdate();
						resolve(true);
					},
					rename: (diagRef, resolve) => {
						diagRef.close();
						actualRename();
						resolve(true);
					},
					cancel: (diagRef, resolve) => {
						diagRef.close();
						actualCancel();
						resolve(false);
					},
				},
			);
		} else {
			actualUpdate();
		}
	}

	save() {
		this.tsrv.updateTreeRequest.emit(true);
	}
}
