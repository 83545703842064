import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {MappingInfo, MappingManagerCreateRequest, MappingManagerResult} from './mapping-model';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {ArtifactInfo} from 'src/app/data-models/core-model.model';

@Injectable({
	providedIn: 'root',
})
export class MappingService {
	instanceId: string;
	defaultHttpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		}),
	};

	constructor(private http: HttpClient) {}

	getMappingInfos(all = false): Observable<MappingInfo[]> {
		if (all) {
			return this.http.get<any[]>(`${environment.apiHost + environment.apiUrl}/mapping/allinfos`).pipe(map((response) => response.map((m) => new MappingInfo(m)).sort(MappingInfo.sort)));
		} else {
			return this.http.get<any[]>(`${environment.apiHost + environment.apiUrl}/mapping`).pipe(map((response) => response.map((m) => new MappingInfo(m)).sort(MappingInfo.sort)));
		}
	}

	getMappingData(mappingId: string): Observable<MappingManagerResult> {
		return this.http.get(`${environment.apiHost + environment.apiUrl}/mapping/${mappingId}`).pipe(
			map((response: any) => {
				return new MappingManagerResult(response);
			}),
		);
	}

	addMapping(mapping: any) {
		return this.http.post(`${environment.apiHost + environment.apiUrl}/mapping`, mapping, this.defaultHttpOptions).pipe(
			map((result: MappingManagerResult) => {
				return result;
			}),
		);
	}

	releaseMapping(id, version) {
		return this.http.post(`${environment.apiHost + environment.apiUrl}/mapping/${id}:${version}/release`, null, this.defaultHttpOptions);
	}

	deleteMapping(mappingId: string) {
		return this.http.delete(`${environment.apiHost + environment.apiUrl}/mapping/${mappingId}`);
	}

	updateMapping(mapping: any) {
		return this.http.put(`${environment.apiHost + environment.apiUrl}/mapping`, mapping);
	}

	compileMapping(mapping) {
		return this.http.put(`${environment.apiHost + environment.apiUrl}/mapping/compile`, mapping);
	}

	importMapping(obj) {
		if (obj.identifier && obj.identifier.version) {
			obj.version = obj.identifier.version;
			delete obj['identifier'];
		}
		const mapping = new MappingManagerCreateRequest(obj);
		return this.addMapping(mapping.serialize());
	}
}
