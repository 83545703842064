import {Directive, ElementRef, HostListener} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Directive({
	selector: 'input[numAsValue]',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: NumAsValue,
			multi: true,
		},
	],
})
export class NumAsValue implements ControlValueAccessor {
	private onChange: (value: any) => void = () => {};
	private onTouched: () => void = () => {};
	private element: HTMLInputElement;

	constructor(private el: ElementRef) {
		this.element = el.nativeElement;
	}

	@HostListener('input', ['$event.target.value'])
	onInputChange(value: string) {
		let transformedValue = this.transformValue(value);
		this.onChange(transformedValue);
	}

	@HostListener('blur')
	onBlur() {
		this.onTouched();
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	writeValue(obj: any): void {
		const normalizedValue = obj == null ? '' : obj;
		this.element.value = normalizedValue;
	}

	private transformValue(value: string): any {
		if (this.isEnvVariableValue(value)) {
			return value;
		}
		return Number(value);
	}

	isEnvVariableValue(value: string): boolean {
		const envVariablePattern = /^\$ENV\[\w+\]$/;
		return envVariablePattern.test(value);
	}
}
