import {MemberDefinitionType} from '../shared/i40-tree/types';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators} from '@angular/forms';

export enum JSONSchemaValidationType {
	required = 'required',
	minLength = 'minLength',
	maxLength = 'maxLength',
}

export enum JSONSchemaPrimitiveTypes {
	string = 'string',
	number = 'number',
	integer = 'integer',
	boolean = 'boolean',
}

export class JSONSchemaProperty {
	refId: string;
	key: string;
	description: string;
	default: any;
	type: string;
	validations: ValidatorFn[];
	items: JSONSchemaProperty;
	properties: JSONSchemaProperty[] = [];
	anyOf: any[];
	oneOf: any[];
	minLength?: string | number;
	maxLength?: string | number;
	enum: any[];
	required: [];
	parentType;
	compatiblePath: string = '';
	compatibleChildren: string[] = [];
	expanded?: boolean = true;
	isRequired: boolean = false;
	parent: JSONSchemaProperty = null;

	constructor(
		input?: {
			$id?: string;
			$ref?: string;
			refId: string;
			description?: string;
			default?: any;
			type?: string;
			validations?: ValidatorFn[];
			items?: JSONSchemaProperty;
			properties?: any;
			anyOf?: any[];
			oneOf?: any[];
			minLength?: string | number;
			maxLength?: string | number;
			enum?: any[];
			required?: [];
			expanded?: boolean;
		},
		required?: string[],
		key?: string,
		parent?: JSONSchemaProperty,
	) {
		if (!(input instanceof JSONSchemaProperty)) {
			this.refId = input.$id ? input.$id : '';
			this.key = key ?? this.refId.split('.').pop();
			this.description = input.description ?? '';
			this.default = input.default ?? null;
			this.type = input.type ?? '';
			this.validations = input.validations ?? [];
			this.anyOf = input.anyOf ?? [];
			this.oneOf = input.oneOf ?? [];
			this.required = input.required ?? [];
			this.expanded = input.expanded ?? true;
			this.enum = input.enum ?? [];

			this.parent = parent ?? null;
			this.compatiblePath = this.getCompatiblePath(input, parent) ?? '';

			this.items = input.items ? new JSONSchemaProperty(input.items, [], undefined, this) : null;

			if (input.properties && Object.entries(input.properties).length) {
				if (input.properties instanceof Array) {
					input.properties.forEach((v: JSONSchemaProperty) => {
						this.properties.push(new JSONSchemaProperty(v, this.required, v.key, this));
					});
				} else {
					Object.entries(input.properties).forEach(([k, v]: [string, any]) => {
						this.properties.push(new JSONSchemaProperty(v, this.required, k, this));
					});
				}
			}

			if (input.minLength) {
				this.validations.push(Validators.minLength(Number(input.minLength)));
			}

			if (input.maxLength) {
				this.validations.push(Validators.maxLength(Number(input.maxLength)));
			}

			if (this.default) {
				required.push(this.key);
			}

			if (required && required.includes(this.key)) {
				this.isRequired = true;
				this.validations.push(Validators.required);
			}

			this.compatibleChildren = this.getCompatibleChildren() ?? [];

			if (this.enum.length > 0) {
				this.type = 'enum';
			}

			if (this.oneOf.length > 0) {
				this.type = 'oneOf';
				this.oneOf.forEach((p, i) => {
					this.oneOf[i] = new JSONSchemaProperty(p, this.required, undefined, this);
				});
			}

			if (this.anyOf.length > 0) {
				this.type = 'anyOf';
				this.anyOf.forEach((p, i) => {
					this.anyOf[i] = new JSONSchemaProperty(p, this.required, undefined, this);
				});
			}

			if (this.key === 'modelPath') {
				this.type = 'model_path';
			}

			if (this.type === 'array') {
				if (this.compatibleChildren.length > 0) {
					this.type = 'model_path_array';
				}
			}

			if (this.key === 'suCredentialIdentifier') {
				this.type = 'credentials-selector';
			}

			if (['string', 'boolean', 'number', 'integer'].includes(this.type)) {
				if (this.default && this.default.length) {
					this.default = this.default.replaceAll('"', '');
					if (this.type === 'boolean') {
						this.default = this.default === 'true';
					}
				}
			}
		} else {
			Object.keys(input).forEach(([k, v]) => {
				this[k] = v;
			});
		}
	}

	parseModelPathType(modelPathRefId: string) {
		const type = modelPathRefId.split('.').pop();

		switch (type) {
			case 'VariablePath':
				return MemberDefinitionType.Variable;
			case 'EventPath':
				return MemberDefinitionType.Event;
			case 'CommandPath':
				return MemberDefinitionType.Command;
			case 'ModelPath':
				return MemberDefinitionType.Model;
			case 'ComplexVariablePath':
				return MemberDefinitionType.StructuredVariable;
			case 'ArrayPath':
				return MemberDefinitionType.Array;
			case 'ComplexArrayPath':
				return MemberDefinitionType.ComplexArray;
			case 'ListPath':
				return MemberDefinitionType.List;
			case 'ComplexListPath':
				return MemberDefinitionType.ComplexList;
			case 'PropertyPath':
				return MemberDefinitionType.Property;
		}
	}

	getCompatiblePath(input: any, parent?: JSONSchemaProperty): string {
		let path = '';
		if (input.items && input.items.properties) {
			const props = Object.entries(input.items.properties);
			if (props.length > 0) {
				props.forEach(([k, p]: [string, any]) => {
					const refId = p.$id ? p.$id : p.$ref ? p.$ref : '';
					if (k === 'modelPath') {
						path = this.parseModelPathType(refId);
					}
				});
			}
		}
		return parent ? parent.compatiblePath + (path ? '/' + path : '') : path;
	}

	getCompatibleChildren(path: string[] = []): string[] {
		if (this.key === 'modelPath') {
			path.push(this.parseModelPathType(this.refId));
		} else {
			if (this.properties.length > 0) {
				this.properties.forEach((p) => {
					const modelPath = p.getCompatibleChildren([]);
					if (modelPath.length) {
						path = path.concat(modelPath);
					}
				});
			} else {
				if (this.items) {
					const modelPath = this.items.getCompatibleChildren([]);
					if (modelPath.length) {
						path = path.concat(modelPath);
					}
				}
			}
		}
		return path;
	}
}

export class JSONSchemaDefinition {
	id: string;
	description: string;
	properties: JSONSchemaProperty[];
	anyOf: any[];
	oneOf: any[];

	constructor(id: string, description: string, properties: JSONSchemaProperty[], anyOf?: any[], oneOf?: any[]) {
		this.id = id;
		this.description = description;
		this.properties = properties ? properties : [];
		this.anyOf = anyOf;
		this.oneOf = oneOf;
	}
}

export class SchemaLayer {
	label: string;
	properties: JSONSchemaProperty[];
	savedValue: any;
	required: string[];
	emptyFormValue: any;
	form: UntypedFormGroup = new UntypedFormGroup({});

	constructor(input?: {label?: string; properties: JSONSchemaProperty[]; savedValue?: any; required?: string[]}) {
		this.label = input.label ?? '';
		this.properties = input.properties ?? [];
		this.savedValue = input.savedValue ?? {};
		this.required = input.required ?? [];

		SchemaLayer.generateForm(this.properties, this.savedValue, this.form, this.required);

		this.emptyFormValue = this.form.value;

		if (Object.keys(this.savedValue).length > 0) {
			this.form.patchValue(this.savedValue);
		}
	}

	static generateForm(properties: JSONSchemaProperty[], savedValue: any, form: UntypedFormGroup, required: string[]) {
		properties.forEach((p) => {
			switch (p.type) {
				case 'object':
				case 'model_path':
					const fg1 = new UntypedFormGroup({});
					if (p.properties) {
						this.generateForm(p.properties, savedValue[p.key] ?? {}, fg1, p.required);
					}
					if (p.default && !savedValue[p.key]) {
						fg1.patchValue(p.default);
					}
					form.addControl(p.key, fg1);
					break;

				case 'anyOf':
				case 'oneOf':
					const fg2 = new UntypedFormGroup({});
					const ptype = p.type;

					if (savedValue?.[p.key]?.['type']) {
						const i = p[ptype].findIndex((t: JSONSchemaProperty) => t.key === savedValue[p.key]['type']);
						if (p[ptype][i] && p[ptype][i].properties) {
							this.generateForm(p[ptype][i].properties, savedValue[p.key] ?? {}, fg2, p.required);
						}
						fg2.addControl('type', new UntypedFormControl(savedValue[p.key]['type']));
					} else if (p.parent?.default?.[p.key]['type']) {
						const i = p[ptype].findIndex((t: JSONSchemaProperty) => t.key === p.parent.default[p.key]['type']);
						if (p[ptype][i] && p[ptype][i].properties) {
							this.generateForm(p[ptype][i].properties, p.parent.default[p.key] ?? {}, fg2, p.required);
						}
						fg2.addControl('type', new UntypedFormControl(p.parent.default[p.key]['type']));
					} else if (!savedValue?.[p.key] && p.default) {
						const i = p[ptype].findIndex((t: JSONSchemaProperty) => t.key === p.default['type']);
						if (p[ptype][i] && p[ptype][i].properties) {
							this.generateForm(p[ptype][i].properties, p.default ?? {}, fg2, p.required);
						}
						fg2.addControl('type', new UntypedFormControl(p.default['type']));
					}

					if (Object.keys(fg2.value).length > 0 || p.isRequired || p.anyOf) {
						form.addControl(p.key, fg2);
					}
					break;

				case 'array':
				case 'model_path_array':
					const fa = new UntypedFormArray([]);
					if (savedValue?.[p.key]) {
						savedValue[p.key].forEach((pp) => {
							switch (p.items.type) {
								case 'object':
									const fg2 = new UntypedFormGroup({});
									if (p.items.properties) {
										SchemaLayer.generateForm(p.items.properties, pp, fg2, p.items.required);
									}
									fg2.setParent(fa);
									fa.push(fg2);
									break;

								case 'string':
									fa.push(new UntypedFormControl(p.items.default ?? ''));
									break;
							}
						});
					}
					form.addControl(p.key, fa);
					break;
				case 'boolean':
					form.addControl(p.key, new UntypedFormControl(p.default ?? false));
					break;
				case 'circular':
					break;
				default:
					form.addControl(p.key, new UntypedFormControl(p.default ?? ''));
					form.get(p.key).setValidators(p.validations);
					if (!p.isRequired && savedValue[p.key] === undefined) {
						form.get(p.key).disable();
					}
					break;
			}
		});
	}
}
