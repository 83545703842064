import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {FormControl, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {I40FlatMember, MemberDefinitionType} from '../../../types';
import {JSONSchemaProperty} from '../../../../../data-models/json-schema.model';
import {I40TreeChannelService} from '../../i40-tree-channel.service';

@Component({
	selector: 'app-model-path',
	templateUrl: './model-path.component.html',
	styleUrls: ['./model-path.component.css'],
})
export class ModelPathComponent implements OnInit, OnChanges {
	@Input() form: UntypedFormGroup;
	@Input() selectedObject: I40FlatMember;
	@Input() property: JSONSchemaProperty;

	selectedObjectPath: string;

	constructor(public treeSrv: I40TreeChannelService) {}

	ngOnInit() {}

	ngOnChanges(): void {
		this.selectedObjectPath = this.selectedObject._fullPath
			.filter((p, i) => {
				return (!(i % 2) && !['Model', 'CommandParameters', 'CommandReply'].includes(p)) || (['CommandParameters', 'CommandReply'].includes(p) && i === this.selectedObject._fullPath.length - 2);
			})
			.map((p, i, arr) => {
				if (p === 'Array' && (i < arr.length - 1 || this.selectedObject._hasChildren)) {
					return 'ComplexArray';
				}
				if (p === 'List' && (i < arr.length - 1 || this.selectedObject._hasChildren)) {
					return 'ComplexList';
				}
				if (p === 'Variable' && (i < arr.length - 1 || this.selectedObject._hasChildren)) {
					return 'StructuredVariable';
				} else {
					return p;
				}
			})
			.join('/');

		// console.log(this.selectedObjectPath !== this.property.compatiblePath ? 'DIFF' : 'SAME', this.form.get(this.property.properties[0].key).value);

		if (this.form.get(this.property.properties[0].key).value.length === 0) {
			if (this.selectedObjectPath !== this.property.compatiblePath) {
				let parentPath = '';
				switch (this.property.compatiblePath) {
					case MemberDefinitionType.Command:
						parentPath = '/' + this.selectedObject._fullPath.slice(0, 2).join('/');
						break;
					default:
						parentPath = '/' + this.selectedObject._fullPath.slice(0, -2).join('/');
				}

				// console.log(this.property);
				// console.log(this.selectedObject._fullPath);
				// console.log(parentPath);

				this.form.setControl(this.property.properties[0].key, new UntypedFormControl(parentPath));
			} else {
				this.form.setControl(this.property.properties[0].key, new UntypedFormControl('/' + this.selectedObject._fullPath.join('/')));
			}
		}
	}

	getModelPathFormControl(): UntypedFormControl {
		return this.form.get(this.property.properties[0].key) as FormControl;
	}
}
