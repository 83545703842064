<ng-container *ngIf="loaded">
	<form [formGroup]="form" class="form" novalidate fxLayout="column" style="padding: 20px" (ngSubmit)="save()">
		<mat-form-field subscriptSizing="dynamic" fxFlex="">
			<mat-label>{{ 'i18n.id' | translate }}</mat-label>
			<input id="id_input" formControlName="id" matInput #selectOnInit required />
			<mat-error *ngIf="form.get('id').errors">{{ form.get('id').errors.message }}</mat-error>
		</mat-form-field>

		<mat-form-field subscriptSizing="dynamic" fxFlex="">
			<mat-label>{{ 'i18n.description' | translate }}</mat-label>
			<input id="description_input" matInput formControlName="description" />
		</mat-form-field>

		<mat-form-field subscriptSizing="dynamic" style="display: none">
			<mat-label>{{ 'i18n.memberType' | translate }}</mat-label>
			<input matInput formControlName="typeName" />
		</mat-form-field>
	</form>
</ng-container>
