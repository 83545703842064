<div flex style="height: 100%; overflow: hidden">
	<mat-toolbar fxLayout="row" class="small_toolbar">
		<span class="md-title">{{ 'i18n.artifactValidationResults' | translate }}</span>
		<div fxFlex></div>
		<button id="compile_artifacts_show_valid_button" mat-icon-button (click)="showValidResults()" matTooltip="{{ showValidButtonTooltip | translate }}">
			<mat-icon>check</mat-icon>
		</button>
		<button id="compile_artifacts_reload_button" mat-icon-button (click)="reloadResults()" matTooltip="{{ 'i18n.reload' | translate }}">
			<mat-icon>cached</mat-icon>
		</button>
		<button id="compile_artifacts_delete_button" mat-icon-button (click)="deleteResults()" matTooltip="{{ 'i18n.clear' | translate }}">
			<mat-icon>delete</mat-icon>
		</button>
		<button id="compile_artifacts_close_button" mat-icon-button (click)="closeResults()" matTooltip="{{ 'i18n.close' | translate }}">
			<mat-icon>close</mat-icon>
		</button>
	</mat-toolbar>
	<section class="table-container mat-elevation-z8">
		<table mat-table [dataSource]="dataSource">
			<ng-container matColumnDef="status">
				<th mat-header-cell *matHeaderCellDef>Status</th>
				<td mat-cell *matCellDef="let element">
					<mat-icon *ngIf="element.status" style="color: green; transform: scale(0.7)">check</mat-icon>
					<mat-icon *ngIf="!element.status" style="color: red; transform: scale(0.7)">cancel</mat-icon>
				</td>
			</ng-container>

			<ng-container matColumnDef="time">
				<th mat-header-cell *matHeaderCellDef>Time</th>
				<td mat-cell *matCellDef="let element">{{ element.time }}</td>
			</ng-container>

			<ng-container matColumnDef="type">
				<th mat-header-cell *matHeaderCellDef>Type</th>
				<td mat-cell *matCellDef="let element">{{ element.type }}</td>
			</ng-container>

			<ng-container matColumnDef="artifact">
				<th mat-header-cell *matHeaderCellDef>Artifact</th>
				<td mat-cell *matCellDef="let element">{{ element.artifact }}</td>
			</ng-container>

			<ng-container matColumnDef="error">
				<th mat-header-cell *matHeaderCellDef>Error</th>
				<td mat-cell *matCellDef="let element">{{ element.error }}</td>
			</ng-container>

			<!-- <ng-container matColumnDef="line">
                <th mat-header-cell *matHeaderCellDef> Line </th>
                <td mat-cell *matCellDef="let element"> {{element.line}} </td>
            </ng-container> -->

			<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
			<tr
				mat-row
				*matRowDef="let row; columns: displayedColumns"
				(click)="selection.toggle(row)"
				(dblclick)="rowDoubleClick(row)"
				[ngClass]="{hovered: row.hovered, highlighted: selection.isSelected(row)}"
				(mouseover)="row.hovered = true"
				(mouseout)="row.hovered = false"
			></tr>
		</table>
	</section>
</div>
