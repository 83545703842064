import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {MatDialog} from '@angular/material/dialog';
import {PluginManagerListItem} from './plugins.model';
import {BehaviorSubject, Subject} from 'rxjs';
import {JsonModelGeneratorModalAddComponent} from './json-model-generator-plugin/json-model-generator-modal-add/json-model-generator-modal-add.component';
import {JsonModelGeneratorModalUpdateComponent} from './json-model-generator-plugin/json-model-generator-modal-update/json-model-generator-modal-update.component';
import {ModelService} from '../information-models/model.service';
import {OpcuaModelGeneratorModalAddComponent} from './opcua-model-generator-plugin/opcua-model-generator-modal-add/opcua-model-generator-modal-add.component';
import {OpcuaModelGeneratorModalUpdateComponent} from './opcua-model-generator-plugin/opcua-model-generator-modal-update/opcua-model-generator-modal-update.component';
import {map} from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class PluginsService {
	plugins: Subject<PluginManagerListItem[]> = new BehaviorSubject([]);

	constructor(
		private http: HttpClient,
		public dialog: MatDialog,
		public modelsSrv: ModelService,
	) {}

	public getPlugins() {
		return this.http.get(`${environment.apiHost + environment.apiUrl}/plugins`, {withCredentials: true}).pipe(
			map((res) => {
				// res[2].locations.push({location: 'model-edit', action: 'update'});
				return res;
			}),
		);
	}

	public runPlugin(plugin: string, data: any) {
		return this.http.post(`${environment.apiHost + environment.apiUrl}/plugins/${encodeURI(plugin)}`, data, {withCredentials: true});
	}

	public openPlugin(plugin: string, action: string, data?: any) {
		let pluginComponent;

		switch (plugin) {
			case 'Json model generator':
				switch (action) {
					case 'add':
						pluginComponent = JsonModelGeneratorModalAddComponent;
						break;

					case 'update':
						pluginComponent = JsonModelGeneratorModalUpdateComponent;
						break;
				}

				break;

			case 'OpcUa model generator':
				switch (action) {
					case 'add':
						pluginComponent = OpcuaModelGeneratorModalAddComponent;
						break;

					case 'update':
						pluginComponent = OpcuaModelGeneratorModalUpdateComponent;
						break;
				}

				break;
		}

		const dialogRef = this.dialog.open(pluginComponent, {
			panelClass: ['plugin-action-popup', 'mat-elevation-z8'],
			backdropClass: 'plugin-action-backdrop',
			data: {
				data,
			},
		});

		return dialogRef.afterClosed();
	}
}
