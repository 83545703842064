<mat-toolbar fxLayout="row" class="small_toolbar">
	<mat-icon *ngIf="listSrv.toolbarIconSVG" [svgIcon]="listSrv.toolbarIcon"></mat-icon>
	<mat-icon *ngIf="!listSrv.toolbarIconSVG">{{ listSrv.toolbarIcon }}</mat-icon>

	<span *ngIf="!listSrv.searchVisible">
		<span class="md-title">{{ listSrv.toolbarTitle | translate }}</span>
	</span>

	<span *ngIf="listSrv.stringFilter.length && !listSrv.searchVisible" class="toolbar-selected-group-filter">
		<div>Searched by: {{ listSrv.stringFilter }}</div>
		<button mat-icon-button class="small_icon" matTooltip="Clear Search" (click)="listSrv.clearSearch()"><mat-icon>close</mat-icon></button>
	</span>

	<span fxFlex *ngIf="!listSrv.searchVisible"></span>

	<span class="toolbar-search-wrapper" fxFlex fxLayout="row" *ngIf="listSrv.searchVisible">
		<mat-form-field fxFlex floatLabel="auto">
			<input #searchInput id="search_input" matInput [(ngModel)]="listSrv.stringFilter" (ngModelChange)="listSrv.refreshStringFilter()" placeholder="{{ 'i18n.placeholder.search' | translate }}" />
		</mat-form-field>
	</span>

	<button id="close_search_button" *ngIf="listSrv.searchVisible" mat-icon-button matTooltip="{{ 'i18n.closesearch' | translate }}" (click)="listSrv.closeSearch()">
		<mat-icon>close</mat-icon>
	</button>

	<button id="search_button" *ngIf="!listSrv.searchVisible" mat-icon-button matTooltip="{{ 'i18n.search' | translate }}" (click)="showSearch()">
		<mat-icon>search</mat-icon>
	</button>

	<button id="import_button" *ngIf="globalSrv.canEdit() && showImport" mat-icon-button matTooltip="{{ 'i18n.import' | translate }}" (click)="import()">
		<mat-icon svgIcon="ico-import"></mat-icon>
	</button>

	<button id="add_button" *ngIf="globalSrv.canEdit() && showAdd" mat-icon-button matTooltip="{{ 'i18n.add' | translate }}" (click)="listSrv.addNew()" color="accent">
		<mat-icon>add</mat-icon>
	</button>
	<button id="reload_button" *ngIf="showRefresh" mat-icon-button (click)="listSrv.reloadData()" matTooltip="{{ 'i18n.reload' | translate }}">
		<mat-icon>cached</mat-icon>
	</button>

	<input type="file" #fileUpload id="fileUpload" name="fileUpload" accept="application/json" style="display: none" />

	<ng-content></ng-content>
</mat-toolbar>
