import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, of} from 'rxjs';
import {AuthService} from './auth.service';
import {catchError, map} from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class AuthGuard {
	constructor(private authSrv: AuthService) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.authSrv.checkUser().pipe(
			map((res: any) => {
				const user = res['user'];
				const license = res?.['license'];

				if (user) {
					this.authSrv.updateLoggedUser(user);
					this.authSrv.parseLicense(license);

					return true;
				} else {
					this.authSrv.accessDenied();
					return false;
				}
			}),
			catchError((err) => {
				this.authSrv.accessDenied();
				return of(false);
			}),
		);
	}
}
