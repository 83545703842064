import {Component, Inject, OnInit} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material/snack-bar';

@Component({
	selector: 'app-feedback-snackbar',
	templateUrl: './feedback-snackbar.component.html',
	styleUrls: ['./feedback-snackbar.component.scss'],
})
export class FeedbackSnackbarComponent implements OnInit {
	countdownTimer = null;
	timer = null;

	constructor(
		public snackRef: MatSnackBarRef<FeedbackSnackbarComponent>,
		@Inject(MAT_SNACK_BAR_DATA) public data: any,
	) {}

	ngOnInit() {}

	close() {
		this.snackRef.dismiss();
	}

	countDown(time) {
		if (!this.countdownTimer) {
			this.timer = time;
			this.countdownTimer = setInterval(() => {
				this.timer--;
				if (this.timer === 0) {
					clearInterval(this.countdownTimer);
					this.close();
				}
			}, 1000);
		}
		return this.timer;
	}
}
