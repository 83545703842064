import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {ArtifactInfo, Root} from '../data-models/core-model.model';
import {AuthService} from './auth.service';
import {Router} from '@angular/router';

@Injectable({
	providedIn: 'root',
})
export class GlobalService {
	uiAdvancedMode = localStorage.getItem('i40UIMode') !== null ? localStorage.getItem('i40UIMode') === 'true' : false;

	uiAdvancedModeChanged: Subject<boolean> = new Subject<boolean>();

	debugMode = localStorage.getItem('i40DebugMode') !== null ? localStorage.getItem('i40DebugMode') === 'true' : false;

	private debugModeSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.debugMode);
	public debugMode$ = this.debugModeSubject.asObservable();

	groups: string[] = [];

	splitTop: number = 100;
	splitBottom = {
		size: 40,
		visible: false,
	};

	appConf = localStorage.getItem('applicationConf');
	applicationConf: any = this.appConf ? JSON.parse(this.appConf) : {validationEnabled: false};

	constructor(
		private http: HttpClient,
		public authSrv: AuthService,
		public router: Router,
	) {}

	downloadFile(content, fileName, contentType) {
		const a = document.createElement('a');
		const file = new Blob([content], {type: contentType});
		a.href = URL.createObjectURL(file);
		a.download = fileName;
		a.click();
	}

	setDebugMode(isDebugMode: boolean) {
		this.debugModeSubject.next(isDebugMode);
	}

	getOs() {
		let OS = 'Unknown';
		if (navigator.userAgent.indexOf('Win') != -1) OS = 'Windows';
		if (navigator.userAgent.indexOf('Mac') != -1) OS = 'MacOS';
		if (navigator.userAgent.indexOf('X11') != -1) OS = 'UNIX';
		if (navigator.userAgent.indexOf('Linux') != -1) OS = 'Linux';
		return OS;
	}

	getMetaKey() {
		let metaKey = '';
		if (this.getOs() === 'MacOS') {
			metaKey = 'metaKey';
		} else {
			metaKey = 'ctrlKey';
		}
		return metaKey;
	}

	backup(api) {
		api.downloadRepository().subscribe((response: any) => {
			this.downloadFile(response, 'workspace.tar', 'application/octet-stream');
		});
	}

	restore(fileUpload, api) {
		const fileUploadEl = fileUpload.nativeElement;
		fileUploadEl.onchange = (e) => {
			const content = e.target.files[0];
			let fileReader = new FileReader();
			fileReader.onload = (e) => {
				let fd = new FormData();
				fd.append('workspace', content, content.name);
				api.restoreRepository(fd).subscribe((res) => {
					// uploaded
				});
			};
			fileReader.readAsText(content);
		};
		fileUploadEl.click();
	}

	toggleUiMode() {
		this.uiAdvancedMode = !this.uiAdvancedMode;
		localStorage.setItem('i40UIMode', this.uiAdvancedMode ? 'true' : 'false');
		this.uiAdvancedModeChanged.next(this.uiAdvancedMode);
	}

	circularJSONParse(j: any) {
		const getCircularReplacer = () => {
			const seen = new WeakSet();
			return (key, value) => {
				// ignore i40FormControl inside i40FormGroup
				if (key === '_ctrl') {
					return;
				}
				// ignore circular structure
				if (typeof value === 'object' && value !== null) {
					if (seen.has(value)) {
						return;
					}
					seen.add(value);
				}
				return value;
			};
		};

		return JSON.parse(JSON.stringify(j, getCircularReplacer()));
	}

	getVersions(module, id) {
		return this.http.get(`${environment.apiHost + environment.apiUrl}/${module}/${id}/versions`).pipe(
			map((result: any) => {
				return result;
			}),
		);
	}

	canEdit(model?: Root): boolean {
		if (model && model.artifactIdentifier() !== null) {
			return this.authSrv.canEdit && model.artifactIdentifier().version === 'latest';
		} else {
			return this.authSrv.canEdit;
		}
	}

	sortSelect(array, property?: string, extraProperty?: string) {
		array.sort((a, b) => {
			if (a instanceof ArtifactInfo) {
				let n1 = a.externalIdentifier.name.trim();
				let n2 = b.externalIdentifier.name.trim();
				return n1 === n2 ? 0 : n1 > n2 ? 1 : -1;
			} else {
				if (!property) {
					return a.trim() === b.trim() ? 0 : a.trim() > b.trim() ? 1 : -1;
				} else {
					if (a[property].length !== 0) {
						return a[property].trim() === b[property].trim() ? 0 : a[property].trim() > b[property].trim() ? 1 : -1;
					} else {
						if (extraProperty) {
							return a[extraProperty].trim() === b[extraProperty].trim() ? 0 : a[extraProperty].trim() > b[extraProperty].trim() ? 1 : -1;
						}
					}
				}
			}
		});
	}

	getGroupsList(data) {
		return data.map((artifact) => artifact?.externalIdentifier?.group);
	}

	reloadCurrentRoute() {
		const currentUrl = this.router.url;
		this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
			this.router.navigate([currentUrl]);
		});
	}
}
