import {Root, ValidationStatus} from '../data-models/core-model.model';
import {ElementRef, Injectable} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {NestedTreeControl} from '@angular/cdk/tree';
import {MatTreeNestedDataSource} from '@angular/material/tree';
import {Observable, Subject, Subscription} from 'rxjs';
import {MessageResponseService} from './message-response.service';
import {Button} from '../shared/generic-dialog/generic-dialog.component';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {MatSort} from '@angular/material/sort';
import {GlobalService} from './global.service';
import {Clipboard} from '@angular/cdk/clipboard';
import {_} from 'ngx-translate-extract';
import {NotificationsService} from './notifications.service';
import * as lodash from 'lodash';

@Injectable({
	providedIn: 'root',
})
export class UnifierListService<T extends Root> {
	route: ActivatedRoute;

	columns: string[];

	toolbarIcon: string = 'widgets';
	toolbarIconSVG: boolean = false;
	toolbarTitle: string;
	deleteTitle: string;
	deleteMessage: string;
	entryName: string;

	apiService: any;
	loadMethod: Function;
	importMethod: Function;
	exportMethod: Function;
	deleteMethod: Function;

	searchVisible = false;

	originalData: T[] = [];
	listData: T[] = [];

	data: MatTableDataSource<any> = new MatTableDataSource([]);

	groupFilterControl = new NestedTreeControl<any>((node) => node.children);
	groupFilterData: MatTreeNestedDataSource<any> = new MatTreeNestedDataSource();

	groupFilterVisible = false;
	stringFilter = '';
	stringFilterChanged: Subject<string> = new Subject<string>();
	groupFilters: any[] = [];
	selectedGroupFilter: any = null;
	private notificationSubscription: Subscription;

	constructor(
		public router: Router,
		public dialog: MatDialog,
		public msgSrv: MessageResponseService,
		public translate: TranslateService,
		public globalSrv: GlobalService,
		private clipboard: Clipboard,
		public notificationsSrv: NotificationsService,
	) {}

	init(route: ActivatedRoute, resolverCallback: Function, sort: MatSort, sortBy: string = 'group', sortOrder: 'asc' | 'desc' = 'asc') {
		this.groupFilterVisible = sessionStorage.getItem('grp-filter-' + this.entryName + '-visible') !== null ? sessionStorage.getItem('grp-filter-' + this.entryName + '-visible') === 'true' : true;
		this.selectedGroupFilter = sessionStorage.getItem('grp-filter-' + this.entryName) !== null ? JSON.parse(sessionStorage.getItem('grp-filter-' + this.entryName)) : null;

		this.route = route;

		this.stringFilterChanged.pipe(debounceTime(200), distinctUntilChanged()).subscribe((searchString) => {
			this.data.filter = searchString;
		});

		this.route.data.subscribe((result) => {
			let updateDataArgs: any = resolverCallback(result);

			if (updateDataArgs instanceof Set) {
				updateDataArgs = [...updateDataArgs];
				this.updateData.apply(this, updateDataArgs);
			} else {
				this.updateData(updateDataArgs);
			}

			sort.active = sortBy;
			sort.direction = sortOrder;
			sort.start = sortOrder;

			this.data.sort = sort;

			this.data.sortingDataAccessor = (item, property) => {
				return this.columnGetter(property, item);
			};

			this.data.filterPredicate = (data: T, filter) => {
				const dataStr = this.stringifyRowData(data).trim().toLowerCase();
				return dataStr.includes(filter);
			};

			this.msgSrv.onLoading.next({command: 'stop'});
		});
	}

	listenToArtifactValidationEvents() {
		this.notificationSubscription = this.notificationsSrv.getArtifactValidationEvents().subscribe((events) => {
			events.forEach((event) => {
				if (event.status.info.artifactType == this.entryName) {
					const listData = lodash.cloneDeep(this.originalData);
					var hasUpdates = false;
					listData.forEach((e) => {
						if (e.info.identifier.id == event.status.info.identifier.id) {
							if (event.status.valid) {
								e.status = new ValidationStatus({status: 'success'});
								hasUpdates = true;
							} else {
								e.status = new ValidationStatus({
									status: 'error',
									messages: event.status.messages.map((m) => {
										return {line: m.line, userHint: m.message};
									}),
								});
								hasUpdates = true;
							}
						}
					});

					if (hasUpdates) {
						this.updateData(listData);
					}
				} else {
					//console.log(`onArtifactValidated`, event)
				}
			});
		});
	}

	destroy() {
		if (this.notificationSubscription) {
			this.notificationSubscription.unsubscribe();
		}
	}

	stringifyRowData(row: T): string {
		const rowStr: string[] = [];
		this.columns.forEach((c) => {
			rowStr.push(this.columnGetter(c, row));
		});
		return rowStr.join('');
	}

	// default column getter for ArtifactInfo
	columnGetter(col: string, row: T) {
		switch (col) {
			case 'id':
				return row['identifier'].getId();
			case 'group':
				return row['externalIdentifier'].group;
			case 'name':
				return row['externalIdentifier'].name;
			case 'version':
				return row['identifier'].version;
			case 'description':
				return row['externalDescriptor'].description;
			case 'status':
				return row['status'] != null ? row['status'].status : '';
			default:
				return row[col];
		}
	}

	copyId(id: string) {
		this.clipboard.copy(id);
	}

	updateData(rows, ...args: any[]) {
		this.originalData = rows;

		rows.sort((a, b) => {
			const grpA = this.columnGetter('group', a).toLowerCase();
			const grpB = this.columnGetter('group', b).toLowerCase();
			if (grpA < grpB) return -1;
			if (grpA > grpB) return 1;
			return 0;
		});

		this.updateGroupFilters(rows);

		if (this.selectedGroupFilter) {
			this.filterByGroup(this.selectedGroupFilter);
		} else {
			this.updateRows(rows);
		}
	}

	updateRows(rows: T[]) {
		this.listData = rows;
		this.data.data = rows;
		this.globalSrv.groups = rows.length !== 0 ? this.globalSrv.getGroupsList(rows) : null;
	}

	reloadData() {
		this.msgSrv.onLoading.next({command: 'start'});
		this.loadMethod.call(this.apiService).subscribe((data: any) => {
			this.updateData(data);
			this.msgSrv.onLoading.next({command: 'stop'});
		});
	}

	closeSearch() {
		this.searchVisible = false;
	}

	clearSearch() {
		this.stringFilter = '';
		this.refreshStringFilter();
	}

	filterByGroup(node?: any) {
		const path = node.path;
		this.selectedGroupFilter = node;

		sessionStorage.setItem('grp-filter-' + this.entryName, JSON.stringify(node));

		if (path) {
			this.updateRows(
				this.originalData.filter((mi) => {
					return this.columnGetter('group', mi).includes(path);
				}),
			);
		} else {
			this.updateRows(this.originalData);
		}
	}

	refreshStringFilter() {
		this.stringFilterChanged.next(this.stringFilter.trim().toLowerCase());
	}

	updateGroupFilters(rows: T[]) {
		this.generateGroupFilters(rows);
		this.groupFilterData.data = this.groupFilters;
		this.groupFilterControl.dataNodes = this.groupFilters;
		this.groupFilterControl.expandAll();
	}

	generateGroupFilters(rows: T[]) {
		if (!rows || rows.length == 0) {
			return;
		}

		const groupTree = {name: this.translate.instant('i18n.groupFilter.showAll'), path: '', children: []};

		rows.forEach((mi: T) => {
			const groups = this.columnGetter('group', mi)?.split('.');
			if (groups.length > 1) {
				this.addToGroupTree(groupTree, groups);
			} else {
				if (!this.parentHasChild(groupTree, groups[0])) {
					groupTree.children.push({name: groups[0], path: groups[0], children: []});
				}
			}
		});

		if (this.groupFilterVisible) {
			this.groupFilterVisible = groupTree.children.length > 0;
		}

		this.groupFilters = [groupTree];
	}

	addToGroupTree(parent, groups, level = 0) {
		if (groups[level] === undefined) return;
		let found = false;
		parent.children.forEach((c) => {
			if (groups[level] === c.name) {
				found = true;
				this.addToGroupTree(c, groups, level + 1);
			}
		});

		if (!found) {
			const child = {name: groups[level], path: (level > 0 ? parent.path + '.' : '') + groups[level], children: []};
			this.addToGroupTree(child, groups, level + 1);
			if (!this.parentHasChild(parent, child.name)) {
				parent.children.push(child);
			}
		}
	}

	parentHasChild(parent, name) {
		let found = false;
		parent.children.forEach((c) => {
			if (c.name === name) {
				found = true;
			}
		});
		return found;
	}

	groupFilterTreeHasChild = (_: number, node: any) => !!node.children && node.children.length > 0;

	toggleGroupFilters() {
		this.groupFilterVisible = !this.groupFilterVisible;
		sessionStorage.setItem('grp-filter-' + this.entryName + '-visible', this.groupFilterVisible ? 'true' : 'false');
	}

	import(fileUpload: ElementRef) {
		const fileUploadEl = fileUpload.nativeElement;
		let onChange = false;

		fileUploadEl.onchange = (e) => {
			this.msgSrv.onLoading.next({command: 'start', message: this.translate.instant('i18n.snackbar.importing')});
			const content = e.target.files[0];

			let fileReader = new FileReader();
			fileReader.onload = (e) => {
				onChange = true;
				const data = JSON.parse(fileReader.result as string);
				this.importMethod.call(this.apiService, data).subscribe({
					next: (res) => {
						this.msgSrv.onLoading.next({command: 'stop'});
						this.reloadData();
						return res;
					},
					error: (err) => {
						this.msgSrv.onLoading.next({command: 'stop'});
						console.error(err);
					},
				});
			};
			fileReader.onerror = (error) => {
				console.error(error);
				this.msgSrv.onLoading.next({command: 'stop'});
			};
			fileReader.readAsText(content);
			fileUploadEl.value = null;
		};

		fileUploadEl.click();
	}

	export(row: T) {
		this.msgSrv.onLoading.next({command: 'start', message: this.translate.instant('i18n.snackbar.exporting')});
		this.exportMethod.call(this.apiService, this.columnGetter('id', row)).subscribe((res) => {
			this.msgSrv.onLoading.next({command: 'stop'});
			this.globalSrv.downloadFile(
				JSON.stringify(res.serialize()),
				this.entryName + '_' + this.columnGetter('group', row) + '_' + this.columnGetter('name', row) + '_' + this.columnGetter('version', row) + '.json',
				'application/json',
			);
		});
	}

	addNew(params?: any) {
		this.router.navigate([`add`], {relativeTo: this.route});
	}

	editRow(row: T) {
		this.router.navigate([`edit/${this.columnGetter('id', row)}`], {relativeTo: this.route});
	}

	deleteRow(event, row: T, index: number) {
		const entryIdentifier = `${this.columnGetter('id', row)}`;
		const entryName = this.columnGetter('version', row)
			? `${this.columnGetter('group', row)}:${this.columnGetter('name', row)}:${this.columnGetter('version', row)}`
			: `${this.columnGetter('group', row)}:${this.columnGetter('name', row)}`;

		return this.msgSrv.customDialogMessage(
			this.translate.instant(this.deleteTitle),
			this.translate.instant(this.deleteMessage, {value: entryName}),
			[new Button(this.translate.instant('i18n.cancel'), 'cancel', 'flat', 'accent', 'end'), new Button(this.translate.instant('i18n.delete'), 'delete', 'flat', 'accent', 'end')],
			{
				delete: (dialogRef, resolve) => {
					this.deleteMethod.call(this.apiService, entryIdentifier).subscribe({
						next: () => {
							this.msgSrv.showSuccess(this.translate.instant('i18n.entryDeletedText'));
							this.reloadData();
							dialogRef.close();
							resolve(true);
						},
						error: (err) => {
							err.error ? this.msgSrv.showUsed(err.error) : '';
							dialogRef.close();
							resolve(false);
						},
					});
				},
				cancel: (dialogRef, resolve) => {
					dialogRef.close();
					resolve(false);
				},
			},
		);
	}
}
