import {ArtifactInfo, Root} from '../../../data-models/core-model.model';

export class OpcuaModelGeneratorItem extends ArtifactInfo {
	_type: 'OpcUa' | 'XML' = 'XML';
	item: OpcUaToModelItem | XMLToModelItem = new XMLToModelItem({}, this);

	constructor(input?: {_type?: 'OpcUa' | 'XML'; identifier?: any; externalIdentifier?: any; externalDescriptor?: any; item?: any}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}

	setItem(item, parent) {
		switch (this._type) {
			case 'XML':
				this.item = new XMLToModelItem(item, parent);
				break;
			case 'OpcUa':
				this.item = new OpcUaToModelItem(item, parent);
				break;
		}
	}

	serialize(): any {
		let output = super.serialize();

		if (output.identifier.id === '') {
			delete output.identifier;
		}

		return output;
	}
}

export class OpcUaToModelItem extends Root {
	namespaceIndex: string = '';
	identifierName: string[] = [];
	securityPolicy: 'None' | 'Basic128Rsa15' | 'Basic256' | 'Basic256Sha256' | 'Aes128_Sha256_RsaOaep' | 'Aes256_Sha256_RsaPs' = 'None';
	ipAddress: string = '';
	tcpPort: number = null;
	endpointPath: string = '';

	constructor(
		input?: {
			namespaceIndex?: string;
			identifierName?: string[];
			securityPolicy?: 'None' | 'Basic128Rsa15' | 'Basic256' | 'Basic256Sha256' | 'Aes128_Sha256_RsaOaep' | 'Aes256_Sha256_RsaPs';
			ipAddress?: string;
			tcpPort?: number;
			endpointPath?: string;
		},
		parent?: Root,
		inherited: boolean = false,
	) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}

	identifierNameToFormControl() {
		return this.rootArrayToFormControl(this.identifierName);
	}
}

export class XMLToModelItem extends Root {
	definitionType: 'Model' | 'Event' | 'Variable' = 'Variable';
	definitionName: string = '';
	inputXml: string = '';

	constructor(input?: {definitionType?: string; definitionName?: string; inputXml?: string}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}
}
