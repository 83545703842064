<button id="add_button" *ngIf="globalSrv.canEdit() && activePlugins.length > 0" mat-icon-button matTooltip="{{ 'i18n.Plugins' | translate }}" [matMenuTriggerFor]="pluginsMenu" color="accent">
	<mat-icon>extension</mat-icon>
</button>
<mat-menu #pluginsMenu id="plugins_menu" class="toolbar_menu">
	<mat-nav-list>
		<mat-list id="plugins_menu_list">
			<mat-list-item id="{{ p.id | lowercase }}_list_item" mat-list-item *ngFor="let p of activePlugins" (click)="pluginSrv.openPlugin(p.name, p.currentAction, p.locationData)"
				><b>{{ p.name }}:</b>&nbsp;{{ p.currentAction.toUpperCase() }}</mat-list-item
			>
		</mat-list>
	</mat-nav-list>
</mat-menu>
