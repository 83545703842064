import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatCheckbox} from '@angular/material/checkbox';
import {MatListOption, MatSelectionList} from '@angular/material/list';
import {TranslateService} from '@ngx-translate/core';
import {BackupRestoreService} from 'src/app/apps/backup-repository/backup-restore.service';
import {MessageResponseService} from 'src/app/core-services/message-response.service';
import {ArtifactFilteredRequest, ArtifactInfo} from 'src/app/data-models/core-model.model';

@Component({
	selector: 'artifact-list',
	templateUrl: './artifact-list.component.html',
	styleUrls: ['./artifact-list.component.scss'],
})
export class ArtifactListComponent implements OnInit {
	constructor(
		private msgSrv: MessageResponseService,
		private translate: TranslateService,
		private backupRestoreSrv: BackupRestoreService,
	) {}

	@Input() artifactInfo: any;
	@Input() type: string;

	artifacts: ArtifactInfo[] = [];
	canClose: boolean;
	title: string = '';
	buttonLabel: string = '';

	@ViewChild('artifactList', {static: false})
	artifactList: MatSelectionList;
	@ViewChild('all', {static: false}) all: MatCheckbox;

	ngOnInit(): void {
		this.title = this.translate.instant('i18n.selectArtifacts', {
			action: this.type,
		});
		this.buttonLabel = this.type === 'backup' ? this.translate.instant('i18n.backup') : this.translate.instant('i18n.restore');
		this.artifacts = this.type === 'backup' ? this.artifactInfo : this.artifactInfo.artifacts;
		setTimeout(() => {
			this.all.checked = true;
			this.artifactList.selectAll();
		});
	}

	setAll(event) {
		setTimeout(() => {
			event.checked ? this.artifactList.selectAll() : this.artifactList.deselectAll();
		});
	}

	changeChecked() {
		this.all.checked = false;
		if (this.artifactList.selectedOptions.selected.length === this.artifacts.length) {
			this.all.checked = true;
		}
	}

	execute() {
		let filteredData: ArtifactFilteredRequest = new ArtifactFilteredRequest({});
		let ids: string[] = [];

		if (!this.all.checked) {
			ids = this.artifactList.selectedOptions.selected.map((a: MatListOption) => a.value.identifier.id);
			filteredData = new ArtifactFilteredRequest({
				identifiers: ids,
			});
		}

		switch (this.type) {
			case 'backup':
				this.backupRestoreSrv.backupFiltered(filteredData);
				break;
			case 'restore':
				if (this.all.checked) {
					this.backupRestoreSrv.advancedRestore();
				} else {
					let data = {
						archiveId: this.artifactInfo.archiveId,
						indentifiers: ids,
					};
					this.backupRestoreSrv.advancedRestore(data);
				}
				break;
		}
	}
}
