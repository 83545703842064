import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {I40TreeComponent} from '../i40-tree.component';
import {ClipboardService} from 'ngx-clipboard';
import {MatDialog} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {I40TreeChannelService} from './i40-tree-channel.service';
import {ChannelManagerResult, LayerConfiguration} from '../../../apps/channel/channel.model';
import {ModelService} from '../../../apps/information-models/model.service';
import {ChannelService} from '../../../apps/channel/channel.service';
import {JsonSchemaParserService} from '../../../core-services/json-schema-parser.service';
import {MessageResponseService} from '../../../core-services/message-response.service';
import {forkJoin} from 'rxjs';
import {Button} from '../../generic-dialog/generic-dialog.component';
import {CommunicationService} from '../../../core-services/communication.service';
import * as _ from 'lodash';
import {ThemeService} from '../../../core-services/theme.service';
import {ChannelTypesService} from '../../../apps/channel-types/channel-types.service';
import {ChannelTypeManagerResult, LayerDetails} from '../../../apps/channel-types/channel-types-model';
import {SchemaLayer} from '../../../data-models/json-schema.model';
import {GlobalService} from '../../../core-services/global.service';
import {AuthService} from '../../../core-services/auth.service';
import {Root} from '../../../data-models/core-model.model';
import {environment} from '../../../../environments/environment';

@Component({
	selector: 'i40-tree-channel',
	templateUrl: './i40-tree-channel.component.html',
	styleUrls: ['../i40-tree.component.scss', './i40-tree-channel.component.scss'],
	providers: [I40TreeChannelService, JsonSchemaParserService],
})
export class I40TreeChannelComponent extends I40TreeComponent implements OnInit {
	@Input() direction: 'horizontal' | 'vertical' = 'horizontal';
	@Input() rootParent: Root = null;

	@Input() channelManagerResult: ChannelManagerResult;
	@Output() channelManagerResultChange: EventEmitter<ChannelManagerResult> = new EventEmitter<ChannelManagerResult>();

	@Output() editorStatusChanged: EventEmitter<string[]> = new EventEmitter();
	@Output() onClose: EventEmitter<any> = new EventEmitter();

	channelManagerResultOutput: ChannelManagerResult;
	options: any;

	loadingLayers = true;

	layerArray: SchemaLayer[] = [];

	stillDirty = false;

	editorCodeInput: string[] = [];
	editorCodeOutput: string[] = [];

	channelType: ChannelTypeManagerResult;

	layerLabels: any = {};

	constructor(
		_clipboardService: ClipboardService,
		public tsrv: I40TreeChannelService,
		public dialog: MatDialog,
		public themeSrv: ThemeService,
		private modelService: ModelService,
		private channelTypesService: ChannelTypesService,
		public authSrv: AuthService,
		private channelService: ChannelService,
		private jsonSchemaParserService: JsonSchemaParserService,
		private msgSrv: MessageResponseService,
		private translate: TranslateService,
		public globalSrv: GlobalService,
		public com: CommunicationService,
	) {
		super(tsrv, _clipboardService, globalSrv);
	}

	ngOnInit() {
		super.ngOnInit();
		this.msgSrv.onLoading.next({command: 'start'});
		this.tsrv.channel = this.channelManagerResult;

		if (this.rootParent !== null) {
			this.tsrv.rootParent = this.rootParent;
		} else {
			this.tsrv.rootParent = this.channelManagerResult;
		}

		forkJoin([
			this.modelService.getModelData(this.channelManagerResult.model.identifier.getId()),
			this.channelTypesService.getChannelTypeById(this.channelManagerResult.channelType.identifier.getId()),
		]).subscribe(
			([modelData, channelType]) => {
				this.channelType = channelType;
				this.model = modelData;
			},
			(error) => {
				this.loaded = true;
				if (error) {
					this.msgSrv.showError(this.translate.instant('i18n.errorMsg.invalidChannel'));
				}
				this.msgSrv.onLoading.next({command: 'stop'});
			},
		);
	}

	initEditor(): Promise<void> {
		this.loaded = true;

		this.channelManagerResultOutput = _.cloneDeep(this.channelManagerResult);
		return this.requestImplementationSchema();
	}

	requestImplementationSchema(): Promise<void> {
		this.loadingLayers = true;

		this.layerArray = [];

		return new Promise((resolve, reject) => {
			this.channelTypesService.getLayersDetails(this.channelType.layers).subscribe((layers: any[]) => {
				layers.forEach((layer: LayerDetails, index) => {
					const rawSchema: any = JSON.parse(layer.layerSchema);

					// console.log(layer.layerSchema);
					// console.log(_.cloneDeep(rawSchema));

					let properties = this.jsonSchemaParserService.parseSchema(rawSchema, layer.implementationType.implementationTypeName);

					if (!this.channelManagerResultOutput.channelTypeConfigurations[index]) {
						this.channelManagerResultOutput.push(new LayerConfiguration({implementationTypeName: layer.implementationType.implementationTypeName}), 'channelTypeConfigurations', index);
					}

					let existingConfiguration = this.channelManagerResultOutput.channelTypeConfigurations[index]?.configuration ?? '{}';
					let savedValue = {};

					if (!(!existingConfiguration || existingConfiguration == '{}' || existingConfiguration == '')) {
						savedValue = JSON.parse(existingConfiguration);
					}

					this.layerArray.push(
						new SchemaLayer({
							label: layer.implementationType.label,
							properties,
							savedValue,
							required: rawSchema.required ?? [],
						}),
					);
				});

				// console.log(_.cloneDeep(this.layerArray));

				this.msgSrv.onLoading.next({command: 'stop'});
				this.loadingLayers = false;
				resolve();
			});
		});
	}

	nodeClickSelect(member) {
		if (!this.loaded) {
			super.nodeClickSelect(member);

			this.initEditor().then(() => {});
		} else {
			if (this.layerArray.some((l) => l.form.invalid)) {
				this.layerArray.forEach((l) => {
					l.form.markAllAsTouched();
					l.form.updateValueAndValidity();
				});

				this.msgSrv.customDialogMessage(
					this.translate.instant('i18n.invalidData'),
					this.translate.instant('i18n.fixErrors'),
					[new Button(this.translate.instant('i18n.fix'), 'fix', 'flat', 'accent', 'center')],
					{
						fix: (dialogRef, resolve) => {
							dialogRef.close();
							resolve(true);
						},
					},
				);
			} else {
				super.nodeClickSelect(member);
			}
		}
	}

	canDeactivate() {
		if (this.layerArray.some((l) => l.form.dirty) || this.channelManagerResult._formGroup.dirty || this.stillDirty) {
			return this.msgSrv.unsavedChangesClose((resolve) => {
				resolve(true);
			});
		} else {
			return true;
		}
	}

	close() {
		const callback = () => {
			this.onClose.emit(this.channelManagerResultOutput);
		};

		if (this.layerArray.some((l) => l.form.dirty)) {
			this.msgSrv.unsavedChangesClose(callback);
		} else {
			callback();
		}
	}

	apply() {
		this.channelManagerResultOutput.channelTypeConfigurations.forEach((layer, index) => {
			layer.configuration = JSON.stringify(this.layerArray[index].form.value);
		});

		this.layerArray.forEach((l) => l.form.markAsPristine());

		this.channelManagerResultChange.emit(this.channelManagerResultOutput);

		this.stillDirty = true;

		this.nodeClickSelect(this.selectedObject);
	}

	toggleEditor() {
		if (!this.showJsonEditor) {
			this.apply();
			this.editorCodeOutput = [];

			this.channelManagerResultOutput.channelTypeConfigurations.forEach((layer, index) => {
				this.editorCodeInput[index] = JSON.stringify(JSON.parse(layer.configuration), null, '\t');
				this.editorCodeOutput[index] = JSON.stringify(JSON.parse(layer.configuration), null, '\t');
			});

			this.showJsonEditor = true;
		} else {
			// RESET FORM
			this.channelManagerResult.channelTypeConfigurations.forEach((layer, index) => {
				layer.configuration = this.editorCodeOutput[index];
			});

			this.showJsonEditor = false;
			this.loaded = true;

			this.initEditor();
		}
	}

	formDisabled() {
		return !(this.layerArray.every((l) => l.form.valid) && this.layerArray.some((l) => l.form.dirty));
	}

	openChannelHelp() {
		const url = environment.apiHost + '/adapter/manual/' + 'index.html?section=' + this.channelType.info.externalIdentifier.name; // Assuming helpLink() returns the full URL
		window.open(url, '_blank', 'noopener,noreferrer');
	}
}
