<div fxLayout="column">
	<div fxLayout="row" style="align-items: center">
		<mat-checkbox [checked]="checked" (change)="onToggleArray($event)" class="{{ treeSrv.getId(property, 'checkbox') }}" [disabled]="!globalSrv.canEdit(treeSrv.rootParent)">{{
			property.description
		}}</mat-checkbox>
		<span fxFlex></span>
		<button mat-icon-button (click)="addElement()" *ngIf="checked" id="{{ treeSrv.getId(property, 'list_add') }}" [disabled]="!globalSrv.canEdit(treeSrv.rootParent)">
			<mat-icon class="{{ !globalSrv.canEdit(treeSrv.rootParent) ? '' : 'mat-accent' }}">add</mat-icon>
		</button>
	</div>
	<div *ngIf="checked">
		<div *ngFor="let fg of form.controls; let i = index" fxLayout="row" style="margin-bottom: 15px; align-items: center">
			<ng-container [ngSwitch]="items.type">
				<app-group
					*ngSwitchCase="'object'"
					fxFlex
					[title]="items.description"
					[selectedObject]="selectedObject"
					[layer]="layer"
					[properties]="items.properties"
					[savedValue]="savedValue ? savedValue[i] : null"
					[emptyFormValue]="emptyFormValue ? emptyFormValue[i] : null"
					[form]="getFormGroup(fg)"
					[expanded]="true"
					[collapsible]="false"
					[showContainer]="true"
					(visible)="subgroupVisible($event)"
				>
				</app-group>

				<app-input fxFlex *ngSwitchCase="'string'" [selectedObject]="selectedObject" [control]="getFormControl(i)" [property]="items"></app-input>
			</ng-container>

			<button mat-icon-button (click)="removeElement(i)" id="{{ treeSrv.getId(property, 'list_remove_' + i) }}" [disabled]="!globalSrv.canEdit(treeSrv.rootParent)">
				<mat-icon class="{{ !globalSrv.canEdit(treeSrv.rootParent) ? '' : 'mat-warn' }}">delete</mat-icon>
			</button>
		</div>
	</div>
</div>
