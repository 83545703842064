import {ElementRef, Injectable} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {Subject} from 'rxjs';
import {MessageResponseService} from './message-response.service';
import {Button} from '../shared/generic-dialog/generic-dialog.component';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {MatSort} from '@angular/material/sort';
import {GlobalService} from './global.service';
import {Clipboard} from '@angular/cdk/clipboard';
import * as _ from 'lodash';

@Injectable({
	providedIn: 'root',
})
export class GenericListService {
	route: ActivatedRoute;

	columns: string[];

	toolbarIcon: string = 'widgets';
	toolbarIconSVG: boolean = false;
	toolbarTitle: string;
	deleteTitle: string;
	deleteMessage: string;
	entryName: string;

	apiService: any;
	loadMethod: Function;
	importMethod: Function;
	exportMethod: Function;
	deleteMethod: Function;

	searchVisible = false;

	originalData: any[] = [];
	listData: any[] = [];

	data: MatTableDataSource<any> = new MatTableDataSource([]);

	stringFilter = '';
	stringFilterChanged: Subject<string> = new Subject<string>();

	constructor(
		public router: Router,
		public dialog: MatDialog,
		public msgSrv: MessageResponseService,
		public translate: TranslateService,
		public globalSrv: GlobalService,
		private clipboard: Clipboard,
	) {}

	init(route: ActivatedRoute, resolverCallback: Function, sort: MatSort, sortBy: string = '', sortOrder: 'asc' | 'desc' = 'asc') {
		this.route = route;

		this.stringFilterChanged.pipe(debounceTime(200), distinctUntilChanged()).subscribe((searchString) => {
			this.data.filter = searchString;
		});

		this.route.data.subscribe((result) => {
			let updateDataArgs: any = resolverCallback(result);

			if (updateDataArgs instanceof Set) {
				updateDataArgs = [...updateDataArgs];
				this.updateData.apply(this, updateDataArgs);
			} else {
				this.updateData(updateDataArgs);
			}

			sort.active = sortBy;
			sort.direction = sortOrder;
			sort.start = sortOrder;

			this.data.sort = sort;

			this.data.sortingDataAccessor = (item, property) => {
				return this.columnGetter(property, item);
			};

			this.data.filterPredicate = (data: any, filter) => {
				const dataStr = this.stringifyRowData(data).trim().toLowerCase();
				return dataStr.includes(filter);
			};

			this.msgSrv.onLoading.next({command: 'stop'});
		});
	}

	stringifyRowData(row: any): string {
		const rowStr: string[] = [];
		this.columns.forEach((c) => {
			rowStr.push(this.columnGetter(c, row));
		});
		return rowStr.join('');
	}

	// default column getter for ArtifactInfo
	columnGetter(col: string, row: any) {
		switch (col) {
			case 'id':
				return row['id'];
			case 'eventType':
				return row['eventType'];
			case 'eventTime':
				return row['eventTime'];
			default:
				return row[col];
		}
	}

	copyId(id: string) {
		this.clipboard.copy(id);
	}

	updateData(rows, ...args: any[]) {
		this.originalData = rows;
		this.updateRows(rows);
	}

	updateRows(rows: any[]) {
		this.listData = rows;
		this.data.data = rows;
	}

	reloadData() {
		this.msgSrv.onLoading.next({command: 'start'});
		this.loadMethod.call(this.apiService).subscribe((data: any) => {
			this.updateData(data);
			this.msgSrv.onLoading.next({command: 'stop'});
		});
	}

	closeSearch() {
		this.searchVisible = false;
	}

	clearSearch() {
		this.stringFilter = '';
		this.refreshStringFilter();
	}

	refreshStringFilter() {
		this.stringFilterChanged.next(this.stringFilter.trim().toLowerCase());
	}

	import(fileUpload: ElementRef) {
		const fileUploadEl = fileUpload.nativeElement;

		fileUploadEl.onchange = (e) => {
			this.msgSrv.onLoading.next({command: 'start', message: this.translate.instant('i18n.snackbar.importing')});
			const content = e.target.files[0];

			let fileReader = new FileReader();
			fileReader.onload = (e) => {
				const data = JSON.parse(fileReader.result as string);
				this.importMethod.call(this.apiService, data).subscribe((res) => {
					this.msgSrv.onLoading.next({command: 'stop'});
					this.reloadData();
				});
			};
			fileReader.readAsText(content);
		};

		fileUploadEl.click();
	}

	export(row: any) {
		this.msgSrv.onLoading.next({command: 'start', message: this.translate.instant('i18n.snackbar.exporting')});
		this.exportMethod.call(this.apiService, this.columnGetter('id', row)).subscribe((res) => {
			this.msgSrv.onLoading.next({command: 'stop'});
			this.globalSrv.downloadFile(JSON.stringify(res.serialize()), this.entryName + '.json', 'application/json');
		});
	}

	addNew(params?: any) {
		this.router.navigate([`add`], {relativeTo: this.route});
	}

	editRow(row: any) {
		this.router.navigate([`edit/${this.columnGetter('id', row)}`], {relativeTo: this.route});
	}

	deleteRow(event, row: any, index: number) {
		const entryIdentifier = `${this.columnGetter('id', row)}`;
		const entryName = this.entryName;

		return this.msgSrv.customDialogMessage(
			this.translate.instant(this.deleteTitle),
			this.translate.instant(this.deleteMessage, {value: entryName}),
			[new Button(this.translate.instant('i18n.cancel'), 'cancel', 'flat', 'accent', 'end'), new Button(this.translate.instant('i18n.delete'), 'delete', 'flat', 'accent', 'end')],
			{
				delete: (dialogRef, resolve) => {
					this.deleteMethod.call(this.apiService, entryIdentifier).subscribe({
						next: () => {
							this.msgSrv.showSuccess(this.translate.instant('i18n.entryDeletedText'));
							this.reloadData();
							dialogRef.close();
							resolve(true);
						},
						error: (err) => {
							err.error ? this.msgSrv.showUsed(err.error) : this.msgSrv.showUsed(err);
							dialogRef.close();
							resolve(false);
						},
					});
				},
				cancel: (dialogRef, resolve) => {
					dialogRef.close();
					resolve(false);
				},
			},
		);
	}
}
