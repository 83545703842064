import {EventEmitter, Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {ModelService} from '../../../apps/information-models/model.service';
import {Identifier, NewArtifactID, NewNodeID, Root} from '../../../data-models/core-model.model';
import {I40TreeService} from '../i40-tree.service';
import {I40FlatMember, MemberDefinitionType, MemberDescription, ObjectDescription} from '../types';
import * as _ from 'lodash';

@Injectable({
	providedIn: 'root',
})
export class I40TreeCrudService extends I40TreeService {
	updateTreeRequest: EventEmitter<any> = new EventEmitter();
	cancelUpdateRequest: EventEmitter<any> = new EventEmitter();
	tellEditor: EventEmitter<any> = new EventEmitter();
	closeMemberEditor: EventEmitter<any> = new EventEmitter();

	isDirty = false;

	constructor(
		translateSrv: TranslateService,
		dialog: MatDialog,
		private modelService: ModelService,
	) {
		super(translateSrv, dialog);
	}

	addFirstInMap(key, value, map: Map<string, any>): Map<string, any> {
		const newMap = new Map();

		// set first element
		newMap.set(key, value);

		map.forEach((v, k) => {
			newMap.set(k, v);
		});

		return newMap;
	}

	moveItemInMap(map: Map<string, any>, from, to) {
		const newMap = new Map();
		let ind = 0;
		let movingElement = null;
		let replacedElement = null;
		const down = to > from;

		map.forEach((v, k) => {
			if (ind === from) {
				movingElement = [k, v];
			}
			if (ind === to) {
				replacedElement = [k, v];
			}
			ind++;
		});

		map.forEach((v, k) => {
			if (down) {
				if (k !== movingElement[0]) {
					newMap.set(k, v);
				}
				if (k === replacedElement[0]) {
					newMap.set(movingElement[0], movingElement[1]);
				}
			} else {
				if (k === replacedElement[0]) {
					newMap.set(movingElement[0], movingElement[1]);
				}
				if (k !== movingElement[0]) {
					newMap.set(k, v);
				}
			}
		});

		return newMap;
	}

	getChildComplexMemberTypes(member: I40FlatMember) {
		const children = this.getChildren(member);
		const customMemberTypes = [];
		children.forEach((c) => {
			if (['model', 'custom'].includes(c._memberType.category)) {
				customMemberTypes.push(c.typeName);
			}
		});
		return customMemberTypes;
	}

	generateNewNode(newNodeType): MemberDescription {
		let newNode: MemberDescription;

		switch (newNodeType.valueOf()) {
			case 'Command':
				newNode = new MemberDescription({
					id: NewNodeID,
					description: '',
					definitionType: newNodeType,
					typeName: 'Command',
					_collapsed: false,
				});
				break;

			case 'Array':
				newNode = new MemberDescription({
					id: NewNodeID,
					description: '',
					definitionType: MemberDefinitionType.Array,
					typeName: '',
					_collapsed: false,
					size: 1,
				});
				break;

			default:
				newNode = new MemberDescription({
					id: NewNodeID,
					description: '',
					definitionType: newNodeType,
					typeName: '',
				});
		}

		return newNode;
	}

	insertRootNode(parent: I40FlatMember, node: I40FlatMember, newNodeType?: MemberDefinitionType) {
		return new Promise((resolve, reject) => {
			let newNode: MemberDescription;

			if (node) {
				const newNodeId = Root.generateUniqueId(node.id, (id) => {
					return this.inputModel.members.has(id);
				});
				const newNodeBody = node.unFlattenNode();
				newNodeBody.id = newNodeId;

				newNode = new MemberDescription(newNodeBody);
			} else {
				newNode = this.generateNewNode(newNodeType);
				newNode.id = Root.generateUniqueId(newNode.id, (id) => {
					return this.inputModel.members.has(id);
				});
			}

			newNode._memberParent = parent._memberType;

			this.inputModel.members = this.addFirstInMap(newNode.id, newNode, this.inputModel.members) as Map<string, MemberDescription>;

			this.isValid = true;

			// refresh model to display new node
			this.refreshModel();

			// mark model as dirty
			this.isDirty = true;

			// return new node
			resolve(this.getNodeByPath(newNode.id));
		});
	}

	insertMemberNode(parent: I40FlatMember, node: I40FlatMember, newNodeType?: MemberDefinitionType) {
		return new Promise((resolve, reject) => {
			const parentMemberType = parent._memberType;

			// only add if parent is custom
			if (parentMemberType.category === 'model') {
				// check pasted member types are not present in parent
				if (node) {
					// node typeName + all children typeNames (if "model" or "custom")
					const nodeMemberTypes = this.getChildComplexMemberTypes(node);
					if (['model', 'custom'].includes(node._memberType.category)) {
						nodeMemberTypes.push(node.typeName);
					}

					const parentsMemberTypes = parent._memberPath;

					const intersection = nodeMemberTypes.filter((type) => parentsMemberTypes.includes(type));

					if (intersection.length) {
						reject({title: this.translateSrv.instant('i18n.circularReference'), message: this.translateSrv.instant('i18n.circularReference')});
						return;
					}
				}

				let newNode: MemberDescription;

				if (node) {
					const newNodeId = Root.generateUniqueId(node.id, (id) => {
						return parentMemberType.members.has(id);
					});
					const newNodeBody = node.unFlattenNode();
					newNodeBody.id = newNodeId;

					newNode = new MemberDescription(newNodeBody);
				} else {
					newNode = this.generateNewNode(newNodeType);
					newNode.id = Root.generateUniqueId(newNode.id, (id) => {
						return parentMemberType.members.has(id);
					});
				}

				newNode._memberParent = parent._memberType;

				parentMemberType.members = this.addFirstInMap(newNode.id, newNode, parentMemberType.members) as Map<string, MemberDescription>;

				// open parent node if not already
				parent._collapsed = false;

				this.isValid = true;

				// refresh model to display new node
				this.refreshModel();

				// mark model as dirty
				this.isDirty = true;

				// return new node
				resolve(this.getNodeByPath(parent._path.join('/') + '/' + newNode.id));
			} else {
				reject({title: 'Invalid Action', message: 'You cannot add new children under this node'});
			}
		});
	}

	insertNode(parent: I40FlatMember, node: I40FlatMember, newNodeType?: MemberDefinitionType): Promise<any> {
		const addToRoot = parent.definitionType === MemberDefinitionType.Model;

		if (addToRoot) {
			return this.insertRootNode(parent, node, newNodeType);
		} else {
			return this.insertMemberNode(parent, node, newNodeType);
		}
	}

	deleteNode(node: I40FlatMember) {
		const parent = node._flatParentRef;

		if (parent) {
			const parentMemberType = parent._memberType;
			parentMemberType.members.delete(node.id);
		} else {
			this.inputModel.members.delete(node.id);
		}

		// refresh model to remove deleted node
		this.refreshModel();

		// mark model as dirty
		this.isDirty = true;

		this.tellEditor.emit('check');
	}

	addCloneTree() {
		const cloneTree = _.cloneDeep(this.inputModel);
		cloneTree.info.identifier = new Identifier({version: 'latest'});
		cloneTree.info.externalIdentifier.name += ' [CLONE]';

		const payload = cloneTree.serialize();

		return this.modelService.saveModel(payload);
	}

	treeCleanup() {
		const actualMemberTypes = [];

		this.flatMembers.forEach((member) => {
			if (['model', 'custom'].includes(member._memberType.category) && !actualMemberTypes.includes(member._memberType.id)) {
				actualMemberTypes.push(member._memberType.id);
			}
		});

		this.inputModel.types.forEach((v, k) => {
			if (!actualMemberTypes.includes(k)) {
				this.inputModel.types.delete(k);
			}
		});
	}

	saveTree() {
		this.treeCleanup();

		const payload = this.inputModel.serialize();

		// UPDATE EXISTING TREE
		if (this.inputModel.info.identifier.id !== NewArtifactID) {
			return this.modelService.updateModel(payload);
		}

		// SAVE NEW TREE
		else {
			return this.modelService.saveModel(payload);
		}
	}

	releaseTree(version: string) {
		return this.modelService.releaseModel(this.inputModel.info.identifier.id, version);
	}

	renameMemberType(input: Map<string, ObjectDescription>, oldType: string, newType: string) {
		let found = false;

		input.forEach((member, k) => {
			if (k === oldType) {
				found = true;
				member.id = newType;
			}

			member.members.forEach((member: MemberDescription) => {
				if (member.typeName === oldType) {
					member.typeName = newType;
				}
			});
		});

		if (found) {
			input = Root.renameMapKey(oldType, newType, input);
		}

		return input;
	}
}
