import {ArtifactInfo, Root} from '../../data-models/core-model.model';

export class ChannelTypeInfo extends ArtifactInfo {
	editable: boolean = true;

	constructor(input?: {identifier?: any; externalIdentifier?: any; externalDescriptor?: any; editable?: boolean}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}
}

export class ChannelTypeManagerResult extends Root {
	_className = 'ChannelTypeManagerResult';

	info: ArtifactInfo = new ArtifactInfo();
	layers: string[] = [];

	constructor(input?: {info?: any; layers?: string[]}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}

	layersToFormControl() {
		return this.rootArrayToFormControl(this.layers);
	}
}

export class Layer {
	constructor(public className: string) {}
}

export class Layers {
	constructor(
		public lower: Layer,
		public upper: Layer,
	) {}
}

export class LayerDetails {
	constructor(
		public implementationType: ImplementationType,
		public layerSchema: string,
	) {}
}

export class ArtifactRevisionIdentifier {
	constructor(
		public group: string,
		public name: string,
		public version: string,
	) {}
}

export class ImplementationType {
	constructor(
		public artifactType: string,
		public implementationTypeName: string,
		public label: string,
		public module: ArtifactRevisionIdentifier,
		public parameters: Layers,
	) {}
}

export class ImplementationComponent {
	constructor(
		public artifactId: ArtifactRevisionIdentifier,
		public version: VersionSchema,
		public componentType: string,
		public label: string,
		public implementationClass: string,
		public artifactType: string,
		public layerParameters: Layers,
		public configurationSchema: string,
		public defaultConfiguration: string,
	) {}
}

export class VersionNumber {
	constructor(
		public number: number,
		public isLowerBound: boolean,
		public isSnapshot: boolean,
	) {}
}

export class VersionSchema {
	constructor(
		public major: VersionNumber,
		public minor: VersionNumber[],
		public patch: VersionNumber[],
		public bug: VersionNumber[],
	) {}
}

export const ChannelTypeClasses = {Layer, Layers, ImplementationType, ImplementationComponent};
