import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {GlobalService} from '../../core-services/global.service';
import {ValidatorService} from '../../core-services/validator.service';
import {MatCheckbox} from '@angular/material/checkbox';
import {
	InstanceAgentDeploymentSettings,
	InstanceAwsDeploymentSettings,
	InstanceDockerDeploymentSettings,
	InstanceJmxProperties,
	InstanceLocalDeploymentSettings,
	InstanceSshDeploymentSettings,
	InstanceVmArgs,
} from '../../apps/deployment/deployment-model';

@Component({
	selector: 'deployment-attribute',
	templateUrl: './deployment-attribute.component.html',
	styleUrls: ['./deployment-attribute.component.css'],
})
export class DeploymentAttributeComponent implements OnInit {
	constructor(
		public globalSrv: GlobalService,
		public validatorSrv: ValidatorService,
		private fb: UntypedFormBuilder,
	) {}

	@Input() dataModel: InstanceLocalDeploymentSettings | InstanceSshDeploymentSettings | InstanceAgentDeploymentSettings | InstanceDockerDeploymentSettings | InstanceAwsDeploymentSettings;
	@Input() form: UntypedFormGroup;
	@Input() isEdit: boolean;
	@Input() isDisabled: boolean;
	@ViewChild('jmx', {static: false}) jmx: MatCheckbox;

	ngOnInit(): void {
		this.form.get('jmxProperties').get('jmxPort').setValidators([Validators.required, this.validatorSrv.validateDeploymentPort()]);
		this.form.updateValueAndValidity();
		this.form.markAllAsTouched();
		if (!this.isEdit) {
			this.form.get('vmArgs').patchValue(this.getDefaultValues(InstanceVmArgs));
			this.form.get('jmxProperties').patchValue(this.getDefaultValues(InstanceJmxProperties));
		} else {
			setTimeout(() => {
				this.jmx.checked = this.form.get('jmxProperties').get('jmxPort').value !== null;
			});
		}
	}

	getDefaultValues(property) {
		return new property().getDefaultValues();
	}

	onCheckJxm(ev) {
		ev.checked ? this.form.addControl('jmxProperties', new InstanceJmxProperties(this.getDefaultValues(InstanceJmxProperties))._formGroup) : this.form.removeControl('jmxProperties');
		if (ev.checked) {
			this.form.get('jmxProperties').get('jmxPort').setValidators([Validators.required, this.validatorSrv.validateDeploymentPort()]);
			this.form.updateValueAndValidity();
			this.form.markAllAsTouched();
		}
	}

	add(controls) {
		controls.push(new UntypedFormControl(''));
	}

	delete(controls, i) {
		controls.removeAt(i);
	}

	getFormArray(abstractControl): UntypedFormArray {
		return abstractControl as UntypedFormArray;
	}
}
