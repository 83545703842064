import {Injectable} from '@angular/core';

import {Observable} from 'rxjs';

export interface CanComponentDeactivate {
	canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
	providedIn: 'root',
})
export class CanDeactivateGuard {
	canDeactivate(component: CanComponentDeactivate) {
		if (component) {
			return component.canDeactivate ? component.canDeactivate() : true;
		}
		return true;
	}
}
