<div fxLayout="column" fxFill style="flex: 1 0 auto">
	<div fxFlex="0 0 auto">
		<mat-toolbar fxLayout="row" class="small_toolbar">
			<mat-icon>{{ mat_icon_type }}</mat-icon>
			<span class="push-left-sm" fxFlex>
				<span class="md-title">{{ mat_title | translate }}</span>
			</span>
			<button id="save_channel_button" color="accent" mat-icon-button matTooltip="{{ 'i18n.save' | translate }}" [disabled]="" *ngIf="globalSrv.canEdit()" (click)="run()">
				<mat-icon>play_arrow</mat-icon>
			</button>
			<button id="cancel_button" color="warn" mat-icon-button matTooltip="{{ 'i18n.close' | translate }}" (click)="close()">
				<mat-icon>cancel</mat-icon>
			</button>
		</mat-toolbar>
		<mat-divider></mat-divider>
	</div>

	<div fxLayout="column" style="overflow: auto; flex: 1 1 100%">
		<div fxLayout="row" class="main-ui" style="flex: 1 0 auto; overflow: auto">
			<mat-card appearance="outlined" fxFlex="100" fxLayout="column" class="form-card">
				<mat-tab-group mat-align-tabs="start" (selectedTabChange)="changedTypeTab($event)" style="flex: 1 0 auto">
					<mat-tab label="UA Nodeset XML Import" #tabXML>
						<div style="flex: 1 0 auto; padding-top: 15px; display: flex; flex-direction: column">
							<app-generic-gnvd [parentGroup]="plugin_add['XML']._formGroup"></app-generic-gnvd>

							<mat-form-field>
								<mat-select id="add_xml_item_type_select" [formControl]="plugin_add['XML'].item.getFormControl('definitionType')" placeholder="{{ 'i18n.type' | translate }}" required>
									<mat-option *ngFor="let type of definitionTypes" [value]="type">{{ type }}</mat-option>
								</mat-select>
							</mat-form-field>

							<mat-form-field>
								<input id="add_xml_item_name_input" matInput placeholder="{{ 'i18n.name' | translate }}" [formControl]="plugin_add['XML'].item.getFormControl('definitionName')" required />
							</mat-form-field>

							<span>UA Nodeset XML Input:</span>
							<ngx-codemirror
								*ngIf="tabXML.isActive"
								id="add_xml_xmlInputEditor_editor"
								[formControl]="plugin_add['XML'].item.getFormControl('inputXml')"
								[options]="{
									lineNumbers: true,
									matchBrackets: true,
									autoCloseBrackets: true,
									styleActiveLine: true,
									mode: 'text/xml',
									theme: themeSrv.selectedTheme === 'light-theme' ? 'eclipse' : 'monokai'
								}"
								style="flex: 1 0 auto; overflow: auto; height: 100%"
							>
							</ngx-codemirror>
						</div>
					</mat-tab>

					<mat-tab label="OpcUa Import" #tabOpcUa>
						<div style="flex: 1 0 auto; padding-top: 15px; display: flex; flex-direction: column">
							<app-generic-gnvd [parentGroup]="plugin_add['OpcUa']._formGroup"></app-generic-gnvd>

							<mat-form-field>
								<input
									id="add_opcua_item_namespace_index_input"
									matInput
									placeholder="{{ 'i18n.namespaceIndex' | translate }}"
									[formControl]="plugin_add['OpcUa'].item.getFormControl('namespaceIndex')"
									required
								/>
							</mat-form-field>

							<mat-toolbar class="smaller_toolbar" fxLayout="row" style="padding-left: 0 !important; padding-right: 0 !important; flex: 0 0 auto">
								<span class="push-left-sm" fxFlex
									><span mat-subheader class="typography" style="padding-left: 6px">{{ 'i18n.identifierName' | translate }}</span></span
								>
								<button
									id="add_add_item_button"
									*ngIf="globalSrv.canEdit()"
									mat-icon-button
									color="accent"
									(click)="add(plugin_add['OpcUa'].item.getFormControl('identifierName'))"
									matTooltip="{{ 'i18n.addIdentifierName' | translate }}"
								>
									<mat-icon>add</mat-icon>
								</button>
							</mat-toolbar>

							<div
								*ngFor="let identName of plugin_add['OpcUa'].item.getFormControl('identifierName')?.controls; let i = index"
								style="display: flex; flex-direction: column; margin-top: 15px; margin-bottom: 15px"
							>
								<div fxFlex fxLayout="row" style="padding-left: 5px">
									<mat-form-field fxFlex>
										<input
											#name
											matInput
											id="add_opcua_item_identifier_name_inputs"
											[formControl]="plugin_add['OpcUa'].item.getFormControl('identifierName')?.controls[i]"
											placeholder="{{ 'i18n.identifierName' | translate }}"
											required
										/>
									</mat-form-field>
									<div fxFlex="5" fxLayoutAlign="center center">
										<button
											id="delete_arg_button"
											mat-icon-button
											color="accent"
											(click)="delete(plugin_add['OpcUa'].item.getFormControl('identifierName'), i)"
											matTooltip="{{ 'i18n.deleteIdentifierName' | translate }}"
										>
											<mat-icon>delete_outline</mat-icon>
										</button>
									</div>
								</div>
							</div>

							<mat-form-field>
								<mat-select
									id="add_opcua_item_security_type_select"
									[formControl]="plugin_add['OpcUa'].item.getFormControl('securityPolicy')"
									placeholder="{{ 'i18n.securityPolicy' | translate }}"
									required
								>
									<mat-option *ngFor="let type of securityTypes" [value]="type">{{ type }}</mat-option>
								</mat-select>
							</mat-form-field>

							<mat-form-field>
								<input id="add_opcua_item_ip_address_input" matInput placeholder="{{ 'i18n.ipAddress' | translate }}" [formControl]="plugin_add['OpcUa'].item.getFormControl('ipAddress')" required />
							</mat-form-field>

							<mat-form-field>
								<input
									id="add_opcua_item_tcp_port_input"
									type="number"
									matInput
									placeholder="{{ 'i18n.tcpPort' | translate }}"
									[formControl]="plugin_add['OpcUa'].item.getFormControl('tcpPort')"
									required
								/>
							</mat-form-field>

							<mat-form-field>
								<input
									id="add_opcua_item_endpoint_path_input"
									matInput
									placeholder="{{ 'i18n.endpointPath' | translate }}"
									[formControl]="plugin_add['OpcUa'].item.getFormControl('endpointPath')"
									required
								/>
							</mat-form-field>
						</div>
					</mat-tab>
				</mat-tab-group>
			</mat-card>
		</div>
	</div>
</div>
