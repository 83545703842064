<div mat-dialog-title style="margin: 0; display: flex; flex-direction: column; align-items: stretch; background-color: var(--bg-color-toolbar); padding: 15px; color: var(--text-color-over-primary)">
	<div style="display: flex; flex-direction: row; align-items: center; width: 100%">
		<img src="../assets/amorph-pro-dark.png" style="height: 1.5em" />
		<span fxFlex="100"></span>
		<span style="font-weight: 100">{{ 'i18n.smart' | translate }}</span>
		<span style="font-weight: bold">{{ 'i18n.unifier' | translate }}</span>
	</div>
	<div style="font-size: 0.5em; margin-top: -10px">© AMORPH SYSTEMS GMBH {{ cyear }}. All rights reserved</div>
	<div style="font-size: 0.7em; font-weight: normal">
		<div>SMART<b>UNIFIER</b> Manager version: {{ version }}</div>
		<div fxLayout="row" style="align-items: center">
			<b>License:&nbsp;</b>
			<span class="license-status-{{ authSrv.license.licenseActivationData.licenseType | lowercase }}">{{
				authSrv.nolicense ? 'NO ACTIVE LICENSE' : authSrv.license.licenseActivationData.licenseType
			}}</span>
			<span *ngIf="!authSrv.nolicense && authSrv.license.licenseActivationData.validTo">&nbsp;/&nbsp;</span>
			<b *ngIf="!authSrv.nolicense && authSrv.license.licenseActivationData.validTo">Expires on:&nbsp;</b>
			<span *ngIf="!authSrv.nolicense && authSrv.license.licenseActivationData.validTo" class="license-status-{{ authSrv.license.licenseActivationData.licenseType | lowercase }}">{{
				authSrv.license.licenseActivationData.validTo | date
			}}</span>
			<span fxFlex></span>
			<button id="license_action_button" mat-raised-button (click)="updateLicense()">{{ (!authSrv.nolicense ? 'i18n.licenseInformation' : 'i18n.activate') | translate }}</button>
		</div>
	</div>
</div>
<div style="padding: 15px; font-size: 0.9em; overflow: auto; flex: 1 0 auto; color: var(--text-color)">
	<div style="margin-bottom: 15px">
		Amorph Systems GmbH<br />
		Handwerkstr. 29<br />
		70565 Stuttgart, Germany<br />
	</div>

	<div style="margin-bottom: 15px">
		<div style="display: flex; flex-direction: row; align-items: center">
			<mat-icon class="small_icon">phone</mat-icon>&nbsp;<a class="clickable-element" href="tel:+497116729122">+49 711 672 9122</a>
		</div>
		<div style="display: flex; flex-direction: row; align-items: center"><mat-icon class="small_icon">print</mat-icon>&nbsp;+49 711 2295 4593</div>
	</div>

	<div style="margin-bottom: 15px">
		<div style="display: flex; flex-direction: row; align-items: center">
			<mat-icon class="small_icon">email</mat-icon>&nbsp;<a class="clickable-element" href="mailto:info@amorphsys.com">info&#64;amorphsys.com</a>
		</div>
		<div style="display: flex; flex-direction: row; align-items: center">
			<mat-icon class="small_icon">website</mat-icon>&nbsp;<a class="clickable-element" href="https://amorph.pro" target="_blank">www.amorph.pro</a>
		</div>
	</div>

	<div><b>Privacy Statement:</b>&nbsp;<a class="clickable-element" href="https://amorph.pro/privacy-statement/" target="_blank">https://amorph.pro/privacy-statement/</a></div>
</div>
<div style="padding: 15px; display: flex; flex-direction: row; justify-content: center">
	<button id="close_about_button" mat-raised-button mat-dialog-close style="align-self: center; justify-self: center" class="mat-primary">CLOSE</button>
</div>
