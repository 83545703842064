import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {JsonModelGeneratorItem, JsonToModelItem} from '../json-model-generator-plugin.model';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../../../core-services/auth.service';
import {MessageResponseService} from '../../../../core-services/message-response.service';
import {TranslateService} from '@ngx-translate/core';
import {ValidatorService} from '../../../../core-services/validator.service';
import {GlobalService} from '../../../../core-services/global.service';
import {PluginsService} from '../../plugins.service';
import {ThemeService} from '../../../../core-services/theme.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ModelManagerResult} from '../../../../shared/i40-tree/types';

@Component({
	selector: 'json-model-generator-modal-update',
	templateUrl: './json-model-generator-modal-update.component.html',
	styleUrls: ['./json-model-generator-modal-update.component.scss'],
})
export class JsonModelGeneratorModalUpdateComponent implements OnInit {
	mat_icon_type: string = 'extension';
	mat_title: string = 'JSON Model Generator Plugin';

	plugin_add: JsonModelGeneratorItem;
	plugin_update: JsonModelGeneratorItem;
	pluginForm_add: UntypedFormGroup;
	pluginForm_update: UntypedFormGroup;

	definitionTypes: string[] = ['Model', 'Event', 'Variable', 'Command'];
	jsonInputOptions;

	constructor(
		public router: Router,
		public route: ActivatedRoute,
		public authSrv: AuthService,
		public msgSrv: MessageResponseService,
		public translate: TranslateService,
		public validatorSrv: ValidatorService,
		public globalSrv: GlobalService,
		public pluginsSrv: PluginsService,
		public cdRef: ChangeDetectorRef,
		public themeSrv: ThemeService,
		public dialogRef: MatDialogRef<JsonModelGeneratorModalUpdateComponent>,
		@Inject(MAT_DIALOG_DATA) public modalData,
	) {
		this.plugin_add = new JsonModelGeneratorItem({});
		this.plugin_update = new JsonModelGeneratorItem({});

		this.pluginForm_add = this.plugin_add._formGroup;
		this.pluginForm_update = this.plugin_update._formGroup;

		this.router.routeReuseStrategy.shouldReuseRoute = () => false;
	}

	ngOnInit(): void {
		this.plugin_update.set((<ModelManagerResult>this.modalData.data).info.serialize()); // set model info from parent component;
		this.cdRef.detectChanges();
	}

	addItem() {
		this.plugin_update.push(new JsonToModelItem({}, this.plugin_update), 'items');
		this.cdRef.detectChanges();
	}

	removeItem(index) {
		this.plugin_update.pop(index, 'items');
		this.cdRef.detectChanges();
	}

	run() {
		this.msgSrv.onLoading.next({command: 'start', message: this.translate.instant('i18n.snackbar.running')});

		this.pluginsSrv.runPlugin('Json model generator', {JsonToModelImportAction: this.plugin_update.serialize()}).subscribe({
			next: (res) => {
				this.msgSrv.onLoading.next({command: 'stop'});
				this.msgSrv.showSuccess('Plugin run successfully');
				this.pluginForm_update.markAsPristine();
				this.close();

				this.globalSrv.reloadCurrentRoute();
			},
			error: (err) => {
				this.msgSrv.onLoading.next({command: 'stop'});
			},
		});
	}

	close(): void {
		const callback = () => {
			this.pluginForm_add.markAsPristine();
			this.pluginForm_update.markAsPristine();
			this.dialogRef.close();
		};

		if (this.pluginForm_add.dirty || this.pluginForm_update.dirty) {
			this.msgSrv.unsavedChangesClose(callback);
		} else {
			callback();
		}
	}

	getFormControl(abstractFormControl): UntypedFormControl {
		return abstractFormControl as UntypedFormControl;
	}
}
