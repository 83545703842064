import {EventEmitter, Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {OverlayContainer} from '@angular/cdk/overlay';
import {BreakpointObserver, Breakpoints, BreakpointState} from '@angular/cdk/layout';
import {Observable} from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ThemeService {
	public themeFallback = 'light-theme';
	public selectedTheme: string = '';
	public isSmallLayout: Observable<BreakpointState>;

	public themeChanged: EventEmitter<string> = new EventEmitter();

	constructor(
		@Inject(DOCUMENT) private document: any,
		public overlayContainer: OverlayContainer,
		public breakpointObserver: BreakpointObserver,
	) {
		this.setTheme(localStorage.getItem('i40theme') || this.themeFallback);
		this.isSmallLayout = this.breakpointObserver.observe([Breakpoints.Small]);
	}

	addBodyClass(cls) {
		if (!this.document.body.classList.contains(cls)) {
			this.document.body.classList.add(cls);
		}
	}

	removeBodyClass(cls) {
		if (this.document.body.classList.contains(cls)) {
			this.document.body.classList.remove(cls);
		}
	}

	addContainerClass(cls) {
		if (!this.overlayContainer.getContainerElement().classList.contains(cls)) {
			this.overlayContainer.getContainerElement().classList.add(cls);
		}
	}

	removeContainerClass(cls) {
		if (this.overlayContainer.getContainerElement().classList.contains(cls)) {
			this.overlayContainer.getContainerElement().classList.remove(cls);
		}
	}

	setTheme(theme) {
		const previousTheme = this.selectedTheme;
		this.selectedTheme = theme;

		localStorage.setItem('i40theme', theme);

		this.removeBodyClass(this.selectedTheme);
		this.removeContainerClass(this.selectedTheme);

		this.addBodyClass(theme);
		this.addContainerClass(theme);

		if (this.selectedTheme !== previousTheme) {
			this.themeChanged.emit(this.selectedTheme);
		}

		if (previousTheme) {
			this.removeBodyClass(previousTheme);
			this.removeContainerClass(previousTheme);
		}
	}
}
