<div fxFlex fxLayout="column" *ngIf="artifacts.length !== 0">
	<div fxFlex="5" class="accent-text sticky title-padding" fxLayoutAlign="start center" fxLayout="row">
		<div fxFlex>
			{{ title }}
		</div>
		<div fxFlex fxLayoutAlign="end end">
			<mat-checkbox #all (change)="setAll($event)" class="set_all_checkbox"></mat-checkbox>
		</div>
	</div>
	<div fxFlex="grow">
		<mat-selection-list #artifactList (selectionChange)="changeChecked()">
			<mat-list-option *ngFor="let a of artifacts" [value]="a"> {{ a.externalIdentifier.group }}:{{ a.externalIdentifier.name }}:{{ a.identifier.version }} </mat-list-option>
		</mat-selection-list>
	</div>
	<div fxFlex="10" fxLayoutAlign="center center">
		<button mat-button id="ok_button" color="accent" style="transform-style: preserve-3d" (click)="execute()">
			{{ buttonLabel }}
		</button>
	</div>
</div>
