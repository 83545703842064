import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from './auth.service';

@Injectable({
	providedIn: 'root',
})
export class RoleGuard {
	constructor(
		private authSrv: AuthService,
		public router: Router,
	) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		const routeRole = route.data['role'];
		const checkAD = route.data['checkAD'];

		if (this.authSrv.isLogged && !this.authSrv.permissions.write && !this.authSrv.userData.roles.includes(routeRole)) {
			return this.router.navigate(['/home']);
		} else {
			if (checkAD) {
				return localStorage.getItem('activeDirectory') === 'true' ? this.router.navigate(['/home']) : this.authSrv.isLogged;
			} else {
				return this.authSrv.isLogged;
			}
		}
	}
}
