import {HttpClient, HttpHeaders} from '@angular/common/http';
import {EventEmitter, Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {GlobalService} from 'src/app/core-services/global.service';
import {MessageResponseService} from 'src/app/core-services/message-response.service';
import {Button} from 'src/app/shared/generic-dialog/generic-dialog.component';
import {environment} from '../../../environments/environment';

@Injectable()
export class CompileArtifactService {
	showValid: boolean = false;

	constructor(
		private http: HttpClient,
		private msgSrv: MessageResponseService,
		private translate: TranslateService,
		private globalSrv: GlobalService,
	) {}

	isDone: EventEmitter<boolean> = new EventEmitter<boolean>();

	getArtifactInfo() {
		return this.http.get(`${environment.apiHost + environment.apiUrl}/allinfos`);
	}

	compile(infos) {
		return this.http.post(`${environment.apiHost + environment.apiUrl}/validation/run/false`, infos);
	}

	getCompilationResults() {
		return this.http.get(`${environment.apiHost + environment.apiUrl}/validation/result`);
	}

	clearCompilationResults() {
		return this.http.delete(`${environment.apiHost + environment.apiUrl}/validation/clear`);
	}

	findDependencyInDataList(dataList, dependency) {
		return dataList.find((data) => dependency.id == data.identifier.id && dependency.version === data.identifier.version);
	}

	findDependency(dependencyList, dataList) {
		const result = [];
		for (const dependency of dependencyList) {
			const dataResult = this.findDependencyInDataList(dataList, dependency);
			if (dataResult) {
				if (dataResult.dependencies.length !== 0) {
					result.push(...this.findDependency(dataResult.dependencies, dataList));
				}
				result.push(dataResult);
			}
		}
		return result;
	}

	checkDependencies(element, type?: string, selection?, data?) {
		let dependencies = element.dependencies.map((d) => {
			if (d.version === element.identifier.version) {
				return d;
			}
		});

		if (dependencies.length !== 0) {
			return this.msgSrv.customDialogMessage(
				this.translate.instant('i18n.dependenciesTitle'),
				this.translate.instant('i18n.dependenciesText', {action: type}),
				[new Button(this.translate.instant('i18n.yes'), 'yes', 'flat', 'accent', 'start'), new Button(this.translate.instant('i18n.no'), 'no', 'flat', 'accent', 'end')],
				{
					yes: (dialogRef, resolve) => {
						let finalDependencies = this.findDependency(dependencies, data);
						if (this.globalSrv.debugMode) {
							console.log('dependencies', finalDependencies);
						}

						switch (type) {
							case 'select':
								selection.select(...finalDependencies);
								break;
							case 'deselect':
								finalDependencies.forEach((d) => {
									selection.deselect(d);
								});
								//selection.deselect(...finalDependencies);
								break;
						}

						dialogRef.close();
						resolve(true);
					},
					no: (dialogRef, resolve) => {
						dialogRef.close();
						resolve(false);
					},
				},
			);
		}
	}
}
