import {Root} from '../../data-models/core-model.model';
import * as moment from 'moment';

export class I40User extends Root {
	_className = 'I40User';

	id: string = '';
	firstName: string = '';
	lastName: string = '';
	userId: string = '';
	email?: string = '';
	languageCode: string = 'en';
	status?: string = '';
	created?: string = '';
	updated?: string = '';
	roles?: string[] = [];
	password?: string = '';

	constructor(
		input?: {
			id?: string;
			firstName?: string;
			lastName?: string;
			userId?: string;
			email?: string;
			languageCode?: string;
			status?: string;
			created?: string;
			updated?: string;
			roles?: string[];
			password?: string;
		},
		parent?: Root,
		inherited: boolean = false,
	) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
		if (!this.created) this.created = moment(new Date()).format('yyyy-MM-DD HH:mm:ss.SSS');
		if (!this.updated) this.updated = moment(new Date()).format('yyyy-MM-DD HH:mm:ss.SSS');
	}

	setRoles(roles, parent) {
		if (roles) {
			if (typeof roles === 'object') {
				this.roles = roles[0];
			} else {
				this.roles = roles;
			}
		}
	}

	serialize() {
		const output: any = super.serialize();

		output.roles = [output.roles];

		if (output.password === '') {
			delete output.password;
		}

		if (output.email === '') {
			delete output.email;
		}

		return output;
	}

	getUsername() {
		return this.firstName + ' ' + this.lastName;
	}

	static sort(a: I40User, b: I40User) {
		if (a.userId < b.userId) return -1;
		if (a.userId > b.userId) return 1;
		return 0;
	}
}

export class ChangeUserPassword extends Root {
	_className = 'ChangeUserPassword';

	oldPassword: string = '';
	newPassword: string = '';
	confirmPassword: string = '';

	constructor(input?: {oldPassword?: string; newPassword?: string; confirmPassword?: string}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}
}

export class I40License {
	licenseNumber: string;
	licenseData: string;
	licenseActivationData: I40LicenseActivationData;

	constructor(input?: {licenseNumber: string; licenseData: string; licenseActivationData: any}) {
		this.licenseNumber = input?.licenseNumber ?? '0000-0000-0000-0000-0000';
		this.licenseData = input?.licenseData ?? 'No License';
		this.licenseActivationData = new I40LicenseActivationData(input?.licenseActivationData);
	}
}

export class I40LicenseActivationData {
	licenseType: 'DEVELOPER' | 'DEMO' | 'PRODUCTIVE' | 'NO';

	softwareId: string;
	userName: string;

	validTo: number;

	deployments: number = 0;

	constructor(input?: {softwareId: string; userName: string; validTo: number; properties: any}) {
		this.licenseType = I40LicenseActivationData.getLicenseType(input?.properties.licenseType);
		this.softwareId = input?.softwareId ?? '';
		this.userName = input?.userName ?? '';
		this.validTo = input?.validTo ?? moment().add(1, 'month').valueOf();
		this.deployments = input?.properties.totalDeployments ?? 0;
	}

	static getLicenseType(l?: string): 'DEVELOPER' | 'DEMO' | 'PRODUCTIVE' | 'NO' {
		if (l) {
			const licenseType = l.toUpperCase();
			if (['DEVELOPER', 'DEMO', 'PRODUCTIVE', 'NO'].includes(licenseType)) return licenseType as 'DEVELOPER' | 'DEMO' | 'PRODUCTIVE' | 'NO';
			else return 'NO';
		} else {
			return 'NO';
		}
	}
}
