import {ChangeDetectorRef, EventEmitter, Injectable} from '@angular/core';
import {AbstractControl, ValidatorFn} from '@angular/forms';
import {_closeDialogVia, MatDialog} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject} from 'rxjs';
import {EditorStatusService} from '../../core-services/editor-status.service';
import {ArtifactInfo, Identifier} from '../../data-models/core-model.model';
import {I40TreeComponent} from '../../shared/i40-tree/i40-tree.component';
import {I40TreeService} from '../../shared/i40-tree/i40-tree.service';
import {I40FlatMember, MemberDefinitionType, ModelManagerResult} from '../../shared/i40-tree/types';
import {
	CastNode,
	DefinitionNode,
	DefinitionNodeNotFound as DefinitionNodeNotFound,
	ExpressionListNode,
	ExpressionListNodeWrapper,
	ExpressionNode,
	ExpressionNodeWrapper,
	LiteralNode,
	MappingManagerResult,
	MappingRule,
	MultiNode,
} from './mapping-model';
import {MappingService} from './mapping.service';
import {ValidatorService} from '../../core-services/validator.service';
import * as _ from 'lodash';
import {Button} from '../../shared/generic-dialog/generic-dialog.component';
import {MessageResponseService} from '../../core-services/message-response.service';

@Injectable({
	providedIn: 'root',
})
export class MappingDataService implements EditorStatusService {
	isEdit = false;
	isDirty = false;
	isValid = false;
	selectedRuleIndex: number = -1;
	mappingRule: MappingRule;

	cacheModels: Map<string, ModelManagerResult>;

	inputMapping: MappingManagerResult;
	modelsInfo: ArtifactInfo[] = [];

	treeInstances: Map<string, {tsrv: I40TreeService; tree: I40TreeComponent}> = new Map();

	_editorStatus = ['clean'];

	editorStatusSubject: BehaviorSubject<string[]> = new BehaviorSubject(this._editorStatus);
	triggerUpdated: EventEmitter<any> = new EventEmitter<any>();
	onDrop: EventEmitter<any> = new EventEmitter<any>();

	// variable assignment between a left operand and all possible right operand types
	allowedAssignments = new Map([
		['Char', ['String']],
		['String', ['Char', 'Byte', 'Int', 'Long', 'Float', 'Double', 'LocalDateTime', 'OffsetDateTime', 'Boolean']],
		['Byte', ['String']],
		['Short', ['String']],
		['Int', ['String']],
		['Long', ['String', 'LocalDateTime', 'OffsetDateTime']],
		['Float', ['String']],
		['Double', ['String']],
		['LocalDateTime', ['String', 'Long', 'OffsetDateTime']],
		['OffsetDateTime', ['String', 'Long', 'LocalDateTime']],
		['Boolean', ['String']],
	]);

	isAssignmentAllowed(left: string, right: string) {
		if (left == right) return true;
		else {
			if (this.allowedAssignments.has(left)) {
				return this.allowedAssignments.get(left).includes(right);
			} else {
				return false;
			}
		}
	}

	set editorStatus(status: string[]) {
		this.editorStatusSubject.next(status);
		this._editorStatus = status;
	}

	get editorStatus() {
		return this._editorStatus;
	}

	constructor(
		public validatorService?: ValidatorService,
		public mappingService?: MappingService,
		public translateSrv?: TranslateService,
		public dialog?: MatDialog,
		public msgSrv?: MessageResponseService,
	) {}

	setTreeInstance(tsrv: I40TreeService, tree?: I40TreeComponent) {
		this.treeInstances.set(tsrv.inputModel.info.identifier.getId(), {
			tsrv,
			tree,
		});
	}

	createTreeService(modelName: string) {
		const modelInput: ModelManagerResult = this.inputMapping.models.get(modelName);
		const srv = new I40TreeService(this.translateSrv, this.dialog);
		if (srv.extraInfo == undefined) {
			srv.extraInfo = {alias: modelName};
		}
		srv.parseModel(modelInput);
		return srv;
	}

	parseRuleNodes() {
		this.mappingRule.expressions.forEach((e) => {
			this.parseNodePath(e);
		});
	}

	parseNodePath(e) {
		const eType = e._type;

		switch (eType) {
			case 'ExpressionNode':
				const leftMember = e[eType].left;
				const rightMember = e[eType].right;

				this.parseNodePath(leftMember);
				this.parseNodePath(rightMember);

				break;

			case 'ConditionExpressionListNode':
				e[eType].expressions.forEach((ex) => {
					this.parseNodePath(ex);
				});
				break;

			case 'DefinitionNode':
				this.setDefinitionNodeMember(e[eType]);
				break;

			case 'ExpressionListNode':
				e[eType].expressions.forEach((ex) => {
					this.parseNodePath(ex);
				});
				break;

			case 'IfExpressionNode':
				const condition = e[eType].condition;
				const thenExpression = e[eType].thenExpression;
				const elseExpression = e[eType].elseExpression;

				this.parseNodePath(condition);
				this.parseNodePath(thenExpression);
				this.parseNodePath(elseExpression);

				break;

			case 'DefinitionNodeNotFound':
				// not found
				break;

			case 'SourceCodeNode':
				// cannot determine
				break;

			case 'FixedRateSchedulerNode':
				break;

			case 'FixedDelaySchedulerNode':
				break;

			case 'TimeoutSchedulerNode':
				break;

			case 'ChannelEventNode':
				break;

			case 'LiteralNode':
				break;

			case 'CastNode':
				break;

			case 'MultiNode':
				break;

			default:
				console.error('Unhandled node type: ' + eType);
				break;
		}
	}

	setDefinitionNodeMember(dn: DefinitionNode) {
		const name_arr = dn.name.split('/');
		const modelAlias = name_arr[1];
		const model_mgr = this.inputMapping.models.get(modelAlias);
		name_arr.shift();
		name_arr.shift();

		if (
			!this.treeInstances.has(model_mgr.info.identifier.getId()) ||
			(this.treeInstances.has(model_mgr.info.identifier.getId()) && this.treeInstances.get(model_mgr.info.identifier.getId()).tsrv.extraInfo.alias !== modelAlias)
		) {
			this.setTreeInstance(this.createTreeService(modelAlias));
		}

		dn._member = this.treeInstances.get(model_mgr.info.identifier.getId()).tsrv.getNodeByFullPath(name_arr.join('/'));

		dn.set({
			nodePath: this.getMappingMemberNodePath(dn._member),
		});
		dn._formGroup.updateValueAndValidity();
	}

	getMappingMemberNodePath(member: I40FlatMember) {
		const modelMappingName = member._extraInfo.alias;
		const path = member.definitionType === MemberDefinitionType.CommandParameters ? member._path[0] : member._path.join('/');
		return modelMappingName + '/' + path;
	}

	getMappingMemberNamePath(member: I40FlatMember) {
		const modelMappingName = member._extraInfo.alias;
		const path = member._fullPath.join('/');
		return 'Model/' + modelMappingName + '/' + path;
	}

	isExpressionListNode(node: any) {
		if (!node) {
			return false;
		}
		return node._type === 'ExpressionListNode';
	}

	isDefinitionNodeNotFound(node: any) {
		if (!node) {
			return false;
		}
		return node._type === 'DefinitionNodeNotFound';
	}

	asExpressionListNode(node: any) {
		switch (node.constructor) {
			case ExpressionListNode:
				return node as ExpressionListNode;
			default:
				return undefined;
		}
	}

	isExpressionNode(node: any) {
		if (!node) {
			return false;
		}
		return node._type === 'ExpressionNode';
	}

	isDefinitionNode(node: any) {
		if (!node) {
			return false;
		}
		return node._type === 'DefinitionNode';
	}

	isLiteralNode(node: any) {
		if (!node) {
			return false;
		}
		return node._type === 'LiteralNode';
	}

	isCastNode(node: any) {
		if (!node) {
			return false;
		}
		return node._type === 'CastNode';
	}

	isMultiNode(node: any) {
		if (!node) {
			return false;
		}
		return node._type === 'MultiNode';
	}

	isSourceCodeNode(node: any) {
		if (!node) {
			return false;
		}
		return node._type === 'SourceCodeNode';
	}

	isFixedRateSchedulerNode(node: any) {
		if (!node) {
			return false;
		}
		return node._type === 'FixedRateSchedulerNode';
	}

	isFixedDelaySchedulerNode(node: any) {
		if (!node) {
			return false;
		}
		return node._type === 'FixedDelaySchedulerNode';
	}

	isTimeoutSchedulerNode(node: any) {
		if (!node) {
			return false;
		}
		return node._type === 'TimeoutSchedulerNode';
	}

	isChannelEventNode(node: any) {
		if (!node) {
			return false;
		}
		return node._type === 'ChannelEventNode';
	}

	updateDefinitionNodeForm(definitionNode: DefinitionNode, member: I40FlatMember) {
		definitionNode.set({
			name: member.definitionType === 'Command' ? this.getMappingMemberNamePath(member) + '/CommandParameters/Parameters' : this.getMappingMemberNamePath(member),
			nodePath: this.getMappingMemberNodePath(member),
			_member: member,
		});
	}

	addTarget(member: I40FlatMember, expListnode: ExpressionListNode | MappingRule, simpleAssign: boolean = false, operator: string = ':=') {
		if (simpleAssign && !['Command', 'CommandParameters', 'Event'].includes(member.definitionType)) {
			expListnode.push(
				new ExpressionNodeWrapper({
					ExpressionNode: {
						operator: operator,
						left: {DefinitionNode: {name: this.getMappingMemberNamePath(member), nodePath: this.getMappingMemberNodePath(member), _member: member}},
						right: {DefinitionNode: {}},
					},
				}),
				'expressions',
			);
		} else {
			switch (member.definitionType) {
				case 'Variable':
				case 'CommandReply':
				case 'List':
				case 'Array':
					if (member._hasChildren) {
						const children = member._srv.getFirstChildren(member);
						children.forEach((child: I40FlatMember) => {
							this.addTarget(child, expListnode);
						});
					} else {
						expListnode.push(
							new ExpressionNodeWrapper({
								ExpressionNode: {
									operator: operator,
									left: {DefinitionNode: {name: this.getMappingMemberNamePath(member), nodePath: this.getMappingMemberNodePath(member), _member: member}},
									right: {DefinitionNode: {}},
								},
							}),
							'expressions',
						);
					}
					break;

				case 'CommandParameters':
					if (member._hasChildren) {
						if (simpleAssign) {
							expListnode.push(
								new ExpressionNodeWrapper({
									ExpressionNode: {
										operator: operator,
										left: {DefinitionNode: {name: this.getMappingMemberNamePath(member), nodePath: this.getMappingMemberNodePath(member), _member: member}},
										right: {DefinitionNode: {}},
									},
								}),
								'expressions',
							);
						} else {
							const children = member._srv.getFirstChildren(member);
							children.forEach((child: I40FlatMember) => {
								this.addTarget(child, expListnode);
							});
						}
					}
					break;

				case 'Event': {
					const eListNode = new ExpressionListNode();
					const childListNode = new ExpressionListNode();

					if (member._hasChildren) {
						if (simpleAssign) {
							childListNode.push(
								new ExpressionNodeWrapper({
									ExpressionNode: {
										operator: ':=',
										left: {DefinitionNode: {name: this.getMappingMemberNamePath(member), nodePath: this.getMappingMemberNodePath(member), _member: member}},
										right: {DefinitionNode: {}},
									},
								}),
								'expressions',
							);
						} else {
							const children = member._srv.getChildren(member);
							children.forEach((child: I40FlatMember) => {
								if (child._memberType.members.size === 0) {
									this.addTarget(child, childListNode);
								}
							});
						}
					}

					eListNode.push(new ExpressionListNodeWrapper({ExpressionListNode: childListNode}), 'expressions');

					const eventExpNode = new ExpressionNode({
						operator: 'send',
						left: {DefinitionNode: {name: this.getMappingMemberNamePath(member), nodePath: this.getMappingMemberNodePath(member), _member: member}},
						right: {ExpressionListNode: eListNode},
					});

					expListnode.push(new ExpressionNodeWrapper({ExpressionNode: eventExpNode}), 'expressions');

					break;
				}

				case 'Command': {
					const commandListNode = new ExpressionListNode();
					let parametersMember;

					if (member._hasChildren) {
						member._srv.getChildren(member).forEach((m) => {
							if (m.definitionType == 'CommandParameters') {
								parametersMember = m;
								const paramsListNode = new ExpressionListNode();
								this.addTarget(parametersMember, paramsListNode, simpleAssign);
								commandListNode.push(new ExpressionListNodeWrapper({ExpressionListNode: paramsListNode}), 'expressions');
							}

							if (m.definitionType == 'CommandReply') {
								const replyListNode = new ExpressionListNode();
								commandListNode.push(new ExpressionListNodeWrapper({ExpressionListNode: replyListNode}), 'expressions');
							}
						});

						const commandExpNode = new ExpressionNode({
							operator: 'execute',
							left: {DefinitionNode: new DefinitionNode({name: this.getMappingMemberNamePath(parametersMember), nodePath: this.getMappingMemberNodePath(member), _member: member})},
							right: {ExpressionListNode: commandListNode},
						});
						expListnode.push(new ExpressionNodeWrapper({ExpressionNode: commandExpNode}), 'expressions');
					}

					break;
				}
			}
		}

		expListnode._formGroup.markAsDirty();
		this.isDirty = true;
	}

	addSource(member: I40FlatMember, expression: ExpressionNode) {
		(<DefinitionNode>expression.right['DefinitionNode']).set({
			name: this.getMappingMemberNamePath(member),
			nodePath: this.getMappingMemberNodePath(member),
			_member: member,
		});
	}

	getTitleFromNode(mapNode: ExpressionNode, connector: string) {
		if (mapNode.left._type !== 'DefinitionNodeNotFound') {
			let leftNode = mapNode.left['DefinitionNode']._member;
			let id = leftNode.id;
			let definitionType = leftNode.definitionType;
			if (leftNode.id == 'Parameters') {
				id = mapNode.left['DefinitionNode']._member._fullPath[1];
				definitionType = 'Command';
			}
			let modelName = (mapNode.left['DefinitionNode'] as DefinitionNode).nodePath.split('/')[0];
			switch (connector) {
				case 'on':
					return id + ' ' + definitionType + ' ' + connector + ' ' + modelName;
				case 'to':
					return id + ' ' + connector + ' ' + modelName;
				case 'Command Reply from':
					return id + ' ' + connector + ' ' + modelName;
			}
		} else {
			return mapNode.left['DefinitionNodeNotFound'].name;
		}
	}

	validateTrigger(member: I40FlatMember): ValidatorFn {
		return (control: AbstractControl): {[key: string]: string} | null => {
			if (control.value && control.value.length > 0) {
				if (member.definitionType === MemberDefinitionType.List) {
					return {message: `${this.translateSrv.instant('i18n.errorMsg.validateDefinitionType', {value: member.definitionType})}`};
				}

				if (!['Event', 'Command'].includes(member.definitionType) && (member._fullPath.includes('Event') || member._fullPath.includes('Command'))) {
					return {message: this.translateSrv.instant('i18n.errorMsg.validateChildTarget')};
				}
			}

			return null;
		};
	}

	validateChildOf(member: I40FlatMember, definitionTypes: Array<string>, message: string): ValidationResult {
		let arr = member._fullPath;
		let result = ValidationResult.send();
		for (let index = 0; index < arr.length; index++) {
			if (definitionTypes.includes(arr[index]) && index < arr.length - 1) {
				result = ValidationResult.send(true, `${arr[index]}${message}`);
				break;
			}
		}
		return result;
	}

	validateRuleTarget(trigger: DefinitionNode, member: I40FlatMember, source: I40FlatMember, parent: DefinitionNode, isUpdating?: boolean) {
		if (trigger !== undefined && trigger.name.includes(this.getMappingMemberNamePath(member))) {
			return ValidationResult.send(true, this.translateSrv.instant('i18n.errorMsg.validateSameAsTrigger'));
		}

		if (isUpdating) {
			if (source && !this.isAssignmentAllowed(member.typeName, source.typeName)) {
				return ValidationResult.send(true, `${this.translateSrv.instant('i18n.errorMsg.validateType', {type0: member.typeName, type1: source.typeName})}`);
			}
		}

		if (parent && parent['DefinitionNode'] && !parent['DefinitionNode']._member._fullPath.every((ai) => member._fullPath.includes(ai))) {
			return ValidationResult.send(true, `${this.translateSrv.instant('i18n.errorMsg.validateNotChildOf')} ${parent['DefinitionNode']._member.id}`);
		}

		if (member.definitionType === MemberDefinitionType.Property) {
			return {message: `${this.translateSrv.instant('i18n.errorMsg.validateDefinitionType', {value: member.definitionType})}`};
		} else {
			if (!['Event', 'Command'].includes(member.definitionType) && (member._fullPath.includes('Event') || member._fullPath.includes('Command')) && !member._hasChildren) {
				return ValidationResult.send(true, this.translateSrv.instant('i18n.errorMsg.validateChildTarget'));
			}
		}

		return null;
	}

	validateMultiRuleTarget(member: I40FlatMember, source: I40FlatMember, parent: DefinitionNode) {
		if (source && !this.isAssignmentAllowed(member.typeName, source.typeName)) {
			return ValidationResult.send(true, `${this.translateSrv.instant('i18n.errorMsg.validateType', {type0: member.typeName, type1: source.typeName})}`);
		}

		if (source && member._fullPath.join('') === source._fullPath.join('') && member._extraInfo.alias === source._extraInfo.alias) {
			return ValidationResult.send(true, `${this.translateSrv.instant('i18n.errorMsg.validateSameVar')}`);
		}

		if (parent && parent['DefinitionNode'] && !parent['DefinitionNode']._member._fullPath.every((ai) => member._fullPath.includes(ai))) {
			return ValidationResult.send(true, `${this.translateSrv.instant('i18n.errorMsg.validateNotChildOf')} ${parent['DefinitionNode']._member.id}`);
		}

		if (['Event', 'Command'].includes(member.definitionType)) {
			return {message: `${this.translateSrv.instant('i18n.errorMsg.validateDefinitionType', {value: member.definitionType})}`};
		}

		if (['Variable'].includes(member.definitionType) && member._hasChildren) {
			return {message: `${this.translateSrv.instant('i18n.errorMsg.validateDefinitionType', {value: 'Complex Variable'})}`};
		}

		if (source && member.definitionType === 'Array' && source.definitionType === 'Array' && (member.size > source.size || member.typeName !== source.typeName)) {
			return ValidationResult.send(true, `${this.translateSrv.instant('i18n.errorMsg.arrayMismatch')}`);
		}

		if (source && member.definitionType !== source.definitionType) {
			return {message: `${this.translateSrv.instant('i18n.errorMsg.validateDefinitionType', {value: member.definitionType})}`};
		} else {
			if (!['Event', 'Command'].includes(member.definitionType) && (member._fullPath.includes('Event') || member._fullPath.includes('Command')) && !member._hasChildren) {
				return ValidationResult.send(true, this.translateSrv.instant('i18n.errorMsg.validateChildTarget'));
			}
		}

		return null;
	}

	validateRuleSource(member: I40FlatMember, target: I40FlatMember, trigger: I40FlatMember, isUpdating?: boolean) {
		// data type check
		const rightPath = this.getMappingMemberNodePath(member);
		const leftPath = this.getMappingMemberNodePath(target);
		const triggerPath = trigger?.id.length ? this.getMappingMemberNodePath(trigger) : '';

		const result = this.validateSamePath(rightPath, leftPath);
		if (result && result.err) {
			return result;
		}

		if (isUpdating) {
			if (!this.isAssignmentAllowed(target.typeName, member.typeName)) {
				return ValidationResult.send(true, `${this.translateSrv.instant('i18n.errorMsg.validateType', {type0: member.typeName, type1: target.typeName})}`);
			}

			// has to work with simple mapping where definitionTypes might be different but also not allow String variables be mapped to Array or List of Strings
			if (member.definitionType !== target.definitionType && (['Array', 'List'].includes(member.definitionType) || ['Array', 'List'].includes(target.definitionType))) {
				return ValidationResult.send(true, `${this.translateSrv.instant('i18n.errorMsg.validateType', {type0: member.definitionType, type1: target.definitionType})}`);
			}

			if (member.definitionType === 'Array' && target.definitionType === 'Array' && (member.size > target.size || member.typeName !== target.typeName)) {
				return ValidationResult.send(true, `${this.translateSrv.instant('i18n.errorMsg.arrayMismatch')}`);
			}
		}

		if (
			trigger !== undefined &&
			!(
				(['Event', 'Command'].includes(trigger.definitionType) &&
					(rightPath.includes(triggerPath) || (member._memberType.category === 'simple' && !member._fullPath.includes('Event') && !member._fullPath.includes('Command')))) ||
				(!['Event', 'Command'].includes(trigger.definitionType) && member._memberType.category === 'simple' && !member._fullPath.includes('Event') && !member._fullPath.includes('Command'))
			)
		) {
			if (!(member._parent as DefinitionNode)._member._fullPath.includes('Reply')) {
				// console.log('VERY COMPLEX SOURCE', member, target);
				return ValidationResult.send(true, `${member.id} ${this.translateSrv.instant('i18n.errorMsg.validateSource')}`);
			}
		}

		return null;
	}

	validateSimpleRuleSource(member: I40FlatMember, target: I40FlatMember, trigger: I40FlatMember, isUpdating?: boolean) {
		// data type check
		const rightPath = this.getMappingMemberNodePath(member);
		const leftPath = this.getMappingMemberNodePath(target);
		const triggerPath = trigger?.id.length ? this.getMappingMemberNodePath(trigger) : '';

		const result = this.validateSamePath(rightPath, leftPath);
		if (result && result.err) {
			return result;
		}

		if (this.isAssignmentAllowed(target.typeName, member.typeName)) {
			return ValidationResult.send(true, `${this.translateSrv.instant('i18n.errorMsg.validateType', {type0: member.typeName, type1: target.typeName})}`);
		}

		if (trigger !== undefined && ['Event', 'Command'].includes(member.definitionType) && rightPath !== triggerPath) {
			console.log('SIMPLE SOURCE');
			return ValidationResult.send(true, `${this.translateSrv.instant('i18n.errorMsg.validateSourceSameAsTrigger', {type0: target.typeName})}`);
		}

		return null;
	}

	validateRuleConditionLeft(left: I40FlatMember, right: I40FlatMember, trigger: I40FlatMember) {
		// data type check
		const leftPath = this.getMappingMemberNodePath(left);
		const rightPath = right.id.length ? this.getMappingMemberNodePath(right) : null;
		const triggerPath = trigger?.id.length ? this.getMappingMemberNodePath(trigger) : null;

		if (rightPath) {
			if (!this.isAssignmentAllowed(left.typeName, right.typeName)) {
				return ValidationResult.send(true, `${this.translateSrv.instant('i18n.errorMsg.validateType', {type0: left.typeName, type1: right.typeName})}`);
			}

			// has to work with simple mapping where definitionTypes might be different but also not allow String variables be mapped to Array or List of Strings
			if (left.definitionType !== right.definitionType && (['Array', 'List'].includes(left.definitionType) || ['Array', 'List'].includes(right.definitionType))) {
				return ValidationResult.send(true, `${this.translateSrv.instant('i18n.errorMsg.validateType', {type0: left.definitionType, type1: right.definitionType})}`);
			}

			if (left.definitionType === 'Array' && right.definitionType === 'Array' && (left.size > right.size || left.typeName !== right.typeName)) {
				return ValidationResult.send(true, `${this.translateSrv.instant('i18n.errorMsg.arrayMismatch')}`);
			}
		}

		if (
			trigger !== undefined &&
			!(
				(['Event', 'Command'].includes(trigger.definitionType) &&
					(leftPath.includes(triggerPath) || (left._memberType.category === 'simple' && !left._fullPath.includes('Event') && !left._fullPath.includes('Command')))) ||
				(!['Event', 'Command'].includes(trigger.definitionType) && left._memberType.category === 'simple' && !left._fullPath.includes('Event') && !left._fullPath.includes('Command'))
			)
		) {
			if (!(left._parent as DefinitionNode)._member._fullPath.includes('Reply')) {
				return ValidationResult.send(true, `${left.id} ${this.translateSrv.instant('i18n.errorMsg.validateSource')}`);
			}
		}

		return null;
	}

	validateRuleConditionRight(left: I40FlatMember, right: I40FlatMember, trigger: I40FlatMember) {
		// data type check
		const leftPath = this.getMappingMemberNodePath(left);
		const rightPath = right.id.length ? this.getMappingMemberNodePath(right) : null;
		const triggerPath = trigger?.id.length ? this.getMappingMemberNodePath(trigger) : null;

		if (rightPath) {
			const result = this.validateSamePath(rightPath, leftPath);
			if (result && result.err) {
				return result;
			}
		}

		if (rightPath) {
			if (!this.isAssignmentAllowed(left.typeName, right.typeName)) {
				return ValidationResult.send(true, `${this.translateSrv.instant('i18n.errorMsg.validateType', {type0: left.typeName, type1: right.typeName})}`);
			}

			// has to work with simple mapping where definitionTypes might be different but also not allow String variables be mapped to Array or List of Strings
			if (left.definitionType !== right.definitionType && (['Array', 'List'].includes(left.definitionType) || ['Array', 'List'].includes(right.definitionType))) {
				return ValidationResult.send(true, `${this.translateSrv.instant('i18n.errorMsg.validateType', {type0: left.definitionType, type1: right.definitionType})}`);
			}

			if (left.definitionType === 'Array' && right.definitionType === 'Array' && (left.size > right.size || left.typeName !== right.typeName)) {
				return ValidationResult.send(true, `${this.translateSrv.instant('i18n.errorMsg.arrayMismatch')}`);
			}

			if (
				trigger !== undefined &&
				!(
					(['Event', 'Command'].includes(trigger.definitionType) &&
						(rightPath.includes(triggerPath) || (right._memberType.category === 'simple' && !right._fullPath.includes('Event') && !right._fullPath.includes('Command')))) ||
					(!['Event', 'Command'].includes(trigger.definitionType) && right._memberType.category === 'simple' && !right._fullPath.includes('Event') && !right._fullPath.includes('Command'))
				)
			) {
				if (!(right._parent as DefinitionNode)._member._fullPath.includes('Reply')) {
					return ValidationResult.send(true, `${right.id} ${this.translateSrv.instant('i18n.errorMsg.validateSource')}`);
				}
			}
		}

		return null;
	}

	checkTriggerChild(member: I40FlatMember, trigger: I40FlatMember): boolean {
		let isChild: boolean = false;
		trigger._memberType.members.forEach((m, k) => {
			if (k === member.id) {
				isChild = true;
			}
		});
		return isChild;
	}

	validateSamePath(path: string, targetPath: string) {
		if (path === targetPath) {
			return ValidationResult.send(true, `${this.translateSrv.instant('i18n.errorMsg.validateSameVar')}`);
		}
		return null;
	}

	hasDefinitionNotFound(rules): boolean {
		let result: boolean;
		for (let i = 0; i < rules.length; i++) {
			let e = rules[i];
			if (this.isExpressionListNode(e.left)) {
				if (e.left.expressions && e.left.expressions.length !== 0) {
					result = this.hasDefinitionNotFound([e.left]);
					break;
				}
			}
			if (this.isExpressionListNode(e.right)) {
				if (e.right.expressions && e.right.expressions.length !== 0) {
					result = this.hasDefinitionNotFound([e.right]);
					break;
				}
			}
			if (e.expressions && e.expressions.length !== 0) {
				result = this.hasDefinitionNotFound(e.expressions);
				break;
			} else {
				if (e.left instanceof DefinitionNodeNotFound || e.right instanceof DefinitionNodeNotFound) {
					result = true;
					break;
				}
			}
		}
		return result ? result : false;
	}

	checkInUse(rules: MappingRule[], modelName: string) {
		let inUse = false;
		rules.forEach((r) => {
			if (r.sourceCodeFlag) {
				if (r.sourceCode.indexOf(modelName) > -1) {
					inUse = true;
				}
			} else {
				r.expressions.forEach((e) => {
					const str_e = JSON.stringify(e.serialize());
					if (str_e.indexOf(`Model/${modelName}/`) > -1) {
						inUse = true;
					}
				});
			}
		});
		return inUse;
	}

	saveMapping() {
		// SAVE GNVD VALUES
		this.inputMapping.info.setFormValues();

		this.inputMapping.mapping.rules.forEach((r) => {
			this.validatorService.formatRule(r);
		});

		const payload = this.inputMapping.serialize();

		// UPDATE MAPPING
		if (this.inputMapping.info.identifier.id !== '') {
			return this.mappingService.updateMapping(payload);
		}

		// SAVE NEW MAPPING
		else {
			return this.mappingService.addMapping(payload);
		}
	}

	cloneMapping() {
		// SAVE GNVD VALUES
		this.inputMapping.info.setFormValues();

		this.inputMapping.mapping.rules.forEach((r) => {
			this.validatorService.formatRule(r);
		});

		const mappingClone: MappingManagerResult = _.cloneDeep(this.inputMapping);
		mappingClone.info.identifier = new Identifier({version: 'latest'});
		mappingClone.info.externalIdentifier.name += ' [CLONE]';

		return this.mappingService.addMapping(mappingClone.serialize());
	}

	releaseMapping(version: string) {
		const mappingClone: MappingManagerResult = _.cloneDeep(this.inputMapping);
		mappingClone.info.identifier = new Identifier({version});

		return this.mappingService.releaseMapping(this.inputMapping.info.identifier.id, version);
	}
}

export class ValidationResult {
	public err: boolean = false;
	public message: string = undefined;

	constructor(err?: boolean, message?: string) {
		this.err = err ? err : false;
		this.message = message ? message : undefined;
	}

	static send(err?: boolean, message?: string) {
		return new ValidationResult(err ? err : false, message);
	}
}
