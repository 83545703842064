import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class ApiService {
	constructor(public http: HttpClient) {}

	reindexRepository(): Observable<any> {
		return this.http.get(`${environment.apiHost + environment.apiUrl}/repository/index`);
	}

	rebuildRepository(): Observable<any> {
		const httpOptions = {
			observe: 'events' as const,
			reportProgress: true,
			responseType: 'text' as const,
		};
		return this.http.get(`${environment.apiHost + environment.apiUrl}/repository/build`, httpOptions);
	}

	getAppConfiguration(): Observable<any> {
		return this.http.get(`${environment.apiHost + environment.apiUrl}/configuration`);
	}

	clearCache(): Observable<any> {
		return this.http.delete(`${environment.apiHost + environment.apiUrl}/managers/clear-cache`);
	}
}
