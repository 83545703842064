import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {I40FlatMember} from '../../../types';
import {SchemaLayer} from '../../../../../data-models/json-schema.model';
import {I40TreeChannelService} from '../../i40-tree-channel.service';
import {AuthService} from '../../../../../core-services/auth.service';

@Component({
	selector: 'app-layer',
	templateUrl: './layer.component.html',
	styleUrls: ['./layer.component.css'],
})
export class LayerComponent implements OnInit, OnChanges {
	@Input() layer: SchemaLayer;
	@Input() selectedObject: I40FlatMember;

	visible: boolean = true;

	constructor(
		public treeSrv: I40TreeChannelService,
		public authSrv: AuthService,
	) {}

	ngOnInit(): void {}

	ngOnChanges() {}

	layerVisible(visible: boolean) {
		this.visible = visible;
	}
}
