import {ElementRef, Injectable, EventEmitter} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {GlobalService} from 'src/app/core-services/global.service';
import {MessageResponseService} from 'src/app/core-services/message-response.service';
import {Button, DialogInput, GenericDialogComponent} from 'src/app/shared/generic-dialog/generic-dialog.component';
import {BackupService} from './backup.service';

@Injectable({
	providedIn: 'root',
})
export class BackupRestoreService {
	constructor(
		private msgSrv: MessageResponseService,
		private translate: TranslateService,
		private backupSrv: BackupService,
		private globalSrv: GlobalService,
	) {}

	file;
	chunkSize = 9 * 1024 * 1024;
	isDone: EventEmitter<boolean> = new EventEmitter<boolean>();

	showBackupDialog() {
		return this.msgSrv
			.customDialogMessage(
				this.translate.instant('i18n.backupDialogTitle'),
				this.translate.instant('i18n.backupDialogText'),
				[new Button(this.translate.instant('i18n.yes'), 'yes', 'flat', 'accent', 'start'), new Button(this.translate.instant('i18n.cancel'), 'cancel', 'flat', 'accent', 'end')],
				{
					yes: (dialogRef, resolve) => {
						dialogRef.close();
						dialogRef.close();
						dialogRef.close();
						resolve(true);
					},
					cancel: (dialogRef, resolve) => {
						dialogRef.close();
						resolve(false);
					},
				},
			)
			.then((res) => {
				return res;
			});
	}

	showRestoreDialog() {
		return this.msgSrv
			.customDialogMessage(
				this.translate.instant('i18n.restoreDialogTitle'),
				this.translate.instant('i18n.restoreDialogText'),
				[new Button(this.translate.instant('i18n.yes'), 'yes', 'flat', 'accent', 'start', true), new Button(this.translate.instant('i18n.cancel'), 'cancel', 'flat', 'accent', 'end')],
				{
					opened: (dialogRef) => {
						const repoFile: ElementRef = (<GenericDialogComponent>dialogRef.componentInstance).fileInput;
						const fileUploadEl = repoFile.nativeElement;
						dialogRef.disableClose = true;
						fileUploadEl.onchange = (e) => {
							(<GenericDialogComponent>dialogRef.componentInstance).fileData = e.target.files[0];
							dialogRef.componentInstance.dialogData.buttons[0].disabled = false;
							dialogRef.disableClose = true;
						};
					},
					yes: (dialogRef, resolve) => {
						// console.log("press yes : " + resolve)

						this.file = (<GenericDialogComponent>dialogRef.componentInstance).fileData;

						if (this.file) {
							this.msgSrv.onLoading.next({command: 'start', message: ''});

							let fileReader = new FileReader();

							fileReader.onload = (e) => {
								// console.log("file read: " + e)

								let uploadedBytes = 0;
								let chunkNumber = 1;
								let fd = new FormData();

								fd.append('file', this.file.slice(uploadedBytes, this.chunkSize + uploadedBytes, this.file.name));
								fd.append('resumableFilename', this.file.name);
								fd.append('resumableChunkNumber', chunkNumber.toString());
								fd.append('resumableTotalChunks', Math.ceil(this.file.size / this.chunkSize).toString());
								fd.append('resumableIdentifier', this.file.name);

								this.backupSrv.retrieveArtifactInfo(fd).subscribe({
									next: (data: any) => {
										dialogRef.close();
										resolve(data);
									},
									error: (err) => {
										dialogRef.close();
										resolve(false);
									},
								});
							};

							fileReader.readAsText(this.file);
						} else {
							dialogRef.componentInstance.dialogData.buttons[0].disabled = true;
						}
					},
					cancel: (dialogRef, resolve) => {
						dialogRef.close();
						resolve(false);
					},
				},
				true,
				[new DialogInput('Repository File', 'repo_file', 'file', '')],
			)
			.then((res) => {
				return res;
			});
	}

	backupFiltered(ids?) {
		this.backupSrv.downloadRepositoryFiltered(ids).subscribe({
			next: (data) => {
				let filename = data.headers.get('content-disposition').split(';')[1].split('=')[1].replace(/\"/g, '');
				let file = new Blob([data.body], {
					type: 'application/tar',
				});
				this.globalSrv.downloadFile(file, filename, 'application/octet-stream');
				this.msgSrv.onLoading.next({command: 'finish'});
			},
			error: (err) => {
				this.msgSrv.onLoading.next({command: 'finish'});
			},
		});

		this.msgSrv.onLoading.next({command: 'finish'});
	}

	restore() {
		this.msgSrv.onLoading.next({command: 'finish'});
		this.msgSrv.onLoading.next({command: 'start'});
		this.sendChunk(this.file, 0, this.chunkSize, 1);
	}

	advancedRestore(data?) {
		this.msgSrv.onLoading.next({command: 'finish'});
		this.msgSrv.onLoading.next({command: 'start'});

		this.backupSrv.advancedRestore(data).subscribe({
			next: (data) => {
				this.sendChunkAdvanced(data, this.file, 0, this.chunkSize, 1);
			},
			error: () => {
				this.msgSrv.onLoading.next({command: 'finish'});
			},
			complete: () => {
				// console.log("STREAM COMPLETED --- RESTORE FINISHED")
				this.msgSrv.onLoading.next({command: 'progress', messages: []});
				setTimeout(() => {}, 2000);
			},
		});
	}

	sendChunkAdvanced(data?, file?, uploadedBytes?, chunkSize?, chunkNumber?) {
		chunkNumber++;
		if (chunkSize + uploadedBytes < file.size) {
			uploadedBytes = uploadedBytes + chunkSize;
			this.sendChunkAdvanced(data, file, uploadedBytes, chunkSize, chunkNumber);
		} else {
			let message = data.partialText ? data.partialText : '';

			if (message.length !== 0) {
				let messagesList = JSON.parse(`[${message.replace(/}{/g, '},{')}]`);

				if (messagesList.length !== 0) {
					this.msgSrv.onLoading.next({command: 'progress', messages: messagesList});
				}
			}
		}
	}

	sendChunk(file?, uploadedBytes?, chunkSize?, chunkNumber?) {
		let fd = new FormData();
		fd.append('file', file.slice(uploadedBytes, chunkSize + uploadedBytes, file.name));
		fd.append('resumableFilename', file.name);
		fd.append('resumableChunkNumber', chunkNumber.toString());
		fd.append('resumableTotalChunks', Math.ceil(file.size / chunkSize).toString());
		fd.append('resumableIdentifier', file.name);

		this.backupSrv.restoreChunked(fd).subscribe({
			next: (data) => {
				chunkNumber++;
				if (chunkSize + uploadedBytes < file.size) {
					this.msgSrv.onLoading.next({command: 'update', message: 'Uploading: ' + Math.floor((uploadedBytes / file.size) * 100) + '%'});
					uploadedBytes = uploadedBytes + chunkSize;
					this.sendChunk(file, uploadedBytes, chunkSize, chunkNumber);
				} else {
					let message = data.partialText ? data.partialText : '';

					if (message.length !== 0) {
						let messagesList = JSON.parse(`[${message.replace(/}{/g, '},{')}]`);

						if (messagesList.length !== 0) {
							this.msgSrv.onLoading.next({command: 'progress', messages: messagesList});
						}
					}
				}
			},
			error: (err) => {
				// console.log("error = " + JSON.stringify(err))
				this.isDone.next(false);
				this.msgSrv.onLoading.next({command: 'finish'});
			},
			complete: () => {
				// console.log("STREAM COMPLETED --- RESTORE FINISHED")
				this.msgSrv.onLoading.next({command: 'progress', messages: []});
				this.isDone.next(true);
				setTimeout(() => {}, 2000);
			},
		});
	}

	findDependencyInDataList(dataList, dependency) {
		return dataList.find((data) => dependency.id == data.identifier.id && dependency.version === data.identifier.version);
	}

	findDependency(dependencyList, dataList) {
		const result = [];
		for (const dependency of dependencyList) {
			const dataResult = this.findDependencyInDataList(dataList, dependency);
			if (dataResult) {
				if (dataResult.dependencies.length !== 0) {
					result.push(...this.findDependency(dataResult.dependencies, dataList));
				}
				result.push(dataResult);
			}
		}
		return result;
	}

	checkDependencies(element, type?: string, selection?, data?) {
		let dependencies = element.dependencies.map((d) => {
			if (d.version === element.identifier.version) {
				return d;
			}
		});

		if (dependencies.length !== 0) {
			return this.msgSrv.customDialogMessage(
				this.translate.instant('i18n.dependenciesTitle'),
				this.translate.instant('i18n.dependenciesText', {action: type}),
				[new Button(this.translate.instant('i18n.yes'), 'yes', 'flat', 'accent', 'start'), new Button(this.translate.instant('i18n.no'), 'no', 'flat', 'accent', 'end')],
				{
					yes: (dialogRef, resolve) => {
						let finalDependencies = this.findDependency(dependencies, data);
						if (this.globalSrv.debugMode) {
							console.log('dependencies', finalDependencies);
						}

						switch (type) {
							case 'select':
								selection.select(...finalDependencies);
								break;
							case 'deselect':
								finalDependencies.forEach((d) => {
									selection.deselect(d);
								});
								//selection.deselect(...finalDependencies);
								break;
						}

						dialogRef.close();
						resolve(true);
					},
					no: (dialogRef, resolve) => {
						dialogRef.close();
						resolve(false);
					},
				},
			);
		}
	}
}
