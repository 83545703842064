import {Component, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from '../../core-services/auth.service';
import {MessageResponseService} from '../../core-services/message-response.service';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
	selector: 'about-unifier',
	templateUrl: './about-unifier.component.html',
	styleUrls: ['./about-unifier.component.scss'],
})
export class AboutUnifierComponent implements OnInit {
	version: string;
	cyear: number;

	constructor(
		public dialogRef: MatDialogRef<AboutUnifierComponent>,
		public translate: TranslateService,
		public authSrv: AuthService,
		public msgSrv: MessageResponseService,
	) {}

	ngOnInit() {
		this.version = environment.version;
		this.cyear = new Date().getFullYear();
	}

	updateLicense() {
		this.dialogRef.close();
		this.msgSrv.licensePopup();
	}
}
