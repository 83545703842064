import {Component, ElementRef, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {UnifierListService} from '../../core-services/unifier-list.service';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from '../../core-services/auth.service';
import {Root} from '../../data-models/core-model.model';
import {MessageResponseService} from '../../core-services/message-response.service';
import {GlobalService} from '../../core-services/global.service';

@Component({
	selector: 'unifier-list-toolbar',
	templateUrl: './unifier-list-toolbar.component.html',
	styleUrls: ['./unifier-list-toolbar.component.css'],
})
export class UnifierListToolbarComponent<T extends Root> implements OnInit {
	@Input() location: string;
	@Input() showImport: boolean = true;
	@Input() showAdd: boolean = true;
	@Input() showRefresh: boolean = true;
	@Input() searchable: boolean = true;

	@ViewChild('searchInput', {static: false}) searchInput: ElementRef;
	@ViewChild('fileUpload', {static: false}) fileUpload: ElementRef;

	constructor(
		public translate: TranslateService,
		public authSrv: AuthService,
		public msgSrv: MessageResponseService,
		public listSrv: UnifierListService<T>,
		public globalSrv: GlobalService,
	) {}

	ngOnInit(): void {}

	showSearch() {
		this.listSrv.searchVisible = true;
		setTimeout(() => {
			this.searchInput.nativeElement.focus();
		});
	}

	import() {
		this.listSrv.import(this.fileUpload);
	}
}
