import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
	constructor(public router: Router) {}

	ngOnInit() {
		this.router.events.subscribe((e) => {
			if (e instanceof NavigationEnd) {
				if (!window.location.pathname.includes('login')) {
					sessionStorage.setItem('loginCallbackURL', window.location.pathname);
				}
			}
		});
	}
}
