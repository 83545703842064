import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {I40FlatMember} from '../types';
import {AuthService} from '../../../core-services/auth.service';

@Component({
	selector: 'i40-tree-member',
	templateUrl: './i40-tree-member.component.html',
	styleUrls: ['./i40-tree-member.component.scss'],
})
export class I40TreeMemberComponent implements OnInit {
	loaded = false;

	@Input() member: I40FlatMember;
	@Output('nodeClick') nodeClick = new EventEmitter();

	tabSize = 32; // px

	constructor(public authSrv: AuthService) {}

	ngOnInit() {
		this.loaded = true;
	}

	toggleCollapse(e) {
		e.preventDefault();
		e.stopPropagation();

		this.nodeClick.emit('toggle');
	}

	onNodeClick(e) {
		this.nodeClick.emit('select');
	}
}
