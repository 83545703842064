import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {I40FlatMember} from '../../../types';
import {JSONSchemaProperty} from '../../../../../data-models/json-schema.model';
import {I40TreeChannelService} from '../../i40-tree-channel.service';
import {AuthService} from '../../../../../core-services/auth.service';
import {GlobalService} from '../../../../../core-services/global.service';

@Component({
	selector: 'app-checkbox',
	templateUrl: './checkbox.component.html',
	styleUrls: ['./checkbox.component.css'],
})
export class CheckboxComponent implements OnInit {
	@Input() control: UntypedFormControl;
	@Input() selectedObject: I40FlatMember;
	@Input() property: JSONSchemaProperty;

	constructor(
		public treeSrv: I40TreeChannelService,
		public authSrv: AuthService,
		public globalSrv: GlobalService,
	) {}

	ngOnInit(): void {}
}
