<div fxLayout="column" class="device-type-description">
	<mat-toolbar class="smaller_toolbar" fxLayout="row">
		<span class="push-left-sm" fxFlex>
			<span mat-subheader class="typography" style="padding-left: 6px">{{ 'i18n.mappings' | translate }}</span>
		</span>
		<button id="add_mapping_button" *ngIf="globalSrv.canEdit(dataSrv.deviceTypeResult) && dataSrv.editable" mat-icon-button color="accent" (click)="addNewMapping()">
			<mat-icon>add</mat-icon>
		</button>
	</mat-toolbar>
	<mat-error *ngIf="dataSrv.deviceTypeResult.mappings && dataSrv.deviceTypeResult.mappings.length === 0" style="font-size: 12px">
		{{ 'i18n.minMappings' | translate }}
	</mat-error>
	<div
		fxLayout="column"
		class="list"
		style="width: 100%; margin-top: 2px"
		[style.border]="dataSrv.deviceTypeResult.mappings && dataSrv.deviceTypeResult.mappings.length !== 0 ? '#34f6c821 2px solid' : '0'"
	>
		<mat-list fxLayout="column" style="height: 100%">
			<mat-list-item *ngFor="let mapping of dataSrv.deviceTypeResult.mappings; let i = index" style="width: 100%; height: auto; padding-bottom: 8px">
				<mat-card class="device-type-mapping-card" appearance="outlined">
					<app-device-type-mapping class="list" style="width: 100%; height: 100%" [mappingIndex]="i"> </app-device-type-mapping>
				</mat-card>
			</mat-list-item>
		</mat-list>
	</div>
</div>
