import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DialogData} from '../generic-dialog/generic-dialog.component';

@Component({
	selector: 'app-error-dialog',
	templateUrl: './error-dialog.component.html',
	styleUrls: ['./error-dialog.component.scss'],
})
export class ErrorDialogComponent implements OnInit {
	dialogData: DialogData;

	constructor(
		public dialogRef: MatDialogRef<ErrorDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: DialogData,
	) {
		dialogRef.disableClose = true;
	}

	ngOnInit() {
		if (this.data) {
			this.dialogData = this.data;
		}
	}
}

export class ErrorDialogData extends DialogData {
	constructor(
		public title: string,
		public message: string,
	) {
		super(title, null, message);
	}
}
