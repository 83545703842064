<div fxLayout="column" style="height: 100%">
	<div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
		<mat-form-field subscriptSizing="dynamic" style="width: 100%" fxFlex>
			<mat-label>{{ 'i18n.mapping' | translate }}</mat-label>
			<mat-select
				id="mapping_select"
				[compareWith]="compareMappings"
				[formControl]="deviceTypeMapping.mappingInfo._formGroup.toFormControl([this.validatorSrv.validateMappingInfo()], !globalSrv.canEdit(dataSrv.deviceTypeResult) || !dataSrv.editable)"
				(selectionChange)="onMappingChange($event)"
				class="mapping_select"
			>
				<mat-option *ngFor="let mappingInfo of dataSrv.mappings" [value]="mappingInfo" class="mapping mapping_option">
					<span>{{ mappingInfo.info.getExternalId() }}</span>
				</mat-option>
			</mat-select>
		</mat-form-field>
		<button
			id="delete_button"
			fxFlex="5"
			fxLayoutAlign="center center"
			*ngIf="globalSrv.canEdit(dataSrv.deviceTypeResult) && dataSrv.editable"
			mat-icon-button
			color="accent"
			(click)="deleteMapping()"
			matTooltip="{{ 'i18n.deleteMapping' | translate }}"
		>
			<mat-icon>delete</mat-icon>
		</button>
	</div>
	<div fxFlex="1 0 auto" *ngIf="deviceTypeMapping.mappingInfo.info.identifier.id.length && !changingMapping">
		<mat-list fxLayout="column">
			<div fxFlex="1 0 auto" fxLayout="row">
				<div fxFlex="30">
					<h3 mat-subheader fxFlex="1 0 auto" style="padding-left: 0">{{ 'i18n.models' | translate }}</h3>
				</div>
				<div fxFlex="1 0 auto" fxLayoutAlign="start center">
					<h3 mat-subheader fxFlex="1 0 auto" style="padding-left: 0">{{ 'i18n.channels' | translate }}</h3>
				</div>
			</div>

			<div fxFlex="1 0 auto" fxLayout="column" *ngFor="let modelChannel of deviceTypeMapping.channels; let j = index">
				<mat-list-item fxFlex="1 0 auto" style="width: 100%" class="mat-typography list">
					<app-device-type-mapping-channel
						fxFlex="1 0 auto"
						[channelInfoList]="getModelChannels(modelChannel.modelInfo.name)"
						[channelIndex]="j"
						[mappingIndex]="mappingIndex"
					></app-device-type-mapping-channel>
				</mat-list-item>
			</div>
		</mat-list>
	</div>
</div>
