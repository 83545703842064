import {Injectable} from '@angular/core';
import {JSONSchemaProperty} from '../data-models/json-schema.model';
import * as _ from 'lodash';

@Injectable({
	providedIn: 'root',
})
export class JsonSchemaParserService {
	refDepth: Map<string, any> = new Map<string, any>();
	specificationVersion = '1.4';
	implementationTypeName = '';

	constructor() {}

	parseSchema(schema: any, implementationTypeName: string): any {
		const properties: JSONSchemaProperty[] = [];

		this.specificationVersion = schema.specificationVersion;
		this.implementationTypeName = implementationTypeName;
		Object.entries(schema.properties).forEach(([k, v]: [string, any]) => {
			schema.properties[k] = new JSONSchemaProperty(this.parseProperty(v, schema), schema.required, k);
			properties.push(schema.properties[k]);
		});

		return properties;
	}

	parseProperty(p, schema) {
		if (p.$ref) {
			if (p.$ref == '#com.amorphsys.commons.configuration.VersionInfo' && this.specificationVersion != '') {
				p.default = {implementationName: this.implementationTypeName, specificationVersion: this.specificationVersion};
			}
			const parsedNode = this.parseRef(p['$ref'], schema);
			const node = {...p, ...parsedNode};

			if (parsedNode['$ref'] === undefined) {
				delete node['$ref'];
			}
			return node;
		} else {
			if (p.items) {
				p.items = this.parseProperty(p.items, schema);
			} else {
				if (p.properties) {
					Object.entries(p.properties).forEach(([kk, pp]: [string, any]) => {
						p.properties[kk] = this.parseProperty(pp, schema);
					});
				}
			}
		}

		return p;
	}

	parseRef(ref: string, schema: any, parentRefs: string[] = []): any {
		const refId = ref.replace('#', '').split('/').pop();

		const refPath = parentRefs.join('/') + ref;
		if (parentRefs.length && parentRefs.includes(ref)) {
			// if(this.refDepth[refPath]) {
			// if(this.refDepth[refPath] < 10) {
			if (
				parentRefs
					.reverse()
					.slice(-5)
					.filter((e) => e == ref).length < 5 &&
				parentRefs
					.reverse()
					.slice(-5)
					.filter((e) => e == ref).length ==
					parentRefs
						.reverse()
						.slice(-6)
						.filter((e) => e == ref).length
			) {
				this.refDepth[refPath]++;
			} else {
				return null;
			}
			// }
			// else {
			// 	this.refDepth[refPath] = 1;
			// }
		}

		if (schema.definitions[refId] !== undefined) {
			const def = _.cloneDeep(schema.definitions[refId]);
			const deleteProperties = [];

			if (def.properties !== undefined) {
				Object.entries(def.properties).forEach(([k, v]: [string, any]) => {
					if (v.$ref) {
						const parsedNode = this.parseRef(v.$ref, schema, [ref, ...parentRefs]);
						if (parsedNode !== null) {
							def.properties[k] = {...v, ...parsedNode};
						} else {
							deleteProperties.push(k);
						}
					} else {
						if (v.items) {
							if (v.items.$ref) {
								const parsedItems = this.parseRef(v.items.$ref, schema, [ref, ...parentRefs]);
								if (parsedItems === null) {
									deleteProperties.push(k);
								} else {
									def.properties[k].items = parsedItems;
								}
							}
						}

						if (v.anyOf?.length) {
							def.properties[k].anyOf.forEach((d, i) => {
								if (d.$ref) {
									def.properties[k].anyOf[i] = this.parseRef(d.$ref, schema, [ref, ...parentRefs]);
								}
							});
						}

						if (v.oneOf?.length) {
							v.oneOf.forEach((d, i) => {
								if (d.$ref) {
									def.properties[k].oneOf[i] = this.parseRef(d.$ref, schema, [ref, ...parentRefs]);
								}
							});
						}
					}
				});
			}

			if (def.items) {
				if (def.items.$ref) {
					def.items = this.parseRef(def.items.$ref, schema, [ref, ...parentRefs]);
				}
			}

			if (def.oneOf?.length) {
				def.oneOf.forEach((d, i) => {
					if (d.$ref) {
						def.oneOf[i] = this.parseRef(d.$ref, schema, [ref, ...parentRefs]);
					}
				});
			}

			if (def.anyOf?.length) {
				def.anyOf.forEach((d, i) => {
					if (d.$ref) {
						def.anyOf[i] = this.parseRef(d.$ref, schema, [ref, ...parentRefs]);
					}
				});
			}

			delete def['$ref'];

			deleteProperties.forEach((p) => {
				delete def.properties[p];
			});

			return def;
		} else {
			return {};
		}
	}
}
