<ng-container *ngIf="loaded">
	<div [ngStyle]="{'margin-left': member._level * tabSize + 'px'}" class="tree-node-wrapper">
		<div class="tree-node" [ngClass]="{'has-children': member._hasChildren}" (click)="onNodeClick($event)">
			<mat-icon *ngIf="member._hasChildren" (click)="toggleCollapse($event)" class="tree-icon parent-toggle">{{ member._collapsed ? 'expand_more' : 'expand_less' }}</mat-icon>
			<span class="{{ member.definitionType.toLowerCase() }}"></span>
			<div style="display: flex; flex-direction: row; align-items: center">
				<span>{{ member.id }}</span>
				<span *ngIf="member._memberType && member._memberType.category !== 'custom' && member.definitionType !== 'Array'">&nbsp;[{{ member.typeName }}]</span>
				<span *ngIf="member.definitionType === 'Array'">&nbsp;[{{ member.typeName }}: {{ member.size }}]</span>
				<span *ngIf="!member._model._formGroup.disabled && ((member._level === 0 && !member._srv.isValid) || !member._isValid)"
					>&nbsp;<mat-icon class="invalid-tree-icon small_icon">warning</mat-icon></span
				>
			</div>
		</div>
	</div>
</ng-container>
