import {HttpBackend, HttpClient} from '@angular/common/http';
import {MissingTranslationHandler, MissingTranslationHandlerParams, TranslateLoader} from '@ngx-translate/core';
import {forkJoin, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

export class MultiTranslateHttpLoader implements TranslateLoader {
	constructor(
		private http_back: HttpBackend,
		public resources: {prefix: string; suffix: string}[],
	) {
		this.resources = [{prefix: '/assets/i18n/', suffix: '.json'}];
	}

	/**
	 * Get the translations
	 * @param lang
	 * @returns {any}
	 */
	public getTranslation(lang: string): any {
		const http_no_intercept = new HttpClient(this.http_back);
		const requests: Observable<any>[] = this.resources.map((config) => {
			return http_no_intercept.get(`${config.prefix}${lang}${config.suffix}`);
		});

		return forkJoin(requests).pipe(
			map((response) => {
				return response.reduce((a, b) => {
					return Object.assign(a, b);
				});
			}),
		);
	}
}

export class MyMissingTranslationHandler implements MissingTranslationHandler {
	handle(params: MissingTranslationHandlerParams) {
		if (params.key.includes('i18n')) {
			return params.key.substr(params.key.lastIndexOf('.') + 1);
		} else {
			return params.key;
		}
	}
}
