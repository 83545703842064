import {ArtifactInfo, ExternalDescriptor, ExternalIdentifier, Root, ValidationStatus} from '../../data-models/core-model.model';
import {I40FlatMember, ModelManagerResult} from '../../shared/i40-tree/types';

export class MappingManagerCreateRequest extends Root {
	_className = 'MappingManagerCreateRequest';

	version: string = 'latest';
	externalIdentifier: ExternalIdentifier = new ExternalIdentifier({}, this);
	externalDescriptor: ExternalDescriptor = new ExternalDescriptor({}, this);
	models: Map<string, ModelManagerResult> = new Map<string, ModelManagerResult>();
	mapping: MappingDescription = new MappingDescription({}, this);
	doCompile: Boolean = true;
	ignoreCompileErrors: Boolean = false;

	constructor(
		input?: {varsion?: string; externalIdentifier?: any; externalDescriptor?: any; models?: any; mapping?: any; doCompile?: Boolean; ignoreCompileErrors?: Boolean},
		parent?: Root,
		inherited: boolean = false,
	) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}

	serialize(): {} {
		const output: any = super.serialize();
		return output;
	}
}

export class MappingManagerResult extends Root {
	_className = 'MappingManagerResult';

	info: ArtifactInfo = new ArtifactInfo({}, this);
	models: Map<string, ModelManagerResult> = new Map<string, ModelManagerResult>();
	mapping: MappingDescription = new MappingDescription({}, this);
	compilationResult: CompilationResult = new CompilationResult();

	constructor(input?: {info?: any; mapping?: any; models?: any[]}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}

	artifactIdentifier() {
		return this.info.identifier;
	}

	setModels(models) {
		if (models) {
			this.models = new Map(
				models.map((el) => {
					return [el[0], new ModelManagerResult(el[1])];
				}),
			);
		}
	}

	serializeModels() {
		const arr = [];
		this.models.forEach((value, key) => {
			arr.push([key, value.info.identifier.serialize()]);
		});
		return arr;
	}

	modelsToFormControl() {
		return this.rootMapToFormControl(this.models);
	}

	serialize(): {} {
		const output: any = super.serialize();

		output.externalIdentifier = output.info.externalIdentifier;
		output.externalDescriptor = output.info.externalDescriptor;

		output.ignoreCompileErrors = false;
		output.doCompile = true;

		if (output.info.identifier.id == '') {
			output.version = output.info.identifier.version;
		} else {
			output.identifier = output.info.identifier;
		}

		delete output.compilationResult;
		delete output.info;

		return output;
	}
}

export class CompilationResult {
	public type: 'CompilationFailed' | 'CompilationSuccessful' | 'NotCompiled';
	public errors: CompilationError[] = [];
}

export class CompilationError {
	public file: string = '';
	public line: number = 1;
	public text: string = '';
}

export class Info extends Root {
	_className = 'Info';

	name: string = '';
	description: string = '';
	tags: string[] = [];

	constructor(input?: {name?: string; description?: string; tags?: string[]}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}

	tagsToFormControl() {
		return this.rootArrayToFormControl(this.tags);
	}
}

export class MappingInfo extends Root {
	_className = 'MappingInfo';

	info: ArtifactInfo = new ArtifactInfo({}, this);
	models: Map<string, ArtifactInfo> = new Map(); // [ {name, identifier, externalIdentifier, externalDescriptor}, {name, identifier, externalIdentifier, externalDescriptor}]
	status: ValidationStatus = undefined;

	constructor(input?: {info?: any; models?: any[]; status?: ValidationStatus}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
		this.status = new ValidationStatus(input.status, this, false);
	}

	setModels(models, parent) {
		if (models) {
			if (!(models instanceof Map)) {
				this.models = new Map(
					models.map((m) => {
						return [m['name'], new ArtifactInfo(m['info'], parent)];
					}),
				);
			} else {
				this.models = models;
			}
		}
	}

	serializeModels() {
		const obj = [];
		this.models.forEach((value, key) => {
			const a = {};
			a['info'] = value.serialize();
			a['name'] = key;
			obj.push(a);
		});
		return obj;
	}

	static sort(a: MappingInfo, b: MappingInfo) {
		if (a.info.getExternalId() < b.info.getExternalId()) return -1;
		if (a.info.getExternalId() > b.info.getExternalId()) return 1;
		return 0;
	}
}

export class MappingDescription extends Root {
	_className = 'MappingDescription';

	name: string = '';
	package: string = '';
	info: Info = new Info({}, this);
	rules: MappingRule[] = [];
	sourceCode: string = '';

	constructor(input?: {name?: any; package?: any; info?: any; rules?: any[]; sourceCode?: string}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}

	setRules(rules, parent) {
		if (rules) {
			this.rules = rules.map((r: any) => {
				return new MappingRule(r, parent);
			});
		}
	}

	serializeRules() {
		return this.rules.map((rule) => {
			return rule.serialize();
		});
	}

	rulesToFormControl() {
		return this.rootArrayToFormControl(this.rules);
	}

	serialize(): {} {
		const output: any = super.serialize();
		if (output.sourceCode == '') {
			delete output.sourceCode;
		}
		return output;
	}
}

export class MappingRule extends Root {
	_className = 'MappingRule';

	public id: string = '';
	public description: string = '';
	public info: Info = new Info({}, this);
	public sourceCodeFlag: boolean = false;
	public sourceCode: string = '';
	public expressions: ExpressionNode[] = [];
	public enabledFlag: boolean = true;
	public isEdited: boolean = false;

	constructor(input?: {id?: string; description?: string; info?: Info; sourceCodeFlag?: boolean; expressions?: any[]; sourceCode?: string}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}

	setExpressions(expressions, parent) {
		if (expressions) {
			this.expressions = expressions.map((e: any) => {
				return new ExpressionNodeWrapper(e, parent);
			});
		}
	}

	serializeExpressions() {
		return this.expressions.map((e) => {
			return e.serialize();
		});
	}

	expressionsToFormControl() {
		return this.rootArrayToFormControl(this.expressions);
	}
}

export class ExpressionNode extends Root {
	_className = 'ExpressionNode';

	public operator: string = '';

	public left: any = {};
	public right: any = {};

	constructor(input?: {operator?: any; left?: any; right?: any}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}

	setLeft(left, parent) {
		if (left) {
			const classNameStr = Object.keys(left)[0];
			const className = mappingClasses[classNameStr];

			if (className) {
				const wrapperClassStr = classNameStr + 'Wrapper';
				const wrapperClass = mappingClasses[wrapperClassStr];

				if (wrapperClass) {
					this.left = new wrapperClass(left, parent);
				} else {
					this.left = {};
					this.left[classNameStr] = new className(left[classNameStr], parent);
				}
			}
		}
	}

	setRight(right, parent) {
		if (right) {
			const classNameStr = Object.keys(right)[0];
			const className = mappingClasses[classNameStr];

			if (className) {
				const wrapperClassStr = classNameStr + 'Wrapper';
				const wrapperClass = mappingClasses[wrapperClassStr];

				if (wrapperClass) {
					this.right = new wrapperClass(right, parent);
				} else {
					this.right = {};
					this.right[classNameStr] = new className(right[classNameStr], parent);
				}
			}
		}
	}

	serialize() {
		const output: any = super.serialize();
		const classNameStrLeft = this.left._type;

		if (output.left[classNameStrLeft].name === '') {
			output.left = undefined;
			output.operator = undefined;
		} else {
			output.left[classNameStrLeft] = this.left[classNameStrLeft].serialize();
		}

		const classNameStrRight = this.right._type;
		output.right[classNameStrRight] = this.right[classNameStrRight].serialize();

		return output;
	}
}

export class ExpressionNodeWrapper extends Root {
	_className = 'ExpressionNodeWrapper';

	_type: string = 'ExpressionNode';

	ExpressionNode: ExpressionNode = new ExpressionNode({}, this);

	constructor(input?: {ExpressionNode?: any}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}

	clear() {
		this[this._type] = new ExpressionNode({}, this);
	}

	serialize() {
		const output: any = super.serialize();
		delete output._type;
		return output;
	}
}

export class DefinitionNode extends Root {
	_className = 'DefinitionNode';

	name: string = '';
	nodePath: string = '';

	_member: I40FlatMember = new I40FlatMember();

	constructor(input?: {name?: string; nodePath?: string; _member?: I40FlatMember}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}
}

export class DefinitionNodeWrapper extends Root {
	_className = 'DefinitionNodeWrapper';

	_type: string = 'DefinitionNode';

	DefinitionNode: DefinitionNode = new DefinitionNode({}, this);

	constructor(input?: {DefinitionNode?: any}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}

	clear() {
		this[this._type] = new DefinitionNode({}, this);
	}

	serialize() {
		const output: any = super.serialize();
		delete output._type;
		return output;
	}
}

export class DefinitionNodeNotFound extends Root {
	_className = 'DefinitionNodeNotFound';

	name: string = '';
	nodePath: string = '';

	constructor(input?: {name?: string; nodePath?: string}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (input?.name) this.nodePath = input.name;
		if (!inherited) {
			super.init(input);
		}
	}
}

export class DefinitionNodeNotFoundWrapper extends Root {
	_className = 'DefinitionNodeNotFoundWrapper';

	_type: string = 'DefinitionNodeNotFound';

	DefinitionNodeNotFound: DefinitionNodeNotFound = new DefinitionNodeNotFound({}, this);

	constructor(input?: {DefinitionNodeNotFound?: any}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}

	clear() {
		this[this._type] = new DefinitionNodeNotFound({}, this);
	}

	serialize() {
		const output: any = super.serialize();
		delete output._type;
		return output;
	}
}

export class ExpressionListNode extends Root {
	_className = 'ExpressionListNode';

	public expressions: any[] = [];

	constructor(input?: {expressions?: any}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}

	setExpressions(expressions: any[], parent) {
		if (expressions) {
			this.expressions = expressions.map((expression) => {
				const classNameStr = Object.keys(expression)[0];
				const className = mappingClasses[classNameStr];
				if (className) {
					const wrapperClassStr = classNameStr + 'Wrapper';
					const wrapperClass = mappingClasses[wrapperClassStr];
					let exp: any;

					if (wrapperClass) {
						exp = new wrapperClass(expression, parent);
					} else {
						exp = {};
						exp[classNameStr] = new className(expression[classNameStr], parent);
					}

					return exp;
				} else {
					return expression;
				}
			});
		}
	}

	serializeExpressions() {
		return this.expressions.map((e) => {
			return e.serialize();
		});
	}

	expressionsToFormControl() {
		return this.rootArrayToFormControl(this.expressions);
	}
}

export class ExpressionListNodeWrapper extends Root {
	_className = 'ExpressionListNodeWrapper';

	_type: string = 'ExpressionListNode';

	ExpressionListNode: ExpressionListNode = new ExpressionListNode({}, this);

	constructor(input?: {ExpressionListNode?: any}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}

	clear() {
		this[this._type] = new ExpressionListNode({}, this);
	}

	serialize() {
		const output: any = super.serialize();
		delete output._type;
		return output;
	}
}

export class ConditionExpressionListNode extends Root {
	_className = 'ConditionExpressionListNode';

	public expressions: any[] = [];
	public operator: '&&' | '||' = '&&';

	constructor(input?: {expressions?: any; operator?: '&&' | '||'}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}

	setExpressions(expressions: any[], parent) {
		if (expressions) {
			this.expressions = expressions.map((expression) => {
				const classNameStr = Object.keys(expression)[0];
				const className = mappingClasses[classNameStr];
				if (className) {
					const wrapperClassStr = classNameStr + 'Wrapper';
					const wrapperClass = mappingClasses[wrapperClassStr];
					let exp: any;

					if (wrapperClass) {
						exp = new wrapperClass(expression, parent);
					} else {
						exp = {};
						exp[classNameStr] = new className(expression[classNameStr], parent);
					}

					return exp;
				} else {
					return expression;
				}
			});
		}
	}

	serializeExpressions() {
		return this.expressions.map((e) => {
			return e.serialize();
		});
	}

	expressionsToFormControl() {
		return this.rootArrayToFormControl(this.expressions);
	}
}

export class ConditionExpressionListNodeWrapper extends Root {
	_className = 'ConditionExpressionListNodeWrapper';

	_type: string = 'ConditionExpressionListNode';

	ConditionExpressionListNode: ConditionExpressionListNode = new ConditionExpressionListNode({}, this);

	constructor(input?: {ConditionExpressionListNode?: any}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}

	clear() {
		this[this._type] = new ConditionExpressionListNode({}, this);
	}

	serialize() {
		const output: any = super.serialize();
		delete output._type;
		return output;
	}
}

export class LiteralNode extends Root {
	_className = 'LiteralNode';

	value: any = '';
	valueType: string = 'String';

	constructor(input?: {value?: any; valueType?: string}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
		if (Object.keys(input).length == 1) {
			this.valueType = Object.keys(input)[0];
			this.value = input[this.valueType].toString();
			this.valueType = this.valueType.charAt(0).toUpperCase() + this.valueType.slice(1);
		}
	}

	serialize() {
		const obj = {};
		obj[this.valueType] = this.value;
		return obj;
	}
}

export class LiteralNodeWrapper extends Root {
	_className = 'LiteralNodeWrapper';

	_type: string = 'LiteralNode';

	LiteralNode: LiteralNode = new LiteralNode({}, this);

	constructor(input: {LiteralNode?: any}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}

	clear() {
		this[this._type] = new LiteralNode({}, this);
	}

	serialize() {
		const output: any = super.serialize();
		delete output._type;
		return output;
	}
}

export class CastNode extends Root {
	_className = 'CastNode';

	name: string = '';
	definitionNode: DefinitionNode = new DefinitionNode({}, this);
	castMethod: string = '';

	_hasConfig = true;

	constructor(input?: {name?: any; definitionNode?: any; castMethod?: any}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (this._className === 'CastNode') {
			if (input) {
				super.init(input);
			}
		}
	}
}

export class CastNodeWrapper extends Root {
	_className = 'CastNodeWrapper';

	_type: string = 'CastNode';

	CastNode: CastNode = new CastNode({}, this);

	constructor(input: {CastNode?: any}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}

	clear() {
		this[this._type] = new CastNode({}, this);
	}

	serialize() {
		const output: any = super.serialize();
		delete output._type;
		return output;
	}
}

export class MultiNode extends Root {
	_className = 'MultiNode';

	name: string = '';
	nodes: DefinitionNode[] = [];
	aggregationMethod: string = '';

	_hasConfig = true;

	constructor(input?: {name?: any; nodes?: any; aggregationMethod?: any}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			if (input) {
				super.init(input);
			}
		}
	}

	setNodes(nodes, parent) {
		if (nodes && nodes.length) {
			this.nodes = nodes.map((node) => new DefinitionNodeWrapper(node, parent));
		}
	}
}

export class MultiNodeWrapper extends Root {
	_className = 'MultiNodeWrapper';

	_type: string = 'MultiNode';

	MultiNode: MultiNode = new MultiNode({}, this);

	constructor(input: {MultiNode?: any}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}

	clear() {
		this[this._type] = new MultiNode({}, this);
	}

	serialize() {
		const output: any = super.serialize();
		delete output._type;
		return output;
	}
}

export class IfExpressionNode extends Root {
	_className = 'IfExpressionNode';

	condition: ConditionExpressionListNodeWrapper = new ConditionExpressionListNodeWrapper({ConditionExpressionListNode: {expressions: [{ConditionExpressionListNode: {expressions: []}}]}}, this);
	thenExpression: ExpressionListNodeWrapper = new ExpressionListNodeWrapper({}, this);
	elseExpression: ExpressionListNodeWrapper = new ExpressionListNodeWrapper({}, this);

	constructor(input?: {condition?: any; thenExpression?: any; elseExpression?: any}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			if (input) {
				super.init(input);
			}
		}
	}

	serialize() {
		const output: any = super.serialize();
		if (output.elseExpression['ExpressionListNode'].expressions.length === 0) {
			delete output.elseExpression;
		}
		return output;
	}
}

export class IfExpressionNodeWrapper extends Root {
	_className = 'IfExpressionNodeWrapper';

	_type: string = 'IfExpressionNode';

	IfExpressionNode: IfExpressionNode = new IfExpressionNode({}, this);

	constructor(input: {IfExpressionNode?: any}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}

	clear() {
		this[this._type] = new IfExpressionNode({}, this);
	}

	serialize() {
		const output: any = super.serialize();
		delete output._type;
		return output;
	}
}

export class SourceCodeNode extends Root {
	_className = 'SourceCodeNode';

	value: string = '';

	constructor(input?: {value?: any}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}

	setValue(sourceCode, parent, input) {
		if (input.nodePath !== undefined && input.nodePath.length > 0) {
			if (input.name && input.name.includes('/Event/')) {
				const path = input.nodePath.split('/');
				path.shift();
				path[0] = 'event';
				this.value = '// ' + path.join('.');
			} else if (input.name && input.name.includes('/Command/')) {
				const path = input.nodePath.split('/');
				path.shift();
				path.shift();
				path[0] = 'command';
				this.value = '// ' + path.join('.');
			} else {
				this.value = '// ' + input.nodePath.replaceAll('/', '.');
			}
		} else {
			if (input.name !== undefined && input.name.length > 0) {
				this.value = '// ' + input.name.replaceAll('/', '.');
			} else {
				this.value = sourceCode;
			}
		}
	}
}

export class SourceCodeNodeWrapper extends Root {
	_className = 'SourceCodeNodeWrapper';

	_type: string = 'SourceCodeNode';

	SourceCodeNode: SourceCodeNode = new SourceCodeNode({}, this);

	constructor(input: {SourceCodeNode?: any}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}

	clear() {
		this[this._type] = new SourceCodeNode({}, this);
	}

	serialize() {
		const output: any = super.serialize();
		delete output._type;
		return output;
	}
}

export class FixedRateSchedulerNode extends Root {
	_className = 'FixedRateSchedulerNode';

	cronExpression: string = '';

	constructor(input?: {cronExpression?: any}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			if (input) {
				super.init(input);
			}
		}
	}
}

export class FixedRateSchedulerNodeWrapper extends Root {
	_className = 'FixedRateSchedulerNodeWrapper';

	_type: string = 'FixedRateSchedulerNode';

	FixedRateSchedulerNode: FixedRateSchedulerNode = new FixedRateSchedulerNode({}, this);

	constructor(input: {FixedRateSchedulerNode?: any}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}

	clear() {
		this[this._type] = new FixedRateSchedulerNode({}, this);
	}

	serialize() {
		const output: any = super.serialize();
		delete output._type;
		return output;
	}
}

export class FixedDelaySchedulerNode extends Root {
	_className = 'FixedDelaySchedulerNode';

	initialDelay: number = 1;
	period: number = 1;
	unit: 'NANOSECONDS' | 'MICROSECONDS' | 'MILLISECONDS' | 'SECONDS' | 'MINUTES' | 'HOURS' | 'DAYS' = 'SECONDS';

	constructor(
		input?: {initialDelay?: number; period?: number; unit?: 'NANOSECONDS' | 'MICROSECONDS' | 'MILLISECONDS' | 'SECONDS' | 'MINUTES' | 'HOURS' | 'DAYS'},
		parent?: Root,
		inherited: boolean = false,
	) {
		super(input, parent);
		if (!inherited) {
			if (input) {
				super.init(input);
			}
		}
	}
}

export class FixedDelaySchedulerNodeWrapper extends Root {
	_className = 'FixedDelaySchedulerNodeWrapper';

	_type: string = 'FixedDelaySchedulerNode';

	FixedDelaySchedulerNode: FixedDelaySchedulerNode = new FixedDelaySchedulerNode({}, this);

	constructor(input: {FixedDelaySchedulerNode?: any}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}

	clear() {
		this[this._type] = new FixedDelaySchedulerNode({}, this);
	}

	serialize() {
		const output: any = super.serialize();
		delete output._type;
		return output;
	}
}

export class TimeoutSchedulerNode extends Root {
	_className = 'TimeoutSchedulerNode';

	delay: number = 1;
	unit: 'NANOSECONDS' | 'MICROSECONDS' | 'MILLISECONDS' | 'SECONDS' | 'MINUTES' | 'HOURS' | 'DAYS' = 'SECONDS';

	constructor(input?: {delay?: number; period?: number; unit?: 'NANOSECONDS' | 'MICROSECONDS' | 'MILLISECONDS' | 'SECONDS' | 'MINUTES' | 'HOURS' | 'DAYS'}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			if (input) {
				super.init(input);
			}
		}
	}
}

export class TimeoutSchedulerNodeWrapper extends Root {
	_className = 'TimeoutSchedulerNodeWrapper';

	_type: string = 'TimeoutSchedulerNode';

	TimeoutSchedulerNode: TimeoutSchedulerNode = new TimeoutSchedulerNode({}, this);

	constructor(input: {TimeoutSchedulerNode?: any}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}

	clear() {
		this[this._type] = new TimeoutSchedulerNode({}, this);
	}

	serialize() {
		const output: any = super.serialize();
		delete output._type;
		return output;
	}
}

export class ChannelEventNode extends Root {
	_className = 'ChannelEventNode';

	event: string = '';

	constructor(input?: {event?: string}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			if (input) {
				super.init(input);
			}
		}
	}
}

export class ChannelEventNodeWrapper extends Root {
	_className = 'ChannelEventNodeWrapper';

	_type: string = 'ChannelEventNode';

	ChannelEventNode: ChannelEventNode = new ChannelEventNode({}, this);

	constructor(input: {ChannelEventNode?: any}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}

	clear() {
		this[this._type] = new ChannelEventNode({}, this);
	}

	serialize() {
		const output: any = super.serialize();
		delete output._type;
		return output;
	}
}

export const mappingClasses = {
	MappingRule,
	MappingDescription,
	MappingManagerResult,
	Info,
	ExpressionNode,
	ExpressionNodeWrapper,
	LiteralNode,
	LiteralNodeWrapper,
	DefinitionNode,
	DefinitionNodeWrapper,
	DefinitionNodeNotFound,
	DefinitionNodeNotFoundWrapper,
	ExpressionListNode,
	ExpressionListNodeWrapper,
	CastNode,
	CastNodeWrapper,
	MultiNode,
	MultiNodeWrapper,
	SourceCodeNode,
	SourceCodeNodeWrapper,
	FixedRateSchedulerNode,
	FixedRateSchedulerNodeWrapper,
	FixedDelaySchedulerNode,
	FixedDelaySchedulerNodeWrapper,
	TimeoutSchedulerNode,
	TimeoutSchedulerNodeWrapper,
	ChannelEventNode,
	ChannelEventNodeWrapper,
	IfExpressionNode,
	IfExpressionNodeWrapper,
	ConditionExpressionListNode,
	ConditionExpressionListNodeWrapper,
};
