import {CdkDragDrop} from '@angular/cdk/drag-drop';
import {Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatMenuTrigger} from '@angular/material/menu';
import {TranslateService} from '@ngx-translate/core';
import * as _ from 'lodash';
import {ClipboardService} from 'ngx-clipboard';
import {Observable} from 'rxjs';
import {debounceTime, map} from 'rxjs/operators';
import {AuthService} from '../../../core-services/auth.service';
import {CommunicationService} from '../../../core-services/communication.service';
import {MessageResponseService} from '../../../core-services/message-response.service';
import {Button} from '../../generic-dialog/generic-dialog.component';
import {I40TreeComponent} from '../i40-tree.component';
import {ContextAction, I40FlatMember, ModelRoot} from '../types';
import {I40TreeCrudService} from './i40-tree-crud.service';
import {I40TreeMemberEditorWrapper} from './i40-tree-member-editor/i40-tree-member-editor-wrapper';
import {GlobalService} from '../../../core-services/global.service';
import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {FloatLabelType, MatFormFieldModule} from '@angular/material/form-field';

@Component({
	selector: 'i40-tree-crud',
	templateUrl: './i40-tree-crud.component.html',
	styleUrls: ['../i40-tree.component.scss', './i40-tree-crud.component.scss'],
	providers: [I40TreeCrudService],
})
export class I40TreeCrudComponent extends I40TreeComponent implements OnInit {
	@Input() direction: 'horizontal' | 'vertical' = 'horizontal';

	@Output('onContextAction') onContextAction: EventEmitter<ContextAction> = new EventEmitter<ContextAction>();

	@ViewChild(MatMenuTrigger) memberMenu: MatMenuTrigger;
	@ViewChild('treeEditorWrapper') editorWrapper: I40TreeMemberEditorWrapper;

	tellEditor: EventEmitter<any> = new EventEmitter();

	menusState = 'closed';
	memberMenuPosition = {x: '0px', y: '0px'};

	deleteModalOpen = false;

	constructor(
		public authSrv: AuthService,
		_clipboardService: ClipboardService,
		public tsrv: I40TreeCrudService,
		public dialog: MatDialog,
		public translateSrv: TranslateService,
		public com: CommunicationService,
		public msgResponse: MessageResponseService,
		public globalSrv: GlobalService,
	) {
		super(tsrv, _clipboardService, globalSrv);
	}

	ngOnInit() {
		super.ngOnInit();

		this.tsrv.closeMemberEditor.subscribe(() => {
			this.selectedObject = this.tsrv.flatMembers[0];
		});

		this.tsrv.parentDataSrv.editorStatusSubject
			.pipe(
				debounceTime(500),
				map((status) => {
					if (!this.tsrv.isValid && status.includes('valid')) {
						this.tsrv.isValid = true;
						this.tsrv.refreshModel();
					}
				}),
			)
			.subscribe();

		setTimeout(() => {
			this.memberMenu.menuOpened.subscribe(() => {
				this.menusState = 'open';
			});

			this.memberMenu.menuClosed.subscribe(() => {
				this.menusState = 'closed';
			});
		});
	}

	@HostListener('window:keydown', ['$event'])
	onKeyPress($event: KeyboardEvent) {
		const selectedText = window.getSelection().toString();
		const inputs = ['input', 'textarea'];
		const activeElement = document.activeElement;
		const activeInput = activeElement && inputs.includes(activeElement.tagName.toLowerCase());

		if (!selectedText) {
			if ($event[this.metaKey] && !$event['shiftKey'] && $event.key && $event.key.toLowerCase() === 'c') {
				if (this.selectedObject !== null && this.selectedObject._isValid) {
					console.log('TREE (COPY): CTRL+C');
					this.copyNode(this.selectedObject);
					return false;
				}
			}

			if (!activeInput) {
				if ($event[this.metaKey] && $event.key && $event.key.toLowerCase() === 'v') {
					if (this.clipboardObject) {
						console.log('TREE (PASTE): CTRL+V');
						this.paseNode(this.selectedObject);
						return false;
					}
				}

				if (this.menusState === 'closed') {
					if ($event.key === 'ArrowUp') {
						this.selectUp();
						return false;
					}

					if ($event.key === 'ArrowDown') {
						this.selectDown();
						return false;
					}

					if ($event.key === 'ArrowLeft') {
						if (this.selectedObject !== null) {
							if (this.selectedObject._hasChildren) {
								if (!this.selectedObject._collapsed) {
									this.nodeClick('toggle', this.selectedObject);
								} else {
									if (this.selectedObject._flatParentRef) {
										this.nodeClick('select', this.selectedObject._flatParentRef);
									}
								}
							}
							if (!this.selectedObject._hasChildren) {
								if (this.selectedObject._flatParentRef) {
									this.nodeClick('select', this.selectedObject._flatParentRef);
								}
							}
						}
						return false;
					}

					if ($event.key === 'ArrowRight') {
						if (this.selectedObject !== null && this.selectedObject._hasChildren && this.selectedObject._collapsed) {
							this.nodeClick('toggle', this.selectedObject);
						}
						return false;
					}
				}
			}

			if ($event[this.modifierKey] && $event.key && $event.key.toLowerCase() === 'n') {
				console.log('NEW MENU');
				if (this.selectedObject !== null && this.selectedObject._isValid) {
					this.openMenu(
						new MouseEvent('contextmenu', {
							clientX: this.viewPort.elementRef.nativeElement.getElementsByClassName('selected')[0].getBoundingClientRect().left,
							clientY: this.viewPort.elementRef.nativeElement.getElementsByClassName('selected')[0].getBoundingClientRect().top,
						}),
						this.selectedObject,
					);
					return false;
				}
			}

			if ($event[this.metaKey] && $event.key && $event.key.toLowerCase() === 'x') {
				console.log('TREE (CUT): CTRL+X');
				if (this.selectedObject !== null && this.selectedObject._isValid) {
					this.cutNode(this.selectedObject);
					return false;
				}
			}

			if ($event[this.metaKey] && $event.key && $event.key.toLowerCase() === 'd') {
				console.log('TREE (DUPLICATE): CTRL+D');
				if (this.selectedObject !== null && this.selectedObject._isValid) {
					this.copyNode(this.selectedObject);
					this.paseNode(this.selectedObject._flatParentRef);
					return false;
				}
			}
		}

		if (!this.deleteModalOpen) {
			if ($event[this.metaKey] && $event.key && $event.key.toLowerCase() === 'delete') {
				console.log('TREE (DELETE): CTRL+DEL');
				if (this.selectedObject !== null && this.selectedObject._isValid) {
					this.menuDeteleNode(this.selectedObject);
					return false;
				}
			}
		}
	}

	updateTree() {
		this.tsrv.updateTreeRequest.emit(true);
	}

	saveTree(): Observable<any> {
		return this.tsrv.saveTree();
	}

	cloneTree(): Observable<any> {
		return this.tsrv.addCloneTree();
	}

	releaseTree(version: string): Observable<any> {
		return this.tsrv.releaseTree(version);
	}

	unsavedDataWarning() {
		return this.msgResponse.customDialogMessage(
			this.translateSrv.instant('i18n.unsavedData'),
			this.translateSrv.instant('i18n.applyData'),
			[
				new Button(this.translateSrv.instant('i18n.cancel'), 'cancel', 'flat', 'accent', 'start'),
				new Button(this.translateSrv.instant('i18n.no'), 'no', 'flat', 'accent', 'end'),
				new Button(this.translateSrv.instant('i18n.apply'), 'apply', 'flat', 'accent', 'end'),
			],
			{
				apply: (dialogRef, resolve) => {
					this.tsrv.updateTreeRequest.emit(true);
					dialogRef.close();
					resolve(true);
				},
				no: (dialogRef, resolve) => {
					this.tsrv.cancelUpdateRequest.emit(true);
					dialogRef.close();
					resolve(true);
				},
				cancel: (dialogRef, resolve) => {
					dialogRef.close();
					resolve(false);
				},
			},
		);
	}

	invalidDataWarning() {
		return this.msgResponse.customDialogMessage(
			this.translateSrv.instant('i18n.invalidData'),
			this.translateSrv.instant('i18n.invalidData'),
			[new Button(this.translateSrv.instant('i18n.discard'), 'discard', 'flat', 'accent', 'start'), new Button(this.translateSrv.instant('i18n.fix'), 'fix', 'flat', 'accent', 'end')],
			{
				discard: (dialogRef, resolve) => {
					if (this.selectedObject._new) {
						this.tsrv.deleteNode(this.selectedObject);
					} else {
						this.tsrv.cancelUpdateRequest.emit(true);
					}
					dialogRef.close();
					resolve(true);
				},
				fix: (dialogRef, resolve) => {
					dialogRef.close();
					resolve(false);
				},
			},
		);
	}

	validateEditorThen(callback) {
		const editorStatus = this.dataSrv.editorStatus;

		if (editorStatus.length === 0 || editorStatus.includes('clean')) {
			callback();
		} else {
			if (editorStatus.includes('invalid')) {
				this.invalidDataWarning().then((res) => {
					if (res) {
						this.validateEditorThen(callback);
					}
				});
			} else {
				this.unsavedDataWarning().then((res) => {
					if (res) {
						this.validateEditorThen(callback);
					}
				});
			}
		}
	}

	modelInfo() {
		this.validateEditorThen(() => {
			this.selectedObject = this.tsrv.flatMembers[0];
		});
	}

	nodeClickSelect(member) {
		let parentPathIndex = 0;
		let childOfSelectedObject = false;

		// when selecting a child node of currently selected member make sure to account for when the parent ID changes thus changing the children path
		if (this.selectedObject) {
			childOfSelectedObject = this.selectedObject._hasChildren && member._path.includes(this.selectedObject.id);

			if (childOfSelectedObject) {
				parentPathIndex = member._path.indexOf(this.selectedObject.id);
			}
		}

		if (this.loaded) {
			this.validateEditorThen(() => {
				setTimeout(() => {
					if (childOfSelectedObject) {
						member._path[parentPathIndex] = this.selectedObject.id;
						member = this.tsrv.getNodeByPath(member._path.join('/'));
					}
					super.nodeClickSelect(member);
				});
			});
		} else {
			super.nodeClickSelect(member);
		}
	}

	removeContextListener(offEvent: any) {
		offEvent.preventDefault();
		this.memberMenu.closeMenu();
	}

	openMenu(event: MouseEvent, member: I40FlatMember) {
		event.preventDefault();

		let isArrayMember = false;
		if (member._memberPath.length > 1) {
			isArrayMember = member._memberPath[member._memberPath.length - 2].substr(0, 5) === 'Array';
		}

		if (!member._new && member._memberType && !isArrayMember && this.tsrv.isValid) {
			this.memberMenuPosition.x = event.clientX + 'px';
			this.memberMenuPosition.y = event.clientY + 'px';
			this.memberMenu.menuData = {member: member, data: {member: member}};

			this.memberMenu.openMenu();

			document.getElementsByClassName('cdk-overlay-backdrop')[0].addEventListener('contextmenu', this.removeContextListener.bind(this));
		}
	}

	closeMenu() {
		this.memberMenu.closeMenu();
		document.getElementsByClassName('cdk-overlay-backdrop')[0].removeEventListener('contextmenu', this.removeContextListener.bind(this));
	}

	getAvailableMemberTypes(member) {
		const definitionType = member.definitionType.toLowerCase();

		switch (definitionType) {
			case 'event':
			case 'command':
			case 'variable':
			case 'array':
			case 'commandparameters':
			case 'commandreply':
			case 'list':
				return ['Variable', 'Array', 'List', 'Property'];
			case 'property':
				return ['Variable', 'Array', 'List'];
			case 'model':
			default:
				return ['Variable', 'Event', 'Command', 'Array', 'List', 'Property'];
		}
	}

	menuAddNode(member, type) {
		this.closeMenu();

		this.validateEditorThen(() => {
			this.tsrv.insertNode(member, null, type).then(
				(node) => {
					this.selectedObject = node;
					// console.log(this.selectedObject);
				},
				(err) => {
					this.msgResponse.customDialogMessage(err.title, err.message);
				},
			);
		});
	}

	paseNode(member) {
		this.tsrv.insertNode(member, _.clone(this.clipboardObject)).then(
			(node) => {
				// cannot think of anything right meow
			},
			(err) => {
				this.msgResponse.customDialogMessage(err.title, err.message);
			},
		);
	}

	cutNode(member) {
		this.copyNode(member);
		this.tsrv.deleteNode(member);
	}

	menuCopyNode(member) {
		this.closeMenu();
		this.copyNode(member);
	}

	menuCutNode(member) {
		this.closeMenu();
		this.cutNode(member);
	}

	menuPasteNode(member) {
		this.closeMenu();
		if (this.clipboardObject) {
			this.paseNode(member);
		} else {
			this.msgResponse.customDialogMessage('Clipboard Empty', 'You need to copy something first');
		}
	}

	menuDeteleNode(member) {
		this.deleteModalOpen = true;
		this.msgResponse.customDialogMessage(
			this.translateSrv.instant('i18n.deleteAction'),
			this.translateSrv.instant('i18n.deleteElement', {value: 'node'}),
			[new Button(this.translateSrv.instant('i18n.delete'), 'delete', 'flat', 'accent', 'start'), new Button(this.translateSrv.instant('i18n.cancel'), 'cancel', 'flat', 'accent', 'end')],
			{
				delete: (dialogRef) => {
					dialogRef.close();
					this.tsrv.deleteNode(member);
					this.deleteModalOpen = false;
				},
				cancel: (dialogRef) => {
					dialogRef.close();
					this.deleteModalOpen = false;
				},
			},
		);
	}

	// drag drop nodes around the tree
	onNodeDrop(event: CdkDragDrop<string[]>) {
		const member: I40FlatMember = event.item.data || null;

		if (event.previousIndex !== member._visibleIndex) {
			const diff = event.previousIndex - member._visibleIndex;
			event.previousIndex = member._visibleIndex;
			event.currentIndex = event.currentIndex - diff;
		}

		let replaces = this.tsrv.visibleFlatMembers[event.currentIndex];

		if (member._flatParentRef === replaces._flatParentRef) {
			if (member._memberParent.id === ModelRoot.id) {
				this.tsrv.inputModel.members = this.tsrv.moveItemInMap(this.tsrv.inputModel.members, member._levelIndex, replaces._levelIndex);
			} else {
				member._memberParent.members = this.tsrv.moveItemInMap(member._memberParent.members, member._levelIndex, replaces._levelIndex);
			}

			this.tsrv.refreshModel();

			this.tsrv.isDirty = true;
		}
	}
}
