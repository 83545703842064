import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from '../../../core-services/auth.service';
import {DeviceTypeMapping} from '../device-type.model';
import {DeviceTypeDataService} from '../device-type-data.service';
import {GlobalService} from '../../../core-services/global.service';

@Component({
	selector: 'app-device-type-description',
	templateUrl: './device-type-description.component.html',
	styleUrls: ['./device-type-description.component.scss'],
})
export class DeviceTypeDescriptionComponent implements OnInit {
	constructor(
		public authSrv: AuthService,
		public dialog: MatDialog,
		public translate: TranslateService,
		public dataSrv: DeviceTypeDataService,
		public globalSrv: GlobalService,
		public cdRef: ChangeDetectorRef,
	) {}

	ngOnInit(): void {}

	async addNewMapping() {
		const mapping = new DeviceTypeMapping();
		this.dataSrv.deviceTypeResult.push(mapping, 'mappings');
		this.cdRef.detectChanges();
	}
}
