import {Injectable} from '@angular/core';
import {EditorStatusService} from '../../core-services/editor-status.service';
import * as _ from 'lodash';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class InformationModelService implements EditorStatusService {
	_editorStatus = ['clean'];

	editorStatusSubject: BehaviorSubject<string[]> = new BehaviorSubject(this._editorStatus);

	set editorStatus(status: string[]) {
		if (_.difference(this._editorStatus, status).length) {
			this.editorStatusSubject.next(status);
			this._editorStatus = status;
		}
	}

	get editorStatus() {
		return this._editorStatus;
	}

	constructor() {}
}
