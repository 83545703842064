import {ArtifactInfo, Root} from '../../../data-models/core-model.model';

export class JsonModelGeneratorItem extends ArtifactInfo {
	items: JsonToModelItem[] = [];

	constructor(input?: {identifier?: any; externalIdentifier?: any; externalDescriptor?: any; items?: any[]}, parent?: Root) {
		super(input, parent);
		if (this.constructor.name === 'JsonModelGeneratorItem') {
			super.init(input);
		}
	}

	setItems(items, parent) {
		if (items) {
			this.items = items.map((r: any) => {
				return new JsonToModelItem(r, parent);
			});
		}
	}

	serializeItems() {
		return this.items.map((item) => {
			return item.serialize();
		});
	}

	itemsToFormControl() {
		return this.rootArrayToFormControl(this.items);
	}

	serialize(): any {
		let output = super.serialize();

		if (output.identifier.id === '') {
			delete output.identifier;
		}

		return output;
	}
}

export class JsonToModelItem extends Root {
	definitionType: 'Model' | 'Event' | 'Variable' | 'Command' = 'Variable';
	definitionName: string = '';
	inputJson: string = '';
	replyInputJson: string = '';

	constructor(input?: {definitionType?: 'Model' | 'Event' | 'Variable' | 'Command'; definitionName?: string; inputJson?: string}, parent?: Root) {
		super(input, parent);
		if (this.constructor.name === 'JsonToModelItem') {
			super.init(input);
		}
	}
}
