import {Root} from '../../data-models/core-model.model';

export class PluginManagerListItem extends Root {
	name: string = '';
	pluginType: string = '';
	description: string = '';
	state: string = '';
	version: string = '';
	locations: PluginLocation[] = [];
	currentAction?: string; // set action inside component menu for quick access
	locationData?: any; // relevant data from parent component

	constructor(input?: {name?: string; pluginType?: string; description?: string; state?: string; version?: string; locations?: PluginLocation[]}, parent?: Root) {
		super(input, parent);
		if (this.constructor.name === 'PluginManagerListItem') {
			super.init(input);
		}
	}

	get id() {
		return this.name.replace(/\s+/, '_');
	}
}

export class PluginLocation {
	public location: string;
	public action: string;
}
