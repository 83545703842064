import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {environment} from '../../../environments/environment';
import {InstanceDeploymentInfoView, InstanceDeploymentSettings} from './deployment-model';
import {map} from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class DeploymentService {
	isNewDeploymentConfiguration = false;
	hasArgs: boolean = false;

	private instanceName = new Subject<string>();

	constructor(private http: HttpClient) {}

	setInstanceName(iName: string) {
		this.instanceName.next(iName);
	}

	//Add new deployment setting
	addInstanceDeploymentSetting(instanceDeploymentSetting: InstanceDeploymentSettings) {
		return this.http.post(`${environment.apiHost + environment.apiUrl}/instanceDeploymentSettings`, instanceDeploymentSetting.serialize());
	}

	//Update existing deployment setting
	updateInstanceDeploymentSetting(instanceDeploymentSetting: InstanceDeploymentSettings) {
		return this.http.put(`${environment.apiHost + environment.apiUrl}/instanceDeploymentSettings`, instanceDeploymentSetting.serialize());
	}

	//Get deployment Info with state
	getInstanceDeploymentInfo(): Observable<InstanceDeploymentInfoView[]> {
		return this.http.get<any[]>(`${environment.apiHost + environment.apiUrl}/instanceDeploymentInfo`).pipe(map((results) => results.map((result) => new InstanceDeploymentInfoView(result))));
	}

	//Remove Deployment Setting
	removeInstanceDeploymentSettingById(id: string) {
		return this.http.delete<InstanceDeploymentSettings>(`${environment.apiHost + environment.apiUrl}/instanceDeploymentSettings/${id}`);
	}

	//Get deployment setting by id
	getInstanceDeploymentSettingById(id: string) {
		return this.http.get<InstanceDeploymentSettings>(`${environment.apiHost + environment.apiUrl}/instanceDeploymentSettings/${id}`);
	}

	//Deploy instance
	deployInstance(id: string): Observable<any> {
		return this.http.post(`${environment.apiHost + environment.apiUrl}/instance/${id}/deploy`, id);
	}

	//Start instance
	startInstance(id: string): Observable<any> {
		return this.http.post(`${environment.apiHost + environment.apiUrl}/instance/${id}/start`, id);
	}

	//Stop Instance
	stopInstance(id: string): Observable<any> {
		return this.http.post(`${environment.apiHost + environment.apiUrl}/instance/${id}/stop`, id);
	}

	//Remove deployment from instance while maintaining deloyment settings
	removeDeployment(id: string, deleteLogs?: boolean, skipInterceptor: boolean = true): Observable<any> {
		if (skipInterceptor) {
			const headers = {
				headers: new HttpHeaders({
					'Content-Type': 'application/x-www-form-urlencoded',
					skipInterceptor: 'true',
				}),
			};
			return this.http.post(`${environment.apiHost + environment.apiUrl}/removeDeployment/${id}?deleteLogs=${deleteLogs}`, 'deleteLogs=' + deleteLogs, headers);
		} else {
			return this.http.post(`${environment.apiHost + environment.apiUrl}/removeDeployment/${id}?deleteLogs=${deleteLogs}`, 'deleteLogs=' + deleteLogs);
		}
	}

	getAwsAccountResources(endpointId) {
		return this.http.get<any>(`${environment.apiHost + environment.apiUrl}/deployment/aws/account-resources/${endpointId}`);
	}

	getAwsVpcResources(endpointId, vpcId) {
		return this.http.get<any>(`${environment.apiHost + environment.apiUrl}/deployment/aws/${endpointId}/vpc-resources/${vpcId}`);
	}

	getDeploymentTypes() {
		return this.http.get<any>(`${environment.apiHost + environment.apiUrl}/deployment/types`);
	}
}
