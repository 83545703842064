import {AfterViewChecked, Component, ElementRef, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {MessageResponseService} from '../../core-services/message-response.service';
import {Router} from '@angular/router';
import {ClipboardService} from 'ngx-clipboard';

@Component({
	selector: 'progress-log',
	templateUrl: './progress-log.component.html',
	styleUrls: ['./progress-log.component.scss'],
})
export class ProgressLogComponent implements OnInit, AfterViewChecked {
	constructor(
		private msgSrv: MessageResponseService,
		public router: Router,
		private _clipboardService: ClipboardService,
	) {}

	@Input() messages: any[] = [];
	@Input() finished: boolean;
	@ViewChild('content') content: ElementRef;
	canClose: boolean = false;

	@HostListener('window:keydown', ['$event'])
	onKeyPress($event: KeyboardEvent) {
		if ($event.key && $event.key.toLowerCase() === 'escape') {
			this.close();
		}
	}

	ngOnInit(): void {}

	ngAfterViewChecked() {
		if (this.content) {
			this.scrollToBottom();
		}
	}

	private scrollToBottom() {
		this.content.nativeElement.scroll({
			top: this.content.nativeElement.scrollHeight,
			left: 0,
			behavior: 'smooth',
		});
	}

	copy() {
		this._clipboardService.copyFromContent(this.content.nativeElement.innerText);
		this._clipboardService.destroy();
	}

	close() {
		this.msgSrv.onLoading.next({command: 'finish'});
		this.canClose = true;
		this.router.navigate(['/']);
	}
}
