import {DoCheck, EventEmitter, Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {IdentifierFactory, IManagerRequest} from '../data-models/core-model.model';
import {ErrorDialogComponent, ErrorDialogData} from '../shared/error-dialog/error-dialog.component';
import {FeedbackSnackbarComponent} from '../shared/feedback-snackbar/feedback-snackbar.component';
import {Observable, Subject} from 'rxjs';
import {Button, DialogData, DialogInput, GenericDialogComponent} from '../shared/generic-dialog/generic-dialog.component';
import {LoginPopupComponent} from '../shared/login-popup/login-popup.component';
import {IFrameComponent} from '../shared/iframe/iframe.component';
import {AboutUnifierComponent} from '../shared/about-unifier/about-unifier.component';
import {GlobalService} from './global.service';
import {LicenseComponent} from '../shared/license/license.component';
import {AuthService} from './auth.service';

@Injectable({
	providedIn: 'root',
})
export class MessageResponseService implements DoCheck {
	onLoading = new EventEmitter<any>();
	weAreInAModal = false;
	overlordReference = window.parent;
	loginPopupOpen = false;

	constructor(
		public snackBar: MatSnackBar,
		public dialog: MatDialog,
		public translate: TranslateService,
		private translateSrv: TranslateService,
		public globalSrv: GlobalService,
		public authSrv: AuthService,
	) {}

	ngDoCheck() {}

	// Show Snackbar success
	showSuccess(message: string): void {
		this.snackBar.openFromComponent(FeedbackSnackbarComponent, {
			data: {text: message, type: 'ok'},
			duration: 5000,
			panelClass: ['ok-snackbar'],
			verticalPosition: 'bottom',
			horizontalPosition: 'center',
		});
	}

	// Show Snackbar success
	showWarning(message: string): void {
		this.snackBar.openFromComponent(FeedbackSnackbarComponent, {
			data: {text: message, type: 'error'},
			duration: 0,
			panelClass: ['warning-snackbar', 'mat-warn'],
			verticalPosition: 'bottom',
			horizontalPosition: 'center',
		});
	}

	// Show error dialog
	showError(message: string, title?: string): void {
		this.dialog.open(ErrorDialogComponent, {
			data: new ErrorDialogData(title ?? '', message),
		});
	}

	// Show SnackBar in use error
	showUsed(error): void {
		const usedBy = [];
		if (error.usedBy) {
			error.usedBy.forEach((element) => {
				const id = new IdentifierFactory().createIdFromIdentifier(element.id);
				usedBy.push(element.artifactType + ' ' + id);
			});
			this.showWarning((this.translate.instant('i18n.usedIn') + ': ' + usedBy.toString()).split(',').join(', '));
		}
	}

	handleSaveResponse<T extends IManagerRequest>(_response: Observable<any>, artifactName: string, onError?: Subject<any>) {
		_response.subscribe({
			next: (response: T) => {
				this.showSuccess(this.translate.instant('i18n.entrySaved', {value: artifactName}));
			},
			error: (message) => {
				if (this.globalSrv.debugMode) {
					console.error(message);
				}
				//TODO handle save errors in a uniform way
				if (message.compilationResult && message.compilationResult.type === 'CompilationFailed') {
					let result = [];
					message.compilationResult.errors.forEach((e) => {
						result.push(`Line: ${e.line}: ${e.text}`);
					});
					this.showError(result.join('\n\r'), this.translate.instant('i18n.compileFailed'));
				}
				if (onError) {
					onError.next(message);
				} else {
					this.showWarning(this.translate.instant('i18n.entryNotValid', {value: artifactName}));
				}
			},
			complete: () => {
				this.onLoading.next({command: 'stop'});
			},
		});
	}

	customDialogMessage(title: string, text: string, buttons?: any[], actions?: any, hasInput?: boolean, formFields?: DialogInput[], panelClass?: string, disableClose?: boolean) {
		return new Promise((resolve) => {
			if (buttons === undefined) {
				buttons = [new Button(this.translateSrv.instant('i18n.close'), 'close', 'flat', 'accent', 'center')];
			}

			const dialogRef = this.dialog.open(GenericDialogComponent, {
				panelClass: panelClass ?? 'i40-generic-dialog',
				data: new DialogData(title, buttons, text, hasInput, formFields),
			});

			dialogRef.disableClose = disableClose;

			dialogRef.afterOpened().subscribe((result) => {
				if (actions) {
					if (actions['opened'] !== undefined) {
						actions['opened'](dialogRef, result);
					}
				}
			});

			(<GenericDialogComponent>dialogRef.componentInstance).buttonClicked.subscribe((btn_id: string) => {
				if (actions) {
					if (actions[btn_id] !== undefined) {
						actions[btn_id](dialogRef, resolve);
					}
				} else {
					if (btn_id === 'close') {
						dialogRef.close();
						resolve(true);
					}
				}
			});

			(<GenericDialogComponent>dialogRef.componentInstance).checboxSelected.subscribe((value: string) => {
				dialogRef.componentInstance.dialogData.formFields[0].selectedValue = value;
			});
		});
	}

	unsavedChangesClose(callback) {
		return this.customDialogMessage(
			this.translateSrv.instant('i18n.unsavedData'),
			this.translateSrv.instant('i18n.unsavedDataClose'),
			[new Button(this.translateSrv.instant('i18n.leave'), 'leave', 'flat', 'accent', 'start'), new Button(this.translateSrv.instant('i18n.cancel'), 'stay', 'flat', 'accent', 'end')],
			{
				leave: (dialogRef, resolve) => {
					dialogRef.close();
					callback(resolve);
				},
				stay: (dialogRef, resolve) => {
					dialogRef.close();
				},
			},
		);
	}

	loginPopup() {
		if (!this.loginPopupOpen) {
			this.loginPopupOpen = true;

			return new Promise((resolve) => {
				const dialogRef = this.dialog.open(LoginPopupComponent, {
					backdropClass: 'login-popup',
				});

				dialogRef.disableClose = true;

				dialogRef.afterClosed().subscribe((result) => {
					this.authSrv.forceReCeheckUser().subscribe((res) => {
						this.loginPopupOpen = false;
						resolve(true);
					});
				});
			});
		}
	}

	addElementPopup(route) {
		const dialogRef = this.dialog.open(IFrameComponent, {
			data: {
				route: route,
			},
			panelClass: 'add-element-popup',
		});

		return dialogRef.afterClosed();
	}

	aboutPopup() {
		const dialogRef = this.dialog.open(AboutUnifierComponent, {
			panelClass: ['about-unifier-popup', 'mat-elevation-z8'],
			backdropClass: 'about-unifier-backdrop',
		});

		return dialogRef.afterClosed();
	}

	licensePopup() {
		const dialogRef = this.dialog.open(LicenseComponent, {
			panelClass: ['unifier-license-popup', 'mat-elevation-z8'],
			backdropClass: 'unifier-license-backdrop',
		});

		return dialogRef.afterClosed();
	}
}
