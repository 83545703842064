<div style="display: flex; flex-direction: row; height: 100%; flex: 1 0 auto">
	<div *ngIf="loaded" style="height: 100%; width: 100%; overflow: hidden">
		<div class="tree-header" [ngClass]="{'search-visible': searchVisible}">
			<mat-form-field floatLabel="auto" *ngIf="searchVisible" class="search-wrapper">
				<input matInput type="text" placeholder="{{ 'i18n.searchModel' | translate }}" [(ngModel)]="searchString" (ngModelChange)="onSearch()" #searchField="matInput" />
				<button mat-button *ngIf="searchString" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
					<mat-icon>close</mat-icon>
				</button>
			</mat-form-field>
			<button mat-icon-button matTooltip="{{ 'i18n.searchModel' | translate }}" (click)="toggleFilter()">
				<mat-icon>search</mat-icon>
			</button>
			<button mat-icon-button matTooltip="{{ 'i18n.toggle' | translate }}" (click)="tsrv.toggleCollapseAll()">
				<mat-icon>unfold_more</mat-icon>
			</button>
		</div>
		<div [ngStyle]="{'padding-top': searchVisible ? '50px' : '15px'}" style="height: 100%; transition: padding-top ease 0.3s">
			<cdk-virtual-scroll-viewport
				[cdkDropListSortingDisabled]="true"
				itemSize="24"
				[ngStyle]="{height: searchVisible ? 'calc(100% - 50px)' : 'calc(100% - 15px)'}"
				cdkDropList
				class="tree-wrapper"
				id="{{ uniqueID }}"
				[cdkDropListEnterPredicate]="canDrop"
				(cdkDropListExited)="onSourceListExited($event)"
				(cdkDropListEntered)="onSourceListEntered($event)"
			>
				<ng-container #container *cdkVirtualFor="let member of tsrv.visibleFlatMembers; trackBy: getIndex">
					<i40-tree-member
						cdkDrag
						[cdkDragData]="member"
						(cdkDragStarted)="dragStart($event)"
						(cdkDragMoved)="moved($event)"
						(cdkDragEntered)="entered($event)"
						(cdkDragReleased)="onRelease($event)"
						[cdkDragDisabled]="!enableDrag || member.definitionType === 'Model' || !authSrv.canEdit"
						[ngClass]="{selected: selectedObject ? member._uniqueIndex === selectedObject._uniqueIndex : false}"
						[member]="member"
						[attr.data-path]="member._path.join('/')"
						[attr.data-fullPath]="mappingDataSrv.getMappingMemberNamePath(member)"
						[attr.data-memberPath]="member._memberPath.join('/')"
						(nodeClick)="nodeClick($event, member)"
					>
					</i40-tree-member>
					<i40-tree-member
						*ngIf="draggedItem === member"
						[member]="member"
						[attr.data-path]="member._path.join('/')"
						[attr.data-fullPath]="mappingDataSrv.getMappingMemberNamePath(member)"
						[attr.data-memberPath]="member._memberPath.join('/')"
					>
					</i40-tree-member>
				</ng-container>
			</cdk-virtual-scroll-viewport>
		</div>
	</div>
</div>
