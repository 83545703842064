<mat-card appearance="outlined" fxLayout="column" *ngIf="layer.properties" [ngStyle]="{display: visible ? 'flex' : 'none'}">
	<app-group
		class="layer-group"
		[selectedObject]="selectedObject"
		[layer]="layer.label"
		[form]="layer.form"
		[properties]="layer.properties"
		[expanded]="true"
		[title]="layer.label"
		[requiredChildren]="layer.required"
		[isRequired]="true"
		[collapsible]="false"
		[savedValue]="layer.savedValue"
		[emptyFormValue]="layer.emptyFormValue"
		(visible)="layerVisible($event)"
	>
	</app-group>
</mat-card>
