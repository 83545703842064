import {CdkDragEnter, CdkDragExit, CdkDragMove, CdkDragRelease, CdkDragStart} from '@angular/cdk/drag-drop';
import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2, ViewChild} from '@angular/core';
import {MatInput} from '@angular/material/input';
import * as _ from 'lodash';
import {ClipboardService} from 'ngx-clipboard';
import {AuthService} from 'src/app/core-services/auth.service';
import {MappingDataService} from '../../apps/mapping/mapping-data.service';
import {I40TreeService} from './i40-tree.service';
import {I40FlatMember, MemberDefinitionType, ModelManagerResult} from './types';
import {GlobalService} from '../../core-services/global.service';
import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';

@Component({
	selector: 'i40-tree',
	templateUrl: './i40-tree.component.html',
	styleUrls: ['./i40-tree.component.scss'],
	providers: [I40TreeService],
})
export class I40TreeComponent implements OnInit {
	loaded = false;
	selectedObject: I40FlatMember = null;
	clipboardObject: I40FlatMember = null;
	searchVisible: boolean = false;
	searchString: string = '';
	searchFieldRef: MatInput = null;
	mappingDataSrv = new MappingDataService();
	showJsonEditor = false;
	draggedItem: I40FlatMember;
	body: HTMLElement = document.body;
	metaKey: string = 'ctrlKey';
	modifierKey: string = 'altKey';

	@ViewChild('container', {read: ElementRef, static: false}) container: ElementRef;

	@ViewChild('searchField', {static: false}) set searchField(el: MatInput) {
		this.searchFieldRef = el;
	}

	@ViewChild(CdkVirtualScrollViewport) viewPort: CdkVirtualScrollViewport;

	@Input() uniqueID: string = 'i40Tree';
	@Input('dataService') dataSrv = null;
	@Input() enableDrag: boolean = false;
	@Input() enableDrop: boolean = false;
	@Input() extraInfo: any = null;
	@Input() overwrites: any = null;

	@Output('onNodeClick') onNodeClick = new EventEmitter();

	@Input()
	set model(m: ModelManagerResult) {
		if (m) {
			if (this.extraInfo) {
				this.tsrv.extraInfo = this.extraInfo;
			}

			this.tsrv.parseModel(m);

			this.nodeClick('select', this.tsrv.flatMembers[0]);

			if (this.dataSrv !== null) {
				this.tsrv.parentDataSrv = this.dataSrv;

				if (this.dataSrv.setTreeInstance !== undefined) {
					this.dataSrv.setTreeInstance(this.tsrv, this);
				}
			}

			this.loaded = true;
		}
	}

	@Input() disabled: boolean = false;

	get model() {
		return this.tsrv.inputModel;
	}

	constructor(
		public tsrv: I40TreeService,
		private _clipboardService: ClipboardService,
		public globalSrv: GlobalService,
		public authSrv?: AuthService,
		private renderer?: Renderer2,
	) {}

	ngOnInit() {
		this.metaKey = this.globalSrv.getMetaKey();
		if (this.globalSrv.getOs() === 'MacOS') {
			this.modifierKey = 'ctrlKey';
		}

		if (this.overwrites) {
			const overwrites = Object.keys(this.overwrites);
			if (overwrites.length) {
				overwrites.forEach((overwite) => {
					if (this[overwite] !== undefined) {
						this[overwite] = this.overwrites[overwite].bind(null, this);
					}
				});
			}
		}
	}

	// @HostListener('window:keydown', ['$event'])
	// onKeyPress($event: KeyboardEvent) {
	// 	const selectedText = window.getSelection().toString();
	// 	if(!selectedText) {

	// 		if($event[this.metaKey] && !$event['shiftKey'] && $event.key.toLowerCase() === "c") {
	// 			if(this.selectedObject !== null && this.selectedObject._isValid) {
	// 				console.log('TREE (COPY): CTRL+C');
	// 				this.copyNode(this.selectedObject);
	// 				return false;
	// 			}
	// 		}

	// 		if($event.key === "ArrowUp") {
	// 			this.selectUp();
	// 			return false;
	// 		}

	// 		if($event.key === "ArrowDown") {
	// 			this.selectDown();
	// 			return false;
	// 		}

	// 		if($event.key === "ArrowLeft") {
	// 			if(this.selectedObject !== null) {
	// 				if(this.selectedObject._hasChildren) {
	// 					if(!this.selectedObject._collapsed) {
	// 						this.nodeClick('toggle', this.selectedObject);
	// 					}
	// 					else{
	// 						if(this.selectedObject._flatParentRef) {
	// 							this.nodeClick('select', this.selectedObject._flatParentRef);
	// 						}
	// 					}
	// 				}
	// 				if(!this.selectedObject._hasChildren) {
	// 					if(this.selectedObject._flatParentRef) {
	// 						this.nodeClick('select', this.selectedObject._flatParentRef);
	// 					}
	// 				}
	// 			}
	// 			return false;
	// 		}

	// 		if($event.key === "ArrowRight") {
	// 			if(this.selectedObject !== null && this.selectedObject._hasChildren && this.selectedObject._collapsed) {
	// 				this.nodeClick('toggle', this.selectedObject);
	// 			}
	// 			return false;
	// 		}

	// 	}
	// }

	copyNode(node: I40FlatMember) {
		console.log('ORIGINAL COPY');
		if (node.definitionType !== MemberDefinitionType.Model) {
			this.copy(node.unFlattenNode().id);
			this.clipboardObject = _.clone(node);
		} else {
			this.copy(JSON.stringify(node._model.serialize()));
		}
	}

	selectUp() {
		if (this.selectedObject._visibleIndex > 0) {
			const newSelectedObject = this.tsrv.visibleFlatMembers[this.selectedObject._visibleIndex - 1];
			this.viewPort.scrollToIndex(this.selectedObject._visibleIndex - 1);
			this.nodeClick('select', newSelectedObject);
		}
	}

	selectDown() {
		if (this.selectedObject._visibleIndex < this.tsrv.visibleFlatMembers.length - 1) {
			const newSelectedObject = this.tsrv.visibleFlatMembers[this.selectedObject._visibleIndex + 1];
			this.viewPort.scrollToIndex(this.selectedObject._visibleIndex + 1);
			this.nodeClick('select', newSelectedObject);
		}
	}

	getIndex(index, member: I40FlatMember) {
		return member._originalIndex;
	}

	toggleFilter() {
		this.searchVisible = !this.searchVisible;
		if (this.searchVisible) {
			setTimeout(() => {
				this.searchFieldRef.focus();
			});
		}
	}

	onSearch() {
		if (this.searchString.length) {
			this.tsrv.flatMembers.forEach((member) => {
				if (member._level > 0) {
					const match = this.matchesSearch(member);
					if (match) {
						this.tsrv.showAllParents(member);
					} else {
						member._visible = false;
					}
				}
			});
		} else {
			this.tsrv.refreshVisibility();
		}

		this.tsrv.getVisibleNodes();
	}

	matchesSearch(node) {
		return node.id.toLowerCase().indexOf(this.searchString.toLowerCase()) > -1;
	}

	clearSearch() {
		this.searchString = '';
		this.tsrv.refreshVisibility();
		this.tsrv.getVisibleNodes();
	}

	copy(text: string) {
		this._clipboardService.copyFromContent(text);
		this._clipboardService.destroy();
	}

	nodeClick(command: string, member: I40FlatMember) {
		const memberDescription = member;

		if (this.disabled) {
			return;
		}

		switch (command) {
			case 'toggle':
				this.nodeClickToggle(member);
				break;

			case 'select':
				if (member !== this.selectedObject) {
					this.nodeClickSelect(member);
				}
				break;
			default:
		}

		this.onNodeClick.emit({command, memberDescription});
	}

	nodeClickSelect(member: I40FlatMember) {
		if (this.selectedObject !== null) {
			if (this.selectedObject !== member) {
				this.selectedObject = member;
			}
		} else {
			this.selectedObject = member;
		}
	}

	nodeClickToggle(member: I40FlatMember) {
		member._collapsed = !member._collapsed;
		this.tsrv.updateFlatVisibility(member);
		this.tsrv.getVisibleNodes();
	}

	canDrop() {
		return false;
	}

	dragStart(e: CdkDragStart) {
		this.setCursor('set', 'grabbing');
	}

	moved(e: CdkDragMove) {}

	entered(e: CdkDragEnter) {}

	onSourceListExited(e: CdkDragExit<any>) {
		this.draggedItem = e.item.data;
		const preview = new ElementRef<HTMLElement>(document.querySelector('.cdk-drag-placeholder'));
		this.renderer.setStyle(preview.nativeElement, 'opacity', '0');
		this.dataSrv.onDrop.subscribe((v) => {
			this.draggedItem = undefined;
			this.setCursor('reset');
		});
	}

	onSourceListEntered(e: CdkDragEnter<any>) {
		const preview = new ElementRef<HTMLElement>(document.querySelector('.cdk-drag-placeholder'));
		this.renderer.setStyle(preview.nativeElement, 'opacity', '100%');
		this.draggedItem = undefined;
	}

	onRelease(e: CdkDragRelease<any>) {
		this.setCursor('reset');
		this.selectedObject = undefined;
		this.draggedItem = undefined;
	}

	setCursor(action: string, style?: string) {
		switch (action) {
			case 'set':
				this.body.classList.add('customCursor');
				this.body.style.cursor = style;
				break;
			case 'reset':
				this.body.classList.remove('customCursor');
				this.body.style.cursor = 'unset';
		}
	}
}
