<mat-dialog-content class="mat-typography" id="dialog">
	<h2 mat-dialog-title [innerHTML]="dialogData.title" style="display: flex; flex-direction: row; align-items: center"></h2>

	<p *ngIf="dialogData.text" [innerHTML]="dialogData.text"></p>

	<div [formGroup]="formGroup" fxLayout="column" *ngIf="dialogData.hasInput">
		<ng-container *ngFor="let field of formFields">
			<ng-container [ngSwitch]="field.type" ]>
				<mat-form-field *ngSwitchCase="'text'" class="padding">
					<label>{{ field.label }}</label>
					<input matInput [formControlName]="field.name" placeholder="{{ field.label }}" type="text" />
					<mat-error>{{ formGroup.get(field.name).errors?.message }}</mat-error>
				</mat-form-field>
				<mat-form-field *ngSwitchCase="'password'" subscriptSizing="dynamic" class="padding">
					<label>{{ field.label }}</label>
					<input matInput [formControlName]="field.name" type="password" />
					<mat-error>{{ formGroup.get(field.name).errors?.message }}</mat-error>
				</mat-form-field>
				<mat-form-field *ngSwitchCase="'number'">
					<label>{{ field.label }}</label>
					<input matInput [formControlName]="field.name" placeholder="{{ field.label }}" type="number" />
				</mat-form-field>
				<div *ngSwitchCase="'file'" style="width: 100%">
					<label>{{ field.label }}</label>
					<input #fileInput [formControlName]="field.name" placeholder="{{ field.label }}" type="file" id="{{ field.name }}_button" />
				</div>
				<mat-form-field *ngSwitchCase="'select'">
					<mat-select placeholder="{{ field.label }}" [value]="field.selectedValue" [formControlName]="field.name">
						<mat-option *ngFor="let option of field.possibleValues" [value]="option.value">{{ option.label }}</mat-option>
					</mat-select>
				</mat-form-field>
				<mat-checkbox *ngSwitchCase="'checkbox'" class="padding" [formControlName]="field.name" [value]="field.selectedValue" (change)="checkboxSelect($event)">
					{{ field.label }}
				</mat-checkbox>

				<div *ngSwitchCase="'checklist'" fxFlex fxLayout="column">
					<mat-list class="list-padding">
						<div mat-subheader>{{ field.label }}</div>
						<mat-list-item *ngFor="let item of dialogData.formFields[0].selectedValue" style="height: 32px !important">
							<div fxFlex fxLayout="row">
								<div mat-line fxFlex>{{ item.id }}</div>
								<div mat-line fxFlex="10" fxLayoutAlign="end center">
									<mat-icon class="{{ item.status === 'success' ? 'mat-accent' : 'mat-warn' }}">{{ item.status === 'success' ? 'check' : 'close' }}</mat-icon>
								</div>
							</div>
							<mat-divider></mat-divider>
						</mat-list-item>
					</mat-list>
				</div>
			</ng-container>
		</ng-container>
	</div>

	<div fxLayout="row" style="width: 100%" fxLayoutGap="30px" *ngIf="this.buttonFlex.length !== 0">
		<div fxFlex *ngFor="let button of buttonFlex">
			<button fxFlex id="{{ button.btnId }}_dialog_button" [disabled]="button.disabled" *ngIf="button.btnType == 'flat'" mat-button (click)="buttonClick(button.btnId)" color="{{ button.btnColor }}">
				{{ button.btnText }}
			</button>
		</div>
	</div>

	<div fxLayout="row" style="width: 100%">
		<div fxFlex fxFlexAlign="start">
			<div fxFlex *ngFor="let button of buttonStart" class="start_buttons">
				<!-- Mat-Button -->
				<button id="{{ button.btnId }}_dialog_button" [disabled]="button.disabled" *ngIf="button.btnType == 'flat'" mat-button (click)="buttonClick(button.btnId)" color="{{ button.btnColor }}">
					{{ button.btnText }}
				</button>
				<!-- Mat-Raised-Button -->
				<button
					id="{{ button.btnId }}_dialog_button"
					[disabled]="button.disabled"
					*ngIf="button.btnType == 'raised'"
					mat-raised-button
					(click)="buttonClick(button.btnId)"
					color="{{ button.btnColor }}"
				>
					{{ button.btnText }}
				</button>
			</div>
		</div>

		<div fxFlex></div>

		<div fxFlex fxFlexAlign="center">
			<div fxFlex *ngFor="let button of buttonCenter" class="center_buttons">
				<!-- Mat-Button -->
				<button id="{{ button.btnId }}_dialog_button" [disabled]="button.disabled" *ngIf="button.btnType == 'flat'" mat-button (click)="buttonClick(button.btnId)" color="{{ button.btnColor }}">
					{{ button.btnText }}
				</button>
				<!-- Mat-Raised-Button -->
				<button
					id="{{ button.btnId }}_dialog_button"
					[disabled]="button.disabled"
					*ngIf="button.btnType == 'raised'"
					mat-raised-button
					(click)="buttonClick(button.btnId)"
					color="{{ button.btnColor }}"
				>
					{{ button.btnText }}
				</button>
			</div>
		</div>

		<div fxFlex></div>

		<div fxFlex fxFlexAlign="end">
			<div fxFlex *ngFor="let button of buttonEnd" class="end_buttons">
				<!-- Mat-Button -->
				<button
					id="{{ button.btnId }}_dialog_button"
					style="float: right; margin: 2px 2px 2px"
					[disabled]="button.disabled"
					*ngIf="button.btnType == 'flat'"
					mat-button
					(click)="buttonClick(button.btnId)"
					color="{{ button.btnColor }}"
				>
					{{ button.btnText }}
				</button>
				<!-- Mat-Raised-Button -->
				<button
					id="{{ button.btnId }}_dialog_button"
					style="float: right; margin: 2px 2px 2px"
					[disabled]="button.disabled"
					*ngIf="button.btnType == 'raised'"
					mat-raised-button
					(click)="buttonClick(button.btnId)"
					color="{{ button.btnColor }}"
				>
					{{ button.btnText }}
				</button>
			</div>
		</div>
	</div>
</mat-dialog-content>
