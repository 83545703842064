<div style="display: flex; flex-direction: row">
	<mat-card appearance="outlined" class="dashboard-kpi healthy">
		<div mat-card-title>Healthy</div>
		<h1>23</h1>
	</mat-card>
	<mat-card appearance="outlined" class="dashboard-kpi unhealthy">
		<div mat-card-title>Unhealthy</div>
		<h1>3</h1>
	</mat-card>
	<mat-card appearance="outlined" class="dashboard-kpi offline">
		<div mat-card-title>Offline</div>
		<h1>5</h1>
	</mat-card>
	<mat-card appearance="outlined" class="dashboard-kpi online">
		<div mat-card-title>Online</div>
		<h1>26</h1>
	</mat-card>
</div>

<table id="deployment_table" mat-table [dataSource]="data" matSort matSortDisableClear="true" style="margin-top: 16px">
	<ng-container matColumnDef="deploymentStatus">
		<th mat-header-cell *matHeaderCellDef mat-sort-header stickyEnd style="width: 64px">&nbsp;</th>
		<td mat-cell *matCellDef="let element" style="width: 64px">
			<mat-icon matTooltip="{{ element.deploymentStatus }}">{{ element.icon }}</mat-icon>
		</td>
	</ng-container>

	<ng-container matColumnDef="info" style="width: 100%">
		<th mat-header-cell *matHeaderCellDef mat-sort-header stickyEnd>Info</th>
		<td mat-cell *matCellDef="let element">
			<div>
				<span class="clickable-element" (click)="showDashboard(element)">{{ element.instanceId }}</span>
			</div>
			<div>{{ element.info.group }} : {{ element.info.name }} : {{ element.info.version }}</div>
		</td>
	</ng-container>

	<ng-container matColumnDef="location" style="width: 100%">
		<th mat-header-cell *matHeaderCellDef mat-sort-header stickyEnd>Location</th>
		<td mat-cell *matCellDef="let element">
			<div>Area: {{ element.location.area }}</div>
			<div>Location: {{ element.location.location }}</div>
		</td>
	</ng-container>

	<ng-container matColumnDef="deployType">
		<th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
		<td mat-cell *matCellDef="let element">
			{{ element.deployType }}
		</td>
	</ng-container>

	<ng-container matColumnDef="usage" style="width: 100%">
		<th mat-header-cell *matHeaderCellDef mat-sort-header stickyEnd>Usage</th>
		<td mat-cell *matCellDef="let element">
			<div>
				CPU: <b>{{ element.usage.cpu }}</b>
			</div>
			<div>
				MEM: <b>{{ element.usage.memory }}</b>
			</div>
		</td>
	</ng-container>

	<ng-container matColumnDef="times" style="width: 100%">
		<th mat-header-cell *matHeaderCellDef mat-sort-header stickyEnd>Time</th>
		<td mat-cell *matCellDef="let element">
			<div>
				Last start: <b>{{ element.times.lastStart }}</b>
			</div>
			<div>
				Last deploy: <b>{{ element.times.lastDeployed }}</b>
			</div>
		</td>
	</ng-container>

	<ng-container matColumnDef="actions">
		<th mat-header-cell *matHeaderCellDef></th>
		<td mat-cell *matCellDef="let element">
			<div fxLayout="row" class="table_cell_wrapper" fxLayoutAlign="end end" stickyEnd>
				<button id="dashboard_button" (click)="showDashboard(element)" mat-icon-button class="mat-accent" matTooltip="{{ 'deploymentList.i18n.button.goToDashboard' | translate }}">
					<mat-icon>dashboard</mat-icon>
				</button>
			</div>
		</td>
	</ng-container>
	<tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
	<tr mat-row *matRowDef="let row; columns: columns"></tr>
</table>
