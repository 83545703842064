import {AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import * as _ from 'lodash';
import {AuthService} from '../core-services/auth.service';
import {ValidatorService} from '../core-services/validator.service';
import {BehaviorSubject, EMPTY, Observable, Subject, Subscription} from 'rxjs';
import {MessageResponseService} from '../core-services/message-response.service';
import {TranslateService} from '@ngx-translate/core';
import {finalize, map, tap} from 'rxjs/operators';
import {Button, DialogInput, GenericDialogComponent} from '../shared/generic-dialog/generic-dialog.component';
import {Router} from '@angular/router';
import {GlobalService} from '../core-services/global.service';
import {Component, Directive} from '@angular/core';

/* ------------------------------------------------------------------------------------- */

export const NewNodeID = '[NEW NODE]';
export const NewArtifactID = '';
export const NewModelGroup = '[MODEL GROUP]';
export const NewModelName = '[MODEL NAME]';

export interface IManagerRequest {
	info: IArtifactInfo;
	description: IArtifactDescription;

	getName(): string;
}

export interface IArtifactInfo {
	identifier: Identifier;
}

export interface IArtifactDescription {}

@Directive()
export abstract class UnifierModelWrapper<T extends Root> {
	form: i40FormGroup = null;
	dataModel: T = null;
	entryName: string = 'The entry';

	icon: string;
	title: string;
	title_name: string;

	isEdit: boolean;
	saving: boolean = false;

	editRoute: string;

	versions: any[] = [];

	constructor(
		public authSrv: AuthService,
		public validatorSrv: ValidatorService,
		public msgSrv: MessageResponseService,
		public translate: TranslateService,
		public router: Router,
		public globalSrv: GlobalService,
	) {}

	changeVersion(version?: any) {
		let new_id_ver = new Identifier({id: this.dataModel.artifactIdentifier().id, version: 'latest'}).getId();
		if (version) {
			new_id_ver = new Identifier(version).getId();
		}
		this.router.navigate([`${this.editRoute}${new_id_ver}`]);
	}

	release() {
		this.msgSrv.customDialogMessage(
			this.translate.instant('i18n.areYouSureRelease'),
			this.translate.instant('i18n.releaseConfirmationMessage'),
			[new Button(this.translate.instant('i18n.ok'), 'ok', 'flat', 'accent', 'start'), new Button(this.translate.instant('i18n.cancel'), 'cancel', 'flat', 'accent', 'end')],
			{
				opened: (dialogRef) => {
					const ok_btn: Button = (<GenericDialogComponent>dialogRef.componentInstance).buttonStart[0];
					(<GenericDialogComponent>dialogRef.componentInstance).formGroup.statusChanges.subscribe((stat) => {
						ok_btn.disabled = stat === 'INVALID';
					});
					(<GenericDialogComponent>dialogRef.componentInstance).formGroup.updateValueAndValidity();
				},
				ok: (dialogRef, resolve) => {
					this.msgSrv.onLoading.next({command: 'start'});

					setTimeout(() => {
						const newVersion: string = (<GenericDialogComponent>dialogRef.componentInstance).formGroup.get('version').value;

						dialogRef.close();

						this.serverRelease(newVersion, dialogRef, resolve).subscribe({
							next: () => {
								resolve(true);
							},
						});
					}, 100);
				},
				cancel: (dialogRef, resolve) => {
					dialogRef.close();
					resolve(false);
				},
			},
			true,
			[new DialogInput('Version', 'version', 'text', '', [], [Validators.required, this.validatorSrv.validateVersion()])],
			'i40-version-dialog',
		);
	}

	save(closeEditor: boolean = false, onError?: Subject<any> | null) {
		this.saving = true;
		this.msgSrv.onLoading.next({command: 'start', message: this.translate.instant('i18n.snackbar.saving')});

		this.beforeSave();

		const saveObs = this.serverSave(closeEditor).pipe(
			tap((res: T) => {
				this.afterSave(res);
			}),
			finalize(() => {
				this.saving = false;
			}),
		);

		this.handleServerResponse(saveObs, onError);
	}

	handleServerResponse(saveObs: Observable<T>, onError?: Subject<any>) {
		this.msgSrv.handleSaveResponse(saveObs, this.entryName, onError);
	}

	clone() {
		return this.msgSrv.customDialogMessage(
			this.translate.instant('i18n.areYouSureClone'),
			this.translate.instant('i18n.cloneArtifactMessage'),
			[new Button(this.translate.instant('i18n.ok'), 'ok', 'flat', 'accent', 'start'), new Button(this.translate.instant('i18n.cancel'), 'cancel', 'flat', 'accent', 'end')],
			{
				ok: (dialogRef, resolve) => {
					this.msgSrv.onLoading.next({command: 'start'});

					dialogRef.close();

					this.serverClone().subscribe({
						next: () => {
							this.msgSrv.onLoading.next({command: 'stop'});
							resolve(true);
						},
					});
				},
				cancel: (dialogRef, resolve) => {
					dialogRef.close();
					resolve(false);
				},
			},
		);
	}

	beforeSave() {
		// hook method that is called before calling save API
	}

	afterSave(res: T) {
		// hook method that is called after succesful save API response
	}

	serverSave(closeEditor: boolean = false): Observable<any> {
		// call save API
		return EMPTY;
	}

	serverRelease(releaseVersion: string, dialogRef, resolve): Observable<any> {
		// call release API
		return EMPTY;
	}

	serverClone(): Observable<any> {
		// call clone API
		return EMPTY;
	}

	checkUserAceess() {
		if (!this.globalSrv.canEdit(this.dataModel)) {
			this.form.disable();
		}
	}

	init(model: T, standalone = true) {
		this.dataModel = model;
		this.form = model._formGroup;

		// if UnifierModelWrapper is used as a standalone class or it is being extended as a UnifierFormWrapper
		if (standalone) {
			this.checkUserAceess();
		}
		if (this.isEdit) {
			if (!this.authSrv.permissions.read) {
				this.icon = 'edit';
				this.title = `i18n.edit${this.entryName.replace(' ', '')}`;
			} else {
				this.icon = 'visibility';
				this.title = `i18n.view${this.entryName.replace(' ', '')}`;
			}
		} else {
			this.title = this.title = `i18n.add${this.entryName.replace(' ', '')}`;
			this.icon = 'add_circle_outline';
		}
	}
}

@Directive()
export abstract class UnifierFormWrapper<T extends Root> extends UnifierModelWrapper<T> {
	existingForm = false;
	formStatusSubscription: Subscription = null;
	formValuesSubscription: Subscription = null;

	editorStatusSubscription: Subscription = null;
	editorStatusSubject: Subject<string[]> = new Subject();

	editorValuesSubscription: Subscription = null;
	editorValuesSubject: Subject<any> = new Subject();

	constructor(
		public authSrv: AuthService,
		public validatorSrv: ValidatorService,
		public msgSrv: MessageResponseService,
		public translate: TranslateService,
		public router: Router,
		public globalSrv: GlobalService,
	) {
		super(authSrv, validatorSrv, msgSrv, translate, router, globalSrv);
	}

	initForm(model: T) {
		this.existingForm = this.form !== null;

		super.init(model, false);

		this.generateFields();

		this.addValidation();

		this.spyFormStatus();

		this.populateFields();

		this.saveInitialValues();

		this.checkUserAceess();

		this.form.markAsPristine();
		this.form.markAllAsTouched();
		this.form.updateValueAndValidity();
	}

	beforeSave() {
		this.dataModel.setFormValues();
	}

	afterSave(res: T) {
		this.syncForm(res);
	}

	syncForm(model?: T) {
		if (model instanceof Root) {
			this.initForm(model);
		}
	}

	generateFields() {
		// this.form = new FormGroup({});
	}

	addValidation() {
		// add validation to controls
	}

	spyFormStatus() {
		if (this.formStatusSubscription !== null) {
			this.formStatusSubscription.unsubscribe();
		}

		this.formStatusSubscription = this.form.statusChanges.subscribe((status) => {
			const states = [];

			if (!this.form.disabled) {
				if (this.form.dirty) {
					if (this.valuesHaveChanged()) {
						states.push('dirty');
					}
				}

				if (this.form.valid) {
					states.push('valid');
				} else {
					states.push('invalid');
				}

				if (states.includes('valid') && !states.includes('dirty')) {
					states.push('clean');
				}
			} else {
				states.push('disabled');
				states.push('clean');
			}

			this.editorStatusSubject.next(states);
		});

		if (this.formValuesSubscription !== null) {
			this.formValuesSubscription.unsubscribe();
		}

		this.formValuesSubscription = this.form.valueChanges.subscribe((value) => {
			this.editorValuesSubject.next(value);
		});
	}

	populateFields() {
		// put some values in it
	}

	saveInitialValues() {
		// save initial form values to determine correct dirty state
	}

	valuesHaveChanged(): boolean {
		return true;
	}
}

export class i40FormGroup extends UntypedFormGroup {
	toFormControl(validators: any[] = [], isDisabled?: boolean): i40FormControl {
		const existing = this.get('_ctrl');

		if (existing) {
			return existing as i40FormControl;
		} else {
			const ctrl = new i40FormControl(this.value, this);
			ctrl.setValidators(validators);
			isDisabled ? ctrl.disable() : ctrl.enable();
			this.addControl('_ctrl', ctrl);

			ctrl.markAsTouched();
			ctrl.updateValueAndValidity();

			return ctrl;
		}
	}
}

export class i40FormControl extends UntypedFormControl {
	groupParent: i40FormGroup;

	constructor(value, parent) {
		super(value);
		this.groupParent = parent;
	}

	setValue(
		value: any,
		options?: {
			onlySelf?: boolean;
			emitEvent?: boolean;
			emitModelToViewChange?: boolean;
			emitViewToModelChange?: boolean;
		},
	) {
		super.setValue(value, options);

		this.groupParent.patchValue(value);
	}
}

export class Root {
	protected _className: string = 'Root';

	public _formGroup: i40FormGroup = new i40FormGroup({});
	public _parent: Root = null;

	public _autoUpdateFields: string[] = []; // list of fields that have an auto update mechanic on generated form fields
	public _fieldObservers: any = {};

	protected _originalInput: any;
	protected _ignore: string[] = ['_originalInput', '_ignore', '_formGroup', '_parent', '_autoUpdateFields', '_fieldObservers'];

	constructor(input: any, parent?: Root, parent_key?: string) {
		this._originalInput = input;

		if (parent) {
			this._parent = parent;
			this._formGroup.setParent(this._parent._formGroup);
			if (parent_key) {
				this._parent._formGroup.setControl(parent_key, this._formGroup);
			}
		}
	}

	artifactIdentifier(): Identifier {
		return null;
	}

	saveOriginal() {
		this._originalInput = this.serialize();
	}

	getRootParent() {
		let parent = this._parent;

		if (parent !== null) {
			return parent.getRootParent();
		} else {
			return this;
		}
	}

	getRootForm() {
		let parentForm = this._formGroup.parent;

		if (parentForm !== null) {
			return this._parent.getRootForm();
		} else {
			return this._formGroup;
		}
	}

	// update data model
	update(input: any) {
		const fields = this.getFields();
		const form_fields = this.getFields(true);

		fields.forEach((f) => {
			const customSetter = 'set' + _.upperFirst(f);

			if (input && input[f] !== undefined) {
				if (this[f] !== null && this[f] !== undefined) {
					// instance property type/constructor
					const fieldConstructor = this[f].constructor;
					const classInstance = Object.create(fieldConstructor);

					if (this[customSetter] !== undefined) {
						this[customSetter](input[f], this, input);
					} else {
						if (classInstance.prototype instanceof Root) {
							if (input[f] instanceof fieldConstructor) {
								this[f] = input[f];
							} else {
								this[f] = new fieldConstructor(input[f], this);
							}
						} else {
							this[f] = input[f];
						}
					}
				} else {
					this[f] = input[f];
				}
			} else {
				if (this[customSetter] !== undefined && input) {
					this[customSetter](input[f], this, input);
				}
			}

			if (this[f] instanceof Root) {
				(<Root>this[f])._parent = this;

				if (form_fields.includes(f)) {
					(<Root>this[f])._formGroup.setParent(this._formGroup);
					this._formGroup.setControl(f, this[f]._formGroup);
				}
			}
		});
	}

	updateFormValues(input: any) {
		const formModel: Root = _.clone(this);
		formModel.update(input);

		this.populateFormControls(formModel._formGroup);
	}

	init(input: any) {
		this.update(input);

		this.toFormControls(this._formGroup); // reinit param: this might still be required in the instance of the data model structure changing keys but needs to be tested

		this.populateFormControls(this._formGroup);
	}

	updateFormControl(field: string) {
		const customToForm = field + 'ToFormControl';
		let fc: AbstractControl;

		if (this[customToForm] !== undefined) {
			fc = this[customToForm]();
		} else {
			fc = this.toFormControl(this[field]);
		}

		this._formGroup.setControl(field, fc);
	}

	set(input: any) {
		this.update(input);

		const fields = this.getFields();

		fields.forEach((f) => {
			if (input && input[f] !== undefined) {
				this.updateFormControl(f);
			}
		});

		this.populateFormControls(this._formGroup);
	}

	cancel() {
		const oldFormGroupParent = this._formGroup.parent;
		const oldFormGroup: i40FormGroup = this._formGroup;
		if (oldFormGroupParent) {
			const oldParentControls = Object.keys(oldFormGroupParent.controls);
			let oldParentControlKey = null;
			oldParentControls.forEach((c) => {
				if (oldFormGroupParent.controls[c] === oldFormGroup) {
					oldParentControlKey = c;
				}
			});
			this._formGroup = new i40FormGroup({});
			this._formGroup.setParent(oldFormGroupParent);
			if (oldParentControlKey) {
				oldFormGroupParent.controls[oldParentControlKey] = this._formGroup;
			}
		} else {
			this._formGroup = new i40FormGroup({});
		}
		this.init(this._originalInput);
		this._formGroup.updateValueAndValidity();
	}

	push(el: any, field: string, key?: string | number) {
		const arr = this[field];

		if (arr instanceof Array) {
			if (el instanceof Root) {
				const farr = <UntypedFormArray>this._formGroup.get(field);

				el._formGroup.setParent(farr);

				if (key) {
					farr.controls[key] = el._formGroup;
				} else {
					farr.controls.push(el._formGroup);
				}
				farr.updateValueAndValidity();

				this._formGroup.markAsDirty();
				this._formGroup.updateValueAndValidity();

				el._parent = this;
			} else {
				const fc = new UntypedFormControl(el);
				const farr = <UntypedFormArray>this._formGroup.get(field);
				fc.setParent(farr);
				if (key) {
					farr.controls[key] = fc;
				} else {
					farr.controls.push(fc);
				}
				farr.updateValueAndValidity();

				this._formGroup.markAsDirty();
				this._formGroup.updateValueAndValidity();
			}

			arr.push(el);

			return el;
		} else if (arr instanceof Map) {
			if (key) {
				if (el instanceof Root) {
					const fg = new i40FormGroup({});
					fg.addControl('map_key', new UntypedFormControl(key));
					fg.addControl('map_value', el._formGroup);
					fg.addControl('map_index', new UntypedFormControl(arr.size));
					fg.setParent(<UntypedFormArray>this._formGroup.get(field));
					(<UntypedFormArray>this._formGroup.get(field)).controls.push(fg);
					this._formGroup.markAsDirty();
					this._formGroup.updateValueAndValidity();

					el._parent = this;
				}

				arr.set(key, el);

				return el;
			} else {
				console.error('Adding to MAP with empty KEY');
				return null;
			}
		} else {
			console.error('Trying to add elements to NON Array field');
			return null;
		}
	}

	pop(key: number | string, field: string, len: number = 1) {
		const arr = this[field];

		if (arr instanceof Array) {
			arr.splice(key as number, len);

			const farr = <UntypedFormArray>this._formGroup.get(field);

			farr.controls.splice(<number>key, len);
			farr.updateValueAndValidity();

			this._formGroup.markAsDirty();
			this._formGroup.updateValueAndValidity();
		} else if (arr instanceof Map) {
			let index = 0;
			let map_numeric_index = 0;
			let el: any;

			this[field].forEach((v, k) => {
				if (k === key) {
					el = v;
					map_numeric_index = index;
				}
				index++;
			});

			arr.delete(key);

			if (el instanceof Root) {
				const farr = <UntypedFormArray>this._formGroup.get(field);

				farr.controls.splice(<number>map_numeric_index, 1);
				farr.updateValueAndValidity();

				// update map index
				let newIndex = 0;
				(<UntypedFormArray>this._formGroup.get(field)).controls.forEach((c) => {
					const fg = c as UntypedFormGroup;
					fg.get('map_index').patchValue(newIndex, {emitEvent: false});
					newIndex++;
				});

				this._formGroup.markAsDirty();
				this._formGroup.updateValueAndValidity();
			}
		} else {
			console.error('Trying to add elements to NON Array field');
		}
	}

	mapSet(el: any, field: string, key: string) {
		const m = this[field];
		const oldValue = m.get(key);
		el._formGroup = oldValue ? oldValue._formGroup : new UntypedFormGroup({});
		el.populateFormControls(el._formGroup);
		m.set(key, el);
	}

	serialize() {
		const output = {};
		const fields = this.getFields(true);

		fields.forEach((f) => {
			const customSerializer = 'serialize' + _.upperFirst(f);

			if (this[customSerializer] !== undefined) {
				output[f] = this[customSerializer]();
			} else {
				if (this[f]?.serialize !== undefined) {
					output[f] = this[f].serialize();
				} else {
					output[f] = this[f];
				}
			}
		});

		return output;
	}

	getFields(formFields: boolean = false) {
		const fields = Object.keys(this);
		if (formFields) {
			return fields.filter((f) => !this._ignore.includes(f) && f[0] !== '_');
		} else {
			return fields.filter((f) => !this._ignore.includes(f));
		}
	}

	toFormControl(el): AbstractControl {
		if (el instanceof Root) {
			return el._formGroup;
		} else {
			return new UntypedFormControl('');
		}
	}

	setAutoUpdatedFields(fields) {
		this._autoUpdateFields = fields;
	}

	toFormControls(form?: i40FormGroup) {
		if (!form) form = this._formGroup;

		const fields = this.getFields(true);

		this.setAutoUpdatedFields(fields);

		fields.forEach((f) => {
			const customToForm = f + 'ToFormControl';
			if (this[customToForm] !== undefined) {
				const fc = this[customToForm]();
				form.addControl(f, fc);
				//fc.setParent(form);
			} else {
				const fc = this.toFormControl(this[f]);
				form.addControl(f, fc);
				//fc.setParent(form);
			}

			const formField = form.get(f);

			if (formField instanceof UntypedFormControl) {
				if (this._autoUpdateFields.includes(f)) {
					if (!this._fieldObservers[f]) {
						this._fieldObservers[f] = formField.valueChanges.subscribe((v) => {
							if (this[f] !== v) {
								if (formField.valid) {
									const updateObj = {};
									updateObj[f] = v;
									this.update(updateObj);
								}
							}
						});
					}
				}
			}
		});
	}

	populateFormControls(form?: i40FormGroup) {
		if (!form) form = this._formGroup;
		form.patchValue(this);
	}

	fromFormControls(form: i40FormGroup) {
		this.update(form.value);
	}

	setFormValues() {
		this.fromFormControls(this._formGroup);
	}

	objectToMap(className, input, parent) {
		return new Map(
			Object.entries(input).map(([key, member]: [string, any]) => {
				return [key, new className(member, parent)];
			}),
		);
	}

	mapToObject(input: Map<string, Root>) {
		const obj = {};
		input.forEach((value, key) => {
			obj[key] = value.serialize();
		});
		return obj;
	}

	mapToArray(input: Map<string, Root>) {
		const arr = [];
		input.forEach((value, key) => {
			arr.push(value.serialize());
		});
		return arr;
	}

	toInstanceArray(className, input: any[], parent) {
		return input.map((member) => {
			if (member instanceof className) {
				member._parent = parent;
				return member;
			} else {
				return new className(member, parent);
			}
		});
	}

	toInstanceMap(className, input: any[], key, parent) {
		return new Map(
			input.map((el) => {
				if (el instanceof className) {
					el._parent = parent;
					return [el[key], el];
				} else {
					return [el[key], new className(el, parent)];
				}
			}),
		);
	}

	toSimpleArray(input: Root[]) {
		return input.map((member) => member.serialize());
	}

	rootArrayToFormControl(arr) {
		const fa = new UntypedFormArray([]);
		if (arr.length) {
			arr.forEach((l) => {
				const el = this.toFormControl(l);
				el.setParent(fa);
				fa.push(el);
			});
		}
		return fa;
	}

	rootMapToFormControl(map) {
		const fa = new UntypedFormArray([]);
		if (map.size) {
			let mapIndex = 0;
			map.forEach((l, k) => {
				const fg = new i40FormGroup({});
				fg.addControl('map_key', new UntypedFormControl(k));
				fg.addControl('map_value', this.toFormControl(l));
				fg.addControl('map_index', new UntypedFormControl(mapIndex));
				fg.setParent(fa);
				fa.push(fg);
				mapIndex++;
			});
		}
		return fa;
	}

	getFormControl(k) {
		return this._formGroup.get(k) as UntypedFormControl;
	}

	getFormArray(k) {
		return this._formGroup.get(k) as UntypedFormArray;
	}

	getFormArrayItemFormControl(k, index) {
		return (this._formGroup.get(k) as UntypedFormArray).controls[index] as UntypedFormControl;
	}

	static generateUniqueId(id: string, condition, nowhitespace = false) {
		let increment: number = 1;
		let newId = id;
		while (condition(newId)) {
			newId = id + (nowhitespace ? '' : '_') + increment++;
		}

		return newId;
	}

	static renameMapKey(oldKey: string, newKey: string, oldMap: Map<string, any>) {
		const newMap = new Map<string, any>();

		oldMap.forEach((v, k) => {
			if (k === oldKey) {
				newMap.set(newKey, v);
			} else {
				newMap.set(k, v);
			}
		});

		return newMap;
	}
}

export class ValidationStatus extends Root {
	protected _className: string = 'ValidationStatus';

	status: string = 'unknown';
	messages: any[] = [];

	constructor(input?: {status?: string; messages?: any[]}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
		if (input != undefined && input.messages != undefined) {
			this.messages = input.messages == undefined ? [] : input.messages;
		} else {
			this.messages = [];
		}
	}

	buildMessageString() {
		var msgStr = '';
		if (this.messages == undefined) {
			return '';
		}
		this.messages.forEach((msg) => (msgStr = msgStr + 'Line ' + msg.line + ': ' + msg.userHint + '\n'));
		return msgStr;
	}
}

export class ArtifactInfo extends Root {
	_className = 'ArtifactInfo';

	identifier: Identifier = new Identifier({}, this);
	externalIdentifier: ExternalIdentifier = new ExternalIdentifier({}, this);
	externalDescriptor: ExternalDescriptor = new ExternalDescriptor({}, this);
	artifactType: string = undefined;
	dependencies: Identifier[] = [];
	frameworkVersionCompatibility: string = undefined;
	status: ValidationStatus = undefined;

	constructor(
		input?: {
			identifier?: any;
			externalIdentifier?: any;
			externalDescriptor?: any;
			artifactType?: string;
			dependencies?: Identifier[];
			frameworkVersionCompatibility?: string;
			status?: ValidationStatus;
		},
		parent?: Root,
		inherited: boolean = false,
	) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
		if (input != undefined && input.status != undefined) {
			this.status = new ValidationStatus(input.status, this, false);
		}
	}

	getExternalId(noversion = false): string {
		if (!this.externalIdentifier.group && !this.externalIdentifier.name) {
			return undefined;
		} else {
			if (noversion) {
				return `${this.externalIdentifier.group}:${this.externalIdentifier.name}`;
			} else {
				return `${this.externalIdentifier.group}:${this.externalIdentifier.name}:${this.identifier.version}`;
			}
		}
	}

	static sort(a: ArtifactInfo, b: ArtifactInfo) {
		if (a.getExternalId() < b.getExternalId()) return -1;
		if (a.getExternalId() > b.getExternalId()) return 1;
		return 0;
	}

	serialize() {
		const output: any = super.serialize();

		if (output.identifier.id === NewArtifactID) {
			output.version = output.identifier.version;
		}

		return output;
	}
}

export class ArtifactFilteredRequest extends Root {
	_className = 'ArtifactFilteredRequest';

	archiveId: string = '';
	identifiers: string[] = [];
	tableNames: string[] = [];
	entityUUIDs: any = new Map<string, string[]>();

	constructor(input?: {archiveId?: string; identifiers?: string[]; tableNames?: string[]; entityUUIDs?: any}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}

	serialize() {
		const output: any = super.serialize();
		if (output.archiveId.length === 0) {
			delete output.archiveId;
		}
		return output;
	}
}

export class ModelInfo extends Root {
	_className = 'ModelInfo';

	public name: string = '';
	public info: ArtifactInfo = new ArtifactInfo({}, this);

	constructor(input?: {name?: string; identifier?: any; externalIdentifier?: any; externalDescriptor?: any}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}
}

export class Identifier extends Root {
	_className = 'Identifier';

	public id: string = '';
	public version: string = 'latest';

	constructor(input?: {id?: string; version?: string}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}

	getId() {
		if (!this.id && !this.version) {
			return undefined;
		} else {
			return `${this.id}:${this.version}`;
		}
	}
}

export class ExternalIdentifier extends Root {
	_className = 'ExternalIdentifier';

	public name: string = '';
	public group: string = '';

	constructor(input?: {group?: string; name?: string}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}

	getName() {
		if (!this.group && !this.name) {
			return undefined;
		} else {
			return `${this.group}:${this.name}`;
		}
	}
}

export class ExternalDescriptor extends Root {
	_className = 'ExternalDescriptor';

	public description: string = '';

	constructor(input?: {description?: string}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}
}

export class IdentifierFactory {
	createIdentifier(obj: any) {
		return new Identifier(obj);
	}

	createIdFromIdentifier(obj: any) {
		return this.createIdentifier(obj).getId();
	}
}

export interface ToolbarMenu {
	id: string;
	icon: string;
	svgIcon: boolean;
	path?: string;
	title: string;
	display: boolean;
	action?: any;
	children?: boolean;
}
