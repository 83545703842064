import {Component, OnInit} from '@angular/core';
import {UnifierListService} from '../../core-services/unifier-list.service';
import {Root} from '../../data-models/core-model.model';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from '../../core-services/auth.service';

@Component({
	selector: 'unifier-list-group-filter',
	templateUrl: './unifier-list-group-filter.component.html',
	styleUrls: ['./unifier-list-group-filter.component.scss'],
})
export class UnifierListGroupFilterComponent<T extends Root> implements OnInit {
	constructor(
		public translate: TranslateService,
		public authSrv: AuthService,
		public listSrv: UnifierListService<T>,
	) {}

	ngOnInit(): void {}
}
