import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import * as _ from 'lodash';
import {AuthService} from '../../../core-services/auth.service';
import {ArtifactInfo, ModelInfo} from '../../../data-models/core-model.model';
import {ChannelInfo} from '../../channel/channel.model';
import {MappingInfo} from '../../mapping/mapping-model';
import {DeviceTypeChannel, DeviceTypeMapping} from '../device-type.model';
import {DeviceTypeDataService} from '../device-type-data.service';
import {Button} from '../../../shared/generic-dialog/generic-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {ValidatorService} from '../../../core-services/validator.service';
import {MessageResponseService} from '../../../core-services/message-response.service';
import {GlobalService} from '../../../core-services/global.service';

@Component({
	selector: 'app-device-type-mapping',
	styleUrls: ['./device-type-mapping.component.scss'],
	templateUrl: './device-type-mapping.component.html',
})
export class DeviceTypeMappingComponent implements OnInit {
	modelChannels: Map<string, Array<ChannelInfo>> = new Map();

	@Input() mappingIndex: number;

	changingMapping = false;

	constructor(
		public authSrv: AuthService,
		public dialog: MatDialog,
		public translate: TranslateService,
		public dataSrv: DeviceTypeDataService,
		public validatorSrv: ValidatorService,
		public msgSrv: MessageResponseService,
		public globalSrv: GlobalService,
		public cdRef: ChangeDetectorRef,
	) {}

	deviceTypeMapping: DeviceTypeMapping;

	sort(list) {
		list.sort((a, b) => {
			let n1 = a.info.getExternalId().toLowerCase();
			let n2 = b.info.getExternalId().toLowerCase();
			return n1 === n2 ? 0 : n1 > n2 ? 1 : -1;
		});
	}

	ngOnInit() {
		this.deviceTypeMapping = this.dataSrv.deviceTypeResult.mappings[this.mappingIndex];
		this.sort(this.dataSrv.mappings);

		if (this.deviceTypeMapping) {
			this.deviceTypeMapping.mappingInfo.models.forEach((modelInfo, modelName) => this.modelChannels.set(modelName, this.getChannels(modelInfo)));
		}
	}

	getChannels(modelInfo: ArtifactInfo): ChannelInfo[] {
		return this.dataSrv.channels.filter((channel) => channel.model.identifier.id === modelInfo.identifier.id);
	}

	onMappingChange(event) {
		if (this.deviceTypeMapping.mappingInfo.info.getExternalId() != event.value.info.getExternalId() || this.deviceTypeMapping.channels.length == 0) {
			this.changingMapping = true;

			const oldChannels: DeviceTypeChannel[] = _.cloneDeep(this.deviceTypeMapping.channels);

			this.deviceTypeMapping.set({channels: []});

			this.deviceTypeMapping.mappingInfo.models.forEach((modelInfo: ArtifactInfo, modelName) => {
				const channelFound = oldChannels.find((old) => old.modelInfo.name === modelName);

				if (channelFound) {
					this.deviceTypeMapping.push(channelFound, 'channels');
				} else {
					const newChannel = new DeviceTypeChannel({modelInfo: {info: modelInfo, name: modelName}});
					this.deviceTypeMapping.push(newChannel, 'channels');
				}

				this.modelChannels.set(modelName, this.getChannels(modelInfo));
			});

			setTimeout(() => {
				this.changingMapping = false;
				this.cdRef.detectChanges();
			});
		}
	}

	deleteMapping() {
		const mappingName = this.deviceTypeMapping.mappingInfo.info.externalIdentifier.getName();

		this.msgSrv.customDialogMessage(
			this.translate.instant('i18n.removeMapping'),
			this.translate.instant('i18n.removeMappingText', {value: mappingName}),
			[new Button(this.translate.instant('i18n.cancel'), 'cancel', 'flat', 'accent', 'end'), new Button(this.translate.instant('i18n.delete'), 'delete', 'flat', 'accent', 'end')],
			{
				delete: (dialogRef, resolve) => {
					this.dataSrv.deviceTypeResult.pop(this.mappingIndex, 'mappings');

					dialogRef.close();
					resolve(true);
				},
				cancel: (dialogRef, resolve) => {
					dialogRef.close();
					resolve(false);
				},
			},
		);
	}

	compareMappings(o1: MappingInfo, o2: any): boolean {
		return o1.info.identifier.getId() === new MappingInfo(o2).info.identifier.getId();
	}

	getModelChannels(modelName: string): ChannelInfo[] {
		return this.modelChannels.get(modelName);
	}
}
