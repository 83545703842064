import {Injectable} from '@angular/core';
import {AbstractControl, FormGroupDirective, NgForm, UntypedFormArray, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {Identifier, Root} from '../data-models/core-model.model';
import {I40FlatMember, ObjectDescription} from '../shared/i40-tree/types';

const keyWords = [
	'abstract',
	'case',
	'catch',
	'class',
	'def',
	'do',
	'else',
	'extends',
	'false',
	'finally',
	'final',
	'for',
	'forSome',
	'if',
	'match',
	'new',
	'null',
	'print',
	'printf',
	'println',
	'this',
	'throw',
	'to',
	'trait',
	'true',
	'try',
	'type',
	'until',
	'val',
	'var',
	'while',
	'with',
	'yield',
	'Array',
	'Boolean',
	'Console',
	'Double',
	'Int',
	'List',
	'Map',
	'None',
	'Option',
	'Ordering',
	'Range',
	'Regex',
	'Set',
	'StdIn',
	'String',
	'StringBuilder',
	'Throwable',
	'Vector',
	'object',
	'implicit',
	'import',
	'lazy',
	'override',
	'package',
	'private',
	'protected',
	'return',
	'sealed',
	'super',
];

const whiteSpaces = /\s/g;
const periods = /\./g;

@Injectable({
	providedIn: 'root',
})
export class ValidatorService extends Validators {
	constructor(public translate: TranslateService) {
		super();
	}

	/* ALREADY WENT OVER THESE - LOOK FOR METHODS AFTER "NEEDS REVIEW" TAG */

	validateArtifactInfo(): ValidatorFn {
		return (control: AbstractControl): {[key: string]: boolean} | null => {
			if (control.value) {
				if (control.value.identifier.id) {
					return null;
				} else {
					return {message: this.translate.instant('i18n.errorMsg.validateNotEmpty')};
				}
			}
		};
	}

	validateMappingInfo(): ValidatorFn {
		return (control: AbstractControl): {[key: string]: boolean} | null => {
			if (control.value) {
				if (control.value.info.identifier.id) {
					return null;
				} else {
					return {message: this.translate.instant('i18n.errorMsg.validateNotEmpty')};
				}
			}
		};
	}

	validateMemberId(): ValidatorFn {
		return (control: AbstractControl): {[key: string]: string} | null => {
			if (control.value) {
				//Get user input
				let memberId = control.value;

				//Define Regex -> 1.0 format need to be handled in BE!
				let VERSION_REGEXP = /^([a-z]|[A-Z]|_)([a-z]|[A-Z]|\d|_)*$/;

				if (memberId && memberId.length > 0) {
					if (VERSION_REGEXP.test(memberId)) {
						//Matches regex return null
						return null;
					} else {
						//Matches not regex return message
						return {message: this.translate.instant('i18n.errorMsg.validateMemberId')};
					}
				}
			}
		};
	}

	validateUniqueSiblings(member: I40FlatMember): ValidatorFn {
		return (control: AbstractControl): {[key: string]: boolean} | null => {
			if (control.value) {
				const id = control.value;
				const siblings = member._memberParent.members;
				const parent_name = member._path[member._path.length - 2] || member._model.info.externalIdentifier.name;

				if (siblings.has(id)) {
					const existing = siblings.get(id);
					if (existing !== member._original) {
						return {message: this.translate.instant('i18n.errorMsg.validateDuplicate')};
					}
				} else {
					return null;
				}
			}
		};
	}

	validateMemberType(member: I40FlatMember): ValidatorFn {
		return (control: AbstractControl): {[key: string]: boolean} | null => {
			if (control.value) {
				if (['Command', 'Event'].includes(control.value) && member._level > 1) {
					return {message: this.translate.instant('i18n.errorMsg.CommandEventOnlyRoot')};
				}

				if (member._hasChildren) {
					const children = member._srv.getChildren(member);
					let invalidChild = false;

					children.forEach((child) => {
						if (child.typeName === control.value) {
							invalidChild = true;
						}
					});

					if (invalidChild) {
						return {message: this.translate.instant('i18n.errorMsg.validateMemberType')};
					}
				}

				if (member._flatParentRef && member._flatParentRef._memberPath) {
					return member._flatParentRef._memberPath.includes(control.value) ? {message: this.translate.instant('i18n.errorMsg.validateMemberType')} : null;
				}

				return null;
			}
		};
	}

	validateGroupName(): ValidatorFn {
		return (control: AbstractControl): {[key: string]: boolean} | null => {
			if (control.value) {
				//Get user input
				let groupName = control.value;

				//Define Regex: lower case letters and numbers only, beginning not with number, dots allowed for separation
				let GROUP_REGEXP = /^[0-9a-zA-Z_\-. ]*$/;

				if (groupName && groupName.length > 0) {
					if (GROUP_REGEXP.test(groupName)) {
						return null;
					} else {
						return {message: this.translate.instant('i18n.errorMsg.validateGroup')};
					}
				}
			}
		};
	}

	validateNotEmpty(): ValidatorFn {
		return (control: AbstractControl): {[key: string]: boolean} | null => {
			if (control.value.length === 0) {
				return {message: this.translate.instant('i18n.errorMsg.validateNotEmpty')};
			}
		};
	}

	validateCharacters(): ValidatorFn {
		return (control: AbstractControl): {[key: string]: boolean} | null => {
			if (control.value) {
				let field = '';

				if (control.parent) {
					let parent = control.parent.controls;
					field = Object.keys(parent).find((name) => control == parent[name]);
				}

				if (control.value && control.value.length > 0) {
					const matches = control.value.match(validCharacters);
					return matches && matches.length ? {message: this.translate.instant('i18n.errorMsg.validateCharacters', {value: field})} : null;
				} else {
					return null;
				}
			}
		};
	}

	validateScalaIdentifierCharacters(): ValidatorFn {
		return (control: AbstractControl): {[key: string]: boolean} | null => {
			if (control.value) {
				let field = '';

				if (control.parent) {
					let parent = control.parent.controls;
					field = Object.keys(parent).find((name) => control == parent[name]);
				}

				if (control.value && control.value.length > 0) {
					const matches = control.value.match('^([a-zA-Z_$][a-zA-Z0-9_]*)$');
					return matches && matches.length ? null : {message: this.translate.instant('i18n.errorMsg.validateCharacters', {value: field})};
				} else {
					return null;
				}
			}
		};
	}

	validateFirstChar(): ValidatorFn {
		return (control: AbstractControl): {[key: string]: boolean} | null => {
			if (control.value) {
				let field = '';

				if (control.parent) {
					let parent = control.parent.controls;
					field = Object.keys(parent).find((name) => control == parent[name]);
				}

				let regex = /[+-]?\d+(?:\.\d+)?/g;

				let valid = !regex.exec(control.value[0]);

				if (!valid) {
					return {message: this.translate.instant('i18n.errorMsg.validateFirstChar', {value: field})};
				}

				return null;
			}
		};
	}

	validateKeyWords(): ValidatorFn {
		return (control: AbstractControl): {[key: string]: boolean} | null => {
			if (control.value) {
				let field = '';

				if (control.parent) {
					let parent = control.parent.controls;

					field = Object.keys(parent).find((name) => control == parent[name]);
				}

				for (let i = 0; i < keyWords.length; i++) {
					if (control.value == keyWords[i]) {
						return {message: this.translate.instant('i18n.errorMsg.validateKeyWords', {value: field})};
					}
				}
				return null;
			}
		};
	}

	validateWhiteSpace(): ValidatorFn {
		return (control: AbstractControl): {[key: string]: boolean} | null => {
			if (control.value) {
				let field = '';

				if (control.parent) {
					let parent = control.parent.controls;
					field = Object.keys(parent).find((name) => control == parent[name]);
				}

				const matches = control.value.match(spaceTabs);
				return matches && matches.length ? {message: this.translate.instant('i18n.errorMsg.validateWhiteSpace', {value: field})} : null;
			}
		};
	}

	invalidMemberTypes(invalidTypes: Map<string, ObjectDescription>): ValidatorFn {
		return (control: AbstractControl): {[key: string]: boolean} | null => {
			if (control.value) {
				if (invalidTypes.has(control.value)) {
					return {message: this.translate.instant('i18n.errorMsg.validateMemberType')};
				} else {
					return null;
				}
			}
		};
	}

	validateName(): ValidatorFn {
		return (control: AbstractControl): {[key: string]: boolean} | null => {
			if (control.value) {
				//Get user input
				let name = control.value;

				//Define Regex
				let NAME_REGEXP = /\./;

				if (name && name.length > 0) {
					if (NAME_REGEXP.test(name)) {
						return {name_err: true, message: this.translate.instant('i18n.errorMsg.validateName')};
					} else {
						return null;
					}
				}
			}
		};
	}

	validateVersion(): ValidatorFn {
		return (control: AbstractControl): {[key: string]: boolean} | null => {
			if (control.value) {
				//Get user input
				let version = control.value;

				//Define Regex -> 1.0 format need to be handled in BE!
				let VERSION_REGEXP = /^([a-u]|[x-z]|[A-U]|[X-Z]|\d)([a-z]|[A-Z]|\d|-|\.)*$/;

				if (version && version.length > 0) {
					if (VERSION_REGEXP.test(version)) {
						//Matches regex return null
						return null;
					} else {
						//Matches not regex return message
						return {message: this.translate.instant('i18n.errorMsg.validateVersion')};
					}
				}
			}
		};
	}

	validatePassword(group: UntypedFormGroup): ValidatorFn {
		return (field: AbstractControl): {[key: string]: any} => {
			const password: string = group.controls.plain.value;
			const repassword: string = group.controls.confirm.value;
			if (password && repassword) {
				if (password === repassword) {
					group.controls.plain.setErrors(null);
					group.controls.confirm.setErrors(null);
					return null;
				} else {
					return {
						password: true,
						message: this.translate.instant('i18n.errorMsg.validatePasswords', {value: field}),
					};
				}
			}
		};
	}

	validateMappingModelName(field_name): ValidatorFn {
		return (control: AbstractControl): {[key: string]: any} => {
			const name = control.value;
			const REGEXP = /^([a-z]|[A-Z])([a-z]|[A-Z]|\d|_)*$/;
			if (name && name.length > 0) {
				if (REGEXP.test(name)) {
					if (keyWords.includes(name)) {
						return {message: this.translate.instant('i18n.errorMsg.validateKeyWords', {value: field_name})};
					}
					return null;
				} else {
					if (name.length == 1) {
						return {message: this.translate.instant('i18n.errorMsg.validateMappingModelNameFirstLetterCharacter', {value: field_name})};
					} else {
						return {message: this.translate.instant('i18n.errorMsg.validateMappingModelName')};
					}
				}
			}
		};
	}

	/* NEEDS REVIEW >>>> */

	/* USED */

	/**
	 * Validate docker endpoint uri - e.g: tcp://192.168.56.101:2376 or unix://192.168.56.101
	 * If Unix, port is not mandatory
	 * @param c
	 */
	validateDockerEndpointUri(c: UntypedFormControl) {
		let uriName = c.value;

		let ENDPOINT_URI = /\b((tcp):\/\/?)[^\s()<>]+(?:\([\w\d]+\)|([^[:punct:]\s]|\/?))|((unix):\/\/?)[^\s()<>]+(?:\([\w\d]+\)|([^[:punct:]\s]|\/?))/;

		if (uriName && uriName.length > 0) {
			if (ENDPOINT_URI.test(uriName)) {
				return null;
			} else {
				return {
					dockerEndpointUri_err: true,
					message: 'Invalid URL. The URL has to be a valid unix socket address (e.g unix:///var/run/docker.sock) or a valid IP address (e.g tcp://127.0.0.1:2375) ',
				};
			}
		}
	}

	public customPatternValid(config: any): ValidatorFn {
		return (control: UntypedFormControl) => {
			let urlRegEx: RegExp = config.pattern;
			if (control.value && !control.value.match(urlRegEx)) {
				return {
					invalidMsg: config.msg,
				};
			} else {
				return null;
			}
		};
	}

	validateDuplicateModelName(models: Array<[string, Identifier]>) {
		return (modelNameFormControl: AbstractControl): {[key: string]: any} => {
			if (modelNameFormControl.dirty || modelNameFormControl.touched) {
				const found = models.find((model) => modelNameFormControl.value === model[0]);
				if (found) {
					return {member_id_err: true, message: this.translate.instant('i18n.errorMsg.validateDuplicateModelName')};
				} else {
					return null;
				}
			}
		};
	}

	validateRuleName(formControl: UntypedFormControl) {
		const value = formControl.value;
		if (!(value instanceof Object)) {
			const regexp = new RegExp(/[a-zA-Z0-9_/\s]*/g);
			let matches = formControl.value.match(whiteSpaces);
			matches += formControl.value.match(periods);
			if (regexp.test(value)) {
				return matches && matches.length !== 0 ? {err: true, message: 'i18n.errorMsg.validateRuleName'} : null;
			} else {
				return {err: true, message: 'i18n.errorMsg.validateRuleName'};
			}
		}
	}

	validateInstanceName(instanceName: string): ValidatorFn {
		return (control: AbstractControl): {[key: string]: boolean} | null => {
			if (control.value !== instanceName) {
				return {message: this.translate.instant('i18n.errorMsg.validateInstanceName')};
			} else {
				return null;
			}
		};
	}

	formatRule(rule) {
		if (rule.id.indexOf(' ') !== 0) {
			rule.id = rule.id.replace(new RegExp(/\s/g), '_');
		}
	}

	validateDockerContainerName(c: UntypedFormControl) {
		let containerName = c.value;

		let CONTAINER_NAME = /^[a-zA-Z0-9][a-zA-Z0-9_.-]*$/;

		if (containerName && containerName.length > 0) {
			if (CONTAINER_NAME.test(containerName)) {
				return null;
			} else {
				return {containerName_err: true, message: this.translate.instant('i18n.errorMsg.validateDockerContainerName')};
			}
		}
	}

	// validateFormGroup = (form): boolean => {
	// 	if(form) {
	// 		let isValid = _.every(form.controls, control => {
	// 			if(control instanceof FormGroup) {
	// 				if(control.controls) {
	// 					return this.validateFormGroup(control);
	// 				}
	// 				else {
	// 					return control.valid;
	// 				}
	// 			}
	// 			else {
	// 				if(control instanceof FormArray) {
	// 					return !this.validateForm(control);
	// 				}
	// 				else {
	// 					return control.valid;
	// 				}
	// 			}
	// 		});
	// 		return isValid;
	// 	}
	// };

	// not used
	// validateForm = (form): any => {
	// 	if(form) {
	// 		let control;
	// 		for(let c of Object.values(form.controls)) {
	// 			if(c instanceof FormControl) {
	// 				if(c.invalid) {
	// 					control = c;
	// 					break;
	// 				}
	// 			}
	// 			if(c instanceof FormGroup) {
	// 				control = this.validateForm(c);
	// 				if(control) {
	// 					break;
	// 				}
	// 			}
	// 			if(c instanceof FormArray && c.invalid) {
	// 				let found;
	// 				found = Object.values(form.controls).find((f: AbstractControl) => {
	// 					return f == c;
	// 				});
	// 				if(found) {
	// 					control = found;
	// 					if(c.controls.length != 0) {
	// 						this.validateForm(c);
	// 					}
	// 					else {
	// 						break;
	// 					}
	// 				}
	// 			}
	// 		}
	// 		return control;
	// 	}
	// };

	// validatePassword(group: FormGroup) {
	//   let password = group.controls.password.value;
	//   let repassword = group.controls.repassword.value;

	//   return password === repassword ? null : { 'password': true, 'message':
	//    this.translate.instant('i18n.errorMsg.validatePasswords',
	//     { value: group.controls.password })
	//   }
	// }

	validatePort(type): ValidatorFn {
		return (port: AbstractControl): {[key: string]: any} => {
			if (typeof port.value == undefined || port.value.length == '') {
				if (type == 'opc-ua-server') {
					return {port: true, message: this.translate.instant('i18n.errorMsg.validatePortNotEmpty')};
				} else {
					return null;
				}
			} else {
				if (type == 'opc-ua-server') {
					const matches = port.value.match(numbers);
					return matches && matches.length ? null : {port: true, message: this.translate.instant('i18n.errorMsg.validatePortOnlyNumber')};
				} else {
					return null;
				}
			}
		};
	}

	validateMinChars(c: UntypedFormControl, min): ValidatorFn {
		return () => {
			let field = '';
			if (c.parent) {
				let parent = c.parent.controls;
				field = Object.keys(parent).find((name) => c == parent[name]);
			}
			let valid = false;

			if (c.value && c.value.length < min) {
				valid = false;
			} else {
				if (c.value) {
					valid = true;
				} else {
					valid = false;
				}
			}

			if (!valid) {
				if (min == 1) {
					return {input: true, message: `The ${field} must be at least ${min} character.`};
				} else {
					return {input: true, message: `The ${field} must be ${min} characters.`};
				}
			}
			return null;
		};
	}

	validateMinItems(arr: UntypedFormArray, min, fieldName: string = ''): ValidatorFn {
		return () => {
			let field = '';
			if (fieldName != '') {
				field = fieldName;
			} else {
				if (arr.parent) {
					const parent = arr.parent.controls;
					field = Object.keys(parent).find((name) => arr === parent[name]);
				}
			}
			let valid = false;

			if (arr.controls && arr.controls.length < min) {
				valid = false;
			} else {
				valid = true;
			}

			if (!valid) {
				if (min === 1) {
					return {input: true, message: `The ${field} must have at least ${min} item.`};
				} else {
					return {input: true, message: `The ${field} must have at least ${min} items.`};
				}
			}
			return null;
		};
	}

	validateRequired(c: UntypedFormControl): ValidatorFn {
		return () => {
			let field = '';
			if (c.parent) {
				let parent = c.parent.controls;
				field = Object.keys(parent).find((name) => c == parent[name]);
			}
			let valid = false;

			if (c.value) {
				valid = true;
			} else {
				valid = false;
			}

			if (!valid) {
				return {input: true, message: `The ${field} is required.`};
			}
			return null;
		};
	}

	highlightId(copiedId, action): ValidatorFn {
		return (id: AbstractControl): {[key: string]: any} => {
			if (copiedId == id.value && action == 'paste' && id.value.length != 0) {
				return {pastedId: true, message: 'Change the id.'};
			}
			return null;
		};
	}

	validateDuplicate(selectedNode: Node, target: Node): ValidatorFn {
		return (id: AbstractControl): {[key: string]: any} => {
			let valid = true;
			if (target) {
				let duplicated;
				// duplicated = target.children.find(kid => {
				// 	return kid.memberDescription.id == id.value;
				// });

				if (duplicated) {
					valid = false;
				} else {
					valid = true;
				}

				if (selectedNode) {
					// if(selectedNode.memberDescription.id == id.value) {
					// 	valid = true;
					// }
				}
			}

			if (!valid) {
				return {duplicate: true, message: 'This id already exists.'};
			}
			return null;
		};
	}

	validateNotFound(): ValidatorFn {
		return (control: AbstractControl): {[key: string]: boolean} | null => {
			return {message: this.translate.instant('Definition not found')};
		};
	}

	public getLocaleString(key: string | string[], params?: object, instant?: boolean): Observable<string | object> | any {
		return instant ? this.translate.instant(key, params) : this.translate.get(key, params);
	}

	validateDeploymentPort(): ValidatorFn {
		return (control: AbstractControl): {[key: string]: string} | null => {
			if (control.value) {
				let port = control.value;
				// console.log('port', port);
				let PORT_REGEXP = /^((6553[0-5])|(655[0-2][0-9])|(65[0-4][0-9]{2})|(6[0-4][0-9]{3})|([1-5][0-9]{4})|([0-5]{0,5})|([0-9]{1,4}))$/;

				if (port && port !== 0) {
					if (PORT_REGEXP.test(port)) {
						return null;
					} else {
						return {message: this.translate.instant('i18n.errorMsg.invalidPort')};
					}
				} else {
					return {message: this.translate.instant('i18n.errorMsg.validatePortNotEmpty')};
				}
			}
		};
	}

	inputFieldValidator(type: string, controlName: string): ValidatorFn {
		return (control: AbstractControl): {[key: string]: boolean} | null => {
			if (control.value.toString().includes('$ENV[')) {
				return null;
			} else {
				if (type == 'number' && control.value.toString().match(numbers)) {
					return null;
				} else if (type == 'text') {
					return null;
				} else return {message: this.translate.instant('i18n.errorMsg.validateNotANumber', {value: controlName})};
			}
		};
	}
}

const validCharacters = /[^\s\w,._:&\/()+%'`@-]/;
const spaceTabs = /[ \t]/;
const numbers = /^-?\d*\.?\d*$/;

export class passwordErrorStateMatcher implements ErrorStateMatcher {
	isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
		const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
		const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

		return invalidCtrl || invalidParent;
	}
}
