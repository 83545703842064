import {ChangeDetectorRef, Component, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {I40TreeMemberEditorComponent} from '../i40-tree-member-editor-wrapper';
import {I40TreeCrudService} from '../../i40-tree-crud.service';
import {AuthService} from '../../../../../core-services/auth.service';
import {ValidatorService} from '../../../../../core-services/validator.service';
import {MemberDefinitionType, MemberDescription, NewCommandMemberType} from '../../../types';
import {MessageResponseService} from '../../../../../core-services/message-response.service';
import {TranslateService} from '@ngx-translate/core';
import * as _ from 'lodash';
import {Router} from '@angular/router';
import {GlobalService} from '../../../../../core-services/global.service';

@Component({
	selector: 'app-i40-tree-command-editor',
	templateUrl: './i40-tree-command-editor.component.html',
	styleUrls: ['./i40-tree-command-editor.component.css', '../i40-tree-member-editor-wrapper.scss'],
})
export class I40TreeCommandEditorComponent extends I40TreeMemberEditorComponent implements OnInit, OnChanges, OnDestroy {
	constructor(
		tsrv: I40TreeCrudService,
		authSrv: AuthService,
		validatorSrv: ValidatorService,
		msgSrv: MessageResponseService,
		translate: TranslateService,
		router: Router,
		globalSrv: GlobalService,
		cdRef: ChangeDetectorRef,
	) {
		super(tsrv, authSrv, validatorSrv, msgSrv, translate, router, globalSrv, cdRef);
	}

	updateTree() {
		const oldMemberTypeName = this.selectedObject.typeName;
		const generatedTypeName = 'Command_' + this.form.get('id').value;

		this.selectedObject._memberType.members.forEach((v: MemberDescription, k: string) => {
			let type = '';
			let id = this.selectedObject._formGroup.get('id').value;
			id = id.replace(/^./, id[0].toUpperCase());
			type = v.definitionType === MemberDefinitionType.CommandParameters ? id + 'Type' : id + 'ReplyType';
			v.typeName = type;
			this.selectedObject._memberType.members.set(k, v);

			this.tsrv.addNewModelMemberType(type);
		});

		let newMemberType = _.cloneDeep(this.selectedObject._memberType);

		if (oldMemberTypeName !== generatedTypeName) {
			newMemberType.id = generatedTypeName;
		}

		this.form.get('typeName').setValue(generatedTypeName);

		// set new values for flat member as well as original member
		this.selectedObject.setFormValues();

		if (oldMemberTypeName !== generatedTypeName) {
			if (oldMemberTypeName !== NewCommandMemberType.id) {
				this.tsrv.modelMemberTypes.delete(oldMemberTypeName);
			}

			this.tsrv.modelMemberTypes.set(generatedTypeName, newMemberType);
			this.tsrv.allMemberTypes = this.tsrv.getAllMemberTypes();
		}

		this.selectedObject._path = this.tsrv.buildPath(this.selectedObject).reverse();
		this.selectedObject._uniqueIndex = this.tsrv.generateId(this.selectedObject);

		// presume valid until refresh when it mark invalid nodes
		this.tsrv.isValid = true;

		// refresh model to display update
		this.tsrv.refreshModel();

		this.selectedObject = this.tsrv.getNodeByPath(this.selectedObject._path.join('/'));

		this.onSelectedObjectChange();

		// mark model as dirty
		this.tsrv.isDirty = true;

		this.tsrv.parentDataSrv.editorStatus = ['clean'];
	}
}
