import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {ArtifactInfo, Identifier} from '../../../../data-models/core-model.model';
import {JsonModelGeneratorItem, JsonToModelItem} from '../json-model-generator-plugin.model';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../../../core-services/auth.service';
import {MessageResponseService} from '../../../../core-services/message-response.service';
import {TranslateService} from '@ngx-translate/core';
import {ValidatorService} from '../../../../core-services/validator.service';
import {GlobalService} from '../../../../core-services/global.service';
import {PluginsService} from '../../plugins.service';
import {ThemeService} from '../../../../core-services/theme.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
	selector: 'json-model-generator-modal-add',
	templateUrl: './json-model-generator-modal-add.component.html',
	styleUrls: ['./json-model-generator-modal-add.component.scss'],
})
export class JsonModelGeneratorModalAddComponent implements OnInit {
	mat_icon_type: string = 'extension';
	mat_title: string = 'JSON Model Generator Plugin';

	modelsList: ArtifactInfo[] = [];

	plugin_add: JsonModelGeneratorItem;
	plugin_update: JsonModelGeneratorItem;
	pluginForm_add: UntypedFormGroup;
	pluginForm_update: UntypedFormGroup;

	definitionTypes: string[] = ['Model', 'Event', 'Variable', 'Command'];
	jsonInputOptions;

	constructor(
		public router: Router,
		public route: ActivatedRoute,
		public authSrv: AuthService,
		public msgSrv: MessageResponseService,
		public translate: TranslateService,
		public validatorSrv: ValidatorService,
		public globalSrv: GlobalService,
		public pluginsSrv: PluginsService,
		public cdRef: ChangeDetectorRef,
		public themeSrv: ThemeService,
		public dialogRef: MatDialogRef<JsonModelGeneratorModalAddComponent>,
		@Inject(MAT_DIALOG_DATA) public modalData,
	) {
		this.plugin_add = new JsonModelGeneratorItem({});
		this.plugin_update = new JsonModelGeneratorItem({});

		this.pluginForm_add = this.plugin_add._formGroup;
		this.pluginForm_update = this.plugin_update._formGroup;
	}

	ngOnInit(): void {}

	compareModels(o1: ArtifactInfo, o2: ArtifactInfo) {
		return o1.identifier.id === o2.identifier.id && o1.identifier.version === o2.identifier.version;
	}

	addItem() {
		this.plugin_add.push(new JsonToModelItem({}, this.plugin_add), 'items');
		this.cdRef.detectChanges();
	}

	removeItem(index) {
		this.plugin_add.pop(index, 'items');
		this.cdRef.detectChanges();
	}

	run() {
		this.msgSrv.onLoading.next({command: 'start', message: this.translate.instant('i18n.snackbar.running')});

		this.pluginsSrv.runPlugin('Json model generator', {JsonToModelImportAction: this.plugin_add.serialize()}).subscribe({
			next: (res) => {
				this.msgSrv.onLoading.next({command: 'stop'});
				this.msgSrv.showSuccess('Plugin run successfully');
				this.pluginForm_add.markAsPristine();

				this.close();

				if (res['JsonToModelImportActionResult']) {
					const new_id_ver = new Identifier(res['JsonToModelImportActionResult'].model.info.identifier).getId();
					this.router.navigate([`/information-models/edit/${new_id_ver}`]);
				}
			},
			error: (err) => {
				this.msgSrv.onLoading.next({command: 'stop'});
			},
		});
	}

	close(): void {
		const callback = () => {
			this.pluginForm_add.markAsPristine();
			this.pluginForm_update.markAsPristine();
			this.dialogRef.close();
		};

		if (this.pluginForm_add.dirty || this.pluginForm_update.dirty) {
			this.msgSrv.unsavedChangesClose(callback);
		} else {
			callback();
		}
	}

	getFormControl(abstractFormControl): UntypedFormControl {
		return abstractFormControl as UntypedFormControl;
	}
}
