import {Routes} from '@angular/router';
import {AuthGuard} from './core-services/auth.guard';
import {WrapperComponent} from './root/wrapper.component';
import {StartScreenComponent} from './apps/start-screen/start-screen.component';
import {HomeDashboardComponent} from './apps/dashboard/home-dashboard.component';
import {WrapperModalComponent} from './root/wrapper-modal/wrapper-modal.component';

export const rootRoutes: Routes = [
	{
		path: '',
		component: WrapperComponent,
		canActivate: [AuthGuard],
		children: [
			{path: '', redirectTo: 'home', pathMatch: 'full'},
			{path: 'home', component: StartScreenComponent, canActivate: [AuthGuard]},
			{path: 'dashboard', component: HomeDashboardComponent, canActivate: [AuthGuard]},
			{path: 'information-models', loadChildren: () => import('./apps/information-models/information-models.module').then((m) => m.InformationModelsModule), canActivate: [AuthGuard]},
			{path: 'channel-management', loadChildren: () => import('./apps/channel/channel.module').then((m) => m.ChannelModule), canActivate: [AuthGuard]},
			{path: 'channel-types', loadChildren: () => import('./apps/channel-types/channel-types.module').then((m) => m.ChannelTypesModule), canActivate: [AuthGuard]},
			{path: 'model-mapping', loadChildren: () => import('./apps/mapping/mapping.module').then((m) => m.MappingModule), canActivate: [AuthGuard]},
			{path: 'device-type', loadChildren: () => import('./apps/device-type/device-type.module').then((m) => m.DeviceTypeModule), canActivate: [AuthGuard]},
			{path: 'instance', loadChildren: () => import('./apps/instance/instance.module').then((m) => m.InstanceModule), canActivate: [AuthGuard]},
			{path: 'deployments', loadChildren: () => import('./apps/deployment/deployment.module').then((m) => m.DeploymentModule), canActivate: [AuthGuard]},
			{path: 'deployment-endpoints', loadChildren: () => import('./apps/deployment-endpoints/deployment-endpoints.module').then((m) => m.DeploymentEndpointsModule), canActivate: [AuthGuard]},
			{path: 'status', loadChildren: () => import('./apps/instance-dashboard/dashboard.module').then((m) => m.DashboardModule), canActivate: [AuthGuard]},
			{path: 'users', loadChildren: () => import('./apps/users/user-management.module').then((m) => m.UserManagementModule), canActivate: [AuthGuard]},
			{path: 'credential-management', loadChildren: () => import('./apps/credential-management/credential-management.module').then((m) => m.CredentialManagementModule), canActivate: [AuthGuard]},
			{path: 'versions', loadChildren: () => import('./apps/docker-jre-versions/docker-jre-versions.module').then((m) => m.DockerJreVersionsModule), canActivate: [AuthGuard]},
			{path: 'translations', loadChildren: () => import('./apps/translation-manager/translation-manager.module').then((m) => m.TranslationManagerModule), canActivate: [AuthGuard]},
			{path: 'logging-configurations', loadChildren: () => import('./apps/logging-configurations/logging-configurations.module').then((m) => m.LoggingConfigurationsModule), canActivate: [AuthGuard]},
			{path: 'notifications', loadChildren: () => import('./apps/notifications/notifications.module').then((m) => m.NotificationsModule), canActivate: [AuthGuard]},
			{path: 'logs', loadChildren: () => import('./apps/log-inspector/log-inspector.module').then((m) => m.LogInspectorModule), canActivate: [AuthGuard]},
			{path: 'repository', loadChildren: () => import('./apps/backup-repository/backup-repository.module').then((m) => m.BackupRepositoryModule), canActivate: [AuthGuard]},
			{path: 'plugins', loadChildren: () => import('./apps/plugins/plugins.module').then((m) => m.PluginsModule), canActivate: [AuthGuard]},
			{path: 'compile', loadChildren: () => import('./apps/compile-artifact/compile-artifact.module').then((m) => m.CompileArtifactModule), canActivate: [AuthGuard]},
			{path: 'alert', loadChildren: () => import('./apps/alert/alert.module').then((m) => m.AlertModule), canActivate: [AuthGuard]},
			{path: 'environment-variables', loadChildren: () => import('./apps/environment-variables/environment-variables.module').then((m) => m.EnvironmentVariablesModule), canActivate: [AuthGuard]},
		],
	},
	{
		path: 'modal',
		component: WrapperModalComponent,
		canActivate: [AuthGuard],
		children: [
			{path: 'home', component: StartScreenComponent, canActivate: [AuthGuard]},
			{path: 'information-models', loadChildren: () => import('./apps/information-models/information-models.module').then((m) => m.InformationModelsModule), canActivate: [AuthGuard]},
			{path: 'channel-management', loadChildren: () => import('./apps/channel/channel.module').then((m) => m.ChannelModule), canActivate: [AuthGuard]},
			{path: 'channel-types', loadChildren: () => import('./apps/channel-types/channel-types.module').then((m) => m.ChannelTypesModule), canActivate: [AuthGuard]},
			{path: 'model-mapping', loadChildren: () => import('./apps/mapping/mapping.module').then((m) => m.MappingModule), canActivate: [AuthGuard]},
			{path: 'device-type', loadChildren: () => import('./apps/device-type/device-type.module').then((m) => m.DeviceTypeModule), canActivate: [AuthGuard]},
			{path: 'instance', loadChildren: () => import('./apps/instance/instance.module').then((m) => m.InstanceModule), canActivate: [AuthGuard]},
			{path: 'deployments', loadChildren: () => import('./apps/deployment/deployment.module').then((m) => m.DeploymentModule), canActivate: [AuthGuard]},
			{path: 'deployment-endpoints', loadChildren: () => import('./apps/deployment-endpoints/deployment-endpoints.module').then((m) => m.DeploymentEndpointsModule), canActivate: [AuthGuard]},
			{path: 'users', loadChildren: () => import('./apps/users/user-management.module').then((m) => m.UserManagementModule), canActivate: [AuthGuard]},
			{path: 'credential-management', loadChildren: () => import('./apps/credential-management/credential-management.module').then((m) => m.CredentialManagementModule), canActivate: [AuthGuard]},
			{path: 'versions', loadChildren: () => import('./apps/docker-jre-versions/docker-jre-versions.module').then((m) => m.DockerJreVersionsModule), canActivate: [AuthGuard]},
			{path: 'logs', loadChildren: () => import('./apps/log-viewer/log-viewer.module').then((m) => m.LogViewerModule), canActivate: [AuthGuard]},
			{path: 'environment-variables', loadChildren: () => import('./apps/environment-variables/environment-variables.module').then((m) => m.EnvironmentVariablesModule), canActivate: [AuthGuard]},
		],
	},
	{path: 'login', loadChildren: () => import('./apps/login/login.module').then((m) => m.LoginModule)},
];
