import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {I40FlatMember, MemberDefinitionType} from '../../../types';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {JSONSchemaProperty} from '../../../../../data-models/json-schema.model';
import * as _ from 'lodash';
import {I40TreeChannelService} from '../../i40-tree-channel.service';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from '../../../../../core-services/auth.service';
import {GlobalService} from '../../../../../core-services/global.service';

@Component({
	selector: 'app-group',
	templateUrl: './group.component.html',
	styleUrls: ['./group.component.css'],
})
export class GroupComponent implements OnInit, OnChanges {
	@Input() title?: string = '';
	@Input() form: UntypedFormGroup;
	@Input() properties: JSONSchemaProperty[];
	@Input() selectedObject: I40FlatMember;
	@Input() layer: string;
	@Input() isRequired: boolean = false;
	@Input() requiredChildren?: string[] = [];
	@Input() expanded?: boolean = true;
	@Input() collapsible: boolean = true;
	@Input() showContainer: boolean = true;

	@Input() savedValue: any;
	@Input() emptyFormValue: any;

	visibleElements = [];
	visibilityMap = {};
	selectedObjectPath: string = '';

	@Output() visible: EventEmitter<boolean> = new EventEmitter<boolean>();

	@Output('onExpandToggle') onExpandToggle: EventEmitter<any> = new EventEmitter<any>();

	parsed: boolean = false;

	constructor(
		public treeSrv: I40TreeChannelService,
		public translateSrv: TranslateService,
		public authSrv: AuthService,
		public globalSrv: GlobalService,
	) {}

	ngOnInit() {}

	ngOnChanges() {
		this.parsed = false;
		this.visibleElements = [];
		this.visibilityMap = {};

		// console.log(this.selectedObject._fullPath);

		this.selectedObjectPath = this.selectedObject._fullPath
			.filter((p, i) => {
				return (!(i % 2) && !['Model', 'CommandParameters', 'CommandReply'].includes(p)) || (['CommandParameters', 'CommandReply'].includes(p) && i === this.selectedObject._fullPath.length - 2);
			})
			.map((p, i, arr) => {
				if (p === 'Array' && (i < arr.length - 1 || this.selectedObject._hasChildren)) {
					return 'ComplexArray';
				}
				if (p === 'List' && (i < arr.length - 1 || this.selectedObject._hasChildren)) {
					return 'ComplexList';
				}
				if (p === 'Variable' && (i < arr.length - 1 || this.selectedObject._hasChildren)) {
					return 'StructuredVariable';
				} else {
					return p;
				}
			})
			.join('/');

		// console.log(this.title, this.form);

		if (this.form) {
			this.properties.forEach((p) => {
				p.expanded = this.isExpanded(p);

				if (p.type === 'object') {
					const gkey = this.layer + '_' + p.key + '_' + p.compatiblePath;
					const emptyValue = this.emptyFormValue && this.emptyFormValue[p.key] ? this.emptyFormValue[p.key] : undefined;
					const savedValue = this.savedValue && this.savedValue[p.key] ? this.savedValue[p.key] : undefined;
					const formValue = this.form.value[p.key];

					if (
						!this.requiredChildren.includes(p.key) &&
						!((savedValue !== undefined && JSON.stringify(formValue) !== JSON.stringify(savedValue)) || JSON.stringify(formValue) !== JSON.stringify(emptyValue))
					) {
						if (this.form.controls[p.key]) {
							this.form.get(p.key).setParent(null);

							if (this.treeSrv.groupForms[gkey] === undefined) {
								this.treeSrv.groupForms[gkey] = _.cloneDeep(this.form.get(p.key));
							}
							this.form.removeControl(p.key);
						}
					}
				}

				if (this.form.get(p.key)) {
					this.form.get(p.key).markAsTouched();
					this.form.get(p.key).updateValueAndValidity({onlySelf: true});
				}
			});

			setTimeout(() => {
				this.properties.forEach((p) => {
					this.visibilityMap[p.key] = this.isVisible(p);
				});

				this.visible.emit(this.visibleElements.length > 0);

				if (!this.globalSrv.canEdit(this.treeSrv.rootParent)) {
					this.form.disable();
				}

				this.parsed = true;
			});
		} else {
			setTimeout(() => {
				this.properties.forEach((p) => {
					this.visibilityMap[p.key] = this.isVisible(p);
				});

				this.visible.emit(this.visibleElements.length > 0);
				this.parsed = true;
			});
		}
	}

	titleToId(type) {
		return (this.title.includes('i18n') ? this.translateSrv.instant(this.title).replace(/[ .]/g, '_').toLowerCase() : this.title.replace(/[ .]/g, '_').toLowerCase()) + '_' + type;
	}

	onExpantionToggle(expanded) {
		this.onExpandToggle.emit(expanded);
	}

	toggleGroup(expaded, property) {
		if (!this.requiredChildren.includes(property.key)) {
			const gkey = this.layer + '_' + property.key + '_' + property.compatiblePath;

			if (expaded) {
				if (this.treeSrv.groupForms[gkey] !== undefined) {
					this.treeSrv.groupForms[gkey].setParent(this.form);
					this.form.addControl(property.key, this.treeSrv.groupForms[gkey]);
					// this.emptyFormValue[property.key] = this.treeSrv.groupForms[gkey].value;
				}
			} else {
				this.form.get(property.key).setParent(null);

				if (this.treeSrv.groupForms[gkey] === undefined) {
					this.treeSrv.groupForms[gkey] = _.cloneDeep(this.form.get(property.key));
				}
				this.form.removeControl(property.key);
			}
		}
	}

	isExpanded(property: JSONSchemaProperty) {
		// if(property.key=="subscriptionConfiguration"){
		// 	console.log(property.key, `isExpanded=`)
		// }

		const savedValue = this.savedValue && this.savedValue[property.key] ? this.savedValue[property.key] : undefined;
		const emptyValue = this.emptyFormValue && this.emptyFormValue[property.key] ? this.emptyFormValue[property.key] : undefined;
		const formValue = this.form.value[property.key];

		// added condition for opc-ua variable subscription node not displayed even we had a saved value
		if (!this.isRequired && JSON.stringify(savedValue) !== undefined) {
			return true;
		} else {
			return (
				this.requiredChildren.includes(property.key) ||
				(JSON.stringify(formValue) !== undefined &&
					(JSON.stringify(formValue) !== JSON.stringify(savedValue) || JSON.stringify(savedValue) !== JSON.stringify(emptyValue)) &&
					!(savedValue === undefined && JSON.stringify(formValue) === JSON.stringify(emptyValue)))
			);
		}
	}

	subgroupVisible(visible: boolean, property: JSONSchemaProperty) {
		if (property.key === 'versionInfo') {
			visible = false;
		}
		if (visible) {
			this.visibleElements.push(property);
			this.visibilityMap[property.key] = true;
			this.visible.emit(this.visibleElements.length > 0);
		}
	}

	isVisible(property: JSONSchemaProperty) {
		let visible: boolean;

		let selectedObjectType: any = this.selectedObject.definitionType;
		if (selectedObjectType === MemberDefinitionType.Variable && this.selectedObject._hasChildren) {
			selectedObjectType = 'StructuredVariable';
		}
		if (selectedObjectType === MemberDefinitionType.Array && this.selectedObject._hasChildren) {
			selectedObjectType = 'ComplexArray';
		}
		if (selectedObjectType === MemberDefinitionType.List && this.selectedObject._hasChildren) {
			selectedObjectType = 'ComplexList';
		}

		switch (property.type) {
			case 'string':
			case 'number':
			case 'date':
			case 'integer':
			case 'enum':
			case 'boolean':
			case 'object':
			case 'anyOf':
			case 'oneOf':
			case 'credentials-selector':
				visible =
					(property.compatiblePath === '' && selectedObjectType === 'Model') ||
					property.compatiblePath === this.selectedObjectPath ||
					(property.compatiblePath === this.selectedObjectPath && property.compatibleChildren.length === 0) ||
					(property.compatibleChildren.includes(selectedObjectType) && this.selectedObjectPath.includes(property.compatiblePath) && (this.form.parent?.valid ?? this.form.valid)) ||
					this.form?.invalid;
				break;
			case 'model_path_array':
			case 'array':
				visible =
					(property.compatiblePath === '' && selectedObjectType === 'Model') ||
					(this.selectedObjectPath.indexOf(property.compatiblePath) === 0 &&
						(this.selectedObjectPath === property.compatiblePath ||
							(property.compatibleChildren.includes(selectedObjectType) &&
								this.form?.value[property.key].some((p) => {
									return ('/' + this.selectedObjectPath).includes(p.modelPath);
								}))));
				break;
			case 'model_path':
				visible = false;
				break;
			default:
				visible = true;
		}

		if (property.key === 'versionInfo') {
			visible = false;
		}

		//console.log(property.key, visible, this.selectedObjectPath, property.compatiblePath, property);

		if (visible) {
			this.visibleElements.push(property);
		}

		return visible;
	}

	getFormGroup(key): UntypedFormGroup {
		return this.form.get(key) as UntypedFormGroup;
	}

	getFormControl(key): UntypedFormControl {
		return this.form.get(key) as UntypedFormControl;
	}

	getFormArray(key): UntypedFormArray {
		return this.form.get(key) as UntypedFormArray;
	}
}
