import {ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {map, Observable, startWith} from 'rxjs';
import {GlobalService} from 'src/app/core-services/global.service';
import {ValidatorService} from '../../core-services/validator.service';

@Component({
	selector: 'app-generic-gnvd',
	templateUrl: './generic-gnvd.component.html',
	styleUrls: ['./generic-gnvd.component.scss'],
})
export class GenericGnvdComponent implements OnInit, OnChanges {
	form: UntypedFormGroup;
	groupControl: UntypedFormControl;
	nameControl: UntypedFormControl;
	versionControl: UntypedFormControl;
	descriptionControl: UntypedFormControl;
	filteredGroups: Observable<string[]>;

	isEdit = false;

	@Input('fieldOnInit') fieldOnInit = '';
	@Input('parentGroup') parentGroup;

	@ViewChild('selectOnInit', {static: false}) selectOnInit: ElementRef;

	selectFirstFieldTimeout = null;

	constructor(
		public validatorService: ValidatorService,
		public cdRef: ChangeDetectorRef,
		public globalSrv: GlobalService,
	) {}

	initForm() {
		this.globalSrv.groups = Array.from(new Set(this.globalSrv.groups));
		this.form = this.parentGroup;

		this.form.get('externalIdentifier')
			? (this.groupControl = this.form.get('externalIdentifier').get('group') as UntypedFormControl)
			: (this.groupControl = this.form.get('group') as UntypedFormControl);
		this.groupControl.setValidators([
			Validators.required,
			this.validatorService.validateGroupName(),
			this.validatorService.validateCharacters(),
			this.validatorService.validateKeyWords(),
			this.validatorService.validateWhiteSpace(),
		]);

		this.form.get('externalIdentifier') ? (this.nameControl = this.form.get('externalIdentifier').get('name') as UntypedFormControl) : (this.nameControl = this.form.get('name') as UntypedFormControl);
		this.nameControl.setValidators([Validators.required, this.validatorService.validateName(), this.validatorService.validateKeyWords()]);

		this.descriptionControl = this.form.get('externalDescriptor') && (this.form.get('externalDescriptor').get('description') as UntypedFormControl);

		if (this.fieldOnInit !== '') {
			if (this.selectFirstFieldTimeout) {
				clearTimeout(this.selectFirstFieldTimeout);
			}

			this.selectFirstFieldTimeout = setTimeout(() => {
				this.selectFirstField();
			}, 200);
		}

		if ((this.form.value.identifier && this.form.value.identifier.id && this.form.value.identifier.version) || this.form.value.id) {
			this.isEdit = true;
		}

		this.form.markAsPristine();
		this.form.markAllAsTouched();
		this.form.updateValueAndValidity();

		this.cdRef.detectChanges();
	}

	ngOnInit() {
		this.initForm();
		this.filteredGroups = this.groupControl.valueChanges.pipe(
			startWith(''),
			map((value) => {
				if (value) {
					return this.filter(value);
				}
			}),
		);
	}

	filter(value: string): string[] {
		return this.globalSrv.groups.filter((option) => option.toLowerCase().includes(value.toLowerCase()));
	}

	ngOnChanges() {
		if (this.parentGroup) {
			this.initForm();
		}
	}

	selectFirstField() {
		if (this.selectOnInit) {
			setTimeout(() => {
				if (this.fieldOnInit === 'select') {
					this.selectOnInit.nativeElement.select();
				} else {
					// this.selectOnInit.nativeElement.focus();
				}
			});
		}
	}
}
