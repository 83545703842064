import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../core-services/auth.service';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {GlobalService} from '../../core-services/global.service';
import {ClipboardService} from 'ngx-clipboard';
import {MatDialogRef} from '@angular/material/dialog';
import {MessageResponseService} from '../../core-services/message-response.service';
import {debounceTime, tap} from 'rxjs/operators';

@Component({
	selector: 'license',
	templateUrl: './license.component.html',
	styleUrls: ['./license.component.scss'],
})
export class LicenseComponent implements OnInit {
	mode: 'license' | 'activation';
	licenseForm: UntypedFormGroup = new UntypedFormGroup({});

	licenseNumberControl: UntypedFormControl;
	licenseNumberVisible = false;

	licenseDataControl: UntypedFormControl;
	activationDataControl: UntypedFormControl;

	gettingLicenseData = false;

	offlineActivationVisible = false;
	currentTime: number = new Date().getTime();

	constructor(
		public authSrv: AuthService,
		public globalSrv: GlobalService,
		public msgSrv: MessageResponseService,
		private _clipboardService: ClipboardService,
		public dialogRef: MatDialogRef<LicenseComponent>,
	) {}

	ngOnInit(): void {
		this.mode = this.authSrv.license.licenseActivationData.licenseType !== 'NO' && this.authSrv.license.licenseActivationData.validTo > this.currentTime ? 'license' : 'activation';

		this.licenseNumberControl = new UntypedFormControl(this.authSrv.license.licenseNumber);
		this.licenseForm.addControl('licenseNumberControl', this.licenseNumberControl);

		this.licenseDataControl = new UntypedFormControl('');
		this.licenseForm.addControl('licenseDataControl', this.licenseDataControl);

		this.activationDataControl = new UntypedFormControl('');
		this.licenseForm.addControl('activationDataControl', this.activationDataControl);

		this.licenseNumberControl.valueChanges
			.pipe(
				tap((license) => {
					if (this.offlineActivationVisible) {
						if (license.length === 0) {
							this.toggleOfflineActivation();
						} else {
							this.licenseDataControl.disable();
							this.activationDataControl.disable();
						}
					}
				}),
				debounceTime(500),
			)
			.subscribe((license) => {
				if (license.length > 28 && this.offlineActivationVisible) {
					this.getLicenceDataOffline();
				}
			});
	}

	toggleLicenseVisibility() {
		this.licenseNumberVisible = !this.licenseNumberVisible;
	}

	toggleOfflineActivation() {
		if (this.offlineActivationVisible) {
			this.offlineActivationVisible = false;
			this.gettingLicenseData = false;

			this.licenseDataControl.enable();
			this.activationDataControl.enable();

			this.licenseDataControl.setValue('');
			this.activationDataControl.setValue('');

			this.activationDataControl.setErrors(null);
			this.activationDataControl.markAsPristine();
			this.activationDataControl.markAsUntouched();
		} else {
			this.getLicenceDataOffline();
		}
	}

	getLicenceDataOffline() {
		this.gettingLicenseData = true;

		return this.authSrv.getLicenseData(this.licenseForm.get('licenseNumberControl').value).subscribe({
			next: (data) => {
				this.licenseDataControl.setValue(data);
				this.offlineActivationVisible = true;
				this.gettingLicenseData = false;
				this.licenseDataControl.enable();
				this.activationDataControl.enable();
			},
		});
	}

	copyLicenseData() {
		this._clipboardService.copyFromContent(this.licenseDataControl.value);
		this._clipboardService.destroy();
	}

	changeLicense() {
		this.mode = 'activation';
	}

	activateLicense() {
		if (this.offlineActivationVisible) {
			this.authSrv.licenseActivateOffline(this.licenseNumberControl.value, this.activationDataControl.value).subscribe({
				next: (license) => {
					this.authSrv.parseLicense(license);
					this.mode = 'license';
					this.licenseForm.markAsPristine();
					this.licenseForm.updateValueAndValidity();

					this.msgSrv.showSuccess('You have successfully activated your license');
				},
				error: (err) => {
					this.msgSrv.showError('There was an error activating your license\n' + '\n' + 'Make sure you have entered valid License number and Activation data', 'License Activation Error');
				},
			});
		} else {
			this.authSrv.licenseActivateOnline(this.licenseNumberControl.value).subscribe({
				next: (license) => {
					this.authSrv.parseLicense(license);
					this.mode = 'license';
					this.licenseForm.markAsPristine();
					this.licenseForm.updateValueAndValidity();

					this.msgSrv.showSuccess('You have successfully activated your license');
				},
				error: (err) => {
					this.msgSrv.showError('There was an error activating your license\n' + '\n' + 'Make sure you have entered a valid License number', 'License Activation Error');
				},
			});
		}
	}

	cancelActivation() {
		if (this.authSrv.license.licenseActivationData.licenseType !== 'NO') {
			this.mode = 'license';
		} else {
			this.dialogRef.close();
		}
	}
}
