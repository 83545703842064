import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'highlight',
})
export class HighlightPipe implements PipeTransform {
	constructor() {}

	transform(value: any, args: any): any {
		if (!args) {
			return value;
		}

		// Match in a case insensitive maneer
		const re = new RegExp(args, 'g');
		const match = value.match(re);

		// If there's no match, just return the original value.
		if (!match) {
			return value;
		} else {
			// console.log(match);
		}

		return value.replace(re, '<mark>' + match[0] + '</mark>');
	}
}
