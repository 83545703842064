import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {FormControl, UntypedFormControl} from '@angular/forms';
import {I40FlatMember} from '../../../types';
import {JSONSchemaProperty} from '../../../../../data-models/json-schema.model';
import {I40TreeChannelService} from '../../i40-tree-channel.service';
import {MatInput} from '@angular/material/input';
import {AuthService} from '../../../../../core-services/auth.service';
import {GlobalService} from '../../../../../core-services/global.service';
import {EnvironmentVariablesService} from 'src/app/apps/environment-variables/environment-variables.service';
import {MessageResponseService} from 'src/app/core-services/message-response.service';
import {ValidatorService} from 'src/app/core-services/validator.service';

@Component({
	selector: 'app-input',
	templateUrl: './input.component.html',
	styleUrls: ['./input.component.css'],
})
export class InputComponent implements OnInit, AfterViewInit {
	type: 'text' | 'password' | 'date' | 'number' = 'text';
	loading = false;

	@Input() control: UntypedFormControl;
	@Input() selectedObject: I40FlatMember;
	@Input() property: JSONSchemaProperty;
	@ViewChild('input', {static: false, read: MatInput}) input: MatInput;

	@ViewChild('input', {static: false}) inputElemRef: ElementRef;

	isEnvVariable: boolean = false;

	@HostListener('window:keydown', ['$event'])
	onKeyPress($event: KeyboardEvent) {
		if (this.input.focused && $event.shiftKey && $event.ctrlKey && $event.key && $event.key.toLowerCase() === 'e') {
			this.msgSrv.addElementPopup(`/modal/environment-variables/select/${this.type}`).subscribe((res) => {
				switch (res.action) {
					case 'close':
						break;
					case 'close_reload':
						this.control.setValue(`$ENV[${res.entry.name}]`);
						this.control.markAsDirty();
						break;
				}
			});
		}
	}

	constructor(
		public treeSrv: I40TreeChannelService,
		public authSrv: AuthService,
		public globalSrv: GlobalService,
		public cdRef: ChangeDetectorRef,
		public evSrv: EnvironmentVariablesService,
		public msgSrv: MessageResponseService,
		public validatorService: ValidatorService,
	) {}

	ngOnInit(): void {
		if (this.property.key.includes('password')) {
			this.type = 'password';
		}

		if (this.property.key.includes('date')) {
			this.type = 'date';
		}

		switch (this.property.type) {
			case 'integer':
				this.type = 'text';
				break;
			case 'number':
				this.type = 'number';
				this.control.setValidators([this.numberOrEnvValidator]);
				this.control.updateValueAndValidity();
				break;
			case 'date':
				this.type = 'date';
				break;
		}

		//todo: validator needs to take into account all variable types
		// this.control.setValidators([this.validatorService.inputFieldValidator(this.type, this.property.description)])
		this.control.disable();
	}

	ngAfterViewInit() {
		if (this.property.isRequired) {
			this.control.enable();
		} else {
			if (this.input.value.length > 0) {
				this.control.enable();
			}

			this.input.stateChanges.subscribe((s) => {
				if (this.input.value.length > 0) {
					this.control.enable();
				}
			});
		}

		this.cdRef.detectChanges();
	}

	enableIfRequired() {
		if (this.control.disabled && this.globalSrv.canEdit(this.treeSrv.rootParent)) {
			this.control.enable();
			this.input.focus();
		}
	}

	disableIfRequired() {
		if ((this.control.value == null || this.control.value.length === 0) && !this.property.isRequired) {
			this.control.disable();
			this.control.parent.markAsDirty();
		}
	}

	getRawInputValue() {
		const hiddenInputNativeElement = this.inputElemRef.nativeElement;
		return hiddenInputNativeElement.value;
	}

	numberOrEnvValidator(control: FormControl): {[key: string]: any} | null {
		const value = control.value;
		const isNumber = !isNaN(parseFloat(value)) && isFinite(value);
		const envVariablePattern = /^\$ENV\[\w+\]$/;
		const isEnvVariable = envVariablePattern.test(value);

		if (isNumber || isEnvVariable) {
			return null;
		} else {
			return {invalidFormat: true};
		}
	}
}
