import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {AngularSplitModule} from 'angular-split';
import {BlockUIModule} from 'ng-block-ui';
import {AppMaterialModule} from '../app-material.module';

import {ErrorDialogComponent} from './error-dialog/error-dialog.component';
import {FeedbackSnackbarComponent} from './feedback-snackbar/feedback-snackbar.component';
import {GenericDialogComponent} from './generic-dialog/generic-dialog.component';
import {GenericGnvdComponent} from './generic-gnvd/generic-gnvd.component';

import {I40TreeChannelComponent} from './i40-tree/i40-tree-channel/i40-tree-channel.component';
import {I40TreeCrudComponent} from './i40-tree/i40-tree-crud/i40-tree-crud.component';
import {I40TreeMemberEditorWrapper} from './i40-tree/i40-tree-crud/i40-tree-member-editor/i40-tree-member-editor-wrapper';
import {I40TreeMemberComponent} from './i40-tree/i40-tree-member/i40-tree-member.component';
import {I40TreeComponent} from './i40-tree/i40-tree.component';

import {I40TreeArrayEditorComponent} from './i40-tree/i40-tree-crud/i40-tree-member-editor/i40-tree-array-editor/i40-tree-array-editor.component';
import {I40TreeCommandEditorComponent} from './i40-tree/i40-tree-crud/i40-tree-member-editor/i40-tree-command-editor/i40-tree-command-editor.component';
import {I40TreeCommandParametersEditorComponent} from './i40-tree/i40-tree-crud/i40-tree-member-editor/i40-tree-command-parameters-editor/i40-tree-command-parameters-editor.component';
import {I40TreeCommandReplyEditorComponent} from './i40-tree/i40-tree-crud/i40-tree-member-editor/i40-tree-command-reply-editor/i40-tree-command-reply-editor.component';
import {I40TreeEventEditorComponent} from './i40-tree/i40-tree-crud/i40-tree-member-editor/i40-tree-event-editor/i40-tree-event-editor.component';
import {I40TreeListEditorComponent} from './i40-tree/i40-tree-crud/i40-tree-member-editor/i40-tree-list-editor/i40-tree-list-editor.component';
import {I40TreeModelEditorComponent} from './i40-tree/i40-tree-crud/i40-tree-member-editor/i40-tree-model-editor/i40-tree-model-editor.component';
import {I40TreePropertyEditorComponent} from './i40-tree/i40-tree-crud/i40-tree-member-editor/i40-tree-property-editor/i40-tree-property-editor.component';
import {I40TreeVariableEditorComponent} from './i40-tree/i40-tree-crud/i40-tree-member-editor/i40-tree-variable-editor/i40-tree-variable-editor.component';

import {KeysPipe} from '../core-services/keys.pipe';
import {ToggleFilterPipe} from '../core-services/toggle-filter.pipe';

import {BackupService} from '../apps/backup-repository/backup.service';
import {ChannelTypesService} from '../apps/channel-types/channel-types.service';
import {ChannelService} from '../apps/channel/channel.service';
import {CredentialManagementService} from '../apps/credential-management/credential-management.service';
import {DeviceTypeDescriptionComponent} from '../apps/device-type/device-type-description/device-type-description.component';
import {DeviceTypeMappingChannelComponent} from '../apps/device-type/device-type-mapping-channel/device-type-mapping-channel.component';
import {DeviceTypeMappingComponent} from '../apps/device-type/device-type-mapping/device-type-mapping.component';
import {InformationModelService} from '../apps/information-models/information-model.service';
import {ModelService} from '../apps/information-models/model.service';
import {LoginPopupComponent} from './login-popup/login-popup.component';
import {UnifierListGroupFilterComponent} from './unifier-list-group-filter/unifier-list-group-filter.component';
import {UnifierListToolbarComponent} from './unifier-list-toolbar/unifier-list-toolbar.component';

import {RouterModule} from '@angular/router';
import {CodemirrorModule} from '@ctrl/ngx-codemirror';
import {PluginToolbarMenuComponent} from '../apps/plugins/plugin-toolbar-menu/plugin-toolbar-menu.component';
import {HighlightPipe} from '../core-services/highlight.pipe';
import {LimitPipe} from '../core-services/limit.pipe';
import {LogparserPipe} from '../core-services/logparser.pipe';
import {SafehtmlPipe} from '../core-services/safehtml.pipe';
import {CompileArtifactResultsComponent} from './compile-artifact-results/compile-artifact-results.component';
import {CompileArtifactService} from './compile-artifact-results/compile-artifact.service';
import {DeploymentAttributeComponent} from './deployment-attribute/deployment-attribute.component';
import {GenericListToolbarComponent} from './generic-list-toolbar/generic-list-toolbar.component';
import {AnyofComponent} from './i40-tree/i40-tree-channel/form-items/anyof/anyof.component';
import {ArrayComponent} from './i40-tree/i40-tree-channel/form-items/array/array.component';
import {CheckboxComponent} from './i40-tree/i40-tree-channel/form-items/checkbox/checkbox.component';
import {CredentialsSelectorComponent} from './i40-tree/i40-tree-channel/form-items/credentials-selector/credentials-selector.component';
import {GroupComponent} from './i40-tree/i40-tree-channel/form-items/group/group.component';
import {InputComponent} from './i40-tree/i40-tree-channel/form-items/input/input.component';
import {LayerComponent} from './i40-tree/i40-tree-channel/form-items/layer/layer.component';
import {ModelPathArrayComponent} from './i40-tree/i40-tree-channel/form-items/model-path-array/model-path-array.component';
import {ModelPathComponent} from './i40-tree/i40-tree-channel/form-items/model-path/model-path.component';
import {OneofComponent} from './i40-tree/i40-tree-channel/form-items/oneof/oneof.component';
import {SelectComponent} from './i40-tree/i40-tree-channel/form-items/select/select.component';
import {IFrameComponent} from './iframe/iframe.component';
import {LicenseComponent} from './license/license.component';
import {EnvironmentVariablesService} from '../apps/environment-variables/environment-variables.service';
import {NumAsValue} from './i40-tree/i40-tree-channel/form-items/input/num-as-value.directive';

@NgModule({
	declarations: [
		KeysPipe,
		LimitPipe,
		ToggleFilterPipe,
		HighlightPipe,
		LogparserPipe,
		SafehtmlPipe,
		FeedbackSnackbarComponent,
		GenericDialogComponent,
		ErrorDialogComponent,

		I40TreeComponent,
		I40TreeCrudComponent,
		I40TreeMemberComponent,

		I40TreeMemberEditorWrapper,

		I40TreeVariableEditorComponent,
		I40TreeArrayEditorComponent,
		I40TreeListEditorComponent,
		I40TreeEventEditorComponent,
		I40TreeCommandEditorComponent,
		I40TreeCommandReplyEditorComponent,
		I40TreeCommandParametersEditorComponent,
		I40TreeModelEditorComponent,
		I40TreePropertyEditorComponent,

		GenericGnvdComponent,
		DeviceTypeMappingComponent,
		DeviceTypeMappingChannelComponent,
		DeviceTypeDescriptionComponent,
		I40TreeChannelComponent,
		GenericListToolbarComponent,
		UnifierListToolbarComponent,
		UnifierListGroupFilterComponent,
		LoginPopupComponent,
		InputComponent,
		NumAsValue,
		SelectComponent,
		CheckboxComponent,
		GroupComponent,
		ModelPathArrayComponent,
		ModelPathComponent,
		LayerComponent,
		ArrayComponent,
		OneofComponent,
		AnyofComponent,
		CredentialsSelectorComponent,
		IFrameComponent,
		DeploymentAttributeComponent,
		LicenseComponent,
		PluginToolbarMenuComponent,
		CompileArtifactResultsComponent,
	],
	imports: [
		CommonModule,
		AppMaterialModule,
		FormsModule,
		ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'never'}),
		FlexLayoutModule,
		BlockUIModule,
		AngularSplitModule,
		TranslateModule,
		CodemirrorModule,
		RouterModule,
	],

	exports: [
		CommonModule,
		KeysPipe,
		LimitPipe,
		ToggleFilterPipe,
		GenericDialogComponent,
		FeedbackSnackbarComponent,
		AppMaterialModule,
		TranslateModule,
		FlexLayoutModule,
		ReactiveFormsModule,
		FormsModule,
		BlockUIModule,
		AngularSplitModule,

		I40TreeComponent,
		I40TreeCrudComponent,
		I40TreeMemberComponent,
		I40TreeMemberEditorWrapper,

		GenericGnvdComponent,
		DeviceTypeMappingComponent,
		DeviceTypeMappingChannelComponent,
		DeviceTypeDescriptionComponent,

		I40TreeChannelComponent,
		GenericListToolbarComponent,
		UnifierListToolbarComponent,
		UnifierListGroupFilterComponent,

		InputComponent,
		SelectComponent,
		CheckboxComponent,
		GroupComponent,
		ModelPathArrayComponent,
		HighlightPipe,
		LogparserPipe,
		SafehtmlPipe,
		ArrayComponent,
		DeploymentAttributeComponent,
		PluginToolbarMenuComponent,
		CompileArtifactResultsComponent,
	],
	providers: [ModelService, ChannelService, ChannelTypesService, InformationModelService, CredentialManagementService, EnvironmentVariablesService, BackupService, CompileArtifactService],
})
export class SharedModule {}
