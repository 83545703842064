<ng-container *ngIf="loaded">
	<form [formGroup]="form" class="form" novalidate fxLayout="column" style="padding: 20px" (ngSubmit)="save()">
		<mat-form-field subscriptSizing="dynamic" fxFlex="">
			<mat-label>{{ 'i18n.id' | translate }}</mat-label>
			<input id="id_input" formControlName="id" matInput required #selectOnInit />
			<mat-error *ngIf="form.get('id').errors">{{ form.get('id').errors.message }}</mat-error>
		</mat-form-field>

		<mat-form-field subscriptSizing="dynamic" fxFlex="">
			<mat-label>{{ 'i18n.description' | translate }}</mat-label>
			<input id="description_input" matInput formControlName="description" />
		</mat-form-field>

		<mat-form-field subscriptSizing="dynamic">
			<mat-label>{{ 'i18n.memberType' | translate }}</mat-label>
			<mat-select id="member_type_select" formControlName="typeName" aria-label="memberType" [(ngModel)]="selectedMemberType" required>
				<mat-option *ngFor="let type of allowedMemberTypes$ | async" [value]="type">
					<span>{{ type }}</span>
				</mat-option>
			</mat-select>
		</mat-form-field>
	</form>
</ng-container>
