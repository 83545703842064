<mat-form-field subscriptSizing="dynamic" fxFlex (click)="enableIfRequired()">
	<mat-label>{{ property.description }}</mat-label>

	<!-- Switch input type dynamically based on isEnvVariable and type -->
	<ng-container [ngSwitch]="isEnvVariable ? 'text' : type">
		<!-- Text input for when isEnvVariable is true -->
		<input #input *ngSwitchCase="'text'" type="text" matInput [formControl]="control" id="{{ treeSrv.getId(property, 'input') }}" [required]="property.isRequired" (blur)="disableIfRequired()" />

		<!-- Number input -->
		<input
			#input
			*ngSwitchCase="'number'"
			type="text"
			matInput
			[formControl]="control"
			numAsValue
			id="{{ treeSrv.getId(property, 'input') }}"
			[required]="property.isRequired"
			(blur)="disableIfRequired()"
		/>

		<!-- Date input -->
		<input #input *ngSwitchCase="'date'" type="date" matInput [formControl]="control" id="{{ treeSrv.getId(property, 'input') }}" [required]="property.isRequired" (blur)="disableIfRequired()" />

		<!-- Password input -->
		<input
			#input
			*ngSwitchCase="'password'"
			type="password"
			matInput
			[formControl]="control"
			id="{{ treeSrv.getId(property, 'input') }}"
			[required]="property.isRequired"
			(blur)="disableIfRequired()"
		/>

		<!-- Fallback case for any other types -->
		<input #input *ngSwitchDefault type="text" matInput [formControl]="control" id="{{ treeSrv.getId(property, 'input') }}" [required]="property.isRequired" (blur)="disableIfRequired()" />
	</ng-container>

	<mat-error *ngIf="control.errors?.message && control.value.length > 0">{{ control.errors.message }}</mat-error>
</mat-form-field>
