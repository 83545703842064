import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'limit',
})
export class LimitPipe implements PipeTransform {
	constructor() {}

	transform(items: any[], args: number): any {
		if (!args) {
			return items;
		}

		return items.slice(0, args);
	}
}
