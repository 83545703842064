<mat-toolbar fxLayout="row" class="smaller_toolbar error-title">
	<mat-icon>error_outline</mat-icon>
	<span *ngIf="dialogData.title.length == 0">&nbsp;{{ 'Error' | translate }}</span>
	<span *ngIf="dialogData.title.length > 0">&nbsp;{{ dialogData.title }}</span>
	<span fxFlex></span>
</mat-toolbar>
<mat-dialog-content class="typography">
	<div style="max-height: 200px">
		<pre>{{ dialogData.text }}</pre>
	</div>
</mat-dialog-content>
<mat-dialog-actions fxLayoutAlign="end center" class="error-button">
	<button mat-button mat-dialog-close="dialogData" class="error-title">OK</button>
</mat-dialog-actions>
