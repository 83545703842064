import {Component, ElementRef, EventEmitter, Inject, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, ValidatorFn} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
	selector: 'app-generic-dialog',
	templateUrl: './generic-dialog.component.html',
	styleUrls: ['./generic-dialog.component.scss'],
})
export class GenericDialogComponent implements OnInit {
	//DialogData
	dialogData: DialogData;

	buttonStart: Button[];
	buttonCenter: Button[];
	buttonEnd: Button[];
	buttonFlex: Button[];

	@ViewChild('container', {read: ViewContainerRef, static: true}) container: ViewContainerRef;
	@ViewChild('fileInput', {static: false}) fileInput: ElementRef;

	formGroup: UntypedFormGroup = new UntypedFormGroup({});
	formFields: DialogInput[] = [];

	fileData: any;

	buttonClicked: EventEmitter<string> = new EventEmitter();
	checboxSelected: EventEmitter<string> = new EventEmitter();

	constructor(
		public dialogRef: MatDialogRef<GenericDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: DialogData,
	) {
		dialogRef.disableClose = false;
	}

	ngOnInit() {
		//Get current data
		if (this.data) {
			this.dialogData = this.data;
			this.buttonStart = this.dialogData.buttons.filter((button) => button.btnPlacement == 'start');
			this.buttonCenter = this.dialogData.buttons.filter((button) => button.btnPlacement == 'center');
			this.buttonEnd = this.dialogData.buttons.filter((button) => button.btnPlacement == 'end');
			this.buttonFlex = this.dialogData.buttons.filter((button) => button.btnPlacement == 'flex');

			if (this.data.hasInput) {
				this.generateForm(this.data.formFields);
				this.formFields = this.data.formFields;
			}
		}
	}

	buttonClick(btn_id) {
		this.buttonClicked.emit(btn_id);
	}

	checkboxSelect(event) {
		this.checboxSelected.emit(event.checked);
	}

	generateForm(fields: DialogInput[]) {
		fields.forEach((f) => {
			this.formGroup.addControl(f.name, new UntypedFormControl(f.selectedValue, f.validations));
		});
	}
}

export class DialogData {
	constructor(
		public title: string,
		public buttons?: Button[],
		public text?: string,
		public hasInput: boolean = false,
		public formFields?: DialogInput[],
	) {}
}

export class DialogInput {
	constructor(
		public label?: string,
		public name?: string,
		public type?: 'text' | 'password' | 'number' | 'select' | 'radio' | 'checkbox' | 'file' | 'checklist', // NOT YET IMPLEMENTED: 'radio'
		public selectedValue?: any,
		public possibleValues?: DialogDropdownOption[],
		public validations?: ValidatorFn[],
	) {}
}

export class DialogDropdownOption {
	constructor(
		public label: string,
		public value: string | number,
	) {}
}

export class Button {
	constructor(
		public btnText: string,
		public btnId: string,
		public btnType: string,
		public btnColor: string,
		public btnPlacement: string, // start, center, end
		public disabled: boolean = false,
	) {}
}
