import {Injectable} from '@angular/core';
import {I40FlatMember, MemberDefinitionType, MemberDescription, MemberTypes, ModelManagerResult, ModelRoot, NewCommandMemberType, ObjectDescription} from './types';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog} from '@angular/material/dialog';
import {EditorStatusService} from '../../core-services/editor-status.service';
import {NewModelName, NewNodeID} from '../../data-models/core-model.model';

@Injectable({
	providedIn: 'root',
})
export class I40TreeService {
	inputModel: ModelManagerResult;

	modelMemberTypes: Map<string, ObjectDescription>; // MODEL SPECIFIC CUSTOM MEMBER TYPES
	customMemberTypes: Map<string, ObjectDescription>; // Command, Array - Dynamically generated memberTypes
	simpleMemberTypes: Map<string, ObjectDescription>; // All Simple Member Types
	allMemberTypes: Map<string, ObjectDescription>; // ALL MEMBER TYPES INCLUDING SIMPLE TYPES

	flatMembers: I40FlatMember[];
	visibleFlatMembers: I40FlatMember[];

	memberMap: any = {};
	startCollapsed: boolean = true;
	extraInfo: any = null; // extra info attached to all members

	generatedModelMemberType: ObjectDescription = new ObjectDescription(ModelRoot);
	flatParent: I40FlatMember;

	isValid = true;

	parentDataSrv: EditorStatusService; // potential reference to parent data service

	constructor(
		public translateSrv: TranslateService,
		public dialog: MatDialog,
	) {}

	parseModel(m: ModelManagerResult) {
		this.inputModel = m;
		this.modelMemberTypes = this.inputModel.types;
		this.customMemberTypes = this.generateCustomTypes();
		this.allMemberTypes = this.getAllMemberTypes();

		this.flatParent = new I40FlatMember({
			id: this.inputModel.info.externalIdentifier.name,
			description: this.inputModel.info.externalDescriptor.description,
			definitionType: MemberDefinitionType.Model,
			typeName: ModelRoot.id,

			_hasChildren: true,
			_level: 0,
			_visible: true,
			_originalIndex: 0,
			_levelIndex: 0,

			_collapsed: false,
			_extraInfo: this.extraInfo,
			_memberType: this.generatedModelMemberType,
			_memberParent: null,
			_model: this.inputModel,
			_original: null,
			_srv: this,
		});

		this.isValid = true;

		this.flatMembers = this.flattenMembers(this.inputModel.members).arr;

		// console.log('flatMembers', this.flatMembers);

		this.getVisibleNodes();
	}

	refreshModel() {
		this.isValid = true;

		this.flatMembers = this.flattenMembers(this.inputModel.members).arr;

		this.refreshVisibility();
		this.getVisibleNodes();
	}

	addNewModelMemberType(typeName) {
		// console.log('NEW MEMBER TYPE', typeName);

		// create new memberType
		const newMemberType = new ObjectDescription({
			id: typeName,
			category: 'model',
			members: [],
		});

		this.modelMemberTypes.set(typeName, newMemberType);

		this.allMemberTypes = this.getAllMemberTypes();
	}

	generateCustomTypes(): Map<string, ObjectDescription> {
		return new Map([
			[ModelRoot.id, new ObjectDescription(ModelRoot)],
			['Command', new ObjectDescription(NewCommandMemberType)],
			// ['Array_String_NEW', new ObjectDescription(NewArrayMemberType)]
		]);
	}

	getAllMemberTypes(): Map<string, ObjectDescription> {
		const simpleTypes = Object.values(MemberTypes);
		const customTypesClone = new Map(this.customMemberTypes);

		this.simpleMemberTypes = new Map();
		const allMemberTypes = new Map(this.modelMemberTypes);

		simpleTypes.forEach((t) => {
			this.simpleMemberTypes.set(
				t,
				new ObjectDescription({
					id: t,
					category: 'simple',
					members: [],
				}),
			);

			allMemberTypes.set(
				t,
				new ObjectDescription({
					id: t,
					category: 'simple',
					members: [],
				}),
			);
		});

		customTypesClone.forEach((t) => {
			allMemberTypes.set(t.id, t);
		});

		return allMemberTypes;
	}

	flattenMembers(members: Map<string, MemberDescription>, level = 1, flatParent: I40FlatMember = null, ind: number = 1): any {
		let ret: I40FlatMember[] = [];
		let lvlind = 0;

		if (level === 1) {
			this.flatParent._new = this.inputModel.info.externalIdentifier.name === NewModelName;
			this.flatParent._path = this.buildPath(this.flatParent).reverse();
			this.flatParent._fullPath = this.buildFullPath(this.flatParent).reverse();
			this.flatParent._memberPath = this.buildMemberPath(this.flatParent).reverse();
			this.flatParent._uniqueIndex = this.generateId(this.flatParent);

			this.generatedModelMemberType.members = members;
			this.flatParent._isValid = this.validateMember(this.flatParent);

			ret.push(this.flatParent);

			flatParent = this.flatParent;
		}

		members.forEach((member, i) => {
			const memberType: ObjectDescription = this.allMemberTypes ? this.allMemberTypes.get(member.typeName) : null;

			const hasChildren = memberType && memberType.members.size > 0;

			const flatMember: I40FlatMember = new I40FlatMember(
				{
					id: member.id,
					description: member.description,
					definitionType: member.definitionType,
					typeName: member.typeName,
					size: member.size,

					_hasChildren: hasChildren,
					_level: level,
					_visible: level > 1 ? !flatParent._collapsed : true,
					_originalIndex: ind++,
					_levelIndex: lvlind++,
					_collapsed: hasChildren ? (member._collapsed !== null ? member._collapsed : this.startCollapsed) : false,
					_memberType: memberType,
					_model: this.inputModel,
					_extraInfo: this.extraInfo,
					_memberParent: level === 1 ? flatParent._memberType : member._memberParent ? member._memberParent : flatParent ? flatParent._memberType : null,
					_flatParentRef: flatParent,
					_original: member,
					_srv: this,
				},
				flatParent,
			);

			flatMember._path = this.buildPath(flatMember).reverse();
			flatMember._fullPath = this.buildFullPath(flatMember).reverse();
			flatMember._memberPath = this.buildMemberPath(flatMember).reverse();
			flatMember._uniqueIndex = this.generateId(flatMember);
			flatMember._new = member.id === NewNodeID;

			if (this.memberMap[flatMember._uniqueIndex] !== undefined) {
				flatMember._visible = this.memberMap[flatMember._uniqueIndex]._visible;
				flatMember._collapsed = this.memberMap[flatMember._uniqueIndex]._collapsed;
			}

			flatMember._isValid = this.validateMember(flatMember);

			ret.push(flatMember);

			this.memberMap[flatMember._uniqueIndex] = flatMember;

			if (hasChildren) {
				const ch = this.flattenMembers(memberType.members, level + 1, flatMember, ind);
				ret = ret.concat(ch.arr);
				ind = ch.ind;
			}
		});

		return {
			arr: ret,
			ind,
		};
	}

	validateMember(member: I40FlatMember): boolean {
		// form status subscription
		if (member._validFormSub === null) {
			member._validFormSub = member._formGroup.statusChanges.subscribe((status) => {
				if (status !== 'VALID') {
					this.isValid = false;
					member._isValid = false;
				} else {
					member._isValid = this.validateMember(member);
				}
			});
		}

		// new model
		if (member._level === 0 && member.id === NewModelName) {
			this.isValid = false;
			return false;
		}

		// form invalid
		if (!member._formGroup.valid) {
			this.isValid = false;
			return false;
		}

		// new node
		if (member.id === '' || member.id === NewNodeID) {
			this.isValid = false;
			return false;
		}

		// Command Params
		if (member.definitionType === 'CommandParameters') {
			if (member.typeName === '') {
				this.isValid = false;
				return false;
			}
		}

		// Command Reply
		if (member.definitionType === 'CommandReply') {
			if (member.typeName === '') {
				this.isValid = false;
				return false;
			}
		}

		return true;
	}

	buildPath(member: I40FlatMember): string[] {
		let path = [member.id];

		if (member._flatParentRef instanceof I40FlatMember && member._flatParentRef.definitionType !== MemberDefinitionType.Model) {
			path = path.concat(this.buildPath(member._flatParentRef));
		}

		return path;
	}

	buildMemberPath(member: I40FlatMember): string[] {
		let path = [member.typeName];

		if (member._flatParentRef instanceof I40FlatMember && member._flatParentRef.definitionType !== MemberDefinitionType.Model) {
			path = path.concat(this.buildMemberPath(member._flatParentRef));
		}

		return path;
	}

	buildFullPath(member: I40FlatMember): string[] {
		let path = [member.id, member.definitionType];

		if (member._flatParentRef instanceof I40FlatMember && member._flatParentRef.definitionType !== MemberDefinitionType.Model) {
			path = path.concat(this.buildFullPath(member._flatParentRef));
		}

		return path;
	}

	getVisibleNodes() {
		let ind = 0;
		this.visibleFlatMembers = this.flatMembers.filter((node) => {
			if (node._visible) {
				node._visibleIndex = ind++;
			}

			return node._visible;
		});
	}

	showAllParents(member: I40FlatMember) {
		member._visible = true;
		if (member._flatParentRef instanceof I40FlatMember) {
			this.showAllParents(member._flatParentRef);
		}
	}

	generateId(member: I40FlatMember) {
		return member._path.join('>');
	}

	isParentCollapsed(member: I40FlatMember) {
		if (member._flatParentRef instanceof I40FlatMember) {
			if (member._flatParentRef._collapsed) {
				return true;
			} else {
				return this.isParentCollapsed(member._flatParentRef);
			}
		} else {
			return false;
		}
	}

	getChildren(target: I40FlatMember): I40FlatMember[] {
		let found = false;
		let done = false;

		const lastParent = {};
		const children = [];

		lastParent[target._level] = target;

		this.flatMembers.forEach((member) => {
			if (!done) {
				if (member === target) {
					found = true;
				}

				if (found && member !== target) {
					if (member._level <= target._level) {
						done = true;
					}
					if (!done) {
						children.push(member);
					}
				}
			}
		});

		return children;
	}

	getFirstChildren(target: I40FlatMember): I40FlatMember[] {
		let found = false;
		let done = false;

		const lastParent = {};
		const children = [];

		lastParent[target._level] = target;

		this.flatMembers.forEach((member) => {
			if (!done) {
				if (member === target) {
					found = true;
				}

				if (found && member !== target) {
					if (member._level <= target._level) {
						done = true;
					}
					if (!done && member._level - 1 === target._level) {
						children.push(member);
					}
				}
			}
		});
		return children;
	}

	updateFlatVisibility(target) {
		let found = false;
		let done = false;

		const lastParent = {};

		lastParent[target._level] = target;

		this.flatMembers.forEach((member) => {
			if (!done) {
				if (member === target) {
					found = true;
				}

				if (found && member !== target) {
					if (member._level <= target._level) {
						done = true;
					}
					if (!done) {
						if (target._collapsed) {
							member._visible = false;
						} else {
							member._visible = !lastParent[member._level - 1]._collapsed && lastParent[member._level - 1]._visible;

							if (member._hasChildren) {
								lastParent[member._level] = member;
							}
						}
					}
				}
			}
		});
	}

	refreshVisibility() {
		this.flatMembers.forEach((member) => {
			if (member._level === 1) {
				member._visible = true;
			} else {
				member._visible = !this.isParentCollapsed(member);
			}
		});
	}

	toggleCollapseAll() {
		let currentStatus = null;

		this.flatMembers.some((member) => {
			if (member._hasChildren && member._level > 0) {
				currentStatus = member._collapsed;
				return true;
			}
		});

		this.flatMembers.forEach((member) => {
			// toggle everything except the root node
			if (member._level > 0) {
				if (member._hasChildren) {
					member._collapsed = !currentStatus;
				}

				if (member._level > 1) {
					member._visible = currentStatus;
				}
			}
		});

		this.getVisibleNodes();
	}

	getNodeByFullPath(path) {
		return this.flatMembers.find((member) => {
			return member._fullPath.join('/') === path;
		});
	}

	getNodeByPath(path) {
		return this.flatMembers.find((member) => {
			return member._path.join('/') === path;
		});
	}

	updateOriginalIndexes() {
		this.flatMembers.forEach((m, i) => {
			m._originalIndex = i;
		});
	}
}
