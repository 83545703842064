import {SelectionModel} from '@angular/cdk/collections';
import {Component, NgZone, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {GlobalService} from 'src/app/core-services/global.service';
import {NotificationsService} from 'src/app/core-services/notifications.service';
import {UnifierListService} from 'src/app/core-services/unifier-list.service';
import {ArtifactValidatedEvent, ArtifactValidationResult} from '../../apps/notifications/notifications.model';
import {CompileArtifactService} from './compile-artifact.service';

@Component({
	selector: 'compile-artifact-results',
	templateUrl: './compile-artifact-results.component.html',
	styleUrls: ['./compile-artifact-results.component.scss'],
})
export class CompileArtifactResultsComponent implements OnInit {
	dataSource: any[] = [];
	displayedColumns: string[] = ['status', 'time', 'type', 'artifact', 'error'];
	selection = new SelectionModel<any>(false, []);
	showValidButtonTooltip: 'i18n.showValid' | 'i18n.hideValid' = 'i18n.showValid';

	constructor(
		public router: Router,
		private compileArtifactSrv: CompileArtifactService,
		public notificationsSrv: NotificationsService,
		public ngZone: NgZone,
		public globalSrv: GlobalService,
	) {}

	ngOnInit(): void {
		this.reloadResults();
		this.getArtifactValidationEvents();
		this.selection.toggle;
	}

	reloadResults() {
		// this.compileArtifactSrv.getCompilationResults().subscribe({
		// 	next:  (data: any) => {
		// 		const dataMap = new Map(Object.entries(data))
		//   this.dataSource =  Array.from( dataMap.values() ).flatMap((value:any) => {
		//       if (value._type == "com.amorphsys.smartunifier.manager.validation.ArtifactValid") {
		//         if (this.compileArtifactSrv.showValid){
		//           value.valid = true;
		//           const o = new ArtifactValidationResult(value);
		//           return [{
		//             id: o.info.identifier.getId(),
		//             status: o.valid,
		//             time: o.formatedTime,
		//             type: o.info.artifactType,
		//             artifact: o.info.getExternalId(),
		//             error: '',
		//             line: ''
		//           }]
		//         } else return [];
		//       } else {
		//         value.valid = false;
		//         const o = new ArtifactValidationResult(value);
		//         const err = o.messages.length>0 ?
		//             o.messages[0].message : ''
		//         return [{
		//           id: o.info.identifier.getId(),
		//           status: o.valid,
		//           time: o.formatedTime,
		//           type: o.info.artifactType,
		//           artifact: o.info.getExternalId(),
		//           error: err,
		//           line: ''
		//         }]
		//       }
		//     })
		// 	},
		// 	error: (err) => {
		// 		console.error(err)
		// 	}
		// })
	}

	getArtifactValidationEvents() {
		this.notificationsSrv.getArtifactValidationEvents().subscribe({
			next: (messages: ArtifactValidatedEvent[]) => {
				this.ngZone.run(() => {
					const resultsMap = new Map(
						this.dataSource.map((e) => {
							return [e.artifact, e];
						}),
					);

					messages.forEach((message) => {
						if (message.status.valid) {
							if (this.compileArtifactSrv.showValid) {
								resultsMap.set(message.status.info.getExternalId(), {
									id: message.status.info.identifier.getId(),
									status: message.status.valid,
									time: message.status.formatedTime,
									type: message.status.info.artifactType,
									artifact: message.status.info.getExternalId(),
									error: '',
									line: '',
								});
							}
						} else {
							if (message.status.messages) {
								const err = message.status.messages.length > 0 ? message.status.messages[0].message : '';

								resultsMap.set(message.status.info.getExternalId(), {
									id: message.status.info.identifier.getId(),
									status: message.status.valid,
									time: message.status.formatedTime,
									type: message.status.info.artifactType,
									artifact: message.status.info.getExternalId(),
									error: err,
									line: '',
								});
							}
						}

						this.dataSource = Array.from(resultsMap.values());
					});
				});
			},
		});
	}

	deleteResults() {
		this.compileArtifactSrv.clearCompilationResults().subscribe({
			next: (data: any) => {
				this.dataSource = [];
			},
		});
	}

	rowDoubleClick(row) {
		switch (row.type) {
			case 'model':
				this.router.navigate([`/information-models/edit/${row.id}`]);
				break;
			case 'channel':
				this.router.navigate([`/channel-management/edit/${row.id}`]);
				break;
			case 'mapping':
				this.router.navigate([`/model-mapping/edit/${row.id}`]);
				break;
			case 'devicetype':
				this.router.navigate([`/device-type/edit/${row.id}`]);
				break;
			case 'instance':
				this.router.navigate([`/instance/edit/${row.id}`]);
				break;
			default:
		}
	}

	closeResults() {
		this.globalSrv.splitBottom.size = 1;
		this.globalSrv.splitTop = 99;
		this.globalSrv.splitBottom.visible = false;
	}

	showValidResults() {
		this.compileArtifactSrv.showValid = !this.compileArtifactSrv.showValid;
		if (this.compileArtifactSrv.showValid) {
			this.showValidButtonTooltip = 'i18n.hideValid';
		} else {
			this.showValidButtonTooltip = 'i18n.showValid';
		}
		this.reloadResults();
	}
}
