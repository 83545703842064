import {ChangeDetectorRef, Component, HostListener, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatMenuTrigger} from '@angular/material/menu';
import {MatSidenav} from '@angular/material/sidenav';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {AuthService} from '../core-services/auth.service';
import {MessageResponseService} from '../core-services/message-response.service';
import {ThemeService} from '../core-services/theme.service';
import {ToolbarMenu} from '../data-models/core-model.model';
import {environment} from '../../environments/environment';
import {ApiService} from '../core-services/api.service';
import {GlobalService} from '../core-services/global.service';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {BackupService} from '../apps/backup-repository/backup.service';
import {Button} from '../shared/generic-dialog/generic-dialog.component';
import {ProgressLogComponent} from './progress-log/progress-log.component';
import {i40Notification, NotificationActionEvent} from '../apps/notifications/notifications.model';
import {NotificationsService} from '../core-services/notifications.service';
import {PluginsService} from '../apps/plugins/plugins.service';
import {PluginManagerListItem} from '../apps/plugins/plugins.model';
import {catchError, map} from 'rxjs/operators';
import {Observable, Subscription, forkJoin, of} from 'rxjs';

@Component({
	selector: 'app-wrapper',
	templateUrl: './wrapper.component.html',
	styleUrls: ['./wrapper.component.scss'],
})
export class WrapperComponent implements OnInit, OnDestroy {
	@ViewChild('userMenuTrigger', {static: false}) userMenuTrigger: MatMenuTrigger;
	@ViewChild('notificationsMenuTrigger', {static: false}) notificationsMenuTrigger: MatMenuTrigger;

	@ViewChild('sidenav', {static: true}) sidenav: MatSidenav;
	@BlockUI() blockUI: NgBlockUI;
	@ViewChild('progressLog', {static: false}) progressLog: ProgressLogComponent;

	debugMode: boolean;
	messages: any[] = [];
	artifactInfo = [];
	actionType: string;
	complete: boolean = false;

	isSidebarCollapsed = true;
	isSidebarClosed = false;

	languages = [
		{languageCode: 'en', languageName: 'English'},
		{languageCode: 'de', languageName: 'German'},
	];

	menuItems: any[] = [];
	notifications: any[] = [];
	non_read_notifications: any[] = [];

	userMenuItems: ToolbarMenu[] = [];
	userSubmenus: any = {};

	administrative_menuitem$: Observable<any[]>;
	private subscriptions = new Subscription();

	@HostListener('window:beforeunload', ['$event'])
	onWindowClose(event: any): void {
		event.preventDefault();
		event.returnValue = false;
	}

	@HostListener('window:keyup', ['$event'])
	onIdToggle($event: KeyboardEvent) {
		if ($event.key === 'F9') {
			this.globalSrv.debugMode = !this.globalSrv.debugMode;
			this.globalSrv.setDebugMode(this.globalSrv.debugMode);
			localStorage.setItem('i40DebugMode', this.globalSrv.debugMode ? 'true' : 'false');
		} else if ($event.key === 'F8') {
			this.globalSrv.splitBottom.visible = this.globalSrv.applicationConf.validationEnabled ? true : false;
			this.globalSrv.splitBottom.size = 40;
		}
	}

	constructor(
		public authSrv: AuthService,
		public router: Router,
		public api: ApiService,
		public route: ActivatedRoute,
		public pluginSrv: PluginsService,
		public translate: TranslateService,
		public themeService: ThemeService,
		public msgSrv: MessageResponseService,
		public globalSrv: GlobalService,
		public cdRef: ChangeDetectorRef,
		public backupSrv: BackupService,
		public iconRegistry: MatIconRegistry,
		public sanitizer: DomSanitizer,
		public notificationsSrv: NotificationsService,
		public ngZone: NgZone,
	) {
		// custom icons
		iconRegistry.addSvgIcon('ico-manage-accounts', this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Manage-Accounts.svg'));
		iconRegistry.addSvgIcon('ico-deployment', this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Deployment.svg'));
		iconRegistry.addSvgIcon('ico-deploy', this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Deploy.svg'));
		iconRegistry.addSvgIcon('ico-undeploy', this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Un-Deploy.svg'));
		iconRegistry.addSvgIcon('ico-device-types', this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Device-Types.svg'));
		iconRegistry.addSvgIcon('ico-export', this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Export.svg'));
		iconRegistry.addSvgIcon('ico-import', this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Import.svg'));
		iconRegistry.addSvgIcon('ico-information-models', this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Information-Model.svg'));
		iconRegistry.addSvgIcon('ico-re-index', this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Re-Index-Repository.svg'));

		this.notificationsSrv.refreshRequired.subscribe((from) => {
			if (from !== 'wrapper') {
				this.getNotifications();
			}
		});

		this.setMenuItems();
		this.setUserMenuItems();

		this.blockUI.start('Loading...'); // Start blocking

		this.globalSrv.uiAdvancedModeChanged.subscribe(() => {
			this.setMenuItems();
			this.setUserMenuItems();
		});
	}

	getActivePlugins() {
		return this.pluginSrv.getPlugins().pipe(
			map((res: any[]) => res.map((r) => new PluginManagerListItem(r))),
			catchError((err) => of([])),
		);
	}

	getNotifications() {
		return this.notificationsSrv.getHistoryEvents().pipe(
			map((messages: any[]) => {
				this.notifications = [];

				// get all messages that have not been dismissed from history
				this.notifications = this.notifications.concat(messages.filter((n) => !n.read));
				this.non_read_notifications = this.nonReadNotifications().reverse(); // redundant but necessary

				// listen to any new messages
				this.notificationsSrv.getGlobalEvents().subscribe((messages: any[]) => {
					this.notifications = this.notifications.concat(messages);
					this.non_read_notifications = this.nonReadNotifications().reverse();
				});

				return messages;
			}),
		);
	}

	setMenuItems() {
		this.menuItems = [
			{path: 'SECTION', data: {title: 'i18n.smartUnifierConfiguration', menuitemid: 'smartunifierconfiguration', display: true}},

			{path: '/home', data: {menu: true, icon: 'dashboard', title: 'i18n.menuitem.dashboard', svgIcon: false, menuitemid: 'dashboard', display: false}},
			{path: '/information-models', data: {menu: true, icon: 'ico-information-models', svgIcon: true, title: 'i18n.menuitem.informationModels', menuitemid: 'informationmodels', display: true}},
			{
				path: '/channel-types',
				data: {
					menu: true,
					icon: 'category',
					svgIcon: false,
					title: 'i18n.menuitem.communicationChannelTypes',
					menuitemid: 'communicationchanneltypes',
					display: this.globalSrv.canEdit() && this.globalSrv.uiAdvancedMode,
				},
			},
			{
				path: '/channel-management',
				data: {menu: true, icon: 'settings_input_component', svgIcon: false, title: 'i18n.menuitem.communicationChannels', menuitemid: 'communicationchannels', display: true},
			},
			{path: '/model-mapping', data: {menu: true, title: 'i18n.menuitem.mappings', menuitemid: 'mappings', icon: 'settings_ethernet', svgIcon: false, display: true}},
			{path: '/device-type', data: {menu: true, title: 'i18n.menuitem.deviceTypes', menuitemid: 'devicetypes', icon: 'ico-device-types', svgIcon: true, display: true}},
			{path: '/instance', data: {menu: true, icon: 'memory', svgIcon: false, title: 'i18n.menuitem.instances', menuitemid: 'instances', display: true}},
			{path: '/deployments', data: {menu: true, icon: 'ico-deployment', svgIcon: true, title: 'i18n.menuitem.deployment', menuitemid: 'deployments', display: true}},

			{path: 'SECTION', data: {title: 'i18n.smartUnifierAdministration', display: false, menuitemid: 'smartunifieradministration', id: 'administration'}},

			{
				path: '/deployment-endpoints',
				data: {menu: true, icon: 'settings_input_hdmi', svgIcon: false, title: 'i18n.menuitem.deploymentManagement', menuitemid: 'deploymentendpoints', display: this.globalSrv.canEdit()},
			},
		];
	}

	setUserMenuItems() {
		this.userMenuItems = [
			{
				id: 'account_menuitem',
				icon: 'ico-manage-accounts',
				svgIcon: true,
				path: '/users/account',
				title: 'i18n.accountSettings',
				display: true,
				children: true,
			},
			{
				id: 'clear_cache_menuitem',
				icon: 'ico-re-index',
				svgIcon: true,
				title: 'i18n.clearCache',
				display: this.globalSrv.canEdit(),
				children: false,
				action: (item) => {
					this.clearCache();
				},
			},
			{
				id: 'theme_menuitem',
				icon: 'brightness_medium',
				svgIcon: false,
				path: null,
				title: this.themeService.selectedTheme === 'light-theme' ? 'i18n.darkTheme' : 'i18n.lightTheme',
				display: true,
				children: false,
				action: (item) => {
					if (this.themeService.selectedTheme === 'light-theme') {
						item.title = 'i18n.lightTheme';
						this.themeService.setTheme('dark-theme');
					} else {
						item.title = 'i18n.darkTheme';
						this.themeService.setTheme('light-theme');
					}
				},
			},
			{
				id: 'uimode_menuitem',
				icon: this.globalSrv.uiAdvancedMode ? 'remove_from_queue' : 'add_to_queue',
				svgIcon: false,
				path: null,
				title: this.globalSrv.uiAdvancedMode ? 'i18n.simpleUIMode' : 'i18n.advancedUIMode',
				display: this.globalSrv.canEdit(),
				children: false,
				action: (item) => {
					if (this.globalSrv.uiAdvancedMode) {
						item.title = 'i18n.advancedUIMode';
						item.icon = 'add_to_queue';
						this.globalSrv.toggleUiMode();
					} else {
						item.title = 'i18n.simpleUIMode';
						item.icon = 'remove_from_queue';
						this.globalSrv.toggleUiMode();
					}
				},
			},
			{
				id: 'administrative_menuitem',
				icon: 'build',
				svgIcon: false,
				path: null,
				title: 'i18n.administrative',
				display: this.authSrv.permissions.admin,
				children: true,
			},
			{
				id: 'uiabout_menuitem',
				icon: 'info',
				svgIcon: false,
				path: null,
				title: 'i18n.aboutSmartUnifier',
				display: true,
				children: false,
				action: (item) => {
					this.msgSrv.aboutPopup();
				},
			},
			{
				id: 'signout_menuitem',
				icon: 'exit_to_app',
				svgIcon: false,
				path: null,
				title: 'i18n.signOut',
				display: true,
				children: false,
				action: () => {
					this.authSrv.logout().subscribe((res) => {
						this.authSrv.signOut();
					});
				},
			},
		];

		this.userSubmenus = {
			account_menuitem: [
				{
					id: 'userprofile_menuitem',
					icon: 'account_circle',
					svgIcon: false,
					path: '/users/account',
					title: 'i18n.menuitem.editAccount',
					display: true,
					children: false,
					action: (item) => {
						this.router.navigate([item.path, this.authSrv.userData.id]);
					},
				},
				{
					id: 'changeuserpassword_menuitem',
					icon: 'password',
					svgIcon: false,
					path: '/users/account/changePassword',
					title: 'i18n.menuitem.changeUserPassword',
					display: true,
					children: false,
					action: (item) => {
						this.router.navigate([item.path, this.authSrv.userData.id]);
					},
				},
			],
			administrative_menuitem: [
				{
					id: 'usermanagement_menuitem',
					icon: 'supervisor_account',
					svgIcon: false,
					path: null,
					title: 'i18n.menuitem.userManagement',
					display: this.authSrv.permissions.admin,
					action: (item) => {
						this.router.navigate(['/users/management']);
					},
				},
				{
					id: 'plugins_menuitem',
					icon: 'extension',
					svgIcon: false,
					path: null,
					title: 'i18n.Plugins',
					display: true,
					action: (item) => {
						this.router.navigate(['plugins']);
					},
				},
				{
					id: 'credentials_menuitem',
					icon: 'admin_panel_settings',
					svgIcon: false,
					path: null,
					title: 'i18n.credentialManagement',
					display: true,
					action: (item) => {
						this.router.navigate(['credential-management']);
					},
				},
				{
					id: 'jremanager_menuitem',
					icon: 'extension',
					svgIcon: false,
					path: null,
					title: 'i18n.jreManager',
					display: true,
					action: (item) => {
						this.router.navigate(['versions']);
					},
				},
				{
					id: 'loggingconfig_menuitem',
					icon: 'tune',
					svgIcon: false,
					path: null,
					title: 'i18n.loggingConfigs',
					display: true,
					action: (item) => {
						this.router.navigate(['logging-configurations']);
					},
				},
				{
					id: 'translations_menuitem',
					icon: 'translate',
					svgIcon: false,
					path: null,
					title: 'i18n.translationManager',
					display: this.debugMode,
					action: (item) => {
						this.router.navigate(['translations']);
					},
				},
				{
					id: 'backup_menuitem',
					icon: 'save',
					svgIcon: false,
					path: null,
					title: 'Backup',
					display: true,
					action: (item) => {
						this.router.navigate([`/repository/backup`]);
					},
				},
				{
					id: 'restore_menuitem',
					icon: 'restore',
					svgIcon: false,
					path: null,
					title: 'Restore',
					display: true,
					action: (item) => {
						this.router.navigate([`/repository/restore`]);
					},
				},
				{
					id: 'artifactValidation_menuitem',
					icon: 'construction',
					svgIcon: false,
					path: null,
					title: 'i18n.ArtifactValidation',
					display: this.globalSrv.applicationConf.validationEnabled,
					action: (item) => {
						this.router.navigate([`/compile/compile-artifact-list`]);
					},
				},
				{
					id: 'reindex_menuitem',
					icon: 'ico-re-index',
					svgIcon: true,
					title: 'i18n.reindexRepository',
					display: this.globalSrv.canEdit(),
					children: false,
					action: (item) => {
						this.reindexRepository();
					},
				},
				{
					id: 'alertChannels_menuitem',
					icon: 'mail',
					svgIcon: false,
					path: null,
					title: 'i18n.AlertChannels',
					display: true,
					action: (item) => {
						this.router.navigate([`/alert/channel`]);
					},
				},
				{
					id: 'alerts_menuitem',
					icon: 'notifications',
					svgIcon: false,
					path: null,
					title: 'i18n.Alerts',
					display: true,
					action: (item) => {
						this.router.navigate([`/alert`]);
					},
				},
				{
					id: 'environment_variables_menuitem',
					icon: 'laptop',
					svgIcon: false,
					path: null,
					title: 'i18n.EnvironmentVariables',
					display: true,
					action: (item) => {
						this.router.navigate([`/environment-variables`]);
					},
				},
			],
		};
	}

	execute(item, event?) {
		event?.stopPropagation();

		if ((this.userMenuTrigger && item.action) || (this.notificationsMenuTrigger && item.action)) {
			item.action(item);
			this.userMenuTrigger.closeMenu();
			this.notificationsMenuTrigger.closeMenu();
		}
	}

	onSidebarCloseToggle() {
		this.isSidebarClosed = !this.isSidebarClosed;
	}

	onSidebarCollapseToggle() {
		this.isSidebarCollapsed = !this.isSidebarCollapsed;
	}

	onLogoClick(event) {
		event.stopPropagation();
	}

	onSelectItem() {
		this.isSidebarCollapsed = true;
	}

	helpLink() {
		return environment.apiHost + '/adapter/manual/index.html';
	}

	reindexRepository() {
		this.api.rebuildRepository().subscribe({
			next: (data) => {
				let message = data.partialText ? data.partialText : '';
				if (message.length !== 0) {
					let messagesList = JSON.parse(`[${message.replace(/}{/g, '},{')}]`);
					if (messagesList.length !== 0) {
						this.msgSrv.onLoading.next({command: 'progress', messages: messagesList});
					}
				}
			},
			error: (err) => {
				console.log('error = ' + JSON.stringify(err));
				this.msgSrv.onLoading.next({command: 'finish'});
			},
			complete: () => {
				console.log('STREAM COMPLETED --- BUILD FINISHED');
				this.msgSrv.onLoading.next({command: 'progress', messages: []});
				setTimeout(() => {}, 2000);
			},
		});
	}

	clearCache() {
		this.api.clearCache().subscribe({
			next: (data) => {
				console.log('cleared');
			},
			error: (err) => {
				console.log('error = ' + JSON.stringify(err));
			},
			complete: () => {
				console.log('cleared cached');
				this.msgSrv.onLoading.next({command: 'progress', messages: []});
				setTimeout(() => {}, 2000);
			},
		});
	}

	gotoNotification(notification?: any) {
		if (!notification) {
			this.router.navigate(['notifications']);
		} else {
			switch (notification.eventType) {
				case 'InstanceError':
					this.router.navigate([`status/${notification.instanceId.id}:${notification.instanceId.version}/deployments`]);
					break;
			}
		}

		this.notificationsMenuTrigger.closeMenu();
	}

	dismissNotification(event, notification: i40Notification) {
		event.stopPropagation();

		this.msgSrv.customDialogMessage(
			this.translate.instant('i18n.dismissNotification'),
			this.translate.instant('i18n.dismissNotificationText'),
			[new Button(this.translate.instant('i18n.cancel'), 'cancel', 'flat', 'accent', 'end'), new Button(this.translate.instant('i18n.dismiss'), 'dismiss', 'flat', 'accent', 'end')],
			{
				dismiss: (dialogRef, resolve) => {
					notification.read = true;
					this.notificationsSrv.dismissNotification([notification.id]).subscribe((res) => {
						if (this.router.url === '/notifications') {
							this.notificationsSrv.refreshRequired.next('wrapper');
						}

						this.non_read_notifications = this.nonReadNotifications();

						dialogRef.close();
						resolve(true);
					});
				},
				cancel: (dialogRef, resolve) => {
					dialogRef.close();
					resolve(false);
				},
			},
		);
	}

	nonReadNotifications() {
		return this.notifications.filter((n) => !n.read);
	}

	ngOnInit() {
		this.subscriptions = this.globalSrv.debugMode$.subscribe((isDebugMode) => {
			this.debugMode = isDebugMode;
			this.setUserMenuItems();
		});

		this.translate.use(this.authSrv.userData.languageCode);

		// block keyboard when blocking UI
		document.onkeydown = (e) => {
			return !this.blockUI.isActive;
		};

		this.msgSrv.onLoading.subscribe((event: any) => {
			switch (event.command) {
				case 'start':
					this.blockUI.start(event.message);
					break;
				case 'stop':
					this.blockUI.stop();
					break;
				case 'update':
					this.blockUI.update(event.message);
					break;
				case 'progress':
					if (event.messages?.length !== 0) {
						this.messages = event.messages;
					} else {
						this.progressLog.finished = true;
						this.blockUI.stop();
					}
					break;

				case 'finish':
					this.blockUI.stop();
					this.messages = [];
					this.artifactInfo = [];
					break;
			}
		});

		forkJoin([this.getNotifications(), this.getActivePlugins()]).subscribe({
			next: ([notifications, plugins]) => {
				this.getNotificationActions();
				this.pluginSrv.plugins.next(plugins);
				this.blockUI.stop();
			},
			error: (err) => {
				this.blockUI.stop();
			},
		});
	}

	ngOnDestroy() {
		// this.notificationsSrv.globalStream.close();
		this.subscriptions.unsubscribe();
	}

	getNotificationActions() {
		this.notificationsSrv.getNotificationActionEvents().subscribe({
			next: (messages: NotificationActionEvent[]) => {
				messages.map((m) => {
					if (m.action === 'dismiss') {
						m.notificationId.forEach((id) => {
							this.notifications.forEach((n, index) => {
								if (n.id === id) this.notifications[index].read = true;
							});
						});
					}

					if (m.action === 'delete') {
						m.notificationId.forEach((id) => {
							this.notifications.forEach((n, index) => {
								if (n.id === id) this.notifications.splice(index, 1);
							});
						});
					}
					this.ngZone.run(() => (this.non_read_notifications = this.nonReadNotifications().reverse()));
				});
			},
		});
	}
}
