<div mat-dialog-title style="margin: 0; display: flex; flex-direction: column; align-items: stretch; background-color: var(--bg-color-toolbar); padding: 15px; color: var(--text-color-over-primary)">
	<div style="display: flex; flex-direction: row; align-items: center; width: 100%">
		<img src="../assets/amorph-pro-dark.png" style="height: 1.5em" />
		<span fxFlex="100"></span>
		<span style="font-weight: 100">{{ 'i18n.smart' | translate }}</span>
		<span style="font-weight: bold">{{ 'i18n.unifier' | translate }}</span>
	</div>
	<div>{{ 'i18n.licenseInformation' | translate }}</div>
</div>
<div style="padding: 15px; display: flex; flex-direction: column; justify-content: center; flex: 1 0 auto">
	<div style="display: flex; flex-direction: column; flex: 1 0 auto" *ngIf="mode === 'activation'" [formGroup]="licenseForm">
		<mat-form-field>
			<mat-label>License number</mat-label>
			<input matInput [formControl]="licenseNumberControl" id="license_number_input" required />
			<mat-error *ngIf="licenseNumberControl.errors?.message">{{ licenseNumberControl.errors.message }}</mat-error>
		</mat-form-field>

		<mat-checkbox [checked]="offlineActivationVisible" (change)="toggleOfflineActivation()" [disabled]="licenseNumberControl.value.length < 29">Offline Activation&nbsp;</mat-checkbox>

		<mat-progress-bar mode="indeterminate" *ngIf="gettingLicenseData"></mat-progress-bar>

		<ng-container *ngIf="offlineActivationVisible">
			<mat-divider style="margin-top: 8px; margin-bottom: 8px"></mat-divider>
			<mat-form-field>
				<mat-label>License data</mat-label>
				<input matInput [formControl]="licenseDataControl" />
				<mat-icon matSuffix (click)="copyLicenseData()" class="clickable-element" style="font-size: 1.2em; height: 1.2em; width: 1.2em; position: relative; bottom: -4px">content_copy</mat-icon>
			</mat-form-field>

			<div>Please visit: <a href="https://licensing.amorph.pro" target="_blank" class="clickable-element">https://licensing.amorph.pro</a> to activate using the code above.</div>

			<mat-form-field>
				<mat-label>Activation data</mat-label>
				<input matInput [formControl]="activationDataControl" required />
				<mat-error *ngIf="activationDataControl.errors?.message">{{ activationDataControl.errors.message }}</mat-error>
			</mat-form-field>
		</ng-container>
	</div>

	<div fxLayout="row" style="width: 100%" *ngIf="mode === 'activation'">
		<button mat-raised-button id="activate_button" class="mat-accent" (click)="activateLicense()" [disabled]="licenseForm.invalid || gettingLicenseData">{{ 'i18n.activate' | translate }}</button>
		<div fxFlex></div>
		<button mat-raised-button class="mat-primary" (click)="cancelActivation()">{{ 'i18n.cancel' | translate }}</button>
	</div>

	<div style="display: flex; flex-direction: column; flex: 1 0 auto" *ngIf="mode === 'license'">
		<mat-list class="license-details-wrapper">
			<mat-list-item role="listitem"
				><b>License Number:&nbsp;</b
				><span style="flex: 1 1 auto; overflow: hidden" [style]="licenseNumberVisible ? '' : 'font-size: 1.8em; margin-top: 3px;'">{{
					licenseNumberVisible
						? authSrv.license.licenseNumber
						: '&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;'
				}}</span
				><mat-icon matSuffix (click)="toggleLicenseVisibility()" class="clickable-element">{{ licenseNumberVisible ? 'visibility_off' : 'visibility' }}</mat-icon></mat-list-item
			>
			<mat-list-item role="listitem"
				><b>License Type:&nbsp;</b
				><span style="flex: 1 0 auto" class="license-status-{{ authSrv.license.licenseActivationData.licenseType | lowercase }}">{{
					authSrv.license.licenseActivationData.licenseType
				}}</span></mat-list-item
			>
			<mat-list-item role="listitem"
				><b>Expires on:&nbsp;</b
				><span style="flex: 1 0 auto" class="license-status-{{ authSrv.license.licenseActivationData.licenseType | lowercase }}">{{
					authSrv.license.licenseActivationData.validTo | date
				}}</span></mat-list-item
			>
			<mat-list-item role="listitem"
				><b>No. of deployments:&nbsp;</b><span style="flex: 1 0 auto">{{ authSrv.license.licenseActivationData.deployments }}</span></mat-list-item
			>
		</mat-list>
	</div>

	<mat-expansion-panel expanded="false" style="margin-top: 15px" *ngIf="globalSrv.debugMode">
		<mat-expansion-panel-header>DEBUG LICENSE</mat-expansion-panel-header>
		<pre>{{ authSrv.license | json }}</pre>
	</mat-expansion-panel>

	<mat-expansion-panel expanded="false" style="margin-top: 15px" *ngIf="globalSrv.debugMode">
		<mat-expansion-panel-header>DEBUG FORM [{{ licenseForm.invalid ? 'INVALID' : 'VALID' }}]</mat-expansion-panel-header>
		<pre>{{ licenseForm.value | json }}</pre>
	</mat-expansion-panel>

	<div fxLayout="row" style="width: 100%" *ngIf="mode === 'license'">
		<div fxFlex fxFlexAlign="start">
			<button mat-raised-button id="update_license_button" class="mat-accent" (click)="changeLicense()">{{ 'i18n.updateLicense' | translate }}</button>
		</div>
		<div fxFlex></div>
		<div fxFlex fxFlexAlign="end">
			<button mat-raised-button mat-dialog-close id="close_license_dialog_button" style="float: right; margin: 2px 2px 2px" class="mat-primary">{{ 'i18n.close' | translate }}</button>
		</div>
	</div>
</div>
