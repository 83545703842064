import {HttpBackend, HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {Router} from '@angular/router';
import {I40License, I40User} from '../apps/users/user-management.model';
import * as moment from 'moment';
import {catchError, map} from 'rxjs/operators';
import {of} from 'rxjs';

@Injectable()
export class AuthService {
	public isLogged = false;

	public permissions: any = {admin: false, read: false, write: false};
	public canEdit: boolean;

	public userData: I40User = null;
	public license: I40License = null;

	public licenseWarning = false;
	public expirationWarning = false;
	public expirationWarningBefore = moment.duration(2, 'week');
	public expirationCounter = 0;
	public expired = false;
	public nolicense = false;

	constructor(
		private http: HttpClient,
		private http_back: HttpBackend,
		public router: Router,
	) {}

	signOut() {
		this.router.navigate(['login']);
		this.isLogged = false;
		this.updateLoggedUser(null);
	}

	accessDenied() {
		sessionStorage.setItem('loginCallbackURL', window.location.pathname);
		this.signOut();
	}

	updateLoggedUser(user) {
		if (user) {
			this.isLogged = true;
			this.setPermissionsByRole(null);
			user.roles.forEach((role) => {
				this.setPermissionsByRole(role);
			});
			this.canEdit = this.permissions.admin || this.permissions.write || window.location.pathname.includes('/users/account/changePassword/');
			this.userData = user;

			localStorage.setItem('i40UserData', user);
		} else {
			this.isLogged = false;
			this.setPermissionsByRole(null);
			this.canEdit = false;
			this.userData = null;

			localStorage.removeItem('i40UserData');
		}
	}

	parseLicense(license: any) {
		this.license = new I40License(license);
		this.expired = !this.checkLicenseExpiration();
		this.licenseWarning = this.license.licenseActivationData.licenseType !== 'PRODUCTIVE' && this.license.licenseActivationData.licenseType !== 'DEVELOPER';
		this.nolicense = this.license.licenseActivationData.licenseType == 'NO';

		if (this.license.licenseActivationData.licenseType !== 'NO') {
			this.expirationWarning = Math.ceil(moment.duration(moment(this.license.licenseActivationData.validTo).diff(moment())).asDays()) <= this.expirationWarningBefore.asDays();
			if (this.expirationWarning) {
				this.expirationCounter = Math.ceil(moment.duration(moment(this.license.licenseActivationData.validTo).diff(moment())).asDays());
			}
		}
	}

	checkLincensedDeploymentsLimit(current: number) {
		return this.license.licenseActivationData.deployments > current;
	}

	checkLicenseExpiration() {
		return new Date().getTime() < this.license.licenseActivationData.validTo;
	}

	setPermissionsByRole(role) {
		switch (role) {
			case 'Administrator':
				this.permissions.admin = true;
				break;
			case 'Reader':
				this.permissions.read = true;
				break;
			case 'Writer':
				this.permissions.write = true;
				break;
			default:
				this.permissions = {admin: false, read: false, write: false};
				break;
		}
	}

	forceReCeheckUser() {
		return this.checkUser().pipe(
			map((res: any) => {
				const user = res['user'];
				const license = res?.['license'];

				if (user) {
					this.updateLoggedUser(user);
					this.parseLicense(license);

					return true;
				} else {
					this.accessDenied();
					return false;
				}
			}),
			catchError((err) => {
				this.accessDenied();
				return of(false);
			}),
		);
	}

	// API CALLS
	login(user: any) {
		const http_no_intercept = new HttpClient(this.http_back);
		return http_no_intercept.post(`${environment.apiHost + environment.apiUrl}/login`, user, {withCredentials: true});
	}

	logout() {
		return this.http.get(`${environment.apiHost + environment.apiUrl}/logout`, {withCredentials: true});
	}

	checkUser() {
		const http_no_intercept = new HttpClient(this.http_back);
		return http_no_intercept.get(`${environment.apiHost + environment.apiUrl}/users/current`, {withCredentials: true});
	}

	getLicense() {
		const http_no_intercept = new HttpClient(this.http_back);
		return http_no_intercept.get(`${environment.apiHost + environment.apiUrl}/license`, {withCredentials: true});
	}

	getLicenseData(licenseKey: string) {
		const http_no_intercept = new HttpClient(this.http_back);
		return http_no_intercept.get(`${environment.apiHost + environment.apiUrl}/license-data/${licenseKey}`, {withCredentials: true});
	}

	licenseActivateOnline(licenseKey: string) {
		return this.http.post(`${environment.apiHost + environment.apiUrl}/activate-online`, {licenseKey}, {withCredentials: true});
	}

	licenseActivateOffline(licenseKey: string, activationData: string) {
		return this.http.post(`${environment.apiHost + environment.apiUrl}/activate-offline`, {licenseKey, activationData}, {withCredentials: true});
	}
}
