import {ArtifactInfo, Identifier, Root, ValidationStatus} from '../../data-models/core-model.model';

export class ChannelManagerResult extends Root {
	_className = 'ChannelManagerResult';

	info: ArtifactInfo = new ArtifactInfo();
	model: ArtifactInfo = new ArtifactInfo();
	channelType: ArtifactInfo = new ArtifactInfo();
	channelTypeConfigurations: LayerConfiguration[] = [];

	constructor(input?: {info?: any; model?: any; channelType?: any; channelTypeConfigurations?: any}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}

	artifactIdentifier(): Identifier {
		return this.info.identifier;
	}

	setChannelTypeConfigurations(ctc, parent) {
		if (ctc) {
			this.channelTypeConfigurations = ctc.map((c: any) => {
				return new LayerConfiguration(c, parent);
			});
		}
	}

	setAutoUpdatedFields(fields) {
		this._autoUpdateFields = fields.filter((f) => !['channelType'].includes(f));
	}

	serializeChannelTypeConfigurations() {
		return this.channelTypeConfigurations.map((ct) => {
			return ct.serialize();
		});
	}

	channelTypeConfigurationsToFormControl() {
		return this.rootArrayToFormControl(this.channelTypeConfigurations);
	}

	serialize() {
		const output: any = super.serialize();

		output.version = output.info.identifier.version;

		return output;
	}
}

export class LayerConfiguration extends Root {
	_className = 'LayerConfiguration';

	id: string = '';
	implementationTypeName: string = '';
	configuration: string = '{}';

	constructor(input?: {id?: string; implementationTypeName?: string; configuration?: string}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}
	}

	setConfiguration(conf, parent) {
		if (conf) {
			this.configuration = conf.replace(/Variable\/Parameters/g, 'CommandParameters/Parameters').replace(/Variable\/Reply/g, 'CommandReply/Reply');
		}
	}

	serializeConfiguration() {
		return this.configuration.replace(/CommandParameters\/Parameters/g, 'Variable/Parameters').replace(/CommandReply\/Reply/g, 'Variable/Reply');
	}
}

export class ChannelInfo extends ArtifactInfo {
	_className = 'ChannelInfo';

	model: ArtifactInfo = new ArtifactInfo({}, this);

	constructor(input?: {identifier?: any; externalIdentifier?: any; externalDescriptor?: any; model?: any; version?: string; status?: ValidationStatus}, parent?: Root, inherited: boolean = false) {
		super(input, parent);
		if (!inherited) {
			super.init(input);
		}

		this.status = new ValidationStatus(input.status, this, false);
	}
}
