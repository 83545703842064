<div fxLayout="column">
	<div class="model_path_check_wrapper">
		<mat-checkbox [checked]="checked" (change)="onToggleArray($event)" class="{{ treeSrv.getId(property, 'checkbox') }}" [disabled]="!globalSrv.canEdit(treeSrv.rootParent)">{{
			property.description
		}}</mat-checkbox>
		<app-group
			style="margin-top: 8px"
			*ngIf="checked"
			[title]="items.description"
			[ngClass]="{hidden_form_element: noVisibleChildren}"
			[selectedObject]="selectedObject"
			[properties]="items.properties"
			[savedValue]="savedValue ? savedValue[index] : null"
			[emptyFormValue]="emptyFormValue ? emptyFormValue[index] : null"
			[requiredChildren]="items.required"
			[form]="getFormGroup(index)"
			[expanded]="true"
			[collapsible]="false"
			[showContainer]="false"
			(visible)="subgroupVisible($event)"
		></app-group>
	</div>
</div>
